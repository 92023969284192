import { createGlobalStyle, css } from 'styled-components';
import {
	MaterialIconsOutlined,
	MaterialIconsRegular,
	MaterialIconsRound,
	MaterialIconsTwoTone,
} from './fonts/MaterialIcons';
import {
	MontserratMedium,
	MontserratRegular,
	MontserratSemiBold,
} from './fonts/Montserrat';
import { RobotoMedium, RobotoRegular } from '@Assets/fonts/Roboto';

export type MaterialIconsType =
	| 'Regular'
	| 'Outlined'
	| 'Round'
	| 'Sharp'
	| 'TwoTone';
type FontFormat = 'ttf' | 'otf';
export type Font = 'Montserrat' | 'Roboto';
type FontWeight =
	| '100'
	| '200'
	| '300'
	| '400'
	| '500'
	| '600'
	| '700'
	| '800'
	| '900'
	| 'normal'
	| 'light'
	| 'lighter'
	| 'bold'
	| 'bolder';

type Fonts = {
	[key in Font]?: {
		[key in FontWeight]?: string;
	};
};
type MaterialIconFontFace = {
	[key in MaterialIconsType]?: {
		src: string;
		mapped_name?: string;
		format?: FontFormat;
	};
};

const material_icons: MaterialIconFontFace = {
	Regular: {
		src: MaterialIconsRegular,
		mapped_name: '',
	},
	Outlined: {
		src: MaterialIconsOutlined,
		mapped_name: 'Outlined',
		format: 'otf',
	},
	TwoTone: {
		src: MaterialIconsTwoTone,
		mapped_name: 'TwoTone',
		format: 'otf',
	},

	Round: {
		src: MaterialIconsRound,
		mapped_name: 'Round',
	},
};

const fonts: Fonts = {
	Montserrat: {
		normal: MontserratRegular,
		'500': MontserratMedium,
		'600': MontserratSemiBold,
	},
	Roboto: {
		normal: RobotoRegular,
		'500': RobotoMedium,
	},
};

const generateMaterialIconsFontFaces = () => {
	let css_string = '';
	const familyBaseName = 'Material Icons';
	const getTypeName = (type: string) => {
		return material_icons[type as MaterialIconsType]?.mapped_name ?? ` ${type}`;
	};

	Object.keys(material_icons).forEach((type) => {
		const src = material_icons[type as MaterialIconsType]?.src;
		const typeFamilyName = `${familyBaseName}${
			getTypeName(type).length > 0 ? ` ${getTypeName(type)}` : ''
		}`;
		const typeClassName = `.material-icons${`${
			getTypeName(type).length > 0 ? '-' : ''
		}${getTypeName(type).toLocaleLowerCase()}`}`;
		const typeCss = `
      @font-face {
        font-family: "${typeFamilyName}";
        font-style: normal;
        font-weight: 400;
        src: url(${src}) ;
      }
      ${typeClassName}{
        font-family: "${typeFamilyName}";
        font-weight: normal;
        font-style: normal;
        // font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
      }
    `;
		css_string += typeCss;
	});
	return css`
		${css_string}
	`;
};

const generateFont = (font: Font) => {
	const weights = fonts[font];
	if (!weights) return '';
	let returnCssKeys = '';
	Object.keys(weights).forEach((weight) => {
		const css_string = css`
			@font-face {
				font-family: '${font}';
				font-weight: ${weight};
				src: url(${weights[weight as FontWeight]});
			}
		`;
		if (css_string.length > 0) {
			returnCssKeys += css_string.join('');
		}
	});
	return returnCssKeys;
};

const GlobalStyles = createGlobalStyle`
p{
  margin : 0;
}
  ${generateMaterialIconsFontFaces()}

  ;
  ${generateFont('Montserrat')}

  ;
  ${generateFont('Roboto')}

  ;
  label {
    text-transform: capitalize !important;

    &.required {
      position: relative;

      &::after {
        content: " *";
        font-size: 20px;
        font-weight: bold;
        color: red;
        position: absolute;
        right: -10px;
        top: -3px;
        margin: auto;
        /* vertical-align:-webkit-baseline-middle; */
      }
    }
  }

  td {
    max-width: 80px;
    align-items: center;
    margin: 0 auto;
    white-space: nowrap;
	  text-overflow: ellipsis;
	  overflow: hidden;
  }
  
  .clickable {
    cursor: pointer;
    opacity: 0.95;
    transition: 0.3s ease;

    &:hover {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      opacity: 1;
      transition: 0.3s ease;

    }
  }

  .popover {
    max-width: 450px;

  }

  .point {
    cursor: pointer;
  }


  .full-window {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-container {
    display: flex;
    flex-direction: column;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  .component-loader-parent {
    position: relative;
    /* overflow:hidden; */
  }

  .classroom_head__container,
  .role_head__container {
    display: flex;
    justify-content: flex-end;
  }

  .filters-style {
    width: 100px;
    margin-right: 25px;
  }

  /* snackbar styling */
  .mdc-snackbar {
    &.mdc-snackbar--leading {
      right: 0 !important;
      left: auto !important;
    }

    &.success .mdc-snackbar__surface {

      background-color: #28a745;
    }

    &.error .mdc-snackbar__surface {
      background-color: #dc3545;
    }

    .mdc-snackbar__action {
      background-color: #343a40;
      border: none;
      border-radius: 5px;
      padding: 6px;
      color: #FFF;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    }
  }

  strong {
    font-weight: bold !important;
  }

  /* override */
  .has-danger::after {
    content: "";
    display: none;
  }

   iframe {
    pointer-events : ${(p) =>
			process.env.NODE_ENV === 'development' ? 'none' : ''}
  }

  html, body, #root {
    font-family: 'Montserrat' !important;
    width: 100%;
    min-height: 100vh;
    height: 100%;


  }

  :root {

    font-size: 12.5px;
    body: {
      font-size: 12.5px;

    };
       ${({ theme }) => `${theme.breakpoints.up('md')}{
    font-size : 10px;
    body {
      font-size : 10px;

    }
  }`};
    ${({ theme }) => `${theme.breakpoints.up('lg')}{
    font-size : 12.5px;
    body {
      font-size : 12.5px;

    }
  }`}; 
  ${({ theme }) => `${theme.breakpoints.up('xl')}{
    font-size : 16px;
    body {
      font-size : 16px;

    }
  }`}
  }
`;

export default GlobalStyles;
