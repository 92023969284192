import React from 'react';
import styled from 'styled-components';
import { FiChevronDown } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { useDisclosure } from '@Hooks/useDisclosure';
import { ContentConfig } from '@eduact/utils';
import ConfirmationPopup from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/ConfirmationPopup';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
// import dayjs from 'dayjs'
import {
	useAnswerQuestionMutation,
	useDeleteDiscussionQuestionMutation,
	useUpdateQuestionAnswerMutation,
} from '@Features/classrooms/instructorQuestionsAndAnswersApi';
import normalSweetAlert from '@Utils/normalSweetAlert';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuestionsAndAnswersContext } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/QuestionsAndAnswers/QuestionsAndAnswers';
import { QuestionTypes } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/QuestionsAndAnswers/QuestionsAndAnswersConstants';
import moment from 'moment';

type FormData = {
	content: string;
};

type Props = {
	question: DiscussionQuestion;
};

const schema = yup.object({
	content: yup.string().required('answer field is required'),
});

const Question: React.FC<Props> = ({ question }) => {
	const { queryParams } = useQuestionsAndAnswersContext();
	const accordionDisclosure = useDisclosure({
		defaultIsOpen: queryParams.type === QuestionTypes.unanswered,
	});
	const deletePopupDisclosure = useDisclosure();
	const [deleteQuestionMutation] = useDeleteDiscussionQuestionMutation();
	const [answerQuestionMutation] = useAnswerQuestionMutation();
	const [updateAnswerMutation] = useUpdateQuestionAnswerMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		mode: 'onTouched',
		reValidateMode: 'onChange',
		defaultValues: {
			content: question.solved ? question.replies[0].content : '',
		},
		resolver: yupResolver(schema),
	});

	const onSubmit = async ({ content }: FormData) => {
		if (question.solved) {
			try {
				const response = await updateAnswerMutation({
					reply_id: question.replies[0].id,
					content,
				}).unwrap();
				normalSweetAlert(response?.message ?? 'reply updated successfully');
			} catch (e) {
				const error = e as QueryError;
				normalSweetAlert(
					error?.data?.error ?? 'could not update reply',
					'error'
				);
			}
		} else {
			try {
				const response = await answerQuestionMutation({
					content,
					discussion_id: question.id,
				}).unwrap();
				normalSweetAlert(
					response?.message ?? 'question is answered successfully'
				);
			} catch (e) {
				const error = e as QueryError;
				normalSweetAlert(
					error?.data?.error ?? 'could not answer question',
					'error'
				);
			}
		}
	};

	const deleteQuestionHandler = async () => {
		try {
			const response = await deleteQuestionMutation({
				discussion_id: question.id,
			}).unwrap();
			normalSweetAlert(
				response?.message ?? 'question is deleted successfully',
				'success'
			);
			deletePopupDisclosure.onClose();
		} catch (e) {
			const error = e as QueryError;
			normalSweetAlert(
				error?.data?.error ?? 'could not delete question',
				'error'
			);
			deletePopupDisclosure.onClose();
		}
	};

	return (
		<Container>
			<AccordionTriggerIcon
				size={22}
				onClick={accordionDisclosure.toggle}
				isOpen={accordionDisclosure.isOpen}
			/>
			<DeleteIcon size={22} onClick={deletePopupDisclosure.onOpen} />
			<QuestionSectionContainer>
				<UserInfoContainer>
					<Username>{question.user.username}</Username>
					<FullName>{`${question.user.first_name} ${question.user.last_name}`}</FullName>
				</UserInfoContainer>
				<QuestionText>{question.content}</QuestionText>
				<TimeText>
					{moment(question.updated_at).format('DD/MM/YYYY hh:mm A')}
				</TimeText>
				{accordionDisclosure.isOpen && (
					<form onSubmit={handleSubmit(onSubmit)}>
						<AnswerForm>
							<AnswerInput
								placeholder="type your answer here"
								isInvalid={!!errors.content}
								{...register('content')}
							/>
							<SubmitButton isDisabled={!!errors.content}>Submit</SubmitButton>
						</AnswerForm>
					</form>
				)}
			</QuestionSectionContainer>

			<Divider />

			<QuestionInfoSection>
				<CourseName>{question.course.name}</CourseName>

				<Unit>
					<EdIcon>
						{
							ContentConfig[
								question.unit.unitType.name as 'video' | 'webinar' | 'document'
							].mdIcon
						}
					</EdIcon>
					<UnitType>{question.unit.name}</UnitType>
				</Unit>
			</QuestionInfoSection>

			{deletePopupDisclosure.isOpen && (
				<ConfirmationPopup
					title="are you sure you want to delete this question?"
					confirmButtonText="Yes"
					cancelButtonText="No"
					onConfirm={deleteQuestionHandler}
					{...deletePopupDisclosure}
				/>
			)}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	display: flex;
	gap: 18px;
	margin: 16px 0;
	padding: 20px 32px;
	background-color: white;
	box-shadow: 0 3px 6px #00000029;
	border: 1px solid #f5f5f5;
	border-radius: 5px;
`;

const AccordionTriggerIcon = styled(FiChevronDown)<{ isOpen?: boolean }>`
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;
	transform: ${(props) =>
		props?.isOpen ? 'rotateX(0.5turn)' : 'rotateX(0turn)'};
`;

const DeleteIcon = styled(MdDelete)`
	position: absolute;
	bottom: 16px;
	right: 16px;
	cursor: pointer;
`;

const UserInfoContainer = styled.div`
	display: flex;
	gap: 32px;
	align-items: center;
`;

const Username = styled.p`
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0.14px;
	color: #251b33;
`;

const FullName = styled.p`
	letter-spacing: 0.12px;
	color: #a4a4a4;
`;

const QuestionText = styled.p`
	margin-top: 18px;
	font-weight: 600;
	letter-spacing: 0.12px;
	color: #2f2e41;
`;

const TimeText = styled.p`
	letter-spacing: 0.09px;
	color: #a4a4a4;
	margin-left: auto;
	margin-bottom: 10px;
	margin-top: 10px;
	width: fit-content;
`;

const AnswerForm = styled.div`
	display: flex;
	gap: 14px;
`;

const AnswerInput = styled.input<{ isInvalid: boolean }>`
	width: 100%;
	border: 1px solid ${(props) => (props.isInvalid ? 'red' : '#C4C4C4')};
	border-radius: 5px;
	font-size: 16px;
	padding: 8px 12px;
`;

const SubmitButton = styled.button<{ isDisabled: boolean }>`
	text-transform: uppercase;
	width: 68px;
	background-color: transparent;
	border: 1px solid #ff9800;
	color: #ff9800;
	border-radius: 6px;
	cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
	opacity: ${(props) => (props.isDisabled ? 0.65 : 1)};

	:hover {
		background-color: #ff9800;
		color: white;
	}
`;

const QuestionSectionContainer = styled.div`
	width: 100%;
`;

const Divider = styled.div`
	border-left: 1px solid #c4c4c4;
	margin-top: 16px;
`;

const QuestionInfoSection = styled.div`
	width: 300px;
	margin-top: 16px;
`;

const CourseName = styled.p`
	letter-spacing: 0.12px;
	color: #251b33;
	font-weight: 600;
`;

const Unit = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	margin-top: 10px;
	color: #a4a4a4;
`;

const UnitType = styled.p`
	font-size: 14px;
	letter-spacing: 0.11px;
`;

const UnitImage = styled.img``;

export default Question;
