import { Box, Button, Modal } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { SequenceManagementRequester } from '../Services/Sequence.requester';
import { useDispatch } from 'react-redux';
import { getAllCourses } from '@Features/classrooms/classroomSlice';
import useQueryURL from '@Hooks/useQueryUrl';
import { FlexLayout } from '@Styled/utilities';
import { usePageLoader } from '@Providers/LoadingProvider';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
	openModel: boolean;
};

const SyncUsersModel: React.FC<Props> = ({ handleModelClose, openModel }) => {
	const { label } = useParams<{ label: string }>();
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { selectedQuery } = useQueryURL();
	const { setIsLoading } = usePageLoader();

	const SyncUsers = async () => {
		try {
			setIsLoading(true);

			await SequenceManagementRequester.getInstance().syncUsers(label);
			displaySnackbar('success', `Users synced successfully`);
			dispatch(
				getAllCourses({
					page: 1,
					perPage: 10000,
					filters: [],
					query: selectedQuery,
					sortBy: null,
					classroom: label,
				})
			);
			handleModelClose(false);
			setIsLoading(false);

		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Change User Slot ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			<Modal
				open={openModel}
				onClose={() => {
					handleModelClose(false);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Container>
					<Header>Are you sure you want to sync students....?</Header>
					<FlexLayout justifyContent="flex-end">
						<Button
							variant="text"
							color="warning"
							onClick={() => {
								handleModelClose(false);
							}}
						>
							No
						</Button>
						<Button
							variant="text"
							color="primary"
							onClick={() => {
								SyncUsers();
							}}
						>
							Yes
						</Button>
					</FlexLayout>
				</Container>
			</Modal>
		</>
	);
};

export const Container = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 17.5rem;
	height: 8.5rem;
	border-radius: 4px;
	border: 0;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);
	margin: 3px 83.2px 0px 0;
	padding: 24px 24px 0 24px;
	background-color: #ffffff;
	outline: none;
	padding: 0.938rem 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const Header = styled.p`
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.43;
	letter-spacing: 0.25px;
	text-align: left;
	color: rgba(0, 0, 0, 0.6);
	margin-top: 0.938rem;
`;

export default SyncUsersModel;
