import { Typography } from '@mui/material';
import { Button, Modal } from '@mui/material';
import { FlexLayout, GridLayout } from '@Styled/utilities';
import moment from 'moment';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { AssignmentSubmission } from '../../Types/Submissions.types';

type Props = {
	submission: AssignmentSubmission | undefined;
	onClick: (submission: AssignmentSubmission) => void;
};

const AssignmentCard: FC<Props> = ({ submission, onClick }) => {
	return (
		<Card>
			<GridWrapper
				gridTemplateColumns="1.3fr 1fr 1fr 0.5fr"
				verticalAlign="middle"
			>
				<FlexLayout
					flexDirection="column"
					alignItems="flex-start"
					gridGap="1.875rem"
				>
					<BoldText>{submission?.user.username}</BoldText>
					<GrayText>
						{submission?.user.first_name} {submission?.user.last_name}
					</GrayText>
				</FlexLayout>
				<FlexLayout
					flexDirection="column"
					alignItems="flex-start"
					gridGap="1.875rem"
				>
					<BoldText>{submission?.assignment.unit.course.name}</BoldText>
					<GrayText>{submission?.assignment.unit.name}</GrayText>
				</FlexLayout>
				<FlexLayout
					flexDirection="column"
					alignItems="flex-start"
					gridGap="1.875rem"
				>
					<FlexLayout flexDirection="row" gridGap="0.5rem" alignItems="center">
						{/* <BoldText>Questions :</BoldText> */}
						<GrayText>
							{moment(submission?.updated_at).format('DD/MM/Y hh:mm A')}
						</GrayText>
					</FlexLayout>
					{submission?.score && (
						<FlexLayout gridGap={'0.5rem'}>
							<Typography>
								Score {submission.score}/{submission.assignment.overall_score}
							</Typography>
							<Typography
								color={submission.status === 'passed' ? 'green' : 'red'}
							>
								{submission.status}
							</Typography>
						</FlexLayout>
					)}
				</FlexLayout>
				<FlexLayout flexDirection="column" alignItems="flex-end">
					<ViewButton
						onClick={() => submission && onClick(submission)}
						variant="outlined"
						color="primary"
					>
						View
					</ViewButton>
				</FlexLayout>
			</GridWrapper>
		</Card>
	);
};

const Card = styled.div`
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #f5f5f5;
	border-radius: 5px;
	padding: 1.188rem 2rem;
	margin-bottom: 1rem;
`;
const GridWrapper = styled(GridLayout)`
	align-items: center;
`;
const ViewButton = styled(Button)`
	min-width: 3.5rem;
	width: 3.5rem;
	border-radius: 4px;
	font-size: 0.813rem;
	font-weight: 600;
`;

const BoldText = styled.p`
	font-size: 1.125rem;
	font-weight: 600;
	color: #251b33;
`;
const GrayText = styled.p`
	font-size: 1.125rem;
	font-weight: 500;
	color: #a4a4a4;
`;

export default AssignmentCard;
