import { FlexLayout } from '@eduact/ed-system';
import { DateFormatter } from '@eduact/utils';
import {
	Button,
	Dialog,
	DialogProps,
	Modal,
	ModalProps,
	Typography,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import {
	Submissions,
	SubmitAttemptQuestionPayload,
} from '../Types/Submissions.types';
import moment from 'moment';
import { Spacer } from '@Styled/utilities';
import { Divider } from '@mui/material';
import AttemptQuestion, { AnswerSchema } from './AttemptQuestion';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { array, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import { SubmissionsRequester } from '../Services/Submissions.requester';

type Props = { children?: any; submissionAttempt: Submissions } & Omit<
	DialogProps,
	'children'
>;

const schema = object({
	answers: array(AnswerSchema),
});
const AttemptModal: React.VoidFunctionComponent<Props> = ({
	open,
	children,
	submissionAttempt,
	...props
}) => {
	const { control, ...formProps } = useForm<{
		answers: Array<SubmitAttemptQuestionPayload>;
	}>({
		resolver: yupResolver(schema),
		defaultValues: {
			answers: submissionAttempt.answers.map((answer) => ({
				attempt_id: answer.test_attempt_id,
				question_id: answer.question.id,
				score: answer.score,
				weight: answer.question.weight,
				submitted: !!answer.score,
			})),
		},
	});

	const { fields, update } = useFieldArray({
		control,
		name: 'answers',
	});

	const {
		formState: { isValid },
	} = formProps;

	const handleSubmitAttempt = async () => {
		try {
			await SubmissionsRequester.getInstance().submitAttempt({
				attempt_id: submissionAttempt.id,
			});
			props.onClose?.({}, 'backdropClick');
		} catch (error) {}
	};
	return (
		<Dialog open={open} {...props} fullWidth maxWidth="lg">
			<DevTool control={control} />
			<DialogWrapper>
				<FlexLayout mb={'1.25rem'} justifyContent={'space-between'}>
					<FlexLayout flexDirection={'column'}>
						<Typography fontWeight={600}>
							{submissionAttempt.user.username}
						</Typography>
						<Spacer mb="1.875rem" />
						<Typography color={'GrayText'}>
							{submissionAttempt.user.first_name}{' '}
							{submissionAttempt.user.first_name}
						</Typography>
					</FlexLayout>
					<FlexLayout flexDirection={'column'}>
						<Typography fontWeight={600}>
							{submissionAttempt.test.unit.course.name}
						</Typography>
						<Spacer mb="1.875rem" />
						<Typography color={'GrayText'}>
							{submissionAttempt.test.unit.name}
						</Typography>
					</FlexLayout>
					<FlexLayout flexDirection={'column'}>
						<Typography color={'GrayText'}>
							No. of Questions {submissionAttempt.answersCount}
						</Typography>
						<Spacer mb="1.875rem" />
						<Typography color={'GrayText'}>
							{moment(submissionAttempt.created_at).format('DD/MM/Y hh:mm A')}
						</Typography>
					</FlexLayout>
				</FlexLayout>
				<Divider />
				<Spacer mb="2.813rem" />
				<FormProvider control={control} {...formProps}>
					{fields.map((answer, index) => {
						return (
							<AttemptQuestion
								index={index}
								answer={submissionAttempt.answers[index]}
								update={update}
							/>
						);
					})}
				</FormProvider>
			</DialogWrapper>
			<Footer>
				<Button
					onClick={handleSubmitAttempt}
					disabled={!isValid}
					variant="contained"
				>
					SUBMIT ATTEMPT
				</Button>
			</Footer>
		</Dialog>
	);
};

export default AttemptModal;

const DialogWrapper = styled.div`
	padding: 0 2.5rem;
	padding-top: 3.75rem;
	/* min-width: 60vw; */
	box-sizing: border-box;
	width: 100%;
`;

const Footer = styled.div`
	background: #f5f5f5;
	position: sticky;
	bottom: 0;
	box-shadow: 0px -3px 6px #00000029;
	display: grid;
	place-content: center;
	padding: 1.625rem;
`;
