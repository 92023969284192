import React, { useRef, useState } from 'react';
import EdFormControl, {
	EdFormControlProps,
	TextFieldLabel,
} from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { Button, Dialog, DialogActions, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { Cropper, CropperRef } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import { UploadFolderType } from '@Services/uploads/uploads.req.types';
import { uploadPicture } from '@Services/uploads/uploads.req';
import { Controller, FieldValues, Path, useController } from 'react-hook-form';
import { EdFormControlLabel } from '../EdFormControlLabel/EdFormControlLabel';

type FileType = 'image' | 'document';
type EdUploadFileProps<T extends FieldValues> = {
	uploadIcon?: React.ReactNode;
	uploadLabel: string;
	onFileUpload?: (blob: Blob | File | null) => boolean | Promise<boolean>;
	folder: UploadFolderType;
	aspectRation?: number;
	type?: FileType;
	optimize?: boolean;
} & EdFormControlProps<T>;
const EdUploadFile = <T extends {}>({
	control,
	label,
	name,
	uploadLabel,
	uploadIcon,
	onFileUpload,
	folder,
	aspectRation = 22 / 13,
	type = 'image',
	width = '16.25rem',
	optimize,
	...props
}: React.PropsWithChildren<EdUploadFileProps<T>>) => {
	const [open, setOpen] = useState(false);
	const [isPreview, setIsPreview] = useState(false);
	const [isFileUploading, setIsFileUploading] = useState(false);
	const inputFileRef = useRef<HTMLInputElement>(null);
	const [modalSrc, setModalSrc] = useState<string>();
	const cropperRef = useRef<CropperRef>();
	const {
		field: { onChange, ...inputProps },
	} = useController({
		name: name as Path<T>,
		control,
	});
	const handleChooseFile = () => {
		if (inputFileRef.current) {
			inputFileRef.current.click();
		}
	};
	const handlePreview = (value: string) => {
		if (type === 'document') {
			window.open(inputProps.value, '_blank');
		}
		if (type === 'image') {
			setModalSrc(value);
			setOpen(true);
			setIsPreview(true);
		}
	};

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		const file = e.target.files.item(0);
		if (file) {
			const base64 = await convertToBase64(file);
			if (type === 'image') {
				setModalSrc(base64);
				setOpen(true);
			} else {
				onFileUpload?.(file as Blob);
			}
		}
	};
	const convertToBase64 = (file: File) => {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
		});
	};
	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			setIsPreview(false);
		}, 500);
	};
	const onUpload = async (): Promise<string | void> => {
		setIsFileUploading(true);
		const canvas = cropperRef.current?.getCanvas({
			width: optimize ? 265 : undefined,
			height: optimize ? 156 : undefined,
		});
		if (canvas) {
			canvas.toBlob(
				async (blob) => {
					if (!blob) return;
					if (onFileUpload) {
						await onFileUpload(blob);
					} else {
						const {
							data: {
								data: { uri },
							},
						} = await uploadPicture({
							photo: blob,
							folder,
						});
						onChange(uri);
					}
					setOpen(false);
					setIsFileUploading(false);
				},
				'image/webp',
				0.2
			);
		}
	};
	return (
		<div>
			<FlexLayout>
				<TextFieldLabel required={props.required}>{label}</TextFieldLabel>

				<input
					onChange={handleFileChange}
					type="file"
					ref={inputFileRef}
					hidden
					onClick={(e) => (e.currentTarget.value = '')}
				/>
				<ActionsWrapper>
					<ActionsContainer width={width}>
						<UploadButton
							onClick={handleChooseFile}
							startIcon={uploadIcon}
							variant="outlined"
							color="primary"
						>
							{uploadLabel}
						</UploadButton>
						<Spacer mx="2px" />
						<PreviewButton
							disabled={!inputProps.value}
							onClick={() => handlePreview(inputProps.value)}
							variant="outlined"
							color="warning"
						>
							Preview
						</PreviewButton>
					</ActionsContainer>
				</ActionsWrapper>
				<Dialog onBackdropClick={handleClose} open={open}>
					<DialogWrapper isPreview={isPreview}>
						{isFileUploading && <LinearProgress />}
						{!isPreview && (
							<>
								<Cropper
									stencilProps={{
										aspectRatio: aspectRation,
									}}
									src={modalSrc}
									className={'cropper'}
									ref={cropperRef}
								/>
								<Spacer my="0.938rem" />
								<DialogActions>
									<EdButton
										onClick={async () => {
											await onUpload();
										}}
										variant="text"
										edcolor="purple"
									>
										Save
									</EdButton>
									<Button onClick={handleClose} color="warning">
										Cancel
									</Button>
								</DialogActions>
							</>
						)}
						{isPreview && <img alt="Classroom_Photo" src={inputProps.value} />}
					</DialogWrapper>
				</Dialog>
			</FlexLayout>
		</div>
	);
};
export default EdUploadFile;

const ActionsContainer = styled(FlexLayout)`
	align-items: center;
`;
const ActionsWrapper = styled.div`
	flex: 1;

	.Mui-button {
		text-transform: capitalize;
	}
`;

const UploadButton = styled(Button)`
	flex: 1.5;
`;
const PreviewButton = styled(Button)`
	flex: 0.5;
`;

const DialogWrapper = styled.div<{ isPreview: boolean }>`
	padding: ${(props) => (props.isPreview ? '1rem' : '1rem 1rem 0')};
	max-width: 100%;
	img {
		max-width: 100%;
		max-height: 100%;
	}
`;
