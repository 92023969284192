import React, { useEffect } from 'react';
import styled from 'styled-components';
import AdmissionForm from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/AdmissionForm';
import AdmissionFormList from './AdmissionFormList';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import {
	useQueryParam,
	withDefault,
	StringParam,
	NumberParam,
	UrlUpdateType,
} from 'use-query-params';
import { createContext } from '@Utils/createContext';

type AdmissionsContext = {
	admissionsStatusQueryParam: string;
	selectedAdmissionIdQueryParam?: number | null;
	setSelectedAdmissionIdQueryParam: (
		newValue: number | undefined,
		updateType?: UrlUpdateType | undefined
	) => void;
};

const [AdmissionsContextProvider, useAdmissionsContext] =
	createContext<AdmissionsContext>();

export { useAdmissionsContext };

const Admissions: React.FC = () => {
	const [admissionsStatusQueryParam, setAdmissionsStatueQueryParam] =
		useQueryParam('status', withDefault(StringParam, 'pending'), {
			updateType: 'replaceIn',
		});

	const [selectedAdmissionIdQueryParam, setSelectedAdmissionIdQueryParam] =
		useQueryParam('admission-id', NumberParam, { updateType: 'replaceIn' });

	const requestsButtonClick = () => {
		setAdmissionsStatueQueryParam('pending');
	};

	const rejectsButtonClick = () => {
		setAdmissionsStatueQueryParam('rejected');
	};

	useEffect(() => {
		return () => {
			setAdmissionsStatueQueryParam('pending');
		};
	}, []);

	return (
		<Grid>
			<AdmissionsContextProvider
				value={{
					admissionsStatusQueryParam,
					selectedAdmissionIdQueryParam,
					setSelectedAdmissionIdQueryParam,
				}}
			>
				{/*  placeholder for grid cell 0,0   */}
				<div />

				<div>
					<FlexLayout
						justifyContent="start"
						alignItems="center"
						width="100%"
						marginBottom="1.625rem"
					>
						<ButtonGroup
							value={admissionsStatusQueryParam === 'pending'}
							exclusive
							aria-label="text alignment"
						>
							<TabButtons
								value={true}
								aria-label="pending"
								onClick={requestsButtonClick}
							>
								Requests
							</TabButtons>
							<TabButtons
								value={false}
								aria-label="rejects"
								onClick={rejectsButtonClick}
							>
								Rejects
							</TabButtons>
						</ButtonGroup>
					</FlexLayout>
				</div>

				<AdmissionForm />

				<AdmissionFormList />
			</AdmissionsContextProvider>
		</Grid>
	);
};

const Grid = styled.div`
	display: grid;
	grid-template-columns: 77% 23%;
	grid-template-rows: repeat(2, auto);
	gap: 22px 32px;
	padding-right: 40px;
	box-sizing: border-box;
`;

const TabButtons = styled(ToggleButton)`
	background: #6e7577;
	color: #fff;
	font-size: 0.875rem;
	font-weight: normal;
	height: 1.7rem;

	&:hover {
		background: #6e7577;
		color: #fff;
	}

	&.Mui-selected,
	&.Mui-selected:hover {
		background: #5ac0fc;
		height: 2.2rem;
		color: #fff;
	}
`;

const ButtonGroup = styled(ToggleButtonGroup)`
	align-items: center;
`;

export default Admissions;
