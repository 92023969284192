import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { TableHeader } from '@Features/generics/generics.type';
import { useTypedSelector } from '@Features/store';
import { TableCell } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AssistantsRequester } from './Services/Assistants.Requester';
import {
	assistantsDeleteById,
	assistantsGetAll,
} from './Slices/Assistants.Slices';
import { Assistants } from './Types/Assistants.types';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'first name', fieldName: 'first_name' },
	{ displayName: 'last name', fieldName: 'last_name' },
	{ displayName: 'email', fieldName: 'email' },
	{ displayName: 'phone number', fieldName: 'phone_number' },
	{ displayName: 'active', fieldName: 'is_active' },
];

const AssistantsTable = () => {
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [openModel, setOpenModel] = useState<boolean>(false);
	const [deletedUuid, setDeletedUuid] = useState<any>('');
	const { fetchAll, meta, filters, query, requestStatus } = useTypedSelector(
		(state) => state.Assistants
	);

	useEffect(() => {
		if (requestStatus.deleteById === 'DONE') {
			displaySnackbar('success', 'Assistant delete successfully');
		} else if (requestStatus.deleteById === 'FAILED') {
			displaySnackbar('error', "Couldn't delete Assistant");
		}
	}, [requestStatus.deleteById]);

	const handleModelOpen = (uuid: string) => {
		setOpenModel(true);
		setDeletedUuid(uuid);
	};
	const handleModelClose = (close: boolean, confirm?: boolean) => {
		if (confirm) {
			dispatch(assistantsDeleteById(deletedUuid));
		}
		setOpenModel(false);
		setDeletedUuid(null);
	};

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				assistantsGetAll({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
				})
			);
		},
		[]
	);

	const activate = async (assistant: Assistants) => {
		try {
			await AssistantsRequester.getInstance().update({
				...assistant,
				instructor: {
					is_active: true,
				},
			});
			displaySnackbar('success', ' Assistant activated successfully');
			dispatch(
				assistantsGetAll({
					page: 1,
					perPage: 10,
					sortBy: '',
					filters,
					query: query as string,
				})
			);
		} catch (error) {}
	};
	const deactivate = async (assistant: Assistants) => {
		try {
			await AssistantsRequester.getInstance().update({
				...assistant,
				instructor: {
					is_active: false,
				},
			});
			displaySnackbar('success', ' Assistant deactivated successfully');
			dispatch(
				assistantsGetAll({
					page: 1,
					perPage: 10,
					sortBy: '',
					filters,
					query: query as string,
				})
			);
		} catch (error) {}
	};

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to delete this Assistant"
				open={openModel}
				handleClose={handleModelClose}
			/>
			<FlexLayout justifyContent="flex-end">
				<RoundButton
					onClick={() => history.push('/dashboard/assistants/create/assistant')}
					variant="contained"
				>
					Add Assistant
				</RoundButton>
			</FlexLayout>
			<MainTable
				total={meta?.total ?? 0}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				RowsperPage={Number(meta?.per_page)}
				$hasActions
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll?.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll?.map((assistant) => {
						return (
							<MainTableRow
								key={assistant?.uuid}
								hover
								onClick={() => {
									history.push({
										pathname: `/dashboard/assistants/edit/assistant/${assistant.uuid}`,
									});
								}}
							>
								<TableCell>{assistant?.first_name}</TableCell>
								<TableCell>{assistant?.last_name}</TableCell>
								<TableCell>{assistant?.email}</TableCell>
								<TableCell>{assistant?.phone_number}</TableCell>
								<TableCell>
									{!assistant?.instructor.is_active ? (
										<EdIcon>close</EdIcon>
									) : (
										<EdIcon>check</EdIcon>
									)}
								</TableCell>
								<TableCell>
									<EdSpeedDial ariaLabel="SpeedDial basic example">
										<EdSpeedDialAction
											key="delete"
											icon={<EdIcon>delete</EdIcon>}
											tooltipTitle="Delete"
											onClick={(e) => {
												e.stopPropagation();
												handleModelOpen(assistant?.uuid);
											}}
										/>
										<EdSpeedDialAction
											key="active"
											icon={
												assistant?.instructor?.is_active ? (
													<EdIcon
														onClick={() => {
															deactivate(assistant);
														}}
													>
														block
													</EdIcon>
												) : (
													<EdIcon
														onClick={() => {
															activate(assistant);
														}}
													>
														check
													</EdIcon>
												)
											}
											tooltipTitle={
												assistant?.instructor?.is_active
													? 'Deactivate'
													: 'Activate'
											}
											onClick={(e) => {
												e.stopPropagation();
											}}
										/>
									</EdSpeedDial>
								</TableCell>
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

export default AssistantsTable;
