import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { Assistants } from '../Types/Assistants.types';

interface AssistantsCRUD extends CRUDBase<Assistants, Assistants> {}
export class AssistantsRequester implements AssistantsCRUD {
	private static instance: AssistantsRequester;
	static getInstance() {
		if (!AssistantsRequester.instance) {
			AssistantsRequester.instance = new AssistantsRequester();
		}
		return AssistantsRequester.instance;
	}
	baseURL = 'assistants';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<Assistants[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Assistants[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Assistants[]>>> {
		return await api.post(`${this.baseURL}/all`, payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Assistants>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Assistants>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Assistants>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<Assistants>>> {
		return await api.post(this.baseURL, { uuid: id });
	}

	async create(
		payload: Assistants
	): Promise<AxiosResponse<ApiResponse<Assistants>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: Assistants
	): Promise<AxiosResponse<ApiResponse<Assistants>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}
	async toggleActive(
		assistant: Assistants
	): Promise<AxiosResponse<ApiResponse<Assistants>>> {
		return await api.put(`${this.baseURL}/update`, {
			...assistant,
			is_active: !assistant.instructor.is_active,
		});
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<Assistants>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.post(`${this.baseURL}/delete`, {
			uuid: id,
		});
	}
}
