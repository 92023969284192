import { BaseSliceController } from '@Base/slices/slices.base';
import { TestRequester } from '../Services/TestRequester/TestRequester';
import { Test, TestPayload } from '../../../Types/Test/Test.types';

export class TestSlice extends BaseSliceController<Test, 'Tests', TestPayload> {
	static instance = new TestSlice();

	constructor() {
		super('Tests', TestRequester.instance);
	}
}

export const { testsGetAll, testsGetSingle, testsUpdate } =
	TestSlice.instance.getActions();
