import { Autocomplete, Chip, TextField } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Updater } from 'use-immer';
import { useController } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import { FlexLayout } from '@Styled/utilities';
import {
	MultipleOptionItem,
	MultiSelectOptions,
} from '../types/RolesPaths.types';

type Props = {
	options: MultiSelectOptions;
	setOptions: Updater<MultiSelectOptions[]>;
	prerequisitesPaths: MultiSelectOptions[];
	pathIndex: number;
	control: any;
	name: string;
	setChecked: any;
	clicked: boolean | undefined;
};
const PrerequisitePath: React.FC<Props> = ({
	options,
	setOptions,
	pathIndex,
	control,
	name,
	prerequisitesPaths,
	setChecked,
	clicked,
}) => {
	const {
		field: { onBlur, ref, onChange },
	} = useController({ control, name });

	const [, setSelected] = useState<string>();
	const [, setArray] = useState<any[]>();

	const deleteHandler = (index: number) => {
		setOptions((draft) => {
			draft.splice(index, 1);
		});
	};
	const checkedData = [...new Set(Object.values(options))];
	const isChecked = checkedData?.filter((_) => _.checked === true);
	const active = isChecked === undefined || isChecked?.length > 0;
	useEffect(() => {
		if (clicked) {
			setChecked(active);
		}
	}, [active]);

	const clickHandler = (id: string | number) => {
		if (id) {
			setOptions((draft: any) => {
				const clickedOption = draft[pathIndex][id];
				clickedOption.checked = !clickedOption.checked;
			});
		}
	};

	const opts = [...new Set(Object.values(options))];
	return (
		<FlexLayout alignItems="center" onBlur={onBlur} ref={ref}>
			<AutocompleteStyle
				freeSolo
				disableClearable
				id="courses"
				filterSelectedOptions
				disableCloseOnSelect
				multiple
				readOnly={false}
				clearOnBlur={true}
				options={
					opts.filter((_) => !_.checked) as readonly MultipleOptionItem[]
				}
				getOptionLabel={(option: unknown) => {
					return (option as MultipleOptionItem).label;
				}}
				defaultValue={opts?.filter((_) => _.checked)}
				key={opts?.filter((_) => _.checked).toString()}
				onChange={(e: SyntheticEvent<Element, Event>, v: any) => {
					if (e.currentTarget.textContent) {
						const courseName = e.currentTarget.textContent;
						const values: Array<MultipleOptionItem> = v;
						setArray(values);
						const sorted = [...new Set(Object.values(values))];
						const checkedCourse = sorted.find((_) => _.label === courseName);
						const checkedVal = checkedCourse?.value;
						setSelected(checkedVal);
						clickHandler(checkedVal);
						onChange(checkedVal);
					}
				}}
				renderTags={(opts, getTagProps) => {
					return opts.map((option: unknown, index) => {
						return (
							<ChipStyle
								onDelete={() => {
									setOptions((draft: any) => {
										draft[pathIndex][
											(option as MultipleOptionItem).value
										].checked = false;
									});
									opts?.splice(index as number, 1);
									onChange(pathIndex);
								}}
								label={(option as MultipleOptionItem).label}
							/>
						);
					});
				}}
				renderOption={(props, option, { selected }) => (
					<li {...props}>{(option as MultiSelectOptions).label}</li>
				)}
				renderInput={(params) => <TextField {...params} />}
			/>
			<FlexLayout alignItems="end" width={65}>
				{pathIndex === prerequisitesPaths.length - 1 ? null : (
					<OrLabel>Or</OrLabel>
				)}
				{pathIndex >= 1 ? (
					<DeleteIconStyle
						onClick={() => {
							deleteHandler(pathIndex);
						}}
						fontSize="medium"
					/>
				) : null}
			</FlexLayout>
		</FlexLayout>
	);
};
const OrLabel = styled.p`
	color: #5ac0fc;
	font-size: 1.25rem;
	font-weight: 600;
	margin: 0 0rem 0 1.313rem;
`;
const DeleteIconStyle = styled(DeleteIcon)`
	margin: 0 0 0 1.313rem;
	cursor: pointer;
`;
const AutocompleteStyle = styled(Autocomplete)`
	width: 30.188rem;
	min-height: 5.75rem;
	fieldset {
		border: solid 1px rgba(0, 0, 0, 0.12);
	}
	.MuiOutlinedInput-root {
		min-height: 5.75rem;
	}
`;
const ChipStyle = styled(Chip)`
	margin-right: 10px;
	margin-bottom: 10px;
`;
export default PrerequisitePath;
