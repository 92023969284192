import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import { useTypedSelector } from '@Features/store';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { RouteProvider } from '@Providers/Routes/RoutesProvider';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import { assignmentsSubmissionsGetAll } from '../../Slices/Assignment.slice';
import { submissionsGetAll } from '../../Slices/Submissions.slice';
import {
	AssignmentSubmission,
	Submissions,
} from '../../Types/Submissions.types';
import AttemptModal from '../AttemptModal';
import TableDropdowns from '../TableDropdowns/TableDropdowns';
import TestCard from '../TestCard';
import AssignmentCard from './AssignmentCard';
import AssignmentsDropdowns from './AssignmentsDropdowns';
import SubmissionModal from './SubmissionModal';

const AssignmentsTable = () => {
	const dispatch = useDispatch();
	const { fetchAll, filters, meta } = useTypedSelector(
		(state) => state.AssignmentsSubmissions
	);
	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const [reload, setReload] = useState<boolean | undefined>();
	const [reviewed, setReviewed] = useState(false);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (currentClassroom?.id) {
				dispatch(
					assignmentsSubmissionsGetAll({
						page: activePage,
						filters: filters,
						perPage: perPage ?? 10,
						query: query,
						sortBy: sortBy,
						from: startDate,
						to: endDate,
						classroom_id: currentClassroom?.id,
						submitted: reviewed,
					})
				);
			}
		},
		[currentClassroom?.id, reviewed]
	);
	const { path, url } = useRouteMatch();
	const [isOpen, setIsOpen] = useState(false);
	const [currentAttempt, setCurrentAttempt] = useState<
		AssignmentSubmission | undefined
	>();

	const history = useHistory();

	const handleModalClose = () => {
		setIsOpen(false);
		setCurrentAttempt(undefined);
		history.replace(`${path}`);
	};

	return (
		<RouteProvider path={path}>
			<Container>
				<Header>Assignments</Header>
				<Switch>
					<Route path={`${path}`} exact>
						<Redirect to={`${path}/table`} />
					</Route>
					<Route
						path={`${path}/table`}
						exact
						render={() => {
							return (
								<>
									<MainTable
										renderExtraFilters={() => (
											<AssignmentsDropdowns reviewed={reviewed} />
										)}
										renderSubHeader={() => (
											<ButtonGroup
												value={reviewed}
												exclusive
												aria-label="text alignment"
											>
												<UserButton
													value={false}
													aria-label="Unreviewed"
													onClick={() => {
														setReviewed(false);
													}}
												>
													Unreviewed
												</UserButton>
												<UserButton
													value={true}
													aria-label="Reviewed"
													onClick={() => {
														setReviewed(true);
													}}
												>
													Reviewed
												</UserButton>
											</ButtonGroup>
										)}
										total={meta?.total ?? 0}
										dateFilter={false}
										pageNum={meta?.current_page}
										tableFilters={filters}
										tableHeads={[]}
										totalPages={meta?.last_page ?? 0}
										RowsperPage={Number(meta?.per_page)}
										$hasActions
										onPageChangeRequest={onPageChangeRequest}
										renderItems={() => {
											return fetchAll?.map((test, index) => {
												return (
													<MainTableRow key={index}>
														<AssignmentCard
															submission={test}
															onClick={(test) => {
																setIsOpen(true);
																setCurrentAttempt(test);
																history.replace(`${path}/attempt`);
															}}
														/>
													</MainTableRow>
												);
											});
										}}
									/>
								</>
							);
						}}
					/>
					<Route
						path={`${path}/attempt`}
						exact
						render={() => {
							return (
								<>
									{currentAttempt && (
										<SubmissionModal
											submission={currentAttempt}
											open={isOpen}
											onClose={handleModalClose}
										/>
									)}
								</>
							);
						}}
					></Route>
				</Switch>
			</Container>
		</RouteProvider>
	);
};

const Header = styled.p`
	font-size: 1.125rem;
	color: #6c63ff;
	margin-bottom: 1.125rem;
	padding: 0rem 1rem;
`;
const Container = styled.div`
	thead {
		display: none;
	}
	tr {
		border: none;
		box-shadow: unset;
	}
	div:has(table) {
		box-shadow: none !important;
		position: relative;
	}
	table {
		margin-top: 2rem;
	}
`;

const ButtonGroup = styled(ToggleButtonGroup)`
	align-items: center;
`;

const UserButton = styled(ToggleButton)`
	background: #6e7577;
	color: #fff;
	font-size: 0.875rem;
	font-weight: normal;
	height: 2.7rem;
	transition: all 0.1s ease-in-out;
	&:hover {
		background: #6e7577;
		color: #fff;
		transition: all 0.1s ease-in-out;
	}
	&.Mui-selected,
	&.Mui-selected:hover {
		background: #5ac0fc;
		height: 3rem;
		color: #fff;
		transition: all 0.1s ease-in-out;
	}
`;

export default AssignmentsTable;
