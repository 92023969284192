import { BaseSliceController } from '@Base/slices/slices.base';
import { GrantsRequester } from '@Pages/Assistants/Services/Grants/Grants.requester';
import { Grants } from '@Pages/Assistants/Types/Assistants.types';

export class GrantsSlice extends BaseSliceController<Grants, 'Grants', Grants> {
	private static instance: GrantsSlice;
	static getInstance() {
		if (!GrantsSlice.instance) {
			GrantsSlice.instance = new GrantsSlice();
		}
		return GrantsSlice.instance;
	}
	constructor() {
		super('Grants', GrantsRequester.getInstance());
	}
}

export const { grantsGetAll } = GrantsSlice.getInstance().getActions();
