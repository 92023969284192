import { StudentInvoice } from '@Constants/routes';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import InvoicesTable from './InvoicesTable';

const Invoices = () => {
	const { url, path } = useRouteMatch();

	const { setURL, setCurrentRouteGroup } = useInvalidateRouteGroup({
		routeGroup: StudentInvoice,
		baseURL: `${url}`,
		routePlacement: 2,
		mode: 'end',
	});
	useEffect(() => {
		setURL(url);
		setCurrentRouteGroup?.(StudentInvoice);
	}, [setURL]);

	return (
		<Switch>
			{StudentInvoice && !Array.isArray(StudentInvoice) && (
				<>
					<Route exact path={path}>
						<InvoicesTable />
					</Route>

					{StudentInvoice.routes.map((_, index) => {
						return (
							<Route path={`${path}/${_.route}`} key={`${_.route}-${index}`}>
								{_.component}
							</Route>
						);
					})}
				</>
			)}
		</Switch>
	);
};

export default Invoices;
