import { Grants } from '@Pages/Assistants/Types/Assistants.types';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

interface GrantsCRUD extends CRUDBase<Grants, Grants> {}
export class GrantsRequester implements GrantsCRUD {
	create(
		payload: Omit<Grants, 'id'>
	): Promise<AxiosResponse<ApiResponse<Grants>>> {
		throw new Error('Method not implemented.');
	}
	update(payload: Grants): Promise<AxiosResponse<ApiResponse<Grants>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Grants>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Grants>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<Grants>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<Grants>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: GrantsRequester;
	static getInstance() {
		if (!GrantsRequester.instance) {
			GrantsRequester.instance = new GrantsRequester();
		}
		return GrantsRequester.instance;
	}
	baseURL = 'grants';
	getAll(payload?: GetAllMeta): Promise<AxiosResponse<ApiResponse<Grants[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Grants[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Grants[]>>> {
		return await api.get(this.baseURL, payload);
	}
}
