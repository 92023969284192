import PageLoader from '@Components/PageLoader';
import { loginCheck } from '@Features/auth/authSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Reset } from 'styled-reset';
import {
	fetchClassroomsDropdown,
	fetchTagsDropdown,
} from '@Features/dropdowns/dropdownsSlice';
import { Router } from 'react-router-dom';
import Layout from '@Components/UI/Layout/Layout';
import { createBrowserHistory } from 'history';
import { theme } from '@Styled/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { ApiProvider } from './providers/ApiProvider/ApiProvider';
import { useTypedSelector } from '@Features/store';
import { DefaultTheme } from 'styled-components';
import { Normalize } from 'styled-normalize';
import GlobalStyles from '@Assets/globalStyles';
const history = createBrowserHistory();

if (process.env.NODE_ENV === 'development') {
	console.log(
		'%cEduact Instructor Portal',
		'font-weight: bold; font-size: 40px;color: #00fff2; text-shadow: 3px 3px 0 #1fb1d9 , 6px 6px 0 #0ea2e2 , 9px 9px 0 #0877f5 , 12px 12px 0 #054394 , 15px 15px 0 #0238ce , 18px 18px 0 #100491 , 21px 21px 0 rgb(42,21,113)'
	);
}
function App() {
	const routerRef = useRef<null | Router>(null);

	const dispatch = useDispatch();
	const { isAuth } = useTypedSelector((state) => state.auth);
	useEffect(() => {
		dispatch(loginCheck());
	}, []);
	useEffect(() => {
		const dispatchDropdowns = () => {
			dispatch(fetchClassroomsDropdown());
			dispatch(fetchTagsDropdown());
		};
		if (isAuth) {
			dispatchDropdowns();
		}
	}, [isAuth]);

	return (
		<div className="App">
			
			<ApiProvider>
				<Router ref={routerRef} key="router-v5-dom" history={history}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<GlobalStyles  />
						<Normalize theme={theme as DefaultTheme} />
						<QueryParamProvider adapter={ReactRouter5Adapter}>
							<Layout />
						</QueryParamProvider>
					</LocalizationProvider>
				</Router>
			</ApiProvider>
		</div>
	);
}

export default App;
