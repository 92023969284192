import React, { useCallback, useEffect } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Paper, PaperProps } from '@mui/material';
import { useTypedSelector } from '@Features/store';
import { useDispatch } from 'react-redux';
import { getClassroomUnitsAnalytics } from '@Features/classrooms/classroomSlice';
import { GridLayout } from '@Styled/utilities';

type Time = {
	[key: string]: number;
};

const ClassroomUnitsAnalytics = () => {
	const dispatch = useDispatch();

	const {
		currentClassroom,
		analytics: {
			all: { singleClassroomUnitsAnalytics },
		},
	} = useTypedSelector((state) => state.classroom);

	const pickTypeColor = useCallback((type: string) => {
		if (type === 'total') {
			return rgba('#5AC0FC', 0.1);
		}
		if (type === 'videos') {
			return rgba('#6C63FF', 0.1);
		}
		if (type === 'documents') {
			return rgba('#FF8532', 0.1);
		}
		if (type === 'tests') {
			return rgba('#FFD037', 0.1);
		}
		if (type === 'webinar') {
			return rgba('#ff3100', 0.1);
		}
		if (type === 'webcontent') {
			return rgba('#36a2eb', 0.1);
		}
		if (type === 'assignment') {
			return rgba('#00ce67', 0.1);
		}
	}, []);

	const pickValueColor = useCallback((type: string) => {
		if (type === 'total') {
			return '#5AC0FC';
		}
		if (type === 'videos') {
			return '#6C63FF';
		}
		if (type === 'documents') {
			return '#FF8532';
		}
		if (type === 'tests') {
			return '#FFD037';
		}
		if (type === 'webinar') {
			return '#ff3100';
		}
		if (type === 'webcontent') {
			return '#36a2eb';
		}
		if (type === 'assignment') {
			return '#00ce67';
		}
	}, []);

	const typeText = useCallback((type: string) => {
		if (type === 'total') {
			return 'learning time';
		}
		if (type === 'videos') {
			return 'videos';
		}
		if (type === 'documents') {
			return 'documents';
		}
		if (type === 'tests') {
			return 'tests';
		}
		if (type === 'webinar') {
			return 'webinars';
		}
		if (type === 'webcontent') {
			return 'web content';
		}
		if (type === 'assignment') {
			return 'assignment';
		}
	}, []);

	useEffect(() => {
		if (currentClassroom) {
			dispatch(getClassroomUnitsAnalytics(Number(currentClassroom?.id)));
		}
	}, [currentClassroom?.id]);

	const findTime = (num: number) => {
		if (num < 1) {
			return '0';
		}
		const qualifier = (num: number) => (num > 1 ? '' : '');
		const numToStr = (num: number, unit: string) =>
			num > 0 ? `${num}${unit} ${qualifier(num)}` : '';
		const oneMinute = 60;
		const oneHour = oneMinute * 60;
		const oneDay = oneHour * 24;
		const oneYear = oneDay * 365;
		const times: Time = {
			year: ~~(num / oneYear),
			day: ~~((num % oneYear) / oneDay),
			hour: ~~((num % oneDay) / oneHour),
			minute: ~~((num % oneHour) / oneMinute),
		};
		let str = '';
		for (let [key, value] of Object.entries(times)) {
			str += numToStr(times[key], key[0]);
		}
		const arr = str.trim().split(' ');
		const res: Array<any> = [];
		arr.forEach((x, i) => {
			res.push(x);
		});
		return res.slice(0, 2).join(' ');
	};

	return (
		<GridLayout pb="5rem" gridTemplateColumns="repeat(6,1fr)">
			{Object.entries(singleClassroomUnitsAnalytics)
				.reverse()
				.map(([key, value]) => {
					return (
						<EnrollmentTypeCard color={pickTypeColor(key)}>
							<TypeInfo>
								<TypeValue color={pickValueColor(key)}>
									{typeText(key)}
								</TypeValue>
							</TypeInfo>
							<TypeName>{findTime(value)}</TypeName>
						</EnrollmentTypeCard>
					);
				})}
		</GridLayout>
	);
};

const StyledPaper = styled(Paper).attrs<PaperProps & { color: string }>({
	elevation: 0,
})`
	padding: 1.25rem;
`;

export const TypeValue = styled.div<{ color: string | undefined }>`
	color: ${(props) => props.color};
	font-size: 1.063rem;
	font-weight: 600;
	text-transform: capitalize;
`;
export const TypeInfo = styled.div`
	display: flex;
	justify-content: space-between;
`;
export const TypeName = styled.div`
	font-size: 1.063rem;
	font-weight: 600;
	grid-row: 2/2;
	color: #251b33;
	text-align: end;
`;

export const EnrollmentTypeCard = styled(StyledPaper)`
	display: flex;
	min-width: 10.5rem;
	flex-direction: column;
	justify-content: space-between;
	gap: 0.5rem;
	margin-bottom: 1rem !important;
	background: ${(props) => (props.color ? rgba(props.color, 0.1) : '')};
	color: ${(props) => props.color};
	max-height: 7.75rem;
	${TypeInfo} {
		color: ${(props) => props.color};
	}
	margin: 0 1rem;
	:first-child {
		margin-left: 0;
	}
	:last-child {
		margin-left: 0;
	}
	span {
		margin-bottom: 1.25rem;
	}
	svg {
		max-height: 100%;
	}
`;

export default ClassroomUnitsAnalytics;
