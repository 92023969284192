import React from 'react';
import styled from 'styled-components';
import { useAdmissionsContext } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/Admissions';

const dark = '#251B33';
const light = '#fff';

type Props = {
	id: number;
	username: string;
	studentName: string;
	date: string;
};

const AdmissionListItem: React.FC<Props> = ({
	id,
	username,
	studentName,
	date,
}) => {
	const {
		setSelectedAdmissionIdQueryParam,
		selectedAdmissionIdQueryParam,
		admissionsStatusQueryParam,
	} = useAdmissionsContext();

	const onClickHandler = () => {
		setSelectedAdmissionIdQueryParam(id);
	};

	const background =
		admissionsStatusQueryParam === 'pending'
			? id !== selectedAdmissionIdQueryParam
				? dark
				: light
			: id === selectedAdmissionIdQueryParam
			? dark
			: light;
	const foreground =
		admissionsStatusQueryParam === 'pending'
			? id !== selectedAdmissionIdQueryParam
				? light
				: dark
			: id === selectedAdmissionIdQueryParam
			? light
			: dark;

	const backgroundColorMode =
		admissionsStatusQueryParam === 'pending'
			? id !== selectedAdmissionIdQueryParam
				? 'dark'
				: 'light'
			: id === selectedAdmissionIdQueryParam
			? 'dark'
			: 'light';
	const foregroundColorMode =
		admissionsStatusQueryParam === 'pending'
			? id !== selectedAdmissionIdQueryParam
				? 'light'
				: 'dark'
			: id === selectedAdmissionIdQueryParam
			? 'light'
			: 'dark';

	return (
		<StyledItem
			onClick={onClickHandler}
			status={admissionsStatusQueryParam}
			backgroundMode={backgroundColorMode}
			colorMode={foregroundColorMode}
		>
			<StyledUsername color={foreground}>{username}</StyledUsername>
			<StyledStudentName color={foreground}>{studentName}</StyledStudentName>
			<StyledDate color={foreground}>{date}</StyledDate>
		</StyledItem>
	);
};

const StyledItem = styled.div<{
	backgroundMode: string;
	colorMode: string;
	status: string;
}>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 10px;
	box-shadow: 0 3px 6px #00000029;
	border: 1px solid
		${(props) =>
			props.backgroundMode === 'dark'
				? props.status === 'pending'
					? light
					: light
				: dark};
	border-radius: 5px;
	background-color: ${(props) =>
		props.backgroundMode === 'dark'
			? props.status === 'pending'
				? dark
				: '#2F2E41'
			: light};
	color: ${(props) => (props.colorMode === 'dark' ? dark : light)};

	:hover {
		cursor: pointer;
	}

	:not(:last-child) {
		margin-bottom: 16px;
	}
`;

const StyledUsername = styled.p<{ color: string }>`
	border-right: 1px solid;
	padding-right: 12px;
	//color: ${(props) => props.color};
`;

const StyledStudentName = styled.p<{ color: string }>`
	font-size: 14px;
	//color: ${(props) => props.color};
`;

const StyledDate = styled.p<{ color: string }>`
	font-size: 12px;
	//color: ${(props) => props.color};
`;

export default AdmissionListItem;
