import { useEffect, useState } from 'react';

function useDebounce<T>(initialValue: T, delay?: number) {
	const [value, setValue] = useState<T>(initialValue);
	const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);
	const isDebouncing = value !== debouncedValue;

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return {
		value,
		setValue,
		debouncedValue,
		isDebouncing,
	};
}

export default useDebounce;
