import TagChip from '@Components/UI/Chips/TagChip/TagChip';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useTypedSelector } from '@Features/store';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	Button,
	Checkbox,
	InputAdornment,
	ListItemText,
	MenuItem,
	Typography,
} from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ChoiceSchema, MCQ_SCHEMA } from '../../../../Schema/MCQ.schema';
import { TestQuestionsRequester } from '../../../../Services/TestQuestions/TestQuestionsRequester';
import {
	getQuestion,
	TypedTestQuestionPayload,
} from '../../../../Types/TestQuestion';
import MCQOption from '../MCQOption';
import { cloneDeep } from 'lodash';
import TinyMceControl from '@Components/UI/TinyMceControl/TinyMceControl';
import AddTag from '../../../AddTag';
const EditMCQForm = () => {
	const editorRef = useRef<any>(null);
	const feedbackEditorRef = useRef<any>(null);
	const [addedId, setAddedId] = useState<number | undefined>();
	const { dropdownTags } = useTypedSelector((state) => state.dropdowns);
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const { currentEntity: currentQuestion } = useTypedSelector(
		(state) => state.TestQuestions
	);
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();

	const question = useMemo(() => {
		if (!currentQuestion) return;
		return getQuestion('mcq', currentQuestion);
	}, []);

	const formDefaultValues = {
		test_id: currentEntity?.id ?? 0,
		tags: question?.tags.map((q) => q.id),
		content: currentQuestion?.content,
		feedback: currentQuestion?.feedback,
		id: currentQuestion?.id,
		multipleChoiceOptions: cloneDeep(currentQuestion?.multipleChoiceOptions),
		order: currentQuestion?.order ?? 1,
		type: 'mcq',
		weight: currentQuestion?.weight,
	};

	const { control, watch, formState, handleSubmit, setValue } = useForm<
		TypedTestQuestionPayload<'mcq'>
	>({
		mode: 'all',
		resolver: yupResolver(MCQ_SCHEMA),
		defaultValues: formDefaultValues as any,
	});

	const watchTags = watch('tags');
	const watchMCQ = watch('multipleChoiceOptions');
	const canAddChoice = useMemo(() => {
		const notValid = watchMCQ?.find((_) => !ChoiceSchema.isValidSync(_));
		return notValid === undefined;
	}, [JSON.stringify(watchMCQ)]);

	useEffect(() => {
		if (addedId !== undefined) {
			setValue('tags', [...watchTags, addedId]);
		}
	}, [addedId]);

	const canRemoveOption = useMemo(() => {
		if (!watchMCQ) return false;
		return watchMCQ.length > 2;
	}, [watchMCQ]);

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'multipleChoiceOptions',
		keyName: 'opId',
	});

	const onSave = async (form: TypedTestQuestionPayload<'mcq'>) => {
		try {
			await TestQuestionsRequester.instance.update(form);
			displaySnackbar('success', 'Updated question successfully');
			history.goBack();
		} catch (error) {
			displaySnackbar('error', error.data.message);
		}
	};

	const { isDirty, isValid, isSubmitting } = formState;

	return (
		<FlexLayout flexDirection="column" px="2.625rem" flex="1">
			<FlexLayout flex="1">
				<Controller
					control={control}
					name="content"
					render={({ field: { value, onChange, ...props } }) => {
						return (
							// <Editor
							// 	disabled={currentEntity.active}
							// 	{...props}
							// 	value={value}
							// 	onEditorChange={(e) => onChange(e)}
							// 	onInit={(evt, editor) => {
							// 		editorRef.current = editor;
							// 	}}
							// 	apiKey="tczjjh2rh7xdde7qwvczi1fwld023vsamzdx1t97cdd7fdfu"
							// 	init={{
							// 		height: 200,
							// 		width: '100%',
							// 		menubar: false,
							// 		branding: false,
							// 		resize: false,
							// 		statusbar: true,
							// 		formats: {
							// 			answer: {
							// 				inline: 'span',
							// 				exact: true,
							// 				wrapper: true,
							// 				styles: { color: '#00ff00', fontSize: '20px' },
							// 				attributes: { title: 'My custom format' },
							// 				classes: 'example1',
							// 			},
							// 		},
							// 		text_patterns: [
							// 			{
							// 				start: '{',
							// 				end: '}',
							// 				format: 'answer',
							// 			},
							// 		],

							// 		plugins: [
							// 			'advlist',
							// 			'autolink',
							// 			'lists',
							// 			'link',
							// 			'image',
							// 			'charmap',
							// 			'anchor',
							// 			'searchreplace',
							// 			'visualblocks',
							// 			'code',
							// 			'fullscreen',
							// 			'insertdatetime',
							// 			'media',
							// 			'table',
							// 			'preview',
							// 			'help',
							// 			'wordcount',
							// 			'textpattern',
							// 		],
							// 		toolbar:
							// 			'undo redo | blocks | ' +
							// 			'bold italic forecolor | alignleft aligncenter ' +
							// 			'alignright alignjustify | bullist numlist outdent indent | ' +
							// 			'removeformat | help',
							// 		content_style:
							// 			'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
							// 	}}
							// />
							<TinyMceControl
								{...props}
								value={value}
								disabled={currentEntity.active}
								onEditorChange={(e) => onChange(e)}
							/>
						);
					}}
				/>
			</FlexLayout>
			<Spacer my="1.5rem" />
			<GridLayout gridTemplateColumns={'repeat(1,1fr)'} gridGap="1.875rem">
				<FlexLayout mb="1.875rem" flexDirection="column">
					{fields.map(({ opId, choice, is_correct }, index) => {
						return (
							<MCQOption
								key={opId}
								canRemoveOption={canRemoveOption}
								remove={remove}
								control={control}
								index={index}
							/>
						);
					})}
					<div>
						<Button
							disabled={!canAddChoice || currentEntity.active}
							onClick={() => append({ choice: '', is_correct: false })}
							variant="outlined"
							startIcon={<EdIcon>add</EdIcon>}
						>
							ADD ANSWER
						</Button>
					</div>
				</FlexLayout>
				<FlexLayout alignItems="center">
					<EdFormControl
						required
						disabled={currentEntity.active}
						width="5rem"
						type="number"
						control={control}
						name="weight"
						label="Weight"
					/>
					<Spacer mx="0.5rem" />
					<Typography fontWeight="600">Points</Typography>
				</FlexLayout>
				<FlexLayout gridGap="1rem">
					<EdFormControl
						label="Tags"
						disabled={currentEntity.active}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<EdIcon mIconType="Regular">local_offer</EdIcon>
								</InputAdornment>
							),
						}}
						SelectProps={{
							multiple: true,
							renderValue: (value) => {
								const values = Array.from(value as number[]);
								return (
									<>
										{values.map((v) => {
											const tag = dropdownTags.find((_) => _.id === v);
											return <>{tag?.name},</>;
										})}
									</>
								);
							},
						}}
						control={control}
						name="tags"
						select
						multiple
						renderValues={(value) => {
							return (
								<>
									<Spacer mb="1rem" />
									<FlexLayout
										// maxWidth={"17rem"}
										overflow="auto"
										py={'0.4rem'}
										flexWrap="wrap"
										gridGap="0.5rem"
									>
										{dropdownTags
											.filter((_) => watchTags.includes(_.id))
											.map((tag) => {
												return (
													<TagChip
														bgColor={tag.theme}
														icon={<EdIcon>local_offer</EdIcon>}
														label={tag.name}
													/>
												);
											})}
									</FlexLayout>
								</>
							);
						}}
					>
						{dropdownTags.map((tag, index) => {
							return (
								<MenuItem value={tag.id} key={`tag-${tag.id}`}>
									<Checkbox
										sx={{ color: tag.theme }}
										checked={watchTags.includes(tag.id)}
									/>
									<ListItemText primary={tag.name} />
								</MenuItem>
							);
						})}
					</EdFormControl>
					{!currentEntity.active && <AddTag setAddedId={setAddedId} />}
				</FlexLayout>
				<FlexLayout>
					<EdFormControlLabel>Feedback</EdFormControlLabel>
					<Controller
						control={control}
						name="feedback"
						render={({ field: { onChange, value, ...props }, fieldState }) => {
							return (
								<TinyMceControl
									disabled={currentEntity.active}
									{...props}
									value={value}
									onEditorChange={(e) => onChange(e)}
									onInit={(evt, editor) => (feedbackEditorRef.current = editor)}
								/>
							);
						}}
					/>
				</FlexLayout>
			</GridLayout>

			{!currentEntity.active && (
				<FlexLayout mt="1.688rem" justifyContent="flex-end">
					<Button
						onClick={() => history.goBack()}
						variant="contained"
						color="warning"
					>
						Cancel
					</Button>
					<Spacer mx="0.5rem" />
					<Button
						onClick={handleSubmit(onSave)}
						disabled={isSubmitting || !isValid || !isDirty}
						variant="contained"
					>
						Save
					</Button>
				</FlexLayout>
			)}
			<DevTool control={control} />
		</FlexLayout>
	);
};

export default EditMCQForm;
