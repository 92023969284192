import {
	ClassroomType,
	PaymentMethod,
	ClassroomSubType,
	ClassroomLanguage,
	ClassroomStatus,
} from '../Types/Classroom.types';
import { array, boolean, number, object, string, StringSchema } from 'yup';
import { Flatten } from '@Utils/types/Flatten';
import { ref } from 'yup';

class PaymentMethodSchema extends StringSchema<PaymentMethod> {
	static create() {
		return new PaymentMethodSchema();
	}
}
class ClassroomTypeSchema extends StringSchema<ClassroomType> {
	static create() {
		return new ClassroomTypeSchema();
	}
}
class ClassroomSubTypeSchema extends StringSchema<ClassroomSubType> {
	static create() {
		return new ClassroomSubTypeSchema();
	}
}

class ClassroomLanguageSchema extends StringSchema<ClassroomLanguage> {
	static create() {
		return new ClassroomLanguageSchema();
	}
}
class ClassroomStatusSchema extends StringSchema<ClassroomStatus> {
	static create() {
		return new ClassroomStatusSchema();
	}
}
const isType2 = [['year'], (id: number) => id === 6];

const SCHEMA_PART_1 = object({
	id: number().notRequired(),
	title: string().trim().min(1).required(),
	// instructor_id: number().required(),
	// category_id: number().required(),
	// description: string().trim().required(),
	// prerequisites: string().nullable(),
});
const SCHEMA_PART_2 = object({
	code: string().required(),
	label: string().required(),
	language: ClassroomLanguageSchema.create().required(),
	type: ClassroomTypeSchema.create().required(),
	sub_type: ClassroomSubTypeSchema.create().required(),
});
const SCHEMA_PART_3 = object({
	payment_methods_allowed: PaymentMethodSchema.create().required(),
	status: ClassroomStatusSchema.create().required(),
	active: boolean().default(false).notRequired(),
	accessible: boolean().notRequired(),
	has_admission: boolean().notRequired(),
	admission_status: boolean().notRequired(),
	auto_accept_admission: boolean().default(false),
	instructor_id: number().required(),
	category_id: number().required(),
	description: string(),
	prerequisites: string().nullable(),
});

const SCHEMA_PART_4 = object({
	thumbnail: string().min(1).required(),
	weight: number()
		.positive()
		.nullable(true)
		.transform((v, o) => (o === '' ? null : v))
		.notRequired(),
	current_course: number().nullable().notRequired(),
	// .transform((v, o) => v === Nan ? nu)
});

const SCHEMA_PART_5 = object({
	educationTypes: array(number().required()).min(1).required(),
	educationYears: array(number().required()).min(1).required(),
	educationLanguages: array(number().required()).min(1).required(),
	educationSections: array(number().required()).min(1),

});

export const FULL_SCHEMA = SCHEMA_PART_1.concat(SCHEMA_PART_2)
	.concat(SCHEMA_PART_3)
	.concat(SCHEMA_PART_4)
	.concat(SCHEMA_PART_5);
export type ClassroomForm = typeof SCHEMA_PART_1.__outputType &
	typeof SCHEMA_PART_2.__outputType &
	typeof SCHEMA_PART_3.__outputType &
	typeof SCHEMA_PART_4.__outputType &
	typeof SCHEMA_PART_5.__outputType;

export type FlattenClassroomForm = Flatten<ClassroomForm>;
