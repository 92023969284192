import styled from 'styled-components';

export const AdmissionQuestionContainer = styled.div`
	display: grid;
	grid-template-columns: 10% 80% 10%;
	grid-template-rows: repeat(2, auto);
	gap: 8px 0;
	line-height: 28px;
	margin: 32px 0;
`;

export const AdmissionQuestion = styled.p`
	grid-column: 1 / 3;
	white-space: pre-wrap;
	font-weight: 600;
	color: #2f2e41;
`;

export const AdmissionQuestionAnswer = styled.p`
	letter-spacing: 0.12px;
	color: #2f2e41;
`;

export const AdmissionQuestionType = styled.p`
	color: #000000de;
	font-weight: 500;
	text-transform: capitalize;
`;

export const AdmissionAnswerText = styled.p`
	color: #00000099;
	font-style: italic;
`;
