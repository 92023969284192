import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import TinyMceControl from '@Components/UI/TinyMceControl/TinyMceControl';
import { FlexLayout } from '@eduact/ed-system';
import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Icon, InputAdornment } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { Spacer } from '@Styled/utilities';
import React, { useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ContentConfig } from '../../../../Constants/CourseContent.constants';
import { ASSIGNMENT_SCHEMA } from '../../Schema/Assignment.schema';
import { AssignmentRequester } from '../../Services/Assignment.requester';
import { Assignments } from '../../Types/Assignment.types';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { uploadDocument } from '@Services/uploads/uploads.req';
import AppRegistrationTwoToneIcon from '@mui/icons-material/AppRegistrationTwoTone';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';

const EditAssignmentInfo = () => {
	const dispatch = useDispatch();
	const editorRef = useRef<any>(null);
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();

	const { currentEntity } = useTypedSelector((state) => state.Assignments);
	const { currentCourse } = useTypedSelector((state) => state.classroom);

	const [openModel, setOpenModel] = useState<boolean>(false);
	const [switchModel, setSwitchModel] = useState<boolean>(false);
	const handleModelOpen = () => {
		setOpenModel(true);
	};
	const handleModelClose = (close: boolean, confirm?: boolean) => {
		try {
			if (confirm) {
				handleResetFile();
			}
			setOpenModel(false);
		} catch (e) {
			const msg = e.response?.data.message || "Couldn't delete admin";
			displaySnackbar('error', msg);
		}
	};
	const handleSwitchModelOpen = () => {
		setSwitchModel(true);
	};
	const handleSwitchModelClose = (close: boolean, confirm?: boolean) => {
		try {
			if (confirm) {
				setToggle(!toggle);
			}
			setSwitchModel(false);
		} catch (e) {
			const msg = e.response?.data.message || "Couldn't delete admin";
			displaySnackbar('error', msg);
		}
	};
	const defaultValues = React.useMemo<Assignments | undefined>(() => {
		if (currentEntity) {
			const defaultT: Assignments = {
				id: currentEntity.assignment.id,
				unit: {
					name: currentEntity?.unit?.name,
					course_id: currentEntity?.unit?.course_id,
					type_id: currentEntity?.unit?.type_id,
				},
				assignment: {
					duration: currentEntity?.assignment?.duration,
					overallScore: currentEntity?.assignment?.overallScore,
					question: currentEntity?.assignment?.question,
					questionType: currentEntity?.assignment?.questionType,
					showStatus: currentEntity?.assignment?.showStatus,
				},
			};
			return defaultT;
		}
	}, []);

	const { getValues, control, formState, watch, setValue, resetField } =
		useForm<Assignments>({
			resolver: yupResolver(ASSIGNMENT_SCHEMA),
			mode: 'all',
			defaultValues: defaultValues,
		});

	const watchType = watch('assignment.questionType');
	const isText = useMemo(() => {
		return watchType === 'text' ? true : false;
	}, [watchType]);

	const [toggle, setToggle] = useState<boolean>(isText);
	const [type, setType] = useState<string | undefined>(
		currentEntity?.assignment?.questionType
	);

	const watchQues = watch('assignment.question');
	const watchStatus = watch('assignment.showStatus');
	const question = useMemo(() => {
		return watchQues?.toString();
	}, [watchQues]);

	const UploadDocument = async (blob: File | Blob | null) => {
		try {
			const {
				data: {
					data: { uri, extension },
				},
				status,
			} = await uploadDocument({
				document: blob,
				folder: 'documents',
			});

			setValue('assignment.question', uri, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
			setValue('assignment.extension', extension);
			return status === 200;
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Upload File ';
			displaySnackbar('error', msg);
		}
	};

	const onSave = async (form: Assignments) => {
		try {
			const {
				data: { data },
			} = await AssignmentRequester.getInstance().update({
				...form,
				unit: {
					type_id: 6,
					course_id: currentCourse?.id,
					name: form.unit.name,
				},
				assignment: {
					question: question,
					duration: form.assignment.duration,
					overall_score: form.assignment.overallScore,
					question_type: type,
					show_status: form.assignment.showStatus,
				},
			});
			displaySnackbar('success', `Assignment updated successfully`);
			history.goBack();
		} catch (e) {
			const msg = e?.data.message || 'Unable to updated Assignment ';
			displaySnackbar('error', msg);
		}
	};

	const handleResetFile = () => {
		try {
			resetField('assignment.question', { keepError: false });
		} catch (error) {
		
		}
	};

	const returnNote = () => {
		return (
			<>
				<p>Are you sure to switch question type ?</p>
				<span>NB: question will be erased</span>
			</>
		);
	};

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to delete this File"
				open={openModel}
				handleClose={handleModelClose}
			/>
			<EdConfirmModal
				text={returnNote() as any}
				open={switchModel}
				handleClose={handleSwitchModelClose}
			/>
			<FlexLayout flexDirection="column" px="2.625rem" flex="1">
				<EdFormControl
					size="small"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								{ContentConfig['assignment'].icon}
							</InputAdornment>
						),
					}}
					control={control}
					label="Unit Name"
					name="unit.name"
				/>
				<Spacer mb="1.625rem" />

				<FlexLayout
					flexDirection="column"
					gridGap="0.5rem"
					alignItems="flex-end"
					mt="3rem"
					mb="3.063rem"
				>
					{toggle ? (
						<FlexLayout
							flexDirection="column"
							alignItems="flex-end"
							gridGap="0.5rem"
							width="100%"
						>
							<FlexLayout
								alignItems="end"
								gridGap="0.688rem"
								onClick={() => {
									handleSwitchModelOpen();
									setType('attachment');
									setValue('assignment.question', '');
								}}
							>
								<Switch>Switch to Attachment</Switch>
								<AttachFile fontSize="small" />
							</FlexLayout>
							<FlexLayout flex="1" width="100%">
								<Controller
									control={control}
									name="assignment.question"
									render={({ field: { value, onChange, ...props } }) => {
										return (
											<TinyMceControl
												{...props}
												value={value as any}
												onEditorChange={(e) => onChange(e)}
												onInit={(evt, editor) => (editorRef.current = editor)}
											/>
										);
									}}
								/>
							</FlexLayout>
						</FlexLayout>
					) : (
						<FlexLayout justifyContent="space-between" width="100%">
							<FlexLayout gridGap="1.313rem" alignItems="center">
								<EdUploadFile
									aspectRation={1}
									folder="documents"
									uploadLabel="UPLOAD FILE"
									uploadIcon={<Icon>upload_file</Icon>}
									control={control}
									name="assignment.question"
									label=""
									multiple
									type="document"
									onFileUpload={UploadDocument as any}
								/>
								<DeleteIconStyle
									fontSize="small"
									color="disabled"
									onClick={() => handleModelOpen()}
								/>
							</FlexLayout>
							<FlexLayout
								alignItems="center"
								gridGap="0.688rem"
								onClick={() => {
									handleSwitchModelOpen();
									setType('text');
									setValue('assignment.question', '');
								}}
							>
								<Switch>Switch to text</Switch>
								<AppRegistrationTwoToneIcon fontSize="small" />
							</FlexLayout>
						</FlexLayout>
					)}
				</FlexLayout>

				<FlexLayout flexDirection="column" gridGap="3.313rem">
					<FlexLayout alignItems="center" gridGap="0.938rem">
						<EdFormControl
							control={control}
							width="4.794rem"
							type="number"
							inputMode="numeric"
							name={`assignment.overallScore`}
							label="Score"
							required
						/>
						<p>Points</p>
					</FlexLayout>
					<FlexLayout alignItems="center" gridGap="0.938rem">
						<EdFormControl
							control={control}
							width="4.794rem"
							type="number"
							inputMode="numeric"
							name={`assignment.duration`}
							label="Duration"
							required
						/>
						<p>Days</p>
					</FlexLayout>

					<FlexLayout
						display="flex"
						alignItems="center"
						gridGap="0.938rem"
						mt="0.625rem"
					>
						<Controller
							name="assignment.showStatus"
							control={control}
							render={({ field }) => (
								<FlexLayout
									display="flex"
									alignItems="center"
									gridGap="0.938rem"
									mt="0.625rem"
								>
									<StatusBox
										checked={watchStatus ? true : false}
										{...field}
										id="Status"
									/>
									<LabelStyle>Show status (passed or failed)</LabelStyle>
								</FlexLayout>
							)}
						/>
					</FlexLayout>
				</FlexLayout>

				<FlexLayout mt="2.688rem" mb="5.688rem" justifyContent="flex-end">
					<Button
						variant="contained"
						color="warning"
						onClick={() => history.goBack()}
					>
						Cancel
					</Button>
					<Spacer mx="0.5rem" />
					<Button
						onClick={() => {
							const data = getValues();
							onSave(data);
						}}
						disabled={!formState.isDirty}
						variant="contained"
					>
						Save
					</Button>
				</FlexLayout>
			</FlexLayout>
		</>
	);
};

const EdFormControlStyle = styled(EdFormControl)`
	input {
		width: 18px;
		height: 17px;
	}
`;
const AttachFile = styled(AttachFileIcon)`
	transform: rotate(-45deg);
`;
const Switch = styled.p`
	font-size: 1rem;
	font-weight: 500;
	color: #2f2e41;
	text-decoration: underline;
	cursor: pointer;
`;
const LabelStyle = styled.label`
	font-size: 0.9rem;
	color: ${(props) => props.theme.palette.cadet.main};
	font-weight: normal;
	margin-top: 2px;
`;
const DeleteIconStyle = styled(DeleteIcon)`
	cursor: pointer;
`;
const StatusBox = styled(Checkbox)`
	padding: 0;
	svg,
	input {
		width: 20px;
		height: 20px;
	}
`;
export default EditAssignmentInfo;
