import React, { FC, useCallback, useMemo, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { Accordion, AccordionSummary, Tooltip } from '@mui/material';
import DragButton from '@Components/UI/Buttons/EdDragButton/EdDragButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TourIcon from '@mui/icons-material/Tour';
import { useDataProvider } from './DataProvider';
import { CourseWithPrerequisites } from '@Services/classrooms/classrooms.res.types';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { useSnackbar } from '@Providers/useSnackbar';
import { useTypedSelector } from '@Features/store';

type Props = {
	sequence: CourseWithPrerequisites;
	dragHandlers?: DraggableProvidedDragHandleProps | null;
	index: number;
};

const SingleSequence: FC<Props> = ({ sequence, dragHandlers, index }) => {
	const {
		inSequence,
		setInSequence,
		outSequence,
		setOutSequence,
		current,
		setCurrent,
	} = useDataProvider<CourseWithPrerequisites>();
	const { displaySnackbar } = useSnackbar();
	const [openModel, setOpenModel] = useState<boolean>(false);
	const [openCurrentModel, setOpenCurrentModel] = useState<boolean>(false);
	const [selected, setSelected] = useState<
		CourseWithPrerequisites | undefined
	>();

	const getSequenceText = useMemo(() => {
		var text = sequence?.name.replace(/(<([^>]+)>)/gi, '');
		const splittedText = text.split('\n');
		if (splittedText[0] === '') return 'Expand to view the question';
		return splittedText[0];
	}, [sequence]);
	const handleModelOpen = () => {
		if (sequence?.id === current) {
			displaySnackbar('error', "Can't delete current course");
		} else {
			setOpenModel(true);
		}
	};

	const selectedSequence = useCallback(
		(sequence: CourseWithPrerequisites) => {
			const seqIndex = inSequence?.indexOf(sequence);
			if (seqIndex !== undefined && seqIndex > -1) {
				inSequence?.splice(seqIndex as number, 1);
				const sequenceArray: CourseWithPrerequisites[] | undefined =
					inSequence?.filter((_) => _.sequence !== null);
				setInSequence(sequenceArray);
				const newSeq = Object.assign({}, sequence, { sequence: null as null });
				outSequence?.push(newSeq);
				setOutSequence(outSequence);
			}
		},
		[sequence]
	);

	const handleModelClose = (close: boolean, confirm?: boolean) => {
		if (confirm) {
			selectedSequence(selected as CourseWithPrerequisites);
			displaySnackbar('success', 'course deleted successfully');
		}
		setOpenModel(false);
	};

	const handleCurrentModelOpen = () => {
		setOpenCurrentModel(true);
	};

	const handleCurrentModelClose = (close: boolean, confirm?: boolean) => {
		if (confirm) {
			setCurrent && setCurrent(sequence.id);
			displaySnackbar('success', 'current course set successfully');
		}
		setOpenCurrentModel(false);
	};

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want remove this Course?"
				open={openModel}
				handleClose={handleModelClose}
			/>
			<EdConfirmModal
				text="Are you sure you want to set this Course Current?"
				open={openCurrentModel}
				handleClose={handleCurrentModelClose}
			/>
			<StyledAccordion expanded={false}>
				<AccordionSummary
					onClick={(e: any) => {
						e.stopPropagation();
						e.preventDefault();
					}}
				>
					<FlexLayout justifyContent="space-between" width="100%">
						<FlexLayout
							justifyContent="space-between"
							flex="2"
							alignItems="center"
						>
							<FlexLayout alignItems="center">
								{dragHandlers && <DragButton dragHandlers={dragHandlers} />}
								<QuestionNumber>{index + 1}.</QuestionNumber>
								<QuestionContent>{getSequenceText}</QuestionContent>
								{current === sequence.id && (
									<TourIcon color="primary" fontSize="large" />
								)}
							</FlexLayout>
						</FlexLayout>

						<FlexLayout
							flex="0.5"
							justifyContent="flex-end"
							alignItems="center"
						>
							{current !== sequence.id && (
								<TooltipStyle
									title="Set as current course"
									placement="left-start"
									arrow
								>
									<TourIcon
										color="primary"
										fontSize="large"
										onClick={() => {
											handleCurrentModelOpen();
										}}
									/>
								</TooltipStyle>
							)}

							<Spacer mx="0.5rem" />
							<TooltipStyle
								title="Remove From Sequence"
								placement="left-start"
								arrow
							>
								<RemoveCircleOutlineIcon
									onClick={(e) => {
										setSelected(sequence);
										handleModelOpen();
									}}
								/>
							</TooltipStyle>
						</FlexLayout>
					</FlexLayout>
				</AccordionSummary>
			</StyledAccordion>
		</>
	);
};

const StyledAccordion = styled(Accordion)`
	flex: 1;
	margin: 1rem 0 !important;
	&:hover {
		background: #f5f5f5;
	}
`;

const QuestionNumber = styled.p`
	font-weight: bold;
	margin: 0 1rem;
`;

export const QuestionContent = styled.div`
	min-width: 8.563rem;
	max-width: 17.563rem;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const TooltipStyle = styled(Tooltip)``;

export default SingleSequence;
