import { TOKEN, USER } from '@Constants/cookies';
import { loginRequest } from '@Services/auth/auth.req';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthState, LoginPayload } from './authSlice.types';
import { LoginResponse } from '@Services/auth/auth.res.types';

/* side effects */
export const login = createAsyncThunk<
	LoginResponse,
	LoginPayload,
	{ rejectValue: string }
>('auth/login', async ({ identifier, password }, thunkApi) => {
	try {
		const { data } = await loginRequest(identifier, password);
		return data;
	} catch (error: any) {
		const msg = error.response?.data.message || 'Login failed';
		return thunkApi.rejectWithValue(msg);
	}
});

/* slice */
const initialState: AuthState = {
	isAuth: false,
	user: null,
	loaders: {
		login: null,
	},
	errors: {
		login: '',
	},
	grants: new Map<string, string>(),
};
const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginCheck(state) {
			state.isAuth = !!localStorage.getItem(TOKEN);
			const prevUser = localStorage.getItem(USER);
			state.user = prevUser ? JSON.parse(prevUser) : null;
			if (state.user)
				for (let i = 0; i < state.user.grants?.length; i++)
					state.grants.set(
						state.user.grants[i].code,
						state.user.grants[i].name
					);
		},

		logout(state) {
			state.grants = new Map();
			state.isAuth = false;
			localStorage.clear();
			// Cookies.remove(TOKEN);
			// Cookies.remove(USER);
		},
	},
	extraReducers: (builder) => {
		/* login builder */
		builder
			.addCase(login.pending, (state) => {
				state.loaders.login = true;
				state.errors.login = '';
			})
			.addCase(login.fulfilled, (state, { payload }) => {
				localStorage.setItem(TOKEN, payload.data.token);
				localStorage.setItem(USER, JSON.stringify(payload.data.user));
				state.isAuth = true;
				state.user = payload.data.user;
				state.loaders.login = false;
				for (let i = 0; i < state.user.grants.length; i++) {
					state.grants.set(
						state.user.grants[i].code,
						state.user.grants[i].name
					);
				}
			})
			.addCase(login.rejected, (state, action) => {
				state.errors.login = action.payload;
				state.loaders.login = undefined;
			});
	},
});

export default authSlice.reducer;
export const { loginCheck, logout } = authSlice.actions;
