import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { DASHBOARD_BASE_ROUTE } from '@Constants/routes';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem } from '@mui/material';
import Permissions from '@Pages/Assistants/Components/AssistantPermissions/Permissions';
import { GenderTypes } from '@Pages/Assistants/Constants/AssistantsForm.constants';
import { AssistantsRequester } from '@Pages/Assistants/Services/Assistants.Requester';
import { useSnackbar } from '@Providers/useSnackbar';
import { Divider, FlexLayout, GridLayout } from '@Styled/utilities';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ASSISTANT_SCHEMA } from '../../Schema/Assistant.Schema';
import { Assistants } from '../../Types/Assistants.types';

const AddAssistant = () => {
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();

	const { getValues, control, formState, watch, ...formMethods } =
		useForm<Assistants>({
			resolver: yupResolver(ASSISTANT_SCHEMA),
			mode: 'all',
			defaultValues: {},
		});

	const onSave = async (form: Assistants) => {
		try {
			const {
				data: { data },
			} = await AssistantsRequester.getInstance().create(form);
			displaySnackbar(
				'success',
				`Assistant ${data.first_name} created successfully`
			);
			history.push(
				`${DASHBOARD_BASE_ROUTE}/assistants/edit/assistant/${data.uuid}`
			);
		} catch (e) {
			const msg = e?.data.message;
			displaySnackbar('error', msg);
		}
	};

	return (
		<FormProvider
			control={control}
			getValues={getValues}
			formState={formState}
			watch={watch}
			{...formMethods}
		>
			<form>
				<GridLayout
					gridGap={'2rem'}
					gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
					marginRight="5.5rem"
					width="75%"
					padding="2.438rem 1.25rem"
				>
					<EdFormControl
						control={control}
						name="first_name"
						label="First Name"
						required
					/>
					<EdFormControl
						control={control}
						name="last_name"
						label="Last Name"
						required
					/>
					<EdFormControl
						control={control}
						required
						name="email"
						label="Email"
					/>
					<EdFormControl
						control={control}
						required
						name="phone_number"
						label="Phone Number"
					/>
					<EdFormControl
						control={control}
						required
						name="password"
						label="Password"
					/>
					<EdFormControl control={control} select name="gender" label="Gender">
						{GenderTypes.map((_, index) => (
							<MenuItem key={`${index}-${_}`} value={_}>{`${_}`}</MenuItem>
						))}
					</EdFormControl>
					<EdFormControl
						control={control}
						required
						name="instructor.label"
						label="Label"
					/>
					<EdFormControl
						control={control}
						switch
						name="instructor.is_active"
						label="Active"
						defaultChecked={true}
					/>
				</GridLayout>
				<FlexLayout width="75%" flexDirection="column">
					<Divider label="Permissions" />
					<Permissions name="instructor.grants" />
				</FlexLayout>
				<FlexLayout
					justifyContent="flex-end"
					gridGap="1rem"
					width="75%"
					marginTop="2.938rem"
				>
					<Button
						variant="contained"
						color="warning"
						disabled={!formState.isDirty}
						onClick={() => history.push('/dashboard/assistants')}
					>
						CANCEL
					</Button>
					<Button
						variant="contained"
						disabled={!formState.isDirty || !formState.isValid}
						onClick={() => {
							const data = getValues();
							onSave(data);
						}}
					>
						Save
					</Button>
				</FlexLayout>
			</form>
		</FormProvider>
	);
};

export default AddAssistant;
