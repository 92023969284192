import admin from '@Features/admins/adminSlice';
import { AdminState } from '@Features/admins/adminSlice.types';
import auth from '@Features/auth/authSlice';
import { AuthState } from '@Features/auth/authSlice.types';
import classroom from '@Features/classrooms/classroomSlice';
import roles from '@Pages/Roles/Features/rolesSlice';
import { ClassroomSliceState } from '@Features/classrooms/classroomSlice.types';
import newsFeed from '@Pages/NewsFeeds/Slices/NewsFeedSlice';
import { PermissionState } from '@Features/permissions/permissionSlice.types';
import { StudentSliceState } from '@Features/students/studentSlice.types';
import { CategoriesState } from '@Pages/Categories/Slices/CategoriesSlice.Types';
import { InvoiceState } from '@Features/invoices/invoicesSlice.types';
import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import student from '@Features/students/studentSlice';
import walletCodes from '@Features/walletCodes/walletCodesSlice';
import permission from '@Features/permissions/permissionSlice';
import instructor from '@Pages/Instructors/Slices/instructorsSlice';
import category from '@Pages/Categories/Slices/CategoriesSlice';
import invoice from '@Features/invoices/invoicesSlice';
import classroomTabs from '@Features/classrooms/tabsSlice';
import { NewsFeedState } from '@Pages/NewsFeeds/Slices/NewsFeedSlice.Types';
import { ScratchCardsState } from './scratchCards/scratchCardsSlice.types';
import scratchCard from '@Features/scratchCards/scratchCardsSlice';
import { SettingsState } from './settings/settingsSlice.types';
import settings from './settings/settingsSlice';
import educational, { educationalState } from './classrooms/educationalSlice';
import analysis, { EnrolledAnalysisState } from './classrooms/EnrolledSlice';
import { NotificationsStateType as NotificationsSliceState } from '@Features/notifications/notificationsSlice.types';
import { AdmissionFormStateType } from '@Features/classrooms/admissionFormSlice.types';
import classroomAdmissionForm from '@Features/classrooms/admissionFormSlice';
import dropdowns from '@Features/dropdowns/dropdownsSlice';
import { DropdownsState } from '@Features/dropdowns/dropdownsSlice.types';
import { InitialStateType as ClassroomTabsSliceState } from './classrooms/tabsSlice.types';
import notifications from '@Features/notifications/notificationsSlice';
import { WalletCodesState } from './walletCodes/walletCodesSlice.types';
import exports, { ExportClassroomsState } from './classrooms/exportSlice';
import { IPsState } from './IPs/ipSlice.types';
import IPs from './IPs/ipsSlice';
import { ClassroomAdmissionResponse } from '@Pages/ClassRooms/Types/ClassroomAdmission.types';
import { Course, Tab } from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import ClassroomTabs from '@Pages/ClassRooms/Slices/ClassroomTabs/ClassroomTabs.slice';
import { RolesSliceState } from '@Pages/Roles/Features/roleSilce.types';
import { Admin } from '@Pages/Admin/Types/admin.types';
import { AdminSlice } from '@Pages/Admin/Slice/AdminSlice';
import {
	LoadingReducer,
	LoadingState,
} from '@Providers/LoadingProvider/Reducer/LoadingReducer';
import { InstructorSliceState } from '@Pages/Instructors/Slices/instructorsSlice.types';
import { RechargeCodesSlice } from '@Pages/RechargeCodes/Slices/RechargeCodes.slice';
import { RechargeCode } from '@Pages/RechargeCodes/Types/RechargeCodes.types';
import { BaseSliceState } from '@Types/slices';
import { ScratchCardsSlice } from '@Pages/ScratchCards/Slices/ScratchCards.slice';
import { ScratchCard } from '@Pages/ScratchCards/Types/ScratchCards.types';
import {
	Slot,
	RegisteredUser,
	Webinar,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Webinar/Types/Webinar.types';

import { Test } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Types/Test/Test.types';
import { TestSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Slice/TestSlice';
import {
	Tag,
	TestQuestion,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Types/TestQuestion';
import { TestQuestionsSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Slice/TestQuestions/TestQuestionsSlice';
import { TagsSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Slice/Tags/TagsSlice';
import { Invoice } from '@Pages/Invoices/Types/Invoices.types';
import { InvoicesSlice } from '@Pages/Invoices/Slices/Invoices.slice';
import { WebinarSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Webinar/Slices/Webinar.slice';
import { RegisteredUsersSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Webinar/Slices/Registered.slice';
import { Document } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Types/Documents.types';
import { DocumentsSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Slices/Documents.slice';
import { Content } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Web Content/Types/WebContent.types';
import { webContentSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Web Content/Slice/WebContent.slices';
import { Unit } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Contents/Types/Units.types';
import { UnitSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Contents/Slices/Units.slice';
import { WebinarSlotSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Webinar/Slices/Slot.slice';
import { TestModel } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Types/TestModel.types';
import { TestModelSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Slice/TestModel/TestModel.slice';
import {
	SingleStudentData,
	walletLog,
	ClassRoom,
	Course as CourseTab,
	TimelineLog,
} from '@Services/students/students.res.types';
import { VideoType } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/VideoUnit/Types/Video.types';
import { VideoUnitSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/VideoUnit/Slices/Video.slice';
import { SequenceManagement } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/SequenceManagement/types/sequence.types';
import { SequenceManagementSlice } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/SequenceManagement/Slice/Sequence.slice';
import { EnrolledUser } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Types/UserProgress.types';
import { UserUnitsSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Slice/users.Slice';
import { StudentsSlice } from '@Pages/Students/Slice/Students.Slice';
import { CoursesTabSlice } from '@Pages/Students/StudentTabs/Courses/Slices/CourseTab.Slices';
import { WalletLogsSlice } from '@Pages/Students/StudentTabs/Wallet/Slices/WalletLogs.Slices';
import { ClassRoomTabSlice } from '@Pages/Students/StudentTabs/ClassRooms/Slices/ClassRoomsTab.Slices';
import { Import } from '@Pages/Imports/Types/Imports.types';
import { ImportsSlice } from '@Pages/Imports/Slice/Imports.slice';
import { InvoicesTabSlice } from '@Pages/Students/StudentTabs/Invoices/Slices/Invoices.Slice';
import { RechargeCodeTabSlice } from '@Pages/Students/StudentTabs/RechargeCodes/Slices/RechargeCode.Slices';
import { ScratchCodeTabSlice } from '@Pages/Students/StudentTabs/ScratchCards/Slices/ScratchCards.Slice';
import { TimelineTabSlice } from '@Pages/Students/StudentTabs/Timeline/Slices/Timeline.Slices';
import { baseApiSlice } from '@Features/baseApiSlice';
import { Assistants, Grants } from '@Pages/Assistants/Types/Assistants.types';
import { AssistantsSlice } from '@Pages/Assistants/Slices/Assistants.Slices';
import { GrantsSlice } from '@Pages/Assistants/Slices/Grants/Grants.slices';
import { TestSummarySlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseReports/Slices/TestSummary.slice';
import { Attempt } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseReports/Types/TestSummary.types';
import { TestsReportsSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseReports/Slices/TestsReports.slice';
import {
	CourseSingleTest,
	CourseTestsSummary,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseReports/Types/TestReports.types';
import { SingleTestReportSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseReports/Slices/SingleTestReport.slice';
import courseOverviewSlice, {
	CourseOverviewReportState,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseReports/Slices/ReportOverview.slice';
import { SubmissionsSlice } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Submissions/Slices/Submissions.slice';
import {
	AssignmentSubmission,
	Submissions,
} from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Submissions/Types/Submissions.types';
import {
	StudentTestsPayload,
	TestsCount,
} from '@Pages/Students/StudentTabs/ClassRooms/Pages/Components/Tests/Types/Tests.types';
import { StudentTestsSlice } from '@Pages/Students/StudentTabs/ClassRooms/Pages/Components/Tests/Slices/StudentTestsSlice';
import { StudentTestsCountSlice } from '@Pages/Students/StudentTabs/ClassRooms/Pages/Components/Tests/Slices/TestsCountSlices';
import {
	StudentWebinarAnalytic,
	StudentWebinarsCount,
} from '@Pages/Students/StudentTabs/ClassRooms/Pages/Components/Webinars/Types/StudentWebinars.types';
import { WebinarsCountSlice } from '@Pages/Students/StudentTabs/ClassRooms/Pages/Components/Webinars/Slices/WebinarsCount.slice';
import { StudentWebinarsSlice } from '@Pages/Students/StudentTabs/ClassRooms/Pages/Components/Webinars/Slices/StudentWebinars.slice';
import { Assignments } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Assignment/Types/Assignment.types';
import { AssignmentsSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Assignment/Slices/Assignment.Slice';
import { StudentAttendancesCountSlice } from '@Pages/Students/StudentTabs/ClassRooms/Pages/Components/Attendances/Slices/TestsCountSlices';
import {
	AttendancesCount,
	AttendancesPayload,
} from '@Pages/Students/StudentTabs/ClassRooms/Pages/Components/Attendances/Types/Attendaces.types';
import { StudentAttendancesSlice } from '@Pages/Students/StudentTabs/ClassRooms/Pages/Components/Attendances/Slices/StudentTestsSlice';
import { AssignmentsSubmissionsSlice } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Submissions/Slices/Assignment.slice';
import { NotesSlice } from '@Pages/Assistants copy/Slices/Notes.Slices';
import { Note } from '@Pages/Assistants copy/Types/Notes.types';
import { GroupsSlice } from '@Pages/ClassRooms/Slices/Groups/Groups.slice';
import { GroupStudentsSlice } from '@Pages/ClassRooms/Slices/GroupStudents/GroupStudents.slice';
import { Group } from '@Pages/ClassRooms/Types/Groups.types';
import { TestImport } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Pages/TestImports/Types/Imports.types';
import { TestImportsSlice } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Pages/TestImports/Slice/Imports.slice';

export interface RootState {
	auth: AuthState;
	student: StudentSliceState;
	Students: BaseSliceState<SingleStudentData>;
	Grants: BaseSliceState<Grants>;
	Assistants: BaseSliceState<Assistants>;
	CoursesTab: BaseSliceState<CourseTab>;
	InvoicesTab: BaseSliceState<Invoice>;
	RechargeCodeTab: BaseSliceState<RechargeCode>;
	ScratchCodeTab: BaseSliceState<ScratchCard>;
	TimelineTab: BaseSliceState<TimelineLog>;
	WalletLogs: BaseSliceState<walletLog>;
	ClassRoomTab: BaseSliceState<ClassRoom>;
	admin: AdminState;
	permission: PermissionState;
	newsFeed: NewsFeedState;
	IPs: IPsState;
	scratchCard: ScratchCardsState;
	classroom: ClassroomSliceState;
	roles: RolesSliceState;
	educational: educationalState;
	analysis: EnrolledAnalysisState;
	exports: ExportClassroomsState;
	classroomTabs: ClassroomTabsSliceState;
	instructor: InstructorSliceState;
	category: CategoriesState;
	invoice: InvoiceState;
	settings: SettingsState;
	dropdowns: DropdownsState;
	notifications: NotificationsSliceState;
	classroomAdmissionForm: AdmissionFormStateType;
	walletCodes: WalletCodesState;
	ClassroomTabs: BaseSliceState<Tab>;
	Admins: BaseSliceState<Admin>;
	EnrolledUsersUnits: BaseSliceState<EnrolledUser>;
	Webinars: BaseSliceState<Webinar>;
	Slots: BaseSliceState<Slot>;
	WebContents: BaseSliceState<Content>;
	RegisteredUsers: BaseSliceState<RegisteredUser>;
	VideoUnit: BaseSliceState<VideoType>;
	SequenceManagement: BaseSliceState<SequenceManagement>;
	Submissions: BaseSliceState<Submissions>;
	TestSummary: BaseSliceState<Attempt>;
	TestsReports: BaseSliceState<CourseTestsSummary>;
	SingleTestReport: BaseSliceState<CourseSingleTest>;
	Loading: LoadingState;
	RechargeCodes: BaseSliceState<RechargeCode>;
	ScratchCards: BaseSliceState<ScratchCard>;
	Tests: BaseSliceState<Test>;
	TestQuestions: BaseSliceState<TestQuestion>;
	Tags: BaseSliceState<Tag>;
	Invoices: BaseSliceState<Invoice>;
	Documents: BaseSliceState<Document>;
	Units: BaseSliceState<Unit>;
	StudentTestsCount: BaseSliceState<TestsCount>;
	TestModels: BaseSliceState<TestModel>;
	Imports: BaseSliceState<Import>;
	TestImports: BaseSliceState<TestImport>;
	StudentTests: BaseSliceState<StudentTestsPayload>;
	CourseReports: CourseOverviewReportState;
	StudentWebinarsCount: BaseSliceState<StudentWebinarsCount>;
	StudentWebinars: BaseSliceState<StudentWebinarAnalytic>;
	Assignments: BaseSliceState<Assignments>;
	StudentAttendancesCount: BaseSliceState<AttendancesCount>;
	StudentAttendances: BaseSliceState<AttendancesPayload>;
	AssignmentsSubmissions: BaseSliceState<AssignmentSubmission>;
	Notes: BaseSliceState<Note>;
	Groups: BaseSliceState<Group>;
	GroupStudents: BaseSliceState<GroupStudent>;
}

const rootReducer = {
	auth,
	student,
	admin,
	permission,
	newsFeed,
	IPs,
	scratchCard,
	classroom,
	roles,
	educational,
	analysis,
	exports,
	instructor,
	category,
	invoice,
	settings,
	dropdowns,
	classroomTabs,
	notifications,
	classroomAdmissionForm,
	walletCodes,
	ClassroomTabs,
	Admins: AdminSlice.getInstance().getReducer(),
	EnrolledUsersUnits: UserUnitsSlice.getInstance().getReducer(),
	Webinars: WebinarSlice.getInstance().getReducer(),
	Slots: WebinarSlotSlice.getInstance().getReducer(),
	WebContents: webContentSlice.instance.getReducer(),
	RegisteredUsers: RegisteredUsersSlice.getInstance().getReducer(),
	Students: StudentsSlice.getInstance().getReducer(),
	Grants: GrantsSlice.getInstance().getReducer(),
	StudentTests: StudentTestsSlice.getInstance().getReducer(),
	StudentTestsCount: StudentTestsCountSlice.getInstance().getReducer(),
	Assistants: AssistantsSlice.getInstance().getReducer(),
	CoursesTab: CoursesTabSlice.getInstance().getReducer(),
	InvoicesTab: InvoicesTabSlice.getInstance().getReducer(),
	RechargeCodeTab: RechargeCodeTabSlice.getInstance().getReducer(),
	ScratchCodeTab: ScratchCodeTabSlice.getInstance().getReducer(),
	TimelineTab: TimelineTabSlice.getInstance().getReducer(),
	WalletLogs: WalletLogsSlice.getInstance().getReducer(),
	ClassRoomTab: ClassRoomTabSlice.getInstance().getReducer(),
	SequenceManagement: SequenceManagementSlice.instance.getReducer(),
	Submissions: SubmissionsSlice.instance.getReducer(),
	TestSummary: TestSummarySlice.getInstance().getReducer(),
	TestsReports: TestsReportsSlice.getInstance().getReducer(),
	SingleTestReport: SingleTestReportSlice.getInstance().getReducer(),
	VideoUnit: VideoUnitSlice.getInstance().getReducer(),
	Loading: LoadingReducer,
	RechargeCodes: RechargeCodesSlice.getInstance().getReducer(),
	ScratchCards: ScratchCardsSlice.getInstance().getReducer(),
	Tests: TestSlice.instance.getReducer(),
	TestQuestions: TestQuestionsSlice.instance.getReducer(),
	Tags: TagsSlice.instance.getReducer(),
	Invoices: InvoicesSlice.getInstance().getReducer(),
	Documents: DocumentsSlice.instance.getReducer(),
	Units: UnitSlice.instance.getReducer(),
	TestModels: TestModelSlice.instance.getReducer(),
	Imports: ImportsSlice.getInstance().getReducer(),
	TestImports: TestImportsSlice.getInstance().getReducer(),
	[baseApiSlice.reducerPath]: baseApiSlice.reducer,
	CourseReports: courseOverviewSlice,
	StudentWebinarsCount: WebinarsCountSlice.instance.getReducer(),
	StudentWebinars: StudentWebinarsSlice.instance.getReducer(),
	Assignments: AssignmentsSlice.getInstance().getReducer(),
	StudentAttendancesCount:
		StudentAttendancesCountSlice.getInstance().getReducer(),
	StudentAttendances: StudentAttendancesSlice.getInstance().getReducer(),
	AssignmentsSubmissions: AssignmentsSubmissionsSlice.instance.getReducer(),
	Notes: NotesSlice.getInstance().getReducer(),
	Groups: GroupsSlice.getInstance().getReducer(),
	GroupStudents: GroupStudentsSlice.getInstance().getReducer(),
};

const store = configureStore({
	reducer: rootReducer,
	middleware: (middleware) =>
		middleware({
			serializableCheck: false,
		}).concat(baseApiSlice.middleware),
});

export default store;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
export declare type TRootState = ReturnType<typeof store.getState>;
