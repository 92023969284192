import EdFormControl, {
	TextFieldLabel,
} from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem } from '@mui/material';
import { EDIT_NOTE } from '@Pages/Assistants copy/Schema/Assistant.Schema';
import { notesUpdate } from '@Pages/Assistants copy/Slices/Notes.Slices';
import { Note, NoteUpdate } from '@Pages/Assistants copy/Types/Notes.types';
import Permissions from '@Pages/Assistants/Components/AssistantPermissions/Permissions';
import { GenderTypes } from '@Pages/Assistants/Constants/AssistantsForm.constants';
import { ASSISTANT_SCHEMA } from '@Pages/Assistants/Schema/Assistant.Schema';
import { AssistantsRequester } from '@Pages/Assistants/Services/Assistants.Requester';
import { Assistants } from '@Pages/Assistants/Types/Assistants.types';
import { useSnackbar } from '@Providers/useSnackbar';
import { Divider, FlexLayout, GridLayout } from '@Styled/utilities';
import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { omit } from 'lodash';
import { getEducationalInfo } from '@Features/classrooms/educationalSlice';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';
const EditNoteInfo = () => {
	const { displaySnackbar } = useSnackbar();
	const { currentEntity } = useTypedSelector((state) => state.Notes);
	const defaultValues = useMemo<NoteUpdate>(() => {
		if (currentEntity) {
			const defaults = omit(currentEntity, ['code']);
			return defaults;
		} else {
			return {} as NoteUpdate;
		}
	}, []);

	const parent = useRouteProvider();
	const dispatch = useAppThunkDispatch();
	const { getValues, control, formState, watch, reset, ...formMethods } =
		useForm<Note>({
			resolver: yupResolver(EDIT_NOTE),
			mode: 'all',
			defaultValues,
		});

	const onSave = async (form: NoteUpdate) => {
		try {
			const { data, message } = await dispatch(
				notesUpdate(form as any)
			).unwrap();
			reset(form);
			displaySnackbar('success', message);
		} catch (e) {
			const msg = e?.data.message;
			displaySnackbar('error', msg);
		}
	};

	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);

	const { educational_year, educational_info } = useTypedSelector(
		(state) => state.educational
	);
	const years = React.useMemo(() => {
		const flatYears = educational_info
			.filter((_) => _.id)
			.map(({ educationYears }) => educationYears)
			.flat();
		return flatYears.filterDups('id');
	}, [educational_info]);

	const { isDirty, isValid } = formState;
	return (
		<FormProvider
			control={control}
			getValues={getValues}
			formState={formState}
			watch={watch}
			reset={reset}
			{...formMethods}
		>
			<form>
				<GridLayout
					gridGap={'2rem'}
					gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
					marginRight="5.5rem"
					width="75%"
					padding="2.438rem 1.25rem"
				>
					<EdFormControl control={control} name="name" label="Name" required />
					{/* <EdFormControl control={control} name="code" label="Code" required /> */}
					<Controller
						control={control}
						name="price"
						render={({ field }) => (
							<FlexLayout mb="1rem">
								<TextFieldLabel required>Price</TextFieldLabel>
								<EdNumericInput
									size="small"
									label="Price"
									{...field}
									required
								/>
							</FlexLayout>
						)}
					/>
					<Controller
						control={control}
						name="old_price"
						render={({ field }) => (
							<FlexLayout mb="1rem">
								<EdFormControlLabel>Old Price</EdFormControlLabel>
								<EdNumericInput size="small" label="Old Price" {...field} />
							</FlexLayout>
						)}
					/>
					<EdFormControl
						control={control}
						name="description"
						label="Description"
					/>
					<EdFormControl
						control={control}
						required
						name="purchase_link"
						label="Purchase Link"
					/>
					<EdUploadFile
						required
						folder="classrooms"
						name="photo"
						control={control}
						uploadLabel="Upload"
						label="Photo"
						aspectRation={1 / 1.414}
					/>
					<EdFormControl
						required
						control={control}
						name="education_year_id"
						label={'Study Year'}
						select
					>
						{years.map((year) => (
							<MenuItem key={year.id} value={year.id}>
								{year.name}
							</MenuItem>
						))}
					</EdFormControl>
				</GridLayout>

				<FlexLayout
					justifyContent="flex-end"
					gridGap="1rem"
					width="75%"
					marginTop="2.938rem"
				>
					<Button
						variant="contained"
						color="warning"
						onClick={() => parent?.goToParent()}
						disabled={!isDirty}
					>
						CANCEL
					</Button>
					<Button
						variant="contained"
						disabled={!isDirty || !isValid}
						onClick={() => {
							const data = getValues();
							onSave(data);
						}}
					>
						Save
					</Button>
				</FlexLayout>
			</form>
		</FormProvider>
	);
};

export default EditNoteInfo;
