import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { fetchTestAttemptsDropdown } from '@Features/dropdowns/dropdownsSlice';
import { TableHeader } from '@Features/generics/generics.type';
import { useTypedSelector } from '@Features/store';
import { TableCell } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
	studentTestsExportData,
	studentTestsGetAll,
} from '../Slices/StudentTestsSlice';
import { StudentTestsPayload } from '../Types/Tests.types';
import TestSummaryModel from './TestSummaryModel/TestSummaryModel';

type Props = {
	id: number;
};

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'Course Name', fieldName: 'course_name' },
	{ displayName: 'Test Name', fieldName: 'Test Name' },
	{ displayName: 'Attempt. Num', fieldName: 'attempts_count' },
	{ displayName: 'Score', fieldName: 'score' },
	{ displayName: 'Status', fieldName: 'status' },
	{
		displayName: 'Attempt Date',
		fieldName: 'attempt_date',
	},
];

const TestsTable: FC<Props> = ({ id }) => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState<boolean>(false);
	const [userId, setUserId] = useState<StudentTestsPayload | undefined>();
	const auth = useTypedSelector((state) => state.auth);
	const { fetchAll, meta, filters, selectedFilters, dateRange, query, sortBy } =
		useTypedSelector((state) => state.StudentTests);
	const { currentEntity: StudentEntity } = useTypedSelector(
		(state) => state.Students
	);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
			export: exportFlag,
		}: onPageChangeRequestMeta) => {
			if (StudentEntity)
				dispatch(
					studentTestsGetAll({
						page: activePage,
						perPage: perPage ?? 10,
						sortBy,
						filters,
						query,
						from: startDate,
						to: endDate,
						classroom_id: id,
						student_id: StudentEntity?.id,
						export: exportFlag ?? false,
					})
				);
		},
		[dispatch, StudentEntity?.id]
	);

	const ExportTableData = () => {
		if (studentTestsExportData)
			dispatch(
				studentTestsExportData({
					filters: selectedFilters,
					page: meta?.current_page ?? 1,
					perPage: meta?.per_page ?? 10,
					query: query ?? '',
					sortBy,
					from: dateRange.from,
					to: dateRange.to,
					classroom_id: id,
					student_id: StudentEntity?.id,
				})
			);
	};

	const renderStatus = (status: string) => {
		switch (status) {
			case 'failed':
				return '#ff3100';
			default:
				return '#00d66b';
		}
	};

	const attempts = useTypedSelector(
		(state) => state.dropdowns.dropdownTestAttempts
	);

	useEffect(() => {
		if (userId?.unit_id && StudentEntity?.id) {
			dispatch(
				fetchTestAttemptsDropdown({
					unitId: userId?.unit_id,
					userId: StudentEntity?.id,
				})
			);
		}
	}, [userId?.unit_id, StudentEntity?.id]);

	return (
		<>
			{userId && (
				<TestSummaryModel
					handleModelClose={setShowModal}
					openModel={showModal}
					user={StudentEntity}
					testInfo={userId}
					attempts={attempts}
					setUserId={setUserId}
				/>
			)}
			{(auth.user.type === 'super' || auth.grants.has('exp-st')) && (
				<FlexLayout justifyContent="flex-end">
					<ExportButton
						onClick={() => ExportTableData()}
						startIcon={<EdIcon>south</EdIcon>}
						variant="contained"
					>
						Export
					</ExportButton>
				</FlexLayout>
			)}
			<MainTable
				total={meta?.total}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={Number(meta?.last_page)}
				RowsperPage={Number(meta?.per_page)}
				$hasActions={true}
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll?.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll?.map((test) => {
						return (
							<MainTableRow key={test.test_id} hover>
								<TableCell>{test.course_name}</TableCell>
								<TableCell>{test.test_name}</TableCell>
								<TableCell>{test.attempts_count}</TableCell>
								<TableCell>{test.score}</TableCell>
								<TableCell>
									<StatueText color={renderStatus(test.status)}>
										{test.status}
									</StatueText>
								</TableCell>
								<TableCell>
									<DateTimeFormatter date={test.attempt_date} />
								</TableCell>
								<TableCell>
									<Show
										onClick={(e) => {
											e.stopPropagation();
											setUserId(test);
											setShowModal(true);
										}}
									>
										Show Summary
									</Show>
								</TableCell>
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

const StatueText = styled.div<{ color: string }>`
	color: ${({ color }) => color};
	font-weight: 500;
	text-transform: capitalize;
`;
const Show = styled.p`
	font-weight: 500;
	text-decoration: underline;
	color: #5ac0fc;
`;
const ExportButton = styled(RoundButton)`
	margin-right: 1rem;
	box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.38);
`;
export default TestsTable;
