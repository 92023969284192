import ReactQuill, { Quill } from 'react-quill';
import { UnprivilegedEditor } from 'react-quill';
import MagicUrl from 'quill-magic-url';
import styled from 'styled-components';
import QuillJs from 'quill';
import { direction } from 'html2canvas/dist/types/css/property-descriptors/direction';
// import Quill from 'quill';
// import Quill from 'quill'

const Size: any = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

export function Counter(
	quill: QuillJs,
	options: { container?: string; mounted?: boolean }
) {
	if (!options.container) return;
	const container = document.querySelector(options.container) as HTMLDivElement;
	console.log({ container });

	// if (container) {
	// 	container.innerText = 'Words: 0, Chars: 0';
	// }
	// quill?.on(Quill.events.TEXT_CHANGE, () => {
	// 	const text = quill?.getText();
	// 	const words = (text || '')?.split(/\s+/).filter((_) => !!_).length;
	// 	const chars = quill.getContents().length() - 1;
	// 	if (container) {
	// 		container.innerText = `Words: ${words}, Chars: ${chars}`;
	// 	}
	// });
}

// Quill.register('modules/counter', Counter);

// Add fonts to whitelist and register them
const Font: any = Quill.import('formats/font');
Font.whitelist = [
	'arial',
	'comic-sans',
	'courier-new',
	'georgia',
	'helvetica',
	'lucida',
];

Quill.register(Font, true);

export const toolbarOptions = [
	['bold', 'italic', 'underline', 'strike'], // toggled buttons
	['blockquote', 'code-block'],
	['link', 'image', 'video', 'formula'],

	// [{ header: 1 }, { header: 2 }], // custom button values
	[
		{ list: 'ordered' },
		{ list: 'bullet' },
		{ list: 'check' },
		{ indent: '-1' },
		{ indent: '+1' },
		{ direction: 'rtl' },
	],
	// [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
	// [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
	// [{ direction: 'rtl' }], // text direction

	// [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
	[{ header: [1, 2, 3, 4, 5, 6, false] }],

	[{ color: [] }, { background: [] }], // dropdown with defaults from theme
	// [{ font: [] }],
	[{ align: [] }],

	['clean'], // remove formatting button
];

Quill.register('modules/magicUrl', MagicUrl);
