import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { useController, useFormContext } from 'react-hook-form';
import UploadVideo from './UploadVideo';
import { VideoType } from '../Types/Video.types';

type Props = {};

const VideoLinks: React.FC<Props> = ({}) => {
	const { control } = useFormContext<VideoType>();
	// const [value, setValue] = useState("1");

	const {
		field: { name, value, onChange },
	} = useController({
		control,
		name: 'video.link_schema',
	});


	const inputFileRef = useRef<HTMLInputElement>(null);
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		// setValue(newValue);
		onChange(newValue);
		// newValue === "1"
		//   ? setLinkSchema("upload")
		//   : newValue === "2"
		//   ? setLinkSchema("url")
		//   : newValue === "3" && setLinkSchema("embed");
	};

	const handleChooseFile = () => {
		if (inputFileRef.current) {
			inputFileRef.current.click();
		}
	};

	return (
		<Wrapper>
			<FlexLayout alignItems="start" gridGap="6.4rem">
				<Label>Video Type</Label>
				<FlexLayout flexDirection="column">
					<TabContext value={value ?? ''}>
						<Box>
							<TabList
								onChange={handleChange}
								aria-label="lab API tabs example"
							>
								<Tab
									onClick={handleChooseFile}
									label={
										<FlexLayout alignItems="center" gridGap="0.2rem">
											<UploadFileIcon fontSize="medium" /> upload
										</FlexLayout>
									}
									value="upload"
								/>
								<Tab
									label={
										<FlexLayout alignItems="center" gridGap="0.2rem">
											<InsertLinkIcon fontSize="medium" /> link
										</FlexLayout>
									}
									value="url"
								/>
								<Tab
									label={
										<FlexLayout alignItems="center" gridGap="0.2rem">
											<UnfoldMoreIconStyle fontSize="medium" /> embed
										</FlexLayout>
									}
									value="embed"
								/>
							</TabList>
						</Box>
						<TabPanel value="upload">
							<UploadVideo inputFileRef={inputFileRef} />
						</TabPanel>
						<TabPanel value="url">
							<EdFormControlLink
								control={control}
								name="video.uri"
								labelProps={{
									display: 'none',
								}}
								placeholder="Please insert link from youtube or videocypher or vimeo"
							/>
						</TabPanel>
						<TabPanel value="embed">
							<EdFormControlEmbed
								control={control}
								name="video.uri"
								multiline
								rows="5"
								labelProps={{
									display: 'none',
								}}
							/>
						</TabPanel>
					</TabContext>
				</FlexLayout>
			</FlexLayout>
		</Wrapper>
	);
};

export const Wrapper = styled.div`
	.MuiTabs-root {
		margin-bottom: -8px;
	}
	.Mui-selected {
		background: RGBA(33, 150, 243, 0.1);
	}
	.MuiButtonBase-root {
		border: solid 1px RGBA(68, 149, 236, 0.5);
		max-width: 6.25rem;
		min-width: 6.25rem;
		width: 6.25rem;
		padding: 0;
		min-height: 2.25rem;
		color: #2196f3;
		font-weight: 500;
		font-size: 0.875rem;
		&:nth-of-type(1) {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			border-right-width: 0;
		}
		&:nth-of-type(3) {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			border-left-width: 0;
		}
	}
	.MuiTabs-indicator {
		display: none;
	}
	.MuiTabPanel-root {
		padding: 0;
	}
`;
export const UnfoldMoreIconStyle = styled(UnfoldMoreIcon)`
	transform: rotate(90deg);
	margin-top: -1px;
`;
const EdFormControlLink = styled(EdFormControl)`
	width: 33.313rem;
	height: 2.5rem;
	.MuiOutlinedInput-root {
		height: 2.5rem;
	}
`;
const EdFormControlEmbed = styled(EdFormControl)`
	width: 33.313rem;
	min-height: 9.313rem;
	height: 100%;
	.MuiOutlinedInput-root {
		min-height: 9.313rem;
		height: 100%;
	}
`;

const Label = styled.p`
	margin-top: 9px;
`;

export default VideoLinks;
