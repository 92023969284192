import { BaseSliceController } from '@Base/slices/slices.base';
import { SubmissionsRequester } from '../Services/Submissions.requester';
import { Submissions } from '../Types/Submissions.types';

export class SubmissionsSlice extends BaseSliceController<
	Submissions,
	'Submissions',
	Submissions
> {
	static instance = new SubmissionsSlice();

	constructor() {
		super('Submissions', SubmissionsRequester.getInstance());
	}
}

export const { submissionsGetAll } = SubmissionsSlice.instance.getActions();
