import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import { getCourseById } from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import { FormMode } from '@Pages/Instructors/instructor.types';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
	Redirect,
	Route,
	Switch,
	useLocation,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import { FormContainerStyle } from '../../Form.Styles';
import BasicInfo from '../BasicInfo';
import CourseContent from '../CourseContent/CourseContent';
import ReportsOverview from '../CourseReports/ReportsOverview';
import RolesPaths from '../RolesPaths/RolesPaths';

type Props = {
	baseURL: string;
};
const MainCourseForm: FC<Props> = ({ baseURL }) => {
	const { path, url } = useRouteMatch();
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { currentCourse } = useTypedSelector((state) => state.classroom);
	const mode = useMemo<FormMode | undefined>(() => {
		if (!path) return;
		if (path.includes('create')) {
			return FormMode.ADD;
		} else {
			return FormMode.UPDATE;
		}
	}, [path]);

	useEffect(() => {
		if (mode === FormMode.UPDATE) {
			dispatch(
				getCourseById({
					id,
				})
			);
		}
	}, [mode]);

	const { hash, pathname } = useLocation();
	const tabIndex = useMemo<HashString>(() => {
		if (!hash) return 'info';
		return hash as HashString;
	}, [hash]);
	return (
		<>
			<RoutedTabs baseURL={baseURL} withRouter width="100%">
				<RoutedTabs.Tabs
					onChange={() => {}}
					$hashPrefix="#"
					hashes={['info', 'content']}
					orientation="vertical"
					value={tabIndex}
				>
					<RoutedTabs.Item
						value="info"
						label={<RoutedTabs.ItemTitle>Basic Info</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>menu_book</RoutedTabs.ItemIcon>}
					/>

					<RoutedTabs.Item
						value="content"
						disabled={mode === FormMode.ADD}
						label={<RoutedTabs.ItemTitle>Content</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>content_paste</RoutedTabs.ItemIcon>}
					/>
					<RoutedTabs.Item
						value="rules-paths"
						disabled={mode === FormMode.ADD}
						label={<RoutedTabs.ItemTitle>Rules & Paths</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>insights</RoutedTabs.ItemIcon>}
					/>
					<RoutedTabs.Item
						value="#files"
						disabled={mode === FormMode.ADD}
						label={<RoutedTabs.ItemTitle>Files</RoutedTabs.ItemTitle>}
						icon={
							<RoutedTabs.ItemIcon mIconType="TwoTone">
								folder_open
							</RoutedTabs.ItemIcon>
						}
					/>
					<RoutedTabs.Item
						value="reports"
						disabled={mode === FormMode.ADD}
						label={<RoutedTabs.ItemTitle>Reports</RoutedTabs.ItemTitle>}
						icon={
							<RoutedTabs.ItemIcon mIconType="Outlined">
								summarize
							</RoutedTabs.ItemIcon>
						}
					/>
				</RoutedTabs.Tabs>
				<Spacer mx="1.25rem" />
				<RoutedTabs.Content value={tabIndex}>
					<RoutedTabs.ContentItem hash="info">
						<>
							{mode === FormMode.ADD && !currentCourse && (
								<BasicInfo mode={FormMode.ADD} />
							)}
							{mode === FormMode.UPDATE && currentCourse && (
								<BasicInfo mode={FormMode.UPDATE} />
							)}
						</>
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="content">
						<CourseContent />
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="rules-paths">
						{currentCourse && <RolesPaths />}
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="reports">
						{currentCourse && <ReportsOverview />}
					</RoutedTabs.ContentItem>
				</RoutedTabs.Content>
			</RoutedTabs>
		</>
	);
};

export default MainCourseForm;
