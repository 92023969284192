import { CourseWithPrerequisites } from '@Services/classrooms/classrooms.res.types';
import { FlexLayout } from '@Styled/utilities';
import React, { FC, JSXElementConstructor, ReactElement } from 'react';
import { Draggable, DroppableProvidedProps } from 'react-beautiful-dnd';
import { useDataProvider } from './DataProvider';
import SingleSequence from './SingleSequence';

type Props = {
	droppableProps: DroppableProvidedProps;
	innerRef: (element: HTMLElement | null) => any;
	placeholder:
		| ReactElement<HTMLElement, string | JSXElementConstructor<any>>
		| React.ReactNode
		| null
		| undefined;
};

const DraggableSequence: FC<Props> = ({
	droppableProps,
	innerRef,
	placeholder,
}) => {
	const { inSequence } = useDataProvider<CourseWithPrerequisites>();

	return (
		<>
			<FlexLayout flexDirection="column" {...droppableProps} ref={innerRef}>
				{placeholder}

				{inSequence &&
					inSequence?.map((sequence, index) => {
						return (
							<Draggable
								draggableId={`sequence-${index}`}
								key={`sequence-${index}`}
								index={index}
							>
								{({ draggableProps, innerRef, dragHandleProps }) => {
									return (
										<FlexLayout width="100%" ref={innerRef} {...draggableProps}>
											<SingleSequence
												index={index}
												sequence={sequence}
												dragHandlers={dragHandleProps}
											/>
										</FlexLayout>
									);
								}}
							</Draggable>
						);
					})}
			</FlexLayout>
		</>
	);
};

export default DraggableSequence;
