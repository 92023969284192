import * as yup from 'yup';
import { TestQuestionBaseSchema } from './TestQuestion';

const oneTrue = yup.addMethod(yup.array, 'one_true', function (field, message) {
	return this.test('one_true', function (value) {
		const correct_item = value?.find((_) => _.is_correct);
		if (correct_item) return true;
		return this.createError({
			message,
			path: `${this.path}.${field}`,
		});
	});
});
const unique = yup.addMethod(
	yup.array,
	'unique',
	function (
		mapper = (a: any) => a,
		message: string = '${path} may not have duplicates'
	) {
		return this.test('unique', message, (list) => {
			return list?.length === new Set(list?.map(mapper)).size;
		});
	}
);
export const ChoiceSchema = yup
	.object({
		choice: yup.string().required('Choice is required'),
		is_correct: yup.boolean().default(false).required(),
	})
	.required();
export const MCQ_SCHEMA = TestQuestionBaseSchema.concat(
	yup.object({
		type: yup.string().default('mcq'),
		multipleChoiceOptions: yup.array().of(ChoiceSchema).required().min(2),
	})
);
