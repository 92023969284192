import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GAP_SCHEMA } from '../../../Schema/Gap.schema';
import { TypedTestQuestionPayload } from '../../../Types/TestQuestion';
import { Editor as tinymceEditor } from 'tinymce';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import {
	Button,
	Checkbox,
	InputAdornment,
	ListItemText,
	MenuItem,
	Typography,
	FormControlLabel,
} from '@mui/material';
import TagChip from '@Components/UI/Chips/TagChip/TagChip';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import {
	EdFormControlLabel,
	EdFormControlError,
} from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import { DevTool } from '@hookform/devtools';
import { useHistory, useParams } from 'react-router-dom';
import { TestQuestionsRequester } from '../../../Services/TestQuestions/TestQuestionsRequester';
import { useSnackbar } from '@Providers/useSnackbar';
import EditGapQuestionForm from './EditGapQuestionForm';
import { testQuestionsGetSingle } from '../../../Slice/TestQuestions/TestQuestionsSlice';
import { useDispatch } from 'react-redux';
const EditGapQuestion = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(testQuestionsGetSingle({ id: Number(id) }));
	}, []);

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.TestQuestions);
	const { currentEntity: currentTest } = useTypedSelector(
		(state) => state.Tests
	);
	return (
		<div>
			{currentEntity && currentTest && getSingle === 'fulfilled' && (
				<EditGapQuestionForm />
			)}
		</div>
	);
};

export default EditGapQuestion;
