import { FormControl, FormControlLabel, Radio } from '@mui/material';
import { RadioGroup } from '@mui/material';
import { FormLabel } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { AnswerSchema } from '../Constants/Questions.types';

type Props = {
	control: any;
};

const AnswerSchemaList: FC<Props> = ({ control }) => {
	return (
		<Controller
			render={({ field: { onChange, value, ...fieldProps } }) => {
				return (
					<FormControl>
						<FlexLayout alignItems="center">
							<Label focused={false}>Answer Option</Label>
							<RadioGroup
								value={value}
								onChange={(e) => {
									onChange((e.target as HTMLInputElement).value);
								}}
							>
								<FlexLayout gridGap="3.625rem" alignItems="center">
									{AnswerSchema?.map((_, index) => {
										return (
											<FormLabelControll
												value={_.type}
												control={<Radio size="small" />}
												label={_.title}
											/>
										);
									})}
								</FlexLayout>
							</RadioGroup>
						</FlexLayout>
					</FormControl>
				);
			}}
			control={control}
			name="answer_schema"
		/>
	);
};

const FormLabelControll = styled(FormControlLabel)`
	.Mui-checked {
		color: #6c63ff;
	}
`;

const Label = styled(FormLabel)`
	font-weight: 500;
	white-space: nowrap;
	position: relative;
	width: fit-content;
	min-width: 12rem;
	padding: 8.5px 0;
	height: 1.4375rem;
	display: grid;
	align-items: center;
	color: #251b33;
`;

export default AnswerSchemaList;
