import {
	fetchCoursesDropdown,
	fetchUnitsDropdown,
} from '@Features/dropdowns/dropdownsSlice';
import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { number, object, string } from 'yup';
import { DropdownUnit } from '@Features/dropdowns/dropdownsSlice.types';
import { submissionsGetAll } from '../../Slices/Submissions.slice';
import { Button } from '@mui/material';
import { SortableList } from '../../Constants/Table.constants';
import { assignmentsSubmissionsGetAll } from '../../Slices/Assignment.slice';
import { useUpdateEffect } from 'usehooks-ts';
const schema = object({
	course: number(),
	test: number(),
});

const SortableSchema = object({
	sort: string(),
});

const AssignmentsDropdowns: React.VoidFunctionComponent<{
	reviewed: boolean;
}> = ({ reviewed }) => {
	const dispatch = useDispatch();
	const [allTests, setAllTests] = useState<Array<DropdownUnit> | undefined>([]);
	const { filters, meta, query, dateRange, sortBy } = useTypedSelector(
		(state) => state.AssignmentsSubmissions
	);
	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const {
		control,
		formState: { dirtyFields, isDirty, isValid },
		reset,
		watch,
		setValue,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			course: null,
			test: null,
		},
	});
	const {
		control: controlSort,
		reset: resetSort,
		watch: watchSort,
		formState: sortFormState,
	} = useForm({
		resolver: yupResolver(SortableSchema),
		defaultValues: {
			sort: null,
		},
	});

	const course = watch('course');
	const test = watch('test');
	const sort = watchSort('sort');

	const courses = useTypedSelector((state) => state.dropdowns.dropdownCourses);
	const tests = useTypedSelector((state) => state.dropdowns.DropdownUnits);

	useEffect(() => {
		if (currentClassroom?.id) {
			dispatch(fetchCoursesDropdown(currentClassroom?.id));
		}
	}, [currentClassroom?.id]);

	const getUnitsTest = async () => {
		if (course)
			dispatch(fetchUnitsDropdown(`${course}?type=assignment` as any));
	};

	useEffect(() => {
		getUnitsTest();
	}, [course]);

	useEffect(() => {
		if (tests) {
			setAllTests(tests);
		}
	}, [tests]);

	useEffect(() => {
		dispatch(
			assignmentsSubmissionsGetAll({
				page: (meta?.current_page as number) ?? 1,
				filters: filters,
				perPage: meta?.per_page ?? 10,
				query: query as string,
				sortBy: sort ? { direction: sort, field: 'created_at' } : sortBy,
				from: dateRange.from,
				to: dateRange.to,
				classroom_id: currentClassroom?.id,
				course_id: course && (course as unknown as number),
				submitted: reviewed,
				unit_id: test && (test as unknown as number),
			})
		);
	}, [course, test, sort, reviewed]);

	const resetFilter = () => {
		reset();
		resetSort();
		dispatch(
			assignmentsSubmissionsGetAll({
				page: meta?.current_page as number,
				filters: filters,
				perPage: meta?.per_page ?? 10,
				query: query as string,
				sortBy: null,
				from: dateRange.from,
				to: dateRange.to,
				classroom_id: currentClassroom?.id,
			})
		);
	};

	return (
		<Container>
			<FlexLayout gridGap="0.8rem">
				<Controller
					control={control}
					name="course"
					render={({ field: { onChange, ...others } }) => (
						<FormControl variant="outlined">
							<TextFieldStyle
								select
								{...others}
								onChange={(e) => {
									setValue('test', null);
									onChange(e.target.value);
								}}
								size="small"
								label={course ? null : 'Select course'}
							>
								{courses?.map((cr, index) => (
									<MenuItem key={`${cr.name}-${index}`} value={cr.id}>
										{cr.name}
									</MenuItem>
								))}
							</TextFieldStyle>
						</FormControl>
					)}
				/>
				<Controller
					control={control}
					name="test"
					render={({ field: { onChange, ...others } }) => (
						<TextFieldStyle
							size="small"
							select
							{...others}
							label={test ? null : 'Select Assignment'}
							error={dirtyFields.course && allTests && allTests?.length <= 0}
							helperText={
								dirtyFields.course &&
								allTests &&
								allTests.length <= 0 &&
								'No tests found'
							}
							onChange={(e) => {
								onChange(e.target.value);
							}}
						>
							{allTests &&
								allTests?.map((test, index) => {
									return (
										<MenuItem key={`${test?.name}-${index}`} value={test?.id}>
											{test?.name}
										</MenuItem>
									);
								})}
						</TextFieldStyle>
					)}
				/>
				<Controller
					control={controlSort}
					name="sort"
					render={({ field: { onChange, ...others } }) => (
						<TextFieldStyle
							size="small"
							select
							{...others}
							label={sort ? null : 'Sort by Att. Date'}
							error={SortableList?.length <= 0}
							onChange={(e) => {
								onChange(e.target.value);
							}}
						>
							{SortableList?.map((sort, index) => {
								return (
									<MenuItem
										key={`${sort?.value}-${index}`}
										value={sort?.direction}
									>
										{sort?.value}
									</MenuItem>
								);
							})}
						</TextFieldStyle>
					)}
				/>
			</FlexLayout>
			<ButtonsWrapper gridGap="1.8rem">
				<Button
					disabled={isDirty ? !isDirty : !sortFormState.isDirty}
					color="warning"
					onClick={() => {
						resetFilter();
					}}
				>
					Reset
				</Button>
			</ButtonsWrapper>
		</Container>
	);
};

const Container = styled(FlexLayout)`
	/* position: absolute; */
	/* padding: 0rem 1rem; */
	/* padding-inline-end: 2rem; */
	gap: 0.8rem;
	/* flex-direction: column; */
`;
const ButtonsWrapper = styled(FlexLayout)`
	z-index: 999;
`;
const TextFieldStyle = styled(TextField)`
	width: 10.875rem;
`;

export default AssignmentsDropdowns;
