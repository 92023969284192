import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import { AssistantsRouteGroup } from '@Constants/routes';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AssistantsTable from './AssistantsTable';

const Assistants = () => {
	const { url, path } = useRouteMatch();
	const { isCurrent } = useInvalidateRouteGroup({
		baseURL: '/dashboard/assistants',
		mode: 'include',
		routeGroup: AssistantsRouteGroup,
	});

	const { removeLink } = useContext(BreadcrumbContext);
	useEffect(() => {
		if (isCurrent) {
			removeLink?.('assistants');
		}
	}, [isCurrent]);

	return (
		<Switch>
			{AssistantsRouteGroup && !Array.isArray(AssistantsRouteGroup) && (
				<>
					<Route exact path={url}>
						<AssistantsTable />
					</Route>

					{AssistantsRouteGroup.routes.map((_) => {
						return (
							<Route strict path={`${path}/${_.route}`}>
								{_.component}
							</Route>
						);
					})}
				</>
			)}
		</Switch>
	);
};

export default Assistants;
