import { Switch, TextField, TextFieldProps, SwitchProps } from '@mui/material';
import { orange } from '@mui/material/colors';
import React from 'react';
import { Controller, FieldValues, Path } from 'react-hook-form';
import styled from 'styled-components';
import { layout, LayoutProps, width, WidthProps } from 'styled-system';

export type EdFormControlProps<T extends FieldValues> = {
	label?: string;
	switch?: boolean;
	multiple?: boolean;
	renderValues?: (value: any) => React.ReactNode;
	showLabel?: boolean;
	labelProps?: TextFieldLabelProps;
	control: any;
	name: string;
} & Omit<TextFieldProps, 'control'> &
	WidthProps &
	SwitchProps;
type TextFieldLabelProps = { required?: boolean } & LayoutProps;

export const TextFieldLabel: React.FC<TextFieldLabelProps> = ({
	children,
	...props
}) => {
	return (
		<TextFieldWrapper {...props}>
			<StyledTextFieldLabel required={props.required}>
				{children}
			</StyledTextFieldLabel>
		</TextFieldWrapper>
	);
};

const EdFormControl = <T extends FieldValues>({
	label,
	required,
	name,
	children,
	control,
	select,
	width = '16.25rem',
	showLabel = true,
	labelProps,
	...props
}: React.PropsWithChildren<EdFormControlProps<T>>) => {
	return (
		<StyledFormControl>
			{showLabel && (
				<TextFieldLabel required={required} {...labelProps}>
					{label}
				</TextFieldLabel>
			)}
			<Controller
				name={name as Path<T>}
				control={control}
				render={({
					field: { onChange, ...inputProps },
					fieldState: { error, invalid },
					formState,
				}) => {
					return (
						<>
							{props.switch && (
								<Switch
									checked={inputProps.value}
									{...inputProps}
									{...props}
									onChange={(e, value) => onChange(value)}
								/>
							)}
							{!props.switch && (
								<FormControlFieldWrapper>
									<FormControlField
										width={width ?? '16.25rem'}
										select={select}
										error={invalid}
										helperText={
											error?.message?.replace(`${name}`, `${label}`) ?? ''
										}
										onChange={(e) => onChange(e)}
										{...inputProps}
										{...props}
									>
										{children}
									</FormControlField>
									{select &&
										props.multiple &&
										Array.isArray(inputProps.value) &&
										props.renderValues && (
											<SelectMultipleValuesWrapper>
												<>
													{props.renderValues &&
														props.renderValues(inputProps.value)}
												</>
											</SelectMultipleValuesWrapper>
										)}
								</FormControlFieldWrapper>
							)}
						</>
					);
				}}
			/>
		</StyledFormControl>
	);
};

export default EdFormControl;
const TextFieldWrapper = styled.div<TextFieldLabelProps>`
	min-width: 12rem;
	padding: 8.5px 0;
	height: 1.4375rem;
	display: grid;
	align-items: center;
	${layout}
`;
const StyledTextFieldLabel = styled.span<{ required?: boolean }>`
	font-weight: 500;
	white-space: nowrap;
	/* min-width: 9rem; */
	position: relative;
	width: fit-content;
	/* margin-right: 8.5rem; */
	::before {
		content: ${(props) => (props.required ? "'*'" : '')};
		display: block;
		color: ${orange['500']};
		position: absolute;
		left: calc(100% + 10px);
		bottom: calc(100% - 5px);
	}
`;
export const FormControlFieldWrapper = styled.div`
	flex-grow: 1;
`;
export const FormControlField = styled(TextField)<WidthProps>`
	.MuiOutlinedInput-root {
		padding-left: 0 !important;
	}
	width: 100%;
	flex-grow: ${(props) => (!props.width ? '1' : '')};
	position: relative;
	.MuiFormHelperText-root {
		position: absolute;
		white-space: nowrap;
		top: 100%;
	}
	.MuiChip-sizeSmall {
		height: 20px;
	}
	.MuiInputAdornment-root {
		position: relative;
		height: 100%;
		padding: 0 0.625rem;
		margin-right: 0;
		&::after {
			position: absolute;
			content: '';
			right: 100%;
			padding-right: inherit;
			border-color: inherit;
			border-width: inherit;
			border-right-style: solid;
			border-right: 1px solid;
			border-right-color: rgba(0, 0, 0, 0.12);
			width: 1px;
			height: 100%;
			left: 100%;
			padding: 0;
		}
	}
	${width};
`;

export const StyledFormControl = styled.div<{ required?: boolean }>`
	display: flex;
	grid-template-columns: 8rem 1fr;
	flex-direction: row;
	align-items: flex-start;
	* {
		/* box-sizing: content-box !important; */
	}
	.MuiInputBase-input {
		padding: 8.5px 14px;
	}
`;

const SelectMultipleValuesWrapper = styled.div`
	margin-top: 0.5rem;
`;
