import { array, boolean, number, object, string } from 'yup';

const emailValidation =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^ <>()[\]\\., ;: \s @"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneValidation = /^01[0-2,5]{1}[0-9]{8}$/;

export const ASSISTANT_SCHEMA = object({
	first_name: string().required(),
	last_name: string().required(),
	email: string()
		.required()
		.matches(emailValidation, 'E-mail is not valid')
		.required(),
	phone_number: string()
		.matches(phoneValidation, 'must be 01xxxxxxxx')
		.required(),
	password: string().when('uuid', {
		is: (id: string) => {
			return id !== undefined;
		},
		then: string(),
		otherwise: string().required(),
	}),
	gender: string().nullable(true),
	instructor: object({
		is_active: boolean().default(true).required(),
		grants: array(number().required()),
		label: string().required(),
	}),
});

export const EDIT_ASSISTANT = object({
	id: number().required(),
}).concat(ASSISTANT_SCHEMA);
