import { baseApiSlice } from '@Features/baseApiSlice';

const instructorAdmissionsApi = baseApiSlice.injectEndpoints({
	endpoints: (build) => ({
		classroomResponsesList: build.query<
			ClassroomResponsesListResponsePayload,
			ClassroomResponsesListRequestPayload
		>({
			query: ({ status, ...data }) => ({
				url: `admissions/${data.id}`,
				method: 'POST',
				data,
				params: { status },
			}),
			providesTags: ['Admission'],
		}),
		singleClassroomResponse: build.query<
			SingleClassroomAdmissionResponsePayload,
			SingleClassroomAdmissionRequestPayload
		>({
			query: (data) => ({
				url: 'admissions/single',
				method: 'POST',
				data,
			}),
			providesTags: ['Admission'],
		}),
		admissionAction: build.mutation<
			AdmissionActionResponsePayload,
			AdmissionActionRequestPayload
		>({
			query: (data) => ({
				url: 'admissions/actions',
				method: 'POST',
				data,
			}),
			invalidatesTags: ['Admission'],
		}),
	}),
});

export const {
	useClassroomResponsesListQuery,
	useLazyClassroomResponsesListQuery,
	useSingleClassroomResponseQuery,
	useAdmissionActionMutation,
} = instructorAdmissionsApi;
