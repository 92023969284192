import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { FC } from 'react';
import { TypedTestQuestion } from '../../Types/TestQuestion';
import { QuestionContent } from './QuestionAccordion.styled';

type Props = {
	question: TypedTestQuestion<'gap'>;
};
const GAPAccordion: FC<Props> = ({ question }) => {
	const replaceGaps = () => {
		let str = question.parsed_content;
		question.fillInTheGapOptions.forEach((gap) => {
			const choices: [] = JSON.parse(gap.choices);
			if (str) {
				str.replace(`$$${gap.gap}`, choices.join(' | '));
			}
		});
		return str;
	};
	return (
		<FlexLayout flexDirection="column">
			<QuestionContent>
				{/* {replaceGaps()} */}
				<div dangerouslySetInnerHTML={{ __html: question.content }} />
			</QuestionContent>
			<Spacer mb="2rem" />
			<FlexLayout flexDirection="column"></FlexLayout>
		</FlexLayout>
	);
};

export default GAPAccordion;

const MCQAnswerLabel = styled(FormControlLabel)<{ is_correct: boolean }>`
	width: 100%;
	overflow: hidden;
	background-color: ${(props) => (props.is_correct ? '#e5fbf0' : '')};
	padding: 0 0.5rem;
	border-radius: 4px;
`;
