import { StudentInfo } from '@Constants/routes';
import { useTypedSelector } from '@Features/store';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import { useSnackbar } from '@Providers/useSnackbar';
import { getEducationalInfo } from '@Services/educationalInfo/educationalInfo.req';
import { EducationalInfoResponseData } from '@Services/educationalInfo/educationalInfo.types';
import { FlexLayout, GridLayout } from '@Styled/utilities';
import React, { FC, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import EditStudentForm from './EditStudentForm';
import EduactCardIcon from './assets/eduact-card-icon.svg';
import EduactCardBg from './assets/card_bg.jpg';
import Avatar from './assets/avatar.png';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import QRCode from '@eduact/ed-components/DataDisplay/QRCode';
import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import { rgba } from 'polished';

type Props = {};

const Info: FC<Props> = () => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: string }>();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Students);

	const { educational_info, educational_type } = useTypedSelector(
		(state) => state.educational
	);

	const years = React.useMemo(() => {
		const flatYears = educational_info
			.filter((_) => _.id)
			.map(({ educationYears }) => educationYears)
			.flat();
		return flatYears.filterDups('id');
	}, [educational_info]);

	const sections = React.useMemo(() => {
		const flatYears = educational_info
			.map(({ educationYears }) => educationYears)
			.flat()
			.filterDups('id')
			.filter((_) => _?.id);

		return flatYears
			.map(({ educationSections }) => educationSections)
			.flat()
			.filterDups('id');
	}, [educational_info]);

	useEffect(() => {
		dispatch(studentsGetSingle({ id }));
	}, []);

	const {} = currentEntity ? currentEntity : {};

	const { displaySnackbar } = useSnackbar();
	const [educationalInfoData, setEducationalInfoData] =
		useImmer<EducationalInfoResponseData>([]);

	const loadEducationalTypes = async () => {
		try {
			const {
				data: { data },
			} = await getEducationalInfo();
			setEducationalInfoData(data);
		} catch (e) {
			displaySnackbar(
				'error',
				e.response.data.message ?? 'Error in fetching educational info'
			);
		}
	};

	// get educational info
	useEffect(() => {
		loadEducationalTypes();
	}, []);
	const { url, path } = useRouteMatch();
	const { removeLink, addLink } = useContext(BreadcrumbContext);
	const { setURL, setCurrentRouteGroup, isCurrent } = useInvalidateRouteGroup({
		routeGroup: StudentInfo,
		baseURL: `${url}/info`,
		routePlacement: 2,
		mode: 'end',
		extraProps: {
			id,
		},
	});

	useEffect(() => {
		if (!url || !currentEntity) return;
		if (isCurrent) {
			removeLink?.('students');
		}
		addLink?.({
			route: url,
			title: `${currentEntity?.first_name} ${currentEntity?.last_name}`,
			routeHash: 'info',
		});
		return () => {
			removeLink?.('students');
		};
	}, [isCurrent, currentEntity, url]);

	useEffect(() => {
		if (currentEntity) {
			setURL(url);
			setCurrentRouteGroup?.(StudentInfo);
		}
	}, [currentEntity, setURL, setCurrentRouteGroup, url]);

	return (
		<div>
			{StudentInfo && !Array.isArray(StudentInfo) && (
				<Switch>
					<Route path={path} exact>
						{currentEntity && getSingle === 'fulfilled' && (
							<FlexLayout
								alignItems="start"
								justifyContent="space-between"
								gridGap="1rem"
							>
								<EditStudentForm />

								{/*  student card  */}
								<div
									style={{
										width: '460px',
										height: '275px',
										marginTop: '40px',
										marginRight: '120px',
										borderRadius: '10px',
										overflow: 'hidden',
										position: 'relative',
										boxShadow:
											'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
									}}
								>
									{/*  student card header  */}
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											backgroundColor: '#5AC0FC',
											color: '#fff',
											padding: '0 20px',
											position: 'relative',
											zIndex: 2,
										}}
									>
										<p
											style={{
												fontSize: '28px',
												fontWeight: 600,
												margin: '10px 0',
											}}
										>
											Student ID
										</p>

										<img src={EduactCardIcon} alt="eduact icon" />
									</div>

									{/* card body, wrapper for absolute bg and content  */}
									<div
										style={{
											position: 'relative',
											height: '280px',
											width: '460px',
										}}
									>
										{/*  card body content  */}
										<div
											style={{
												position: 'absolute',
												padding: '10px 20px',
												width: '90%',
											}}
										>
											<div
												style={{
													display: 'flex',
													gap: '12px',
												}}
											>
												<img
													alt="avatar"
													src={currentEntity.profile_photo ?? Avatar}
													style={{
														width: '114px',
														height: '114px',
														objectFit: 'contain',
														zIndex: 9999,
														position: 'relative',
													}}
												/>

												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'space-between',
														padding: '6px 0',
													}}
												>
													<div>
														<p style={{ marginBottom: '3px', fontWeight: 500 }}>
															{`${currentEntity.first_name} ${currentEntity.last_name}`}
														</p>
														<p>
															username:{' '}
															<span
																style={{
																	fontWeight: 500,
																}}
															>
																{currentEntity.username}
															</span>
														</p>
													</div>

													<div>
														<p
															style={{
																marginBottom: '3px',
																color: '#2196F3',
																fontWeight: 500,
															}}
														>
															<EdIcon
																style={{
																	color: 'gray',
																	position: 'relative',
																	top: '6px',
																	marginRight: '2px',
																}}
															>
																phone
															</EdIcon>
															{currentEntity.phone_number}
														</p>
														<p
															style={{
																color: '#2196F3',
																textDecoration: 'underline',
															}}
														>
															<EdIcon
																style={{
																	color: 'gray',
																	position: 'relative',
																	top: '6px',
																	marginRight: '2px',
																}}
															>
																mail
															</EdIcon>
															{currentEntity.email}
														</p>
													</div>
												</div>
											</div>
											{/* card footer  */}
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													padding: '16px 0',
												}}
											>
												<div
													style={{
														display: 'grid',
														gridTemplateColumns: '100px 140px',
														gridTemplateRows: 'repeat(3, 1fr)',
													}}
												>
													<p>Diploma:</p>
													<p style={{ fontWeight: 500 }}>
														{
															educational_type?.find(
																(_) =>
																	_.value ===
																	currentEntity.student.education_type_id
															)?.name
														}
													</p>
													<p>Year:</p>
													<p style={{ fontWeight: 500 }}>
														{years
															?.find(
																(_: any) =>
																	_?.id ===
																	currentEntity.student.education_year_id
															)
															?.name?.replaceAll('_', ' ')}
													</p>
													<p>Branch:</p>
													<p style={{ fontWeight: 500 }}>
														{
															sections.find(
																(_: any) =>
																	_.id ===
																	currentEntity.student.education_section_id
															)?.name
														}
													</p>
												</div>

												{/*  qr code  */}
												<QRCode width={72} value={currentEntity.username} />
											</div>
										</div>
										<StyledBackgroundImage src={EduactCardBg} alt="card-bg" />
									</div>
								</div>
							</FlexLayout>
						)}
					</Route>
					{StudentInfo.routes.map((route, index) => {
						return (
							<Route
								path={`${path}/${route.route}`}
								key={`${route.route}-${index}`}
							>
								{route.component}
							</Route>
						);
					})}
				</Switch>
			)}
		</div>
	);
};

export const SectionLayout = styled(GridLayout)`
	margin-right: 0;
	grid-gap: 1rem;
`;

const StyledBackgroundImage = styled.img`
	position: absolute;
	bottom: 48px;
	z-index: 1;
	width: 460px;
	background: ${rgba('#5AC0FC', 0.1)};
`;

export default Info;
