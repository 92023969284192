import { List, ListItem, Menu, Tooltip } from '@mui/material';
import styled from 'styled-components';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { MenuCloseIcon } from './Assets/Icons/Icons';
import { EduactLogo, MiniEduactLogo } from '@Assets/img';
import { rgba } from 'polished';
type MenuProps = {
	$sidemenuopened?: boolean;
};
export const StyledDrawer = styled(MuiDrawer)<DrawerProps>`
	width: ${(props) => (props.open ? '300px' : '101px')};
	background: ${(props) => props.theme.palette.cadet['50']};
	box-shadow: none;
	transition: width cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s;
	/* position: relative; */
	.MuiDrawer-paper {
		&::-webkit-scrollbar {
			display: none;
			width: 0.5rem;
			height: 1rem;
			background-color: #979797;
		}
		overflow-y: auto;
		box-shadow: none;
		width: inherit;
		background: inherit;
		transform: unset !important;
		visibility: visible !important;
	}
`;

export const MenuHeader = styled.div<DrawerProps>`
  background-repeat: no-repeat;
  height: 55px;
  background-color: ${(props) => props.theme.palette.cadet['50']}
  min-height: 75px;
  padding: ${(props) => (props.open ? '0 20px' : '0 10px')};
  grid-template-columns: 1fr 1fr;
  justify-items: self-end;
  display: grid;
  align-items: center;
  margin-bottom: 2.625rem;
  position: sticky;
  top: 0;
  z-index: 2;
`;
export const LogoImage = styled.div<DrawerProps>`
	height: 55px;
	width: ${(props) => {
		if (props.open) return '200px';
		return '100%';
	}};
	background-image: ${(props) => {
		if (props.open) return `url(${EduactLogo})`;
		return `url(${MiniEduactLogo})`;
	}};
	//margin: 20px 0;
	margin: ${(props) => {
		if (props.open) return '20px 16px';
		return '20px 0';
	}};
	object-fit: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	transition: background-image ease-in-out 400ms;
`;

export const StyledMenuCloseIcon = styled(MenuCloseIcon)<DrawerProps>`
	opacity: 0.8;
	cursor: pointer;
	transform: ${(props) => (!props.open ? 'rotate(180deg)' : '')};
	transition: all ease-in-out 400ms;
`;

export const StyledMenuList = styled(List)`
	/* padding: 0 2.625rem; */
`;
export const StyledTooltip = styled(Tooltip)``;

export const StyledMenuItem = styled(ListItem)<MenuProps>`
	color: ${rgba('#000', 0.8)} !important;
	padding: 0.625rem 2.625rem !important;
	width: 100%;
	cursor: pointer;
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: ${(props) =>
		!props.$sidemenuopened ? '' : '0.5fr 4fr'};
	place-content: ${(props) => (!props.$sidemenuopened ? 'center' : '')};
	&.Mui-selected {
		background: ${(props) => props.theme.palette.cadet['300']};
		font-weight: bold;
	}
	span {
		color: ${(props) => props.theme.palette.primary.main};
	}

	label {
		display: ${(props) => (!props.$sidemenuopened ? 'none' : '')};
		margin: 0 2rem;
		cursor: pointer;
		font-size: 0.75rem;
		::first-line {
			text-transform: uppercase !important;
		}
	}
	${StyledTooltip} {
	}
`;

export const SideMenuMobileGroupList = styled(Menu)`
	ul {
		background-color: ${(props) => props.theme.palette.cadet.main};
	}
	li {
		color: ${(props) => props.theme.palette.grey[100]};
	}
`;
