import React from 'react';
import QuestionsAndAnswersHeader from './QuestionsAndAnswersHeader';
import Questions from './Questions';
import { createContext } from '@Utils/createContext';
import {
	useQueryParams,
	StringParam,
	NumberParam,
	withDefault,
	UrlUpdateType,
	DecodedValueMap,
	QueryParamConfig,
	QueryParamConfigMap,
} from 'use-query-params';
import { QuestionTypes } from './QuestionsAndAnswersConstants';

type Props = {};

type QuestionsAndAnswersContext = {
	queryParams: any;
	setQueryParams: (
		newValue: any | undefined,
		updateType?: UrlUpdateType | undefined
	) => void;
};

const [QuestionsAndAnswersProvider, useQuestionsAndAnswersContext] =
	createContext<QuestionsAndAnswersContext>();

const QuestionsAndAnswers: React.FC<Props> = () => {
	const [queryParams, setQueryParams] = useQueryParams(
		{
			type: withDefault(StringParam, QuestionTypes.unanswered),
			query: StringParam,
			'courses.code': StringParam,
			unit_id: NumberParam,
			from: StringParam,
			to: StringParam,
		},
		{ updateType: 'replaceIn' }
	);

	return (
		<div>
			<QuestionsAndAnswersProvider
				value={{
					queryParams,
					setQueryParams,
				}}
			>
				<QuestionsAndAnswersHeader />
				<Questions />
			</QuestionsAndAnswersProvider>
		</div>
	);
};

export default QuestionsAndAnswers;
export { useQuestionsAndAnswersContext };
