import { RouteGroup, SingleRoute } from '@Constants/routes';
import { Groups } from '../Groups';
import { AddGroup } from '../pages/AddGroup/AddGroup';
import { EditGroup } from '../pages/EditGroup/EditGroup';
import { EditGroupFetch } from '../pages/EditGroup/EditGroupFetch';

export const GroupRouteGroup: RouteGroup = {
	code: 'course_content',
	icon: 'feed',
	component: <Groups />,
	forceNavigate: true,
	route: 'groups',
	title: 'Groups',
	isRouted: true,
	isParent: true,
	routes: [
		{
			route: 'create',
			component: <AddGroup />,
		} as SingleRoute,
		{
			route: 'edit/:groupId',
			component: <EditGroupFetch />,
		} as SingleRoute,
	],
};
