import { useTypedSelector } from '@Features/store';
import { FlexLayout } from '@Styled/utilities';
import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckIcon from '@mui/icons-material/Check';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import { useSnackbar } from '@Providers/useSnackbar';
import { useDispatch } from 'react-redux';
import useFilters from '@Hooks/useFilters';
import useQueryURL from '@Hooks/useQueryUrl';
import { UsersRequester } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Services/Userprogress/UserProgressRequester';
import { coursesTabGetAll } from '../../../Slices/CourseTab.Slices';
import { enrolledUsersUnitsGetSingle } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Slice/users.Slice';
import { UserUnits } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Types/UserProgress.types';
import { rgba } from 'polished';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';

type Props = {
	id: number;
	currentCourse: number;
	setOpenModel: React.Dispatch<boolean>;
};
type Time = {
	[key: string]: number;
};
const UnitCard: React.FC<Props> = ({
	id: userId,
	currentCourse,
	setOpenModel,
}) => {
	const { displaySnackbar } = useSnackbar();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.EnrolledUsersUnits);

	const { selectedFilters } = useFilters();
	const { selectedQuery } = useQueryURL();

	const units = currentEntity;
	const dispatch = useDispatch();

	const toggleUnit = async (unitId: number) => {
		try {
			await UsersRequester.getInstance().toggleUnits(unitId, userId);
			displaySnackbar('success', `Unit Toggled successfully`);
			dispatch(
				coursesTabGetAll({
					page: 1,
					perPage: 10,
					sortBy: '',
					filters: selectedFilters,
					query: selectedQuery,
					id: userId as any,
				})
			);
			dispatch(
				enrolledUsersUnitsGetSingle({
					id: currentCourse,
					tId: userId,
				})
			);
			setOpenModel(true);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Toggle Unit ';
			displaySnackbar('error', msg);
		}
	};

	const findTime = (num: number) => {
		if (num < 1) {
			return '0';
		}
		const qualifier = (num: number) => (num > 1 ? '' : '');
		const numToStr = (num: number, unit: string) =>
			num > 0 ? `${num}${unit} ${qualifier(num)}` : '';
		const oneMinute = 60;
		const oneHour = oneMinute * 60;
		const oneDay = oneHour * 24;
		const oneYear = oneDay * 365;
		const times: Time = {
			year: ~~(num / oneYear),
			day: ~~((num % oneYear) / oneDay),
			hour: ~~((num % oneDay) / oneHour),
			minute: ~~((num % oneHour) / oneMinute),
		};
		let str = '';
		for (let [key, value] of Object.entries(times)) {
			str += numToStr(times[key], key[0]);
		}
		const arr = str.trim().split(' ');
		const res: Array<any> = [];
		arr.forEach((x, i) => {
			res.push(x);
		});
		return res.slice(0, 2).join(' ');
	};

	return (
		<Wrapper flexDirection="column" gridGap="2.5rem">
			{currentEntity &&
				getSingle === 'fulfilled' &&
				(units as unknown as UserUnits[])?.map((_: UserUnits) => {
					const type = _.type_id;
					return (
						<Card alignItems="center" gridGap="0.875rem">
							<FlexLayout alignItems="center" gridGap="0.95rem">
								<FlexLayout alignItems="center" gridGap="0.95rem">
									{_.status === 'completed' ? (
										<CheckCircleIcon color="primary" />
									) : type === 1 ? (
										<DescriptionOutlinedIcon />
									) : type === 2 ? (
										<PlayCircleFilledIcon />
									) : type === 3 ? (
										<LiveHelpOutlinedIcon />
									) : type === 4 ? (
										<DocumentScannerOutlinedIcon />
									) : type === 5 ? (
										<PodcastsIcon />
									) : (
										type === 6 && <BallotOutlinedIcon />
									)}
									<Text>{_.name}</Text>
								</FlexLayout>
								<IconWrapper>
									{_.status === 'completed' ? (
										<ReplayIcon
											color="disabled"
											onClick={() => {
												toggleUnit(_.id);
											}}
										/>
									) : (
										<CheckIcon
											color="disabled"
											onClick={() => {
												toggleUnit(_.id);
											}}
										/>
									)}
								</IconWrapper>
							</FlexLayout>
							<>
								{_.time === '0' || _.time === null ? (
									'-'
								) : (
									<LearningTime>{findTime(Number(_.time))}</LearningTime>
								)}
							</>
						</Card>
					);
				})}
		</Wrapper>
	);
};

const Wrapper = styled(FlexLayout)`
	width: 45.5rem;
	height: 24.4rem;
	overflow-y: scroll;
	overflow-x: hidden;
`;

const Text = styled.p`
	color: #000;
	font-weight: 600;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 65px;
`;

const IconWrapper = styled.div`
	cursor: pointer;
`;
const Card = styled(FlexLayout)`
	padding-inline-start: 3.313rem;
	padding-inline-end: 3.313rem;
	justify-content: space-between;
	&:nth-child(even) {
		background: ${rgba('#707070', 0.1)};
		height: 3.313rem;
	}
	display: grid;
	grid-auto-flow: column;
	justify-items: flex-start;
`;
const LearningTime = styled.p`
	font-size: 0.875rem;
	font-weight: 500;
	color: #251b33;
`;
export default UnitCard;
