import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { useDisclosure } from '@Hooks/useDisclosure';
import {
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	Button,
	SelectChangeEvent,
} from '@mui/material';
import { useTypedSelector } from '@Features/store';
import { useQuestionsAndAnswersContext } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/QuestionsAndAnswers/QuestionsAndAnswers';
import { QuestionTypes } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/QuestionsAndAnswers/QuestionsAndAnswersConstants';
import { useGetDiscussionQuestionsListQuery } from '@Features/classrooms/instructorQuestionsAndAnswersApi';
import DatePicker from '@Components/MainTable/DatePicker';
import TableFiltersButton from '@Components/MainTable/TableFiltersButton';
import { VscClose } from 'react-icons/vsc';

type Props = {};

const QuestionsAndAnswersFilters: React.FC<Props> = () => {
	const filtersDisclosure = useDisclosure();
	const [filters, setFilters] = useImmer<any>({});
	const ref = useRef<HTMLDivElement>(null);

	const currentClassroom = useTypedSelector(
		(state) => state.classroom.currentClassroom
	);
	const { queryParams, setQueryParams } = useQuestionsAndAnswersContext();
	const requestPayload: ListDiscussionQuestionRequestPayload = {
		classroom_id: currentClassroom?.id!,
		filters: [
			{ type: 'Q&A' },
			{ solved: queryParams?.type === QuestionTypes.answered },
		],
	};
	if (queryParams.query) requestPayload.query = queryParams.query;

	const questionsListQuery = useGetDiscussionQuestionsListQuery(
		requestPayload,
		{
			skip: !requestPayload.classroom_id,
		}
	);

	const handleChange = (event: SelectChangeEvent<typeof filters>) => {
		setFilters((draft: any) => {
			draft[event.target.name] = event.target.value;
		});
	};

	const applyFiltersHandler = () => {
		setQueryParams(filters);
	};

	const resetFiltersHandler = () => {
		if (questionsListQuery.isError || !questionsListQuery?.data?.filters)
			return;

		setQueryParams({ unit_id: undefined, 'courses.code': undefined });
		setFilters({});
	};

	useEffect(() => {
		setFilters({
			unit_id: queryParams.unit_id,
			'courses.code': queryParams['courses.code'],
		});
	}, []);

	const renderFilter = (filter: any) => {
		if (['status', 'type'].includes(filter.name.toLowerCase())) return null;
		return (
			<FormControl key={filter.name}>
				<InputLabel sx={{ bg: 'white', width: 'fit-content' }}>
					{filter.name} filter
				</InputLabel>
				<Select
					inputProps={{ name: filter.value }}
					value={filters[filter.value] ?? ''}
					onChange={handleChange}
					sx={{ maxHeight: '500px' }}
					label={`${filter.name} filter`}
				>
					{filter.options.map((option: any) => (
						<MenuItem value={option.value}>{option.name}</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	};

	return (
		<Container ref={ref}>
			<DatePicker />

			<TableFiltersButton handleClick={filtersDisclosure.toggle} />

			{filtersDisclosure.isOpen &&
				questionsListQuery.isSuccess &&
				questionsListQuery?.data?.data && (
					<Body>
						<CloseIcon size={26} onClick={filtersDisclosure.onClose} />
						<FiltersContainer>
							{questionsListQuery.data.filters.map((filter: any) =>
								renderFilter(filter)
							)}
						</FiltersContainer>

						<Footer>
							<Button variant="outlined" onClick={resetFiltersHandler}>
								RESET
							</Button>
							<Button variant="contained" onClick={applyFiltersHandler}>
								APPLY
							</Button>
						</Footer>
					</Body>
				)}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	margin: 46px 0;
`;

const CloseIcon = styled(VscClose)`
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;
`;

const Body = styled.div`
	position: absolute;
	background-color: white;
	z-index: 5;
	width: 880px;
	height: 212px;
	padding: 24px 40px;
	box-shadow: 4px 4px 6px #00000029;
	border: 1px solid #ffffff;
`;

const FiltersContainer = styled.div`
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
`;

const Footer = styled.div`
	display: flex;
	gap: 16px;
	position: absolute;
	bottom: 16px;
	right: 26px;
`;

export default QuestionsAndAnswersFilters;
