import { Course, TabPayload } from './../../../../../Types/ClassroomTabs.types';
import { CURRICULUM_FORM } from '@Pages/ClassRooms/Schema/ClassroomCurriculum.schema';
import { Section, Tab } from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import { createContext } from 'react';
import { UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

export type CurriculumContextDefinition = {
	updateTab?: (index: number, tab: Tab) => void;
	deleteTab?: UseFieldArrayRemove;
};

export const CurriculumContext = createContext<CurriculumContextDefinition>({
	updateTab: () => {},
	deleteTab: () => {},
});

type CurriculumSectionContextDefinition = {
	tabIndex: number;
	appendSection: (index: number, section: Section) => void;
	//   removeSection: UseFieldArrayRemove;
	removeSection: (index: number) => void;
	updateSection: (index: number, section: Section) => void;
	dragHandlers?: DraggableProvidedDragHandleProps | null;
	isDragging: boolean;
	index: number;
};
type CurriculumCourseContextDefinition = {
	tabIndex: number;
	sectionIndex: number;
	updateCourse: (index: number, course: Course) => void;
	appendCourse: (course: Course) => void;
	removeCourse: UseFieldArrayRemove;
	dragHandlers?: DraggableProvidedDragHandleProps | null;
	isDragging: boolean;
	index: number;
};

export const CurriculumSectionContext =
	createContext<CurriculumSectionContextDefinition>({
		appendSection: () => {},
		removeSection: () => {},
		tabIndex: 0,
		isDragging: false,
		index: 0,
		updateSection: () => {},
	});

export const CurriculumCourseContext =
	createContext<CurriculumCourseContextDefinition>({
		updateCourse: () => {},
		appendCourse: () => {},
		removeCourse: () => {},
		tabIndex: 0,
		sectionIndex: 0,
		isDragging: false,
		index: 0,
	});
