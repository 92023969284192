import { array, boolean, object, string } from 'yup';
import { TestQuestionBaseSchema } from './TestQuestion';
const regExp: RegExp = new RegExp(/\[[(.*?)]{1}.*?[(.*?)]{1}(.*?)\]/g);

export const GAP_SCHEMA = TestQuestionBaseSchema.concat(
	object({
		type: string().default('gap'),
		content: string().matches(regExp, { message: 'Question is not correct' }),
	})
);
// const GapsRegexp = /(\[(?:.*?)\])/g
// let correctAnswerFlag: boolean = false
// export const GAP_SCHEMA = TestQuestionBaseSchema.concat(
//   object({
//     type: string().default("gap"),
//     content: string().test((val: any) => {
//       const gaps = val.match(GapsRegexp)
//        
//       if(gaps) {
//         for (let i = 0; i < gaps.length; i++) {
//           const currentGap = gaps[i]
//            \).*/g) })
//           //check correct answer between ()
//           if (currentGap.match(/.*\((.+)\).*/g)) {
//             correctAnswerFlag = true
//           }
//           if(gaps[i].matches(/([\[|])(?=\(.+\))/g))
//              
//         }
//         if (!correctAnswerFlag)
//           return false
//       }
//
//
//       return true
//     }),
//
//   })
// );
