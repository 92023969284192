import React from 'react';
import styled from 'styled-components';
import { Button, AlertProps } from '@mui/material';
import ConfirmationPopup from '../ConfirmationPopup';
import { useDisclosure } from '@Hooks/useDisclosure';
import { useAdmissionActionMutation } from '@Features/classrooms/InstructorAdmissionsApi';
import normalSweetAlert from '@Utils/normalSweetAlert';
import { useAdmissionsContext } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/Admissions';

type Props = {
	admissionRequestId: number;
};

type AdmissionError = {
	status: number;
	data: {
		error: string;
	};
};

const AdmissionsFooter: React.FC<Props> = ({ admissionRequestId }) => {
	const acceptDisclosure = useDisclosure();
	const rejectDisclosure = useDisclosure();
	const [admissionActionMutation] = useAdmissionActionMutation();
	const { setSelectedAdmissionIdQueryParam } = useAdmissionsContext();

	const acceptHandler = async () => {
		try {
			const response = await admissionActionMutation({
				actions: [{ id: admissionRequestId, admit: true }],
			}).unwrap();
			normalSweetAlert(
				response?.message ?? 'Student admission is accepted',
				'success'
			);
			setSelectedAdmissionIdQueryParam(undefined);
			acceptDisclosure.onClose();
		} catch (e) {
			const error = e as AdmissionError;
			normalSweetAlert(
				error?.data?.error ?? 'could not accept admission',
				'error'
			);
			acceptDisclosure.onClose();
		}
	};

	const rejectHandler = async () => {
		try {
			const response = await admissionActionMutation({
				actions: [{ id: admissionRequestId, admit: false }],
			}).unwrap();
			normalSweetAlert(
				response?.message ?? 'Student admission is rejected',
				'success'
			);
			setSelectedAdmissionIdQueryParam(undefined);
			acceptDisclosure.onClose();
		} catch (e) {
			const error = e as AdmissionError;
			normalSweetAlert(
				error?.data?.error ?? 'could not reject admission',
				'error'
			);
			acceptDisclosure.onClose();
		}
	};

	return (
		<Container>
			<FormButton
				onClick={rejectDisclosure.onOpen}
				color="warning"
				variant="contained"
			>
				Reject
			</FormButton>
			<FormButton
				onClick={acceptDisclosure.onOpen}
				style={{ marginRight: 22 }}
				variant="contained"
			>
				Accept
			</FormButton>

			{acceptDisclosure.isOpen && (
				<ConfirmationPopup
					{...acceptDisclosure}
					title="Accept Request"
					body="Are you sure you want to accept current admission request?"
					onConfirm={acceptHandler}
					confirmButtonText="yes"
					cancelButtonText="no"
				/>
			)}

			{rejectDisclosure.isOpen && (
				<ConfirmationPopup
					{...rejectDisclosure}
					title="Reject Request"
					body="Are you sure you want to reject current admission request?"
					onConfirm={rejectHandler}
					confirmButtonText="yes"
					cancelButtonText="no"
				/>
			)}
		</Container>
	);
};

const Container = styled.div`
	position: sticky;
	display: flex;
	justify-content: right;
	align-items: center;
	gap: 16px;
	bottom: 0;
	background-color: white;
	width: 100%;
	height: 84px;
	box-shadow: 0px -3px 6px #00000029;
	border-radius: 0 0 10px 10px;
	z-index: 2;
`;

const FormButton = styled(Button)`
	height: fit-content;
`;

export default AdmissionsFooter;
