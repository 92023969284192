import styled from 'styled-components';

export const PageLoaderWrapper = styled.div`
	/* background-color: rgba(0, 0, 0, 0.1); */
	/* position: sticky; */
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
	background-color: #fff;
	z-index: 99999 !important;
`;
