import { useTypedSelector } from '@Features/store';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { testQuestionsGetSingle } from '../../../Slice/TestQuestions/TestQuestionsSlice';
import EditEssayQuestionInfo from './EditEssayQuestionInfo';

const EditEssayQuestion = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.TestQuestions);
	const { currentEntity: currentTest } = useTypedSelector(
		(state) => state.Tests
	);

	useEffect(() => {
		dispatch(testQuestionsGetSingle({ id: Number(id) }));
	}, []);

	return (
		<div>
			{currentEntity && currentTest && getSingle === 'fulfilled' && (
				<EditEssayQuestionInfo />
			)}
		</div>
	);
};

export default EditEssayQuestion;
