import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { invoicesGetSingle } from '@Pages/Invoices/Slices/Invoices.slice';
import { useTypedSelector } from '@Features/store';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styled from 'styled-components';
import { FlexLayout, GridLayout } from '@Styled/utilities';
import { CardContainer } from '@Pages/ClassRooms/Pages/AddClassroom/AddClassroom';
import { useHistory } from 'react-router-dom';
import InvoicesCard from './InvoicesCard';
import TransactionCard from './TransactionCard';
import CourseCard from './CourseCard';
import { Button } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { InvoicesRequester } from '../../Services/Invoices.req';

const SingleInvoice = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const history = useHistory();
	const { displaySnackbar } = useSnackbar();

	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Invoices);

	useEffect(() => {
		dispatch(invoicesGetSingle({ id: Number(id) }));
	}, []);

	const onProcess = async () => {
		try {
			await InvoicesRequester.getInstance().process(Number(id));
			displaySnackbar('success', `Invoice Processed Successfully`);
			dispatch(invoicesGetSingle({ id: Number(id) }));
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Process Invoice ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			{currentEntity && getSingle === 'fulfilled' && (
				<CardContainer>
					<FlexLayout justifyContent="space-between">
						<BackButton
							alignItems="center"
							justifyContent="center"
							onClick={() => history.goBack()}
						>
							<IconStyle />
						</BackButton>
						<ProcessBtn variant="contained" onClick={() => onProcess()}>
							Process
						</ProcessBtn>
					</FlexLayout>
					<GridLayout gridArea="1.5rem" gridGap="1.5rem">
						<InvoicesCard />
						<TransactionCard />
					</GridLayout>
					{currentEntity.type === 'purchase' &&
						currentEntity?.courses.length > 0 && <CourseCard />}
				</CardContainer>
			)}
		</>
	);
};

export const BackButton = styled(FlexLayout)`
	width: 2.5rem;
	height: 2.5rem;
	background: rgba(90, 192, 252, 50%);
	border-radius: 50%;
	cursor: pointer;
`;

export const IconStyle = styled(ChevronLeftIcon)`
	font-size: 2rem;
	color: ${(props) => props.theme.palette.purple?.main};
	fill: ${(props) => props.theme.palette.purple?.main};
	z-index: 999;
`;

export const ProcessBtn = styled(Button)`
	background: ${(props) => props.theme.palette.purple?.main};
	border-radius: 24px;
	padding: 0.563rem 2.563rem 0.563rem 2.5rem;
	&:hover {
		background: ${(props) => props.theme.palette.purple?.main};
	}
`;

export default SingleInvoice;
