import TagChip from '@Components/UI/Chips/TagChip/TagChip';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout } from '@Styled/utilities';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ADD_TAG_SCHEMA } from '../../Schema/Tag.schema';
import {
	tagsCreate,
	tagsDeleteById,
	tagsGetAll,
} from '../../Slice/Tags/TagsSlice';
import { Tag } from '../../Types/Tags';

const TagsTable = () => {
	const history = useHistory();
	const dispatch = useAppThunkDispatch();
	const {
		loaders: { create, deleteById },
	} = useTypedSelector((state) => state.Tags);
	const { reset } = useForm<Tag>({
		mode: 'all',
		resolver: yupResolver(ADD_TAG_SCHEMA),
		defaultValues: { theme: '#83bdec' },
	});

	const { fetchAll } = useTypedSelector((state) => state.Tags);

	useEffect(() => {
		dispatch(
			tagsGetAll({
				page: 1,
				perPage: 5,
				filters: [],
				sortBy: '',
				query: '',
			})
		);
	}, [create === 'fulfilled', deleteById === 'fulfilled']);

	const resetForm = () => {
		reset({ theme: '#83bdec', name: '' }, { keepValues: false });
	};

	const { displaySnackbar } = useSnackbar();
	const onSave = async (tag: Tag) => {
		try {
			await dispatch(tagsCreate(tag)).unwrap();
			displaySnackbar('success', 'Created tag successfully');
			resetForm();
		} catch (error) {
			displaySnackbar('error', "Couldn't create tag");
		}
	};

	const [deleteModelOpen, setDeleteModalOpen] = useState(false);
	const [toBeDeletedTag, setToBeDeletedTag] = useState<Tag | undefined>();
	const handleModalClose = async (close: boolean, confirm?: boolean) => {
		if (confirm && toBeDeletedTag) {
			try {
				await dispatch(tagsDeleteById(toBeDeletedTag?.id as number)).unwrap();
				displaySnackbar('success', 'Deleted tag successfully');
			} catch (error) {
				displaySnackbar('success', "Couldn't delete tag");
			}
		}
		setDeleteModalOpen(close);
	};
	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to delete this tag ?"
				open={deleteModelOpen}
				handleClose={handleModalClose}
			/>
			<TagsWrapper
				overflow={'auto'}
				maxHeight={'24.375rem'}
				flexDirection="column"
			>
				{fetchAll.map((tag, index) => {
					return (
						<SingleTag key={`tag-${tag.id}-${index}`}>
							<TagChip
								minWidth={'14.25rem'}
								bgColor={tag.theme}
								label={tag.name}
							/>
							<FlexLayout>
								<IconButton
									onClick={() =>
										history.push(`${history.location.pathname}/edit/${tag.id}`)
									}
								>
									<EdIcon>edit</EdIcon>
								</IconButton>
								{/* <Spacer mx={"1.475rem"} />
                <IconButton
                  onClick={() => {
                    setDeleteModalOpen(true);
                    setToBeDeletedTag(tag as Tag);
                  }}
                >
                  <EdIcon>delete</EdIcon>
                </IconButton> */}
							</FlexLayout>
						</SingleTag>
					);
				})}
			</TagsWrapper>
			{/* <AddFormWrapper>
        <DevTool placement="bottom-left" control={control} />
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState: { error } }) => {
            return (
              <TextField
                helperText={error ? error.message : ""}
                size="small"
                {...field}
                error={error ? true : false}
              />
            );
          }}
        />
        <FlexLayout>
          <IconButton
            onClick={handleSubmit(onSave)}
            disabled={!formState.isDirty || !formState.isValid}
            color="primary"
          >
            <EdIcon mIconType="Outlined">check_circle</EdIcon>
          </IconButton>
          <Spacer mx={"1.475rem"} />

          <IconButton
            onClick={resetForm}
            disabled={!formState.isDirty}
            color="warning"
          >
            <EdIcon mIconType="Outlined">cancel</EdIcon>
          </IconButton>
        </FlexLayout>
      </AddFormWrapper> */}
		</>
	);
};

export default TagsTable;

const TagsWrapper = styled(FlexLayout)`
	::-webkit-scrollbar {
		display: none;
	}
`;
const SingleTag = styled(FlexLayout)`
	padding: 1.094rem 1.563rem;
	border-bottom: 2px solid ${(props) => props.theme.palette?.platinum?.main};
	justify-content: space-between;
	align-items: center;
	:last-child {
		border-bottom: none;
	}
`;

const AddFormWrapper = styled(SingleTag)`
	border-bottom: none;
	border-top: 2px solid ${(props) => props.theme.palette?.platinum?.main};
`;
