import { Section, Course } from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import React, { FC, useContext, useState } from 'react';
import {
	Control,
	useController,
	useForm,
	useFieldArray,
} from 'react-hook-form';
import {
	CURRICULUM_FORM,
	SECTION_SCHEMA,
} from '@Pages/ClassRooms/Schema/ClassroomCurriculum.schema';
import DragButton from '@Components/UI/Buttons/EdDragButton/EdDragButton';
import { AccordionDetails, IconButton, Paper, TextField } from '@mui/material';
import styled from 'styled-components';
import { AccordionTitle } from '../../Curriculum.styled';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	FlexItem,
	FlexLayout,
	GridItem,
	GridLayout,
	Spacer,
} from '@Styled/utilities';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { grey } from '@mui/material/colors';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import { ClassroomSectionsRequester } from '@Pages/ClassRooms/Services/ClassroomTabs/ClassroomSections.req';
import { lighten } from 'polished';
import { CurriculumSectionContext } from '../../Context/Curriculum.context';
import { AccordionSummary } from '@mui/material';
import EdCollapseButton from '@Components/UI/Buttons/EdCollapseButton/EdCollapseButton';

type Props = {
	section: Section;
	control: Control<CURRICULUM_FORM>;
};
const SectionAccordion: FC<Props> = ({ section, control }) => {
	const {
		dragHandlers,
		updateSection,
		index,
		isDragging,
		removeSection,
		tabIndex,
	} = useContext(CurriculumSectionContext);

	const { control: SectionControl, reset } = useForm<Section>({
		resolver: yupResolver(SECTION_SCHEMA),
		mode: 'all',
		defaultValues: {
			name: section.name,
		},
	});

	const { field, fieldState } = useController({
		control: SectionControl,
		name: 'name',
	});
	const onConfirm = async () => {
		const { data, status } =
			await ClassroomSectionsRequester.getInstance().update({
				...section,
				name: field.value,
			});
		if (status === 200) {
			updateSection(index, data.data as Section);
			reset({ name: data.data.name });
		}
	};

	const onDelete = async () => {
		if (!section.id) return;
		const { status } = await ClassroomSectionsRequester.getInstance().delete(
			section.id
		);
		if (status === 200) {
			removeSection(index);
		}
	};

	const { fields, append, update, remove } = useFieldArray({
		control,
		name: `tabs.${tabIndex}.sections.${index}.courses`,
		keyName: 'coursefId',
	});

	const onAddCourse = (course: Course) => {};

	const [opened, setOpened] = useState(false);

	return (
		<div>
			<StyledSectionAccordion isDragging={isDragging}>
				<AccordionSummary>
					<EdCollapseButton
						collapsed={!opened}
						onClick={() => setOpened(!opened)}
					/>
					<FlexLayout alignItems="center">
						<SectionTitle>Section {section.order}</SectionTitle>
						<SectionSummaryPaper>
							<FlexLayout alignItems="center">
								{dragHandlers && <DragButton dragHandlers={dragHandlers} />}
								<Spacer mx="0.5rem" />
								<TextField fullWidth size="small" {...field} />
							</FlexLayout>
						</SectionSummaryPaper>
						<FlexItem>
							{fieldState.isDirty && (
								<IconButton onClick={onConfirm} color="warning">
									<EdAnimatedIcon animation="shake">recommend</EdAnimatedIcon>
								</IconButton>
							)}
						</FlexItem>
						<IconButton onClick={onDelete}>
							<EdIcon>delete</EdIcon>
						</IconButton>
					</FlexLayout>
				</AccordionSummary>
				<AccordionDetails></AccordionDetails>
			</StyledSectionAccordion>
		</div>
	);
};

export default SectionAccordion;

const StyledSectionAccordion = styled.div<{ isDragging?: boolean }>`
	margin: 1rem 0;
	padding: 0.5rem 0 1rem 0.406rem;
	border: 1px solid ${(props) => props.theme.palette.grey[600]};
	background: ${grey[100]};
	border: ${(props) => (props.isDragging ? 'none' : '')};
	background-color: ${(props) =>
		props.isDragging
			? lighten(0.25, props.theme.palette.primary?.main ?? '')
			: ''};
	box-shadow: none;
	.MuiAccordionSummary-root {
		padding: 0;
	}
	&.Mui-expanded:last-of-type {
		margin: 1rem 0;
	}
`;

const SectionTitle = styled(AccordionTitle)`
	margin: 0;
`;

const SectionSummaryPaper = styled(Paper)`
	padding: 1rem;
	min-width: 27rem;
	margin: 0 0.5rem;
`;
