import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import QuestionsAndAnswersFilters from './QuestionsAndAnswersFilters';
import { useQuestionsAndAnswersContext } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/QuestionsAndAnswers/QuestionsAndAnswers';
import { QuestionTypes } from './QuestionsAndAnswersConstants';
import { CircleSpinner } from 'react-spinners-kit';
import useDebounce from '@Hooks/useDebounce';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';

type Props = {};

const QuestionsAndAnswersHeader: React.FC<Props> = () => {
	const { queryParams, setQueryParams } = useQuestionsAndAnswersContext();

	const inputRef = useRef<HTMLInputElement>(null);
	const searchDebounce = useDebounce<string>('');

	const onSearchClick = () => {
		inputRef.current?.focus();
	};

	const searchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		searchDebounce.setValue(event.target.value);
	};

	useEffect(() => {
		setQueryParams({ query: searchDebounce.debouncedValue });
	}, [searchDebounce.debouncedValue]);

	return (
		<div>
			<Header>Q & Ass</Header>
			<ButtonGroup
				value={queryParams.type}
				exclusive
				aria-label="text alignment"
			>
				<TabButtons
					value={QuestionTypes.unanswered}
					aria-label="pending"
					onClick={() => setQueryParams({ type: QuestionTypes.unanswered })}
				>
					Unanswered
				</TabButtons>
				<TabButtons
					value={QuestionTypes.answered}
					aria-label="rejects"
					onClick={() => setQueryParams({ type: QuestionTypes.answered })}
				>
					Answered
				</TabButtons>
			</ButtonGroup>

			<QuestionsAndAnswersFilters />

			<StyledSearchInputGroup onClick={onSearchClick}>
				<StyledSearchInput
					ref={inputRef}
					value={searchDebounce.value}
					onChange={searchChangeHandler}
					placeholder="search..."
				/>
				{searchDebounce.isDebouncing ? (
					<CircleSpinner size={12} color="#0e84f8" />
				) : (
					// <StyledSearchIcon alt='search icon' src={SearchIcon} />
					<EdIcon style={{ color: '#6C63FF' }}>search</EdIcon>
				)}
			</StyledSearchInputGroup>
		</div>
	);
};

const Header = styled.h1`
	font-size: 24px;
	letter-spacing: 0.15px;
	color: #2f2e41;
	font-weight: 600;
`;
const TabButtons = styled(ToggleButton)`
	background: #6e7577;
	color: #fff;
	font-size: 0.875rem;
	font-weight: normal;
	height: 1.7rem;

	&:hover {
		background: #6e7577;
		color: #fff;
	}

	&.Mui-selected,
	&.Mui-selected:hover {
		background: #5ac0fc;
		height: 2rem;
		color: #fff;
	}
`;

const ButtonGroup = styled(ToggleButtonGroup)`
	align-items: center;
	margin-top: 16px;
`;

const StyledSearchInputGroup = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #6c63ff;
	border-radius: 35px;
	padding-left: 20px;
	padding-right: 10px;
	margin-top: 14px;
	margin-left: auto;
	margin-bottom: 22px;
	max-width: 500px;

	:hover {
		cursor: text;
	}

	:focus-within {
		border: 1px solid #6c63ff;
	}
`;

const StyledSearchInput = styled.input`
	border: none;
	flex-grow: 1;
	padding: 12px 0;

	:focus {
		outline: none;
	}
`;

const StyledSearchIcon = styled.img`
	color: #6c63ff;
`;

export default QuestionsAndAnswersHeader;
