import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import { Button, TableCell, Tooltip } from '@mui/material';
import { EnrolledUser } from '@Services/classrooms/classrooms.res.types';
import sanitizeTableValue from '@Utils/sanitizeTableData';
import { useCallback, useState } from 'react';
import { TableCellStyled } from '../EnrollmentStudent';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import EnrollementModel from '../../../Components/EnrollmentModel/EnrollmentModel';
import UnEnrollementModel from '../../../Components/EnrollmentModel/UnEnroolmentModel';
import useFilters from '@Hooks/useFilters';
import useSortByURL from '@Hooks/useSortBy';
import useQueryURL from '@Hooks/useQueryUrl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from '@Providers/useSnackbar';
import {
	enrollUserRequest,
	UnEnrollUserRequest,
} from '@Services/classrooms/classrooms.req';
import { getCourseUsers } from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import { usePageLoader } from '@Providers/LoadingProvider';

type Props = {
	enrolled: EnrolledUser;
};

const UsersTable: React.FC<Props> = ({ enrolled }) => {
	const dispatch = useDispatch();
	const params = useParams<{ id: string; name: string; crid: string }>();
	const { setIsLoading, isLoading } = usePageLoader();
	const { displaySnackbar } = useSnackbar();

	const [selectedUser, setSelectedUser] = useState<EnrolledUser | null>(null);
	const [openEnrolled, setOpenEnrolled] = useState(false);
	const [openUnEnrolled, setOpenUnEnrolled] = useState(false);
	const [deduct, setDeduct] = useState<boolean | undefined>(undefined);
	const [refund, setRefund] = useState<boolean | undefined>(undefined);

	const { selectedFilters } = useFilters();
	const { selectedSortBy } = useSortByURL();
	const { selectedQuery } = useQueryURL();

	const { perPage, page } = useTypedSelector((state) => state.classroom);

	const handleOpen = (user: EnrolledUser) => {
		setOpenEnrolled(true);
		setSelectedUser(user);
	};
	const handleClose = () => {
		setOpenEnrolled(false);
	};

	const handleOpenUnEnrolledModel = (user: EnrolledUser) => {
		setOpenUnEnrolled(true);
		setSelectedUser(user);
	};
	const handleCloseUnEnrolledModel = () => {
		setOpenUnEnrolled(false);
	};

	const _onEnroll = useCallback(() => {
		if (selectedUser) {
			setIsLoading(true);
			enrollUserRequest(selectedUser?.email, Number(params.id), false)
				.then(() => {
					setIsLoading(false);
					displaySnackbar('success', 'enrolled successfully');
					dispatch(
						getCourseUsers({
							page: page,
							perPage: perPage,
							sortBy: selectedSortBy?.direction === null ? '' : selectedSortBy,
							filters: selectedFilters,
							query: selectedQuery,
							courseId: Number(params.id),
						})
					);
				})
				.catch((e) => {
					setIsLoading(false);
					const msg = e.response?.data.message || 'Unable to enroll user';
					displaySnackbar('error', msg);
				});
		}
		setOpenEnrolled(false);
		setIsLoading(true);
	}, [
		selectedUser,
		params.id,
		deduct,
		displaySnackbar,
		dispatch,
		setIsLoading,
	]);

	const _onUNEnroll = useCallback(() => {
		if (selectedUser) {
			setIsLoading(true);
			UnEnrollUserRequest(selectedUser?.email || '', Number(params.id), refund)
				.then(() => {
					setIsLoading(false);
					displaySnackbar('success', 'unenrolled successfully');
					dispatch(
						getCourseUsers({
							page: page,
							perPage: perPage,
							sortBy: selectedSortBy?.direction === null ? '' : selectedSortBy,
							filters: selectedFilters,
							query: selectedQuery,
							courseId: Number(params.id),
						})
					);
				})
				.catch((e) => {
					setIsLoading(false);
					const msg = e.response?.data.message || 'Unable to unenrolled user';
					displaySnackbar('error', msg);
				});
		}
		setOpenUnEnrolled(false);
		setIsLoading(true);
	}, [
		selectedUser,
		params.id,
		displaySnackbar,
		refund,
		dispatch,
		setIsLoading,
	]);

	return (
		<>
			<EnrollementModel
				setDeduct={setDeduct}
				handleClose={handleClose}
				openEnrolled={openEnrolled}
				onEnroll={_onEnroll}
			/>

			<UnEnrollementModel
				handleCloseUnEnrolledModel={handleCloseUnEnrolledModel}
				onUNEnroll={_onUNEnroll}
				setRefund={setRefund}
				openUnEnrolled={openUnEnrolled}
			/>
			<TableCellStyled>
				{enrolled.purchase_method
					? sanitizeTableValue(enrolled.purchase_method)
					: '-'}
			</TableCellStyled>
			<TableCell
				sx={{
					fontFamily: 'Roboto',
					fontSize: '1rem',
					padding: '0 1.063rem 0',
				}}
			>
				{enrolled.created_at ? (
					<DateTimeFormatter date={enrolled.created_at} />
				) : (
					'-'
				)}
			</TableCell>
			<TableCell
				sx={{
					fontFamily: 'Roboto',
					fontSize: '1rem',
					padding: '0 1.063rem 0',
				}}
			>
				{!enrolled.purchase_method && (
					<Tooltip placement="left" title="Enroll User">
						<Button id="enrollUser" onClick={() => handleOpen(enrolled)}>
							<AddBoxSharpIcon
								sx={{
									color: '#5ac0fc',
									height: '26px',
									width: '26px',
								}}
							/>
						</Button>
					</Tooltip>
				)}
			</TableCell>
		</>
	);
};

export default UsersTable;
