import React, { createContext, useEffect } from 'react';
import styled from 'styled-components';
import { GridLayout, FlexLayout, Spacer } from '@Styled/utilities';
import {
	useFieldArray,
	UseFieldArrayUpdate,
	useForm,
	UseFieldArrayRemove,
	Controller,
	UseFormSetValue,
} from 'react-hook-form';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import {
	Button,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTypedSelector } from '@Features/store';

import { useDispatch } from 'react-redux';
import { getAdmissionForm } from '@Features/classrooms/admissionFormSlice';
import { ADMISSION_SCHEMA } from '@Pages/ClassRooms/Schema/ClassroomAdmission.schema';
import {
	AdmissionQuestionBase,
	ClassroomAdmissionForm,
	ClassroomAdmissionPayload,
} from '@Pages/ClassRooms/Types/ClassroomAdmission.types';
import { ClassroomAdmissionRequester } from '@Pages/ClassRooms/Services/ClassroomAdmission/RequestsHandler/ClassroomAdmission.req';
import { DevTool } from '@hookform/devtools';
import AdmissionQuestion from '../AdmissionQuestion/AdmissionQuestion';
import { cloneDeep } from 'lodash';
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from 'react-beautiful-dnd';
import { useSnackbar } from '@Providers/useSnackbar';
import { getClassroomByLabel } from '@Features/classrooms/classroomSlice';

type AdmissionQuestionContextMeta = {
	update: UseFieldArrayUpdate<ClassroomAdmissionPayload, 'questions'>;
	remove: UseFieldArrayRemove;
	setValue: UseFormSetValue<ClassroomAdmissionPayload>;
};
export const AdmissionQuestionContext =
	createContext<AdmissionQuestionContextMeta>({
		update: () => {},
		remove: () => {},
		setValue: () => {},
	});

type Props = {
	onNext?: (payload: ClassroomAdmissionPayload) => void;
	mode?: 'edit' | 'create';
};
const ClassroomAdmission: React.FC<Props> = ({ onNext, mode = 'create' }) => {
	const admission = useTypedSelector((state) => state.classroomAdmissionForm);
	const { currentClassroom } = useTypedSelector((state) => state.classroom);

	const dispatch = useDispatch();

	const {
		control,
		handleSubmit,
		formState: { isDirty, isValid },
		setValue,
	} = useForm<ClassroomAdmissionForm>({
		mode: 'all',
		resolver: yupResolver(ADMISSION_SCHEMA),
		defaultValues: cloneDeep(admission),
	});

	const { fields, append, update, remove, move } = useFieldArray({
		control: control,
		name: 'questions',
		keyName: 'questionId',
	});

	const onAddQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
		append({
			question: '',
			order: fields.length + 1,
			type: 'essay',
		});
	};

	const { displaySnackbar } = useSnackbar();
	const onSave = async (formData: ClassroomAdmissionPayload) => {
		formData.questions = formData.questions.map((q, index) => ({
			...q,
			order: index + 1,
		}));
		if (currentClassroom) {
			try {
				const {
					data: {
						data: { id },
					},
				} = await ClassroomAdmissionRequester.getInstance().create({
					classroom_id: currentClassroom.id,
					admissionForm: formData.admissionForm,
					questions: formData.questions,
				});
				displaySnackbar('success', 'Admission created successfully');
				dispatch(getClassroomByLabel({ label: currentClassroom.label }));
				setValue('id', id);
			} catch (error) {}
		} else {
			if (mode === 'create') {
				onNext &&
					onNext({
						admissionForm: formData.admissionForm,
						questions: formData.questions,
					});
			}
		}
	};

	const onUpdate = async (formData: ClassroomAdmissionForm) => {
		try {
			formData.questions = formData.questions.map((q, index) => ({
				...q,
				order: index + 1,
			}));
			await ClassroomAdmissionRequester.getInstance().update(formData);
			displaySnackbar('success', 'Admission updated successfully');
		} catch (error) {}
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) return;
		move(result.source.index, result.destination.index);
	};

	const onRemoveQuestion = async (question: AdmissionQuestionBase) => {
		if (question.id && admission.id) {
			await ClassroomAdmissionRequester.getInstance().removeQuestion({
				question_id: question.id,
				admission_form_id: admission.id,
			});
		}
	};

	const onDeleteQuestion = async (index: number) => {
		const question = fields[index];
		try {
			if (question.id) await onRemoveQuestion(question);
			remove(index);
		} catch (error) {}
	};
	return (
		<>
			<SectionLayout>
				<GridLayout gridTemplateColumns={'1fr'} gridGap={'2rem'}>
					<EdFormControl
						required
						control={control}
						name="admissionForm.name"
						label="Title"
					/>

					<EdFormControl
						required
						control={control}
						name="admissionForm.description"
						label="Subtitle"
					/>
				</GridLayout>
				<FlexLayout alignItems="flex-end" justifyContent="flex-end">
					<Button onClick={onAddQuestion} variant="contained">
						Add Question
					</Button>
				</FlexLayout>
			</SectionLayout>
			<AdmissionQuestionsContainer>
				<AdmissionQuestionContext.Provider
					value={{
						update: update,
						remove: remove,
						setValue,
					}}
				>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="QUESTIONS-DROP">
							{({ droppableProps, innerRef, placeholder }) => {
								return (
									<div {...droppableProps} ref={innerRef}>
										{placeholder}
										{fields.map((question, index) => {
											return (
												<Draggable
													draggableId={`Q-${
														question.id ?? question.questionId
													}`}
													key={`Q-${question.id ?? question.questionId}`}
													index={index}
												>
													{({ draggableProps, innerRef, dragHandleProps }) => (
														<div {...draggableProps} ref={innerRef}>
															<AdmissionQuestion
																key={question.id ?? index}
																onDeleteQuestion={onDeleteQuestion}
																index={index}
																question={question}
																dragHandlers={dragHandleProps}
																control={control}
																onSwitchDelete
															/>
														</div>
													)}
												</Draggable>
											);
										})}
									</div>
								);
							}}
						</Droppable>
					</DragDropContext>
				</AdmissionQuestionContext.Provider>
			</AdmissionQuestionsContainer>
			<ActiosnContainer>
				<Spacer mx={'.5rem'} />
				{(!admission || mode === 'create') && (
					<Button
						onClick={handleSubmit(onSave)}
						disabled={!isValid}
						variant="contained"
					>
						Save
					</Button>
				)}
				{admission && mode === 'edit' && (
					<Button
						variant="contained"
						disabled={!isDirty}
						onClick={handleSubmit(onUpdate)}
					>
						Update
					</Button>
				)}
			</ActiosnContainer>
			<DevTool control={control} />
		</>
	);
};

export default ClassroomAdmission;

const SectionLayout = styled(GridLayout)`
	margin-right: 5.5rem;
	grid-gap: 2rem;
`;

const AdmissionQuestionsContainer = styled.div`
	margin-top: 4.375rem;
`;

const ActiosnContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 8.313rem;
`;
