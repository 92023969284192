import { TestImportsRequester } from "../Services/Imports.req";

export type TestImport = {
	id: number;
	slug: string;
	description: string;
	status: string;
	imported_data: string;
	success_data: string;
	failed_data: string;
	imported_count: number | null;
	success_count: number | null;
	failed_count: number | null;
} & TimeStamp;


export type TestImportType = 'attempts' | 'questions';

type TestImportsHandlerMap = {
	[key in TestImportType]: (arg: FormData) => Promise<any>;
};

export const importsHandlerMap: TestImportsHandlerMap = {
	attempts: TestImportsRequester.getInstance().importAttempts,
	questions: TestImportsRequester.getInstance().importQuestions,
};
