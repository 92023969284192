import { BaseSliceController } from '@Base/slices/slices.base';
import { ScratchCardsRequester } from '../Services/ScratchCards.req';
import { ScratchCard } from '../Types/ScratchCards.types';

export class ScratchCardsSlice extends BaseSliceController<
	ScratchCard,
	'ScratchCards'
> {
	private static instance: ScratchCardsSlice;
	static getInstance() {
		if (!ScratchCardsSlice.instance) {
			ScratchCardsSlice.instance = new ScratchCardsSlice();
		}
		return ScratchCardsSlice.instance;
	}
	constructor() {
		super('ScratchCards', ScratchCardsRequester.getInstance());
	}
}

export const {
	scratchCardsExportData,
	scratchCardsGenerate,
	scratchCardsGetAll,
	scratchCardsToggleActive,
} = ScratchCardsSlice.getInstance().getActions();
