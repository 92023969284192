import { useTypedSelector } from '@Features/store';
import useQueryURL from '@Hooks/useQueryUrl';
import { Box, Button, Modal } from '@mui/material';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { changeUserSlot } from '../../../Services/RegisteredUsers/RegisteredRequester';
import { registeredUsersGetAll } from '../../../Slices/Registered.slice';
import moment from 'moment';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
	openModel: boolean;
};

const ChangeUserSlotModule: React.FC<Props> = ({
	handleModelClose,
	openModel,
}) => {
	const { id } = useParams<{ id: string }>();
	const { displaySnackbar } = useSnackbar();
	const { fetchAll } = useTypedSelector((state) => state.RegisteredUsers);
	const { currentEntity } = useTypedSelector((state) => state.Webinars);
	const [active, setActive] = useState<number>(0);
	const [slotId, setSlotId] = useState<number>(0);
	const dispatch = useDispatch();
	const { selectedQuery } = useQueryURL();

	const studentId = fetchAll.find((user) => user.student_id);
	const currentSlot = currentEntity?.webinarSlots
		?.filter((_) => _.id === Number(id))
		.find((_) => _.id);

	const changeUser = async (fromId: number, studentId: number) => {
		try {
			await changeUserSlot(fromId, studentId, slotId);
			displaySnackbar('success', `User Slot changed successfully`);
			dispatch(
				registeredUsersGetAll({
					page: 1,
					filters: [],
					perPage: 10,
					query: selectedQuery,
					sortBy: null,
					id: id,
				})
			);
			handleModelClose(false);
		} catch (e) {
			const msg = e.response?.data.message || 'Unable to Change User Slot ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<>
			<Modal
				open={openModel}
				onClose={() => {
					handleModelClose(false);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Container>
					<Header>Please select another slot to move user to</Header>
					<CardsContainer>
						{currentEntity?.webinarSlots
							?.filter(
								(_) =>
									_.id !== Number(id) &&
									(_.capacity === null || _.capacity > _.students_count) &&
									_.end_time &&
									new Date(_.end_time).getTime() > Date.now()
							)
							.map((_, index) => (
								<CardWrapper
									bg={active === index ? 'rgba(0, 0, 0, 0.04)' : null}
									onClick={() => {
										setSlotId(_.id);
										setActive(index);
									}}
								>
									<Text>
										{_.name}: {moment(_.start_time).format('ddd. DD/MM')}
									</Text>
									<Text>{moment(_.start_time).format('kk:mm a')}</Text>
								</CardWrapper>
							))}
					</CardsContainer>
					<ButtonsContainer>
						<CancelBtn
							variant="text"
							color="warning"
							onClick={() => {
								handleModelClose(false);
							}}
						>
							Cancel
						</CancelBtn>
						<ConfirmBtn
							variant="text"
							color="inherit"
							type="submit"
							onClick={() => {
								changeUser(
									currentSlot?.id as number,
									studentId?.student_id as number
								);
							}}
						>
							Confirm
						</ConfirmBtn>
					</ButtonsContainer>
				</Container>
			</Modal>
		</>
	);
};

export const Container = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 17.5rem;
	border-radius: 4px;
	border: 0;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);
	margin: 3px 83.2px 0px 0;
	padding: 24px 24px 0 24px;
	background-color: #ffffff;
	outline: none;
`;

export const Header = styled.p`
	color: #707070;
	font-weight: 500;
	font-size: 1rem;
`;

export const CardsContainer = styled.div`
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);
	border-radius: 4px;
	margin-top: 2rem;
	height: 9.75rem;
	overflow-y: scroll;
	overflow-x: hidden;
`;

export const ConfirmBtn = styled(Button)`
	font-family: Roboto;
	font-size: 1rem;
	padding: 6px 16px;
	color: #6c63ff;
	background-color: #fff;
	outline: none;
	font-weight: 500;
`;

export const CancelBtn = styled(Button)`
	font-family: Roboto;
	font-size: 1rem;
	padding: 6px 16px;
	color: #ff9800;
	outline: none;
	background-color: #fff;
	font-weight: 500;
`;

export const ButtonsContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	outline: none;
	margin-top: 2.125rem;
`;

export const CardWrapper = styled.div<{ bg: string | null }>`
	padding: 0.25rem 1rem;
	height: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 11px;
	cursor: pointer;
	background: ${({ bg }) => bg};
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;

const Text = styled.div`
	font-size: 0.9rem;
	font-weight: 500;
	color: ${(props) => props.theme.palette.darkPurple.main};
`;

export default ChangeUserSlotModule;
