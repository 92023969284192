import { baseApiSlice } from '@Features/baseApiSlice';

const instructorQuestionsAndAnswersApi = baseApiSlice.injectEndpoints({
	endpoints: (build) => ({
		getDiscussionQuestionsList: build.query<
			ListDiscussionQuestionsResponsePayload,
			ListDiscussionQuestionRequestPayload
		>({
			query: (data) => ({
				url: '/discussions/fetch',
				method: 'POST',
				data,
			}),
			providesTags: ['DiscussionQuestion'],
		}),
		answerQuestion: build.mutation<
			ApiResponse<undefined>,
			AnswerDiscussionQuestionRequestPayload
		>({
			query: (data) => ({
				url: '/discussions/replies/create',
				method: 'POST',
				data,
			}),
			invalidatesTags: ['DiscussionQuestion'],
		}),
		updateQuestionAnswer: build.mutation<
			ApiResponse<undefined>,
			UpdateDiscussionAnswerRequestPayload
		>({
			query: (data) => ({
				url: '/discussions/replies/update',
				method: 'POST',
				data,
			}),
			invalidatesTags: ['DiscussionQuestion'],
		}),
		deleteDiscussionQuestion: build.mutation<
			ApiResponse<undefined>,
			DeleteDiscussionQuestionRequestPayload
		>({
			query: (data) => ({
				url: '/discussions/delete',
				method: 'POST',
				data,
			}),
			invalidatesTags: ['DiscussionQuestion'],
		}),
	}),
});

export const {
	useGetDiscussionQuestionsListQuery,
	useDeleteDiscussionQuestionMutation,
	useAnswerQuestionMutation,
	useUpdateQuestionAnswerMutation,
} = instructorQuestionsAndAnswersApi;
