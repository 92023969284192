import { routes } from '@Constants/routes';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Menu from '../../Menu/Menu';
import Header from './Header';
import { map } from 'lodash';
import LoadingProvider from '@Providers/LoadingProvider';
import { RouteProvider } from '@Providers/Routes/RoutesProvider';
const DashboardLayout: React.FC = () => {
	const routeGroups = map(routes, 'routeGroups').flat(1);

	const { path } = useRouteMatch();
	return (
		<>
			<DashboardLayoutWrapper>
				<Menu />
				<RoutesLayoutWrapper>
					<LoadingProvider />
					<Header />
					<DashboardContentWrapper>
						<RouteProvider path={path}>
							<Switch>
								{routeGroups &&
									Array.isArray(routeGroups) &&
									routeGroups.map((route, index) => {
										return (
											<Route
												key={`${index}-${route.route}-dl`}
												path={`/dashboard/${route.route}`}
											>
												{route.component}
											</Route>
										);
									})}
							</Switch>
						</RouteProvider>
					</DashboardContentWrapper>
				</RoutesLayoutWrapper>
			</DashboardLayoutWrapper>
		</>
	);
};

export default DashboardLayout;

const RoutesLayoutWrapper = styled.div`
	flex-grow: 1;
	min-height: 100%;
	/* overflow: auto; */
	/* overflow: auto; */
	width: 100%;
	position: relative;
	* {
		/* box-sizing: border-box; */
	}
`;
const DashboardLayoutWrapper = styled.div`
	display: flex;
	height: inherit;
	align-items: stretch;
	min-height: 100vh;
`;

const DashboardContentWrapper = styled.div`
	padding: 0 0.5rem;
	margin-bottom: 1.563rem;
`;
