import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTypedSelector } from '@Features/store';
import { FlexLayout } from '@Styled/utilities';
import { Divider, GridLayout } from '@Styled/utilities';
import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import { getEducationalInfo } from '@Features/classrooms/educationalSlice';
import { useDispatch } from 'react-redux';

const EditStudentForm: React.FC = () => {
	const dispatch = useDispatch();
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const { educational_info, educational_type } = useTypedSelector(
		(state) => state.educational
	);
	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);

	const years = React.useMemo(() => {
		const flatYears = educational_info
			.filter((_) => _.id)
			.map(({ educationYears }) => educationYears)
			.flat();
		return flatYears.filterDups('id');
	}, [educational_info]);

	const languages = React.useMemo(() => {
		const flatLanguages = educational_info
			.filter((_) => _.id)
			.map(({ educationLanguages }) => educationLanguages)
			.flat();
		return flatLanguages.filterDups('id');
	}, [educational_info]);

	const sections = React.useMemo(() => {
		const flatYears = educational_info
			.map(({ educationYears }) => educationYears)
			.flat()
			.filterDups('id')
			.filter((_) => _?.id);

		return flatYears
			.map(({ educationSections }) => educationSections)
			.flat()
			.filterDups('id');
	}, [educational_info]);

	return (
		<div>
			<FlexLayout flexDirection="column">
				<Divider label="Educational Info" />
				<SectionLayout
					gridGap={'2rem'}
					gridTemplateColumns={{ sm: 'repeat(1,1fr)' }}
					marginBottom="2rem"
				>
					<FlexLayout>
						<EdFormControlLabel>School</EdFormControlLabel>
						<EdFormControlLabel>
							{currentEntity.student.school}
						</EdFormControlLabel>
					</FlexLayout>
				</SectionLayout>
				<SectionLayout
					gridGap={'2rem'}
					gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
				>
					<FlexLayout>
						<EdFormControlLabel>Diploma</EdFormControlLabel>
						<EdFormControlLabel>
							{' '}
							{
								educational_type?.find(
									(_) => _.value === currentEntity.student.education_type_id
								)?.name
							}
						</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Study Year</EdFormControlLabel>
						<EdFormControlLabel>
							{years
								?.find(
									(_: any) => _?.id === currentEntity.student.education_year_id
								)
								?.name?.replaceAll('_', ' ')}
						</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Study Branch</EdFormControlLabel>
						<EdFormControlLabel>
							{
								sections.find(
									(_: any) =>
										_.id === currentEntity.student.education_section_id
								)?.name
							}
						</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Study Language</EdFormControlLabel>
						<EdFormControlLabel>
							{' '}
							{
								languages.find(
									(_: any) =>
										_.id === currentEntity.student.education_language_id
								)?.name
							}
						</EdFormControlLabel>
					</FlexLayout>
				</SectionLayout>

				<Divider label="Personal Info" />
				<SectionLayout
					gridGap={'2rem'}
					gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
				>
					<FlexLayout>
						<EdFormControlLabel>First Name</EdFormControlLabel>
						<EdFormControlLabel>{currentEntity.first_name}</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Last Name</EdFormControlLabel>
						<EdFormControlLabel>{currentEntity.last_name}</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Phone no.</EdFormControlLabel>
						<EdFormControlLabel>
							{currentEntity.phone_number}
						</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Email</EdFormControlLabel>
						<EdFormControlLabel>{currentEntity.email}</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Username</EdFormControlLabel>
						<EdFormControlLabel>{currentEntity.username}</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Gender</EdFormControlLabel>
						<EdFormControlLabel>{currentEntity.gender}</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Birthdate </EdFormControlLabel>
						<EdFormControlLabel>
							{new Date(currentEntity.birth_date).toLocaleDateString('en-US')}
						</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Governorate</EdFormControlLabel>
						<EdFormControlLabel>
							{currentEntity.student.address?.governorate}
						</EdFormControlLabel>
					</FlexLayout>
				</SectionLayout>
				<SectionLayout
					gridTemplateColumns={{ sm: 'repeat(1,1fr)' }}
					marginTop="2rem"
					marginBottom="2rem"
				>
					<FlexLayout>
						<EdFormControlLabel>City</EdFormControlLabel>
						<EdFormControlLabel>
							{currentEntity.student.address?.city}
						</EdFormControlLabel>
					</FlexLayout>
				</SectionLayout>
				<SectionLayout>
					<FlexLayout>
						<EdFormControlLabel>Parent Relation</EdFormControlLabel>
						<EdFormControlLabel>
							{currentEntity.student.parent_relation}
						</EdFormControlLabel>
					</FlexLayout>

					<FlexLayout>
						<EdFormControlLabel>Parent Phone</EdFormControlLabel>
						<EdFormControlLabel>
							{currentEntity.student.parent_phone}
						</EdFormControlLabel>
					</FlexLayout>
				</SectionLayout>
			</FlexLayout>
		</div>
	);
};

export const SectionLayout = styled(GridLayout)`
	margin-right: 5.5rem;
	grid-gap: 2rem;
`;

export default EditStudentForm;
