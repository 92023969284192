import {
	AllClassroomsGraphPayload,
	ClassroomEnrollment,
	ClassroomEnrollments,
	ClassroomGraphPayload,
	CourseEnrollment,
	CourseGraphPayload,
	GraphPayload,
	GraphSlice,
} from '@Services/classrooms/classrooms.res.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ClassroomEnrollementsPayload } from '@Features/newsFeeds/newsFeedSlice.types';
import EdResponse from '@Services/generics/response';
import createThunkBody from '@Utils/createThunkBody';
import {
	GetAllClassroomsEnrollmentsRequest,
	GetAllClassroomsGraphRequest,
	getAllEnrollments,
	getClassroomSectionsRequest,
	getCourseUsersRequest,
	getEnrolledCountRequest,
	getEnrolledUsersRequest,
	toggleBanStudentClassroom,
	GetClassroomEnrollmentsRequest,
	GetClassroomGraphRequest,
	GetCourseGraphRequest,
	GetCourseEnrollmentsRequest,
	GetAllClassroomsAnalyticsDatatableRequest,
	GetClassroomAnalyticsDatatableRequest,
	getCourseProgressRequest,
	GetClassroomUnitsAnalyticsRequest,
	GetCourseUnitsAnalyticsRequest,
} from '@Services/classrooms/classrooms.req';
import {
	getAllClassRoomsRequest,
	getAllCoursesRequest,
	getSingleClassroomRequest,
	getSingleCourseRequest,
	getUnenrolledUsersRequest,
} from '@Services/classrooms/classrooms.req';
import {
	ClassroomSection,
	FetchClassroomsResponse,
	GetAllCoursesResponse,
	GetEnrolledCountResponse,
	GetEnrolledResponse,
	GetSingleClassroomResponse,
	GetSingleCourseResponse,
	GetUnEnrolledResponse,
} from '@Services/classrooms/classrooms.res.types';
import {
	BanStudentPayload,
	ClassroomSliceState,
	GetAllClassroomsPayload,
	GetAllCoursesPayload,
	GetEnrolledCountPayload,
	GetEnrolledUsersPayload,
	GetUnEnrolledUsersPayload,
} from './classroomSlice.types';
import { ClassroomResponse } from '@Pages/ClassRooms/Types/Classroom.types';
import { ClassroomRequester } from '@Pages/ClassRooms/Services/Classroom/Requests/Classroom.req';
import { AxiosResponse } from 'axios';
import { ClassroomUnitsAnalytics } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/ClassroomUnitsAnalytics/Types/ClassroomUnitsAnalytics.types';
import { CourseUnitsAnalytics } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Analytics/CourseUnitsAnalytics/Types/CourseUnitsAnalytics.types';

const SLICE_BASE_NAME = 'classroom';
/* SIDE EFFECTS */
const columnHeadToField: any = {
	name: 'first_name',
	category: '',
};

/* classroom */

export const getAllClassrooms = createAsyncThunk<
	FetchClassroomsResponse,
	GetAllClassroomsPayload,
	{ rejectValue: string }
>(
	'classroom/getAll',
	async ({ page, perPage, sortBy, filters, query, from, to }, thunkApi) => {
		try {
			const { data } = await getAllClassRoomsRequest(
				page,
				perPage,
				filters,
				sortBy,
				query,
				from,
				to
			);
			return data;
		} catch (error) {
			return error;
		}
	}
);

export const getClassroomByLabel = createAsyncThunk<
	ApiResponse<ClassroomResponse>,
	{ label: string },
	{ rejectValue: string }
>('classroom/getOne', async ({ label }) => {
	const { data } = await ClassroomRequester.getInstance().get(label, { label });
	return data;
});

export const getAllClassroomEnrollements = createAsyncThunk<
	ApiResponse<ClassroomEnrollments[]>,
	ClassroomEnrollementsPayload,
	{ rejectValue: string }
>(
	'classroom/enrollments',
	async (
		{ page, perPage, classroom_id, filters, sortBy, query, from, to },
		thunkApi
	) => {
		try {
			const { data } = await getAllEnrollments(
				page,
				perPage,
				classroom_id,
				filters,
				query,
				sortBy,
				from,
				to
			);
			return data;
		} catch (err) {
			const msg = err.response?.data.message || 'failed';
			return thunkApi.rejectWithValue(msg);
		}
	}
);

export const getClassroomSections = createAsyncThunk<
	EdResponse<ClassroomSection[]>,
	{ id: string },
	{ rejectValue: string }
>('classroom/getSections', async ({ id }, thunkApi) => {
	return await createThunkBody<EdResponse<ClassroomSection[]>>(
		getClassroomSectionsRequest(id),
		thunkApi,
		"Error Fetching Classroom's Sections"
	);
});

/* courses */

export const getAllCourses = createAsyncThunk<
	GetAllCoursesResponse,
	GetAllCoursesPayload,
	{ rejectValue: string }
>(
	'courses/getAll',
	async (
		{ page, perPage, sortBy, filters, query, classroom, from, to },
		thunkApi
	) => {
		return await createThunkBody<GetAllCoursesResponse>(
			getAllCoursesRequest(
				page,
				perPage,
				filters,
				sortBy,
				query,
				classroom,
				from,
				to
			),
			thunkApi,
			'Error fetching courses'
		);
	}
);

export const getCourseById = createAsyncThunk<
	GetSingleCourseResponse,
	{ id: string },
	{ rejectValue: string }
>('course/getOne', async ({ id }, thunkApi) => {
	return await createThunkBody<GetSingleCourseResponse>(
		getSingleCourseRequest(id),
		thunkApi,
		'Error fetching course'
	);
});

export const getUnEnrolledUsers = createAsyncThunk<
	GetUnEnrolledResponse,
	GetUnEnrolledUsersPayload,
	{ rejectValue: string }
>(
	'course/getUnenrolled',
	async (
		{ courseId, filters, page, perPage, query, sortBy, from, to },
		thunkApi
	) => {
		return await createThunkBody<GetUnEnrolledResponse>(
			getUnenrolledUsersRequest(
				page,
				perPage,
				filters,
				sortBy,
				query,
				courseId,
				from,
				to
			),
			thunkApi,
			'Error fetching unEnrolled users'
		);
	}
);

export const getEnrolledUsers = createAsyncThunk<
	GetEnrolledResponse,
	GetEnrolledUsersPayload,
	{ rejectValue: string }
>(
	'course/getEnrolled',
	async (
		{ courseId, filters, page, perPage, query, sortBy, from, to },
		thunkApi
	) => {
		return await createThunkBody<GetEnrolledResponse>(
			getEnrolledUsersRequest(
				page,
				perPage,
				filters,
				sortBy,
				query,
				courseId,
				from,
				to
			),
			thunkApi,
			'Error fetching Enrolled users'
		);
	}
);

export const getCourseUsers = createAsyncThunk<
	GetEnrolledResponse,
	GetEnrolledUsersPayload,
	{ rejectValue: string }
>(
	'course/users',
	async (
		{ courseId, filters, page, perPage, query, sortBy, from, to },
		thunkApi
	) => {
		return await createThunkBody<GetEnrolledResponse>(
			getCourseUsersRequest(
				page,
				perPage,
				filters,
				sortBy,
				query,
				courseId,
				from,
				to
			),
			thunkApi,
			'Error fetching Course users'
		);
	}
);
export const getCourseProgress = createAsyncThunk<
	GetEnrolledResponse,
	GetEnrolledUsersPayload,
	{ rejectValue: string }
>(
	'course/users',
	async (
		{ courseId, filters, page, perPage, query, sortBy, from, to },
		thunkApi
	) => {
		return await createThunkBody<GetEnrolledResponse>(
			getCourseProgressRequest(
				page,
				perPage,
				filters,
				sortBy,
				query,
				courseId,
				from,
				to
			),
			thunkApi,
			'Error fetching Course users'
		);
	}
);

export const getEnrolledCount = createAsyncThunk<
	GetEnrolledCountResponse,
	GetEnrolledCountPayload,
	{ rejectValue: string }
>('enrollment/count', async ({ classroomId, courseId }, thunkApi) => {
	return await createThunkBody<GetEnrolledCountResponse>(
		getEnrolledCountRequest(classroomId, courseId),
		thunkApi,
		'Error fetching Enrolled Count Purchase methods'
	);
});

//Ban Student
export const banStudentFromClassroom = createAsyncThunk<
	ApiResponse<any>,
	BanStudentPayload,
	{ rejectValue: string }
>('classrooms/ban', async ({ classroom_id, user_id, active ,note}, thunkAPI) => {
	try {
		const { data } = await toggleBanStudentClassroom({
			classroom_id,
			user_id,
			active,
			note 
		});
		return data;
	} catch (err) {
		const msg = err?.data?.message || 'failed';
		return thunkAPI.rejectWithValue(msg);
	}
});

export const getAllClassroomEnrollments = createAsyncThunk<
	ApiResponse<ClassroomEnrollment>,
	void,
	{ rejectValue: string }
>(`${SLICE_BASE_NAME}/all_enrollments`, async () => {
	const { data } = await GetAllClassroomsEnrollmentsRequest();
	return data;
});

export const getClassroomUnitsAnalytics = createAsyncThunk<
	ApiResponse<ClassroomUnitsAnalytics>,
	number
>(`${SLICE_BASE_NAME}/classroom_all_units`, async (id: number) => {
	const { data } = await GetClassroomUnitsAnalyticsRequest(id);
	return data;
});

export const getCourseUnitsAnalytics = createAsyncThunk<
	ApiResponse<CourseUnitsAnalytics>,
	number
>(`enrollment/course_all_units`, async (id: number) => {
	const { data } = await GetCourseUnitsAnalyticsRequest(id);
	return data;
});

export const getAllClassroomsAnalyticsDatatable = createAsyncThunk<
	ApiResponse<ClassroomEnrollment[]>,
	DatatablePayload,
	{ rejectValue: string }
>(`${SLICE_BASE_NAME}/all_analytics_database`, async (payload) => {
	const { data } = await GetAllClassroomsAnalyticsDatatableRequest(payload);
	return data;
});

export const getClassroomAnalyticsDatatable = createAsyncThunk<
	ApiResponse<CourseEnrollment[]>,
	ClassroomDatatablePayload,
	{ rejectValue: string }
>(`${SLICE_BASE_NAME}/classroom_analytics_database`, async (payload) => {
	const { data } = await GetClassroomAnalyticsDatatableRequest(payload);
	return data;
});

export const getClassroomEnrollments = createAsyncThunk<
	ApiResponse<ClassroomEnrollment>,
	number
>(`${SLICE_BASE_NAME}/classroom_enrollments`, async (id: number) => {
	const { data } = await GetClassroomEnrollmentsRequest(id);
	return data;
});

export const getAllClassroomsGraph = createAsyncThunk<
	ApiResponse<GraphSlice[]>,
	AllClassroomsGraphPayload
>(`${SLICE_BASE_NAME}/graph`, async (payload) => {
	const { data } = await GetAllClassroomsGraphRequest(payload);
	return data;
});

export const getClassroomGraph = createAsyncThunk<
	ApiResponse<GraphSlice[]>,
	ClassroomGraphPayload
>(`${SLICE_BASE_NAME}/classroom/graph`, async (payload) => {
	const { data } = await GetClassroomGraphRequest(payload);
	return data;
});

export const getCourseEnrollments = createAsyncThunk<
	ApiResponse<CourseEnrollment>,
	number
>(`${SLICE_BASE_NAME}/course_enrollments`, async (id: number) => {
	const { data } = await GetCourseEnrollmentsRequest(id);
	return data;
});

export const getCourseGraph = createAsyncThunk<
	ApiResponse<GraphSlice[]>,
	CourseGraphPayload
>(`${SLICE_BASE_NAME}/course/graph`, async (payload) => {
	const { data } = await GetCourseGraphRequest(payload);
	return data;
});
/* SLICE */
const initialState: ClassroomSliceState = {
	/* classroom */
	classrooms: [],
	classroomFilters: [],
	currentClassroom: null,
	classroomEnrollments: [],
	classroomEnrollementsFilters: [],
	unEnrolledUsers: [],
	courseUsers: [],
	courses: [],
	currentCourse: null,
	currentClassroomSections: [],
	coursesFilters: [],
	coursesSelectedFilters: [],
	lastPage: 0,
	totalResults: 0,
	perPage: 0,
	query: '',
	from: undefined,
	sortBy: undefined,
	to: undefined,
	page: 0,
	name: '',
	EnrolledUsers: [],
	EnrolledPurchaseCount: [],
	analytics: {
		all: {
			enrollments: null,
			graph: [],
			singleClassroomUnitsAnalytics: {
				documents: 0,
				tests: 0,
				total: 0,
				videos: 0,
				webcontent: 0,
				webinar: 0,
				assignment: 0,
			},
			singleCourseUnitsAnalytics: {
				documents: 0,
				tests: 0,
				total: 0,
				videos: 0,
				webcontent: 0,
				webinar: 0,
				assignment: 0,
			},
			datatable: {
				data: [],
			},
		},
		currentClassroom: {
			enrollments: null,
			graph: [],
			datatable: {
				data: [],
			},
		},
		currentCourse: {
			enrollments: null,
			graph: [],
		},
	},

	loaders: {
		getAll: null,
		getOne: null,
		getAllCourses: null,
		getOneCourse: null,
		getUnEnrolled: null,
		getCourseUsers: null,
		getEnrolledCount: null,
		getEnrolledAnalysis: null,
		getClassroomAnalytics: null,
		getEnrolled: null,
		getEducationalInfo: null,
		getClassroomEnrollments: null,
		toggleBanLoader: null,
	},
	errors: {
		getAll: '',
		getOne: '',
		getAllCourses: '',
		getOneCourse: '',
		getCourseUsers: '',
		getUnEnrolled: '',
		getEnrolledCount: '',
		getEnrolledAnalysis: '',
		getClassroomAnalytics: '',
		getEnrolled: '',
		getEducationalInfo: '',
		getClassroomEnrollments: '',
		toggleBanError: '',
	},
};

const classRoomSlice = createSlice({
	name: SLICE_BASE_NAME,
	initialState,
	reducers: {
		resetCurrentCourse: (state) => {
			state.currentCourse = null;
		},
	},
	extraReducers: (builder) => {
		/**
		 * @section CLASSROOMS
		 */
		/* getting Educational info case */

		/* get all classrooms case */
		builder
			.addCase(getAllClassrooms.pending, (state) => {
				state.loaders.getAll = true;
				state.errors.getAll = '';
			})
			.addCase(getAllClassrooms.fulfilled, (state, { payload }) => {
				if (!payload) return;
				state.loaders.getAll = false;
				state.classrooms = payload.data;
				state.classroomFilters = payload.filters;
				state.lastPage = payload.meta.last_page;
				state.totalResults = payload.meta.total;
				state.perPage = payload.meta.per_page;
				state.page = payload.meta.current_page;
				state.currentClassroom = null;
			})
			.addCase(getAllClassrooms.rejected, (state, { payload }) => {
				state.loaders.getAll = undefined;
				state.errors.getAll =
					payload || 'could not get classrooms, please try again!';
			});

		/* get single classroom case */
		builder
			.addCase(getClassroomByLabel.pending, (state) => {
				state.loaders.getOne = true;
				state.errors.getOne = '';
			})
			.addCase(getClassroomByLabel.fulfilled, (state, { payload }) => {
				state.currentClassroom = payload.data;
				state.loaders.getOne = 'fulfilled';
			})
			.addCase(getClassroomByLabel.rejected, (state, { payload }) => {
				state.loaders.getOne = undefined;

				state.errors.getOne =
					payload || 'could not get classroom, please try again!';
			});
		builder
			.addCase(getClassroomSections.pending, (state) => {
				state.errors.getOne = '';
			})
			.addCase(
				getClassroomSections.fulfilled,
				(state, { payload: { data } }) => {
					state.currentClassroomSections = data;
				}
			)
			.addCase(getClassroomSections.rejected, (state, { payload }) => {
				state.loaders.getOne = undefined;
			});

		/**
		 * @section COURSES
		 */

		/* get all courses case */
		builder
			.addCase(getAllCourses.pending, (state) => {
				state.loaders.getAllCourses = true;
				state.errors.getAllCourses = '';
			})
			.addCase(getAllCourses.fulfilled, (state, { payload, meta }) => {
				state.loaders.getAllCourses = false;
				state.courses = payload.data;
				state.coursesFilters = payload.filters;
				state.lastPage = payload.meta.last_page;
				state.totalResults = payload.meta.total;
				state.perPage = payload.meta.per_page;
				state.page = payload.meta.current_page;
				state.currentSequenceCours = payload.meta.current_course;
				state.from = meta.arg.from;
				state.query = meta.arg.query;
				state.to = meta.arg.to;
				state.sortBy = meta.arg.sortBy;
				state.coursesSelectedFilters = meta.arg.filters
			})
			.addCase(getAllCourses.rejected, (state, { payload }) => {
				state.loaders.getAllCourses = undefined;

				state.errors.getAllCourses =
					payload || 'could not get course, please try again!';
			});

		/* get single course case */
		builder
			.addCase(getCourseById.pending, (state) => {
				state.loaders.getOneCourse = true;
				state.errors.getOneCourse = '';
			})
			.addCase(getCourseById.fulfilled, (state, { payload }) => {
				state.loaders.getOneCourse = false;

				state.currentCourse = payload.data;
			})
			.addCase(getCourseById.rejected, (state, { payload }) => {
				state.loaders.getAllCourses = undefined;

				state.errors.getOneCourse =
					payload || 'could not get course, please try again!';
			});

		/* get course users case */
		builder
			.addCase(getCourseUsers.pending, (state) => {
				state.loaders.getCourseUsers = true;
				state.errors.getCourseUsers = '';
			})
			.addCase(
				getCourseUsers.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					state.loaders.getCourseUsers = false;
					state.coursesFilters = filters;
					state.courseUsers = data;
					state.lastPage = meta.last_page;
					state.perPage = meta?.per_page || 0;
					state.page = meta?.current_page || 0;
					state.totalResults = meta.total;
				}
			)
			.addCase(getCourseUsers.rejected, (state, { payload }) => {
				state.loaders.getCourseUsers = undefined;

				state.errors.getCourseUsers =
					payload || 'could not get course users, please try again!';
			});

		/* get unenrolled users case */
		builder
			.addCase(getUnEnrolledUsers.pending, (state) => {
				state.loaders.getUnEnrolled = true;
				state.errors.getUnEnrolled = '';
			})
			.addCase(
				getUnEnrolledUsers.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					state.coursesFilters = filters;
					state.loaders.getUnEnrolled = false;
					state.unEnrolledUsers = data;
					state.totalResults = meta.total;
				}
			)
			.addCase(getUnEnrolledUsers.rejected, (state, { payload }) => {
				state.loaders.getAllCourses = undefined;

				state.errors.getUnEnrolled =
					payload || 'could not get unenrolled users, please try again!';
			});

		/* get enrolled users case */
		builder
			.addCase(getEnrolledUsers.pending, (state) => {
				state.loaders.getEnrolled = true;
				state.errors.getEnrolled = '';
			})
			.addCase(
				getEnrolledUsers.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					state.loaders.getEnrolled = false;
					state.EnrolledUsers = data;
					state.coursesFilters = filters;
					state.lastPage = meta.last_page;
					state.perPage = meta?.per_page || 0;
					state.page = meta?.current_page || 0;
					state.totalResults = meta.total;
				}
			)
			.addCase(getEnrolledUsers.rejected, (state, { payload }) => {
				state.loaders.getAllCourses = undefined;

				state.errors.getEnrolled =
					payload || 'could not get enrolled users, please try again!';
			});

		/* getting the count of each purchase methods  */
		builder
			.addCase(getEnrolledCount.pending, (state) => {
				state.loaders.getEnrolledCount = true;
				state.errors.getEnrolledCount = '';
			})

			.addCase(
				getEnrolledCount.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					state.loaders.getEnrolledCount = false;
					state.EnrolledPurchaseCount = data;
				}
			)
			.addCase(getEnrolledCount.rejected, (state, { payload }) => {
				state.loaders.getEnrolledCount = undefined;

				state.errors.getEnrolledCount =
					payload || 'could not get unenrolled users, please try again!';
			});

		builder
			.addCase(getAllClassroomEnrollements.pending, (state) => {
				state.loaders.getClassroomEnrollments = true;
				state.errors.getClassroomEnrollments = '';
			})

			.addCase(
				getAllClassroomEnrollements.fulfilled,
				(state, { payload: { data, filters, message, meta } }) => {
					state.loaders.getClassroomEnrollments = false;
					state.classroomEnrollments = data;
					state.classroomEnrollementsFilters = filters;
					state.lastPage = meta?.last_page || 0;
					state.totalResults = meta?.total || 0;
					state.perPage = meta?.per_page || 0;
					state.page = meta?.current_page || 0;
				}
			)
			.addCase(getAllClassroomEnrollements.rejected, (state, { payload }) => {
				state.loaders.getClassroomEnrollments = undefined;

				state.errors.getClassroomEnrollments =
					payload || 'could not get unenrolled users, please try again!';
			});
		builder
			.addCase(getClassroomUnitsAnalytics.pending, () => {})
			.addCase(getClassroomUnitsAnalytics.fulfilled, (state, { payload }) => {
				state.analytics.all.singleClassroomUnitsAnalytics = payload.data;
			});
		builder
			.addCase(getCourseUnitsAnalytics.pending, () => {})
			.addCase(getCourseUnitsAnalytics.fulfilled, (state, { payload }) => {
				state.analytics.all.singleCourseUnitsAnalytics = payload.data;
			});
		builder
			.addCase(banStudentFromClassroom.pending, (state) => {
				state.loaders.toggleBanLoader = true;
				state.errors.toggleBanError = '';
			})
			.addCase(banStudentFromClassroom.fulfilled, (state, { payload }) => {
				state.loaders.toggleBanLoader = false;
			})
			.addCase(banStudentFromClassroom.rejected, (state, { payload }) => {
				state.loaders.toggleBanLoader = undefined;
				state.errors.toggleBanError =
					payload || 'could not toggle ban student from classroom';
			});
		builder
			.addCase(getAllClassroomEnrollments.pending, () => {})
			.addCase(getAllClassroomEnrollments.fulfilled, (state, { payload }) => {
				state.analytics.all.enrollments = payload.data;
			});
		builder.addCase(getAllClassroomsGraph.fulfilled, (state, { payload }) => {
			state.analytics.all.graph = payload.data;
		});
		builder.addCase(getClassroomEnrollments.fulfilled, (state, { payload }) => {
			state.analytics.currentClassroom.enrollments = payload.data;
		});
		builder.addCase(getClassroomGraph.fulfilled, (state, { payload }) => {
			state.analytics.currentClassroom.graph = payload.data;
		});
		builder.addCase(getCourseEnrollments.fulfilled, (state, { payload }) => {
			state.analytics.currentCourse.enrollments = payload.data;
		});
		builder.addCase(getCourseGraph.fulfilled, (state, { payload }) => {
			state.analytics.currentCourse.graph = payload.data;
		});
		builder.addCase(
			getAllClassroomsAnalyticsDatatable.fulfilled,
			(state, { payload }) => {
				state.analytics.all.datatable.data = payload.data;
				state.analytics.all.datatable.filters = payload.filters;
				state.analytics.all.datatable.meta = payload.meta;
			}
		);
		builder.addCase(
			getClassroomAnalyticsDatatable.fulfilled,
			(state, { payload }) => {
				state.analytics.currentClassroom.datatable.data = payload.data;
				state.analytics.currentClassroom.datatable.filters = payload.filters;
				state.analytics.currentClassroom.datatable.meta = payload.meta;
			}
		);
	},
});

export const { resetCurrentCourse } = classRoomSlice.actions;
export default classRoomSlice.reducer;
