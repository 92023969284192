import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ClassroomAdmissionRequester } from '@Pages/ClassRooms/Services/ClassroomAdmission/RequestsHandler/ClassroomAdmission.req';
import { ClassroomAdmissionForm } from '@Pages/ClassRooms/Types/ClassroomAdmission.types';
import { AdmissionFormStateType } from './admissionFormSlice.types';

const initialState: AdmissionFormStateType = {
	admissionForm: {
		active: false,
		description: '',
		name: '',
	},
	questions: [],
	classroom_id: undefined,
	id: undefined,
	loaders: {
		getOne: null,
	},
};

export const getAdmissionForm = createAsyncThunk(
	'admission-form/fetch-all',
	async (admissionFormId: number | string, thunkAPI) => {
		const {
			data: { data },
		} = await ClassroomAdmissionRequester.getInstance().get(
			admissionFormId.toString()
		);
		return data;
	}
);

export const admissionFormSlice = createSlice({
	name: 'admission-form',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAdmissionForm.pending, (state) => {
				state.loaders.getOne = true;
			})
			.addCase(getAdmissionForm.fulfilled, (state, { payload }) => {
				state.id = payload.id;
				state.admissionForm = {
					active: payload.active,
					name: payload.name,
					description: payload.description,
				};
				state.classroom_id = payload.classroom_id;
				const mappedQuestions = payload.questions.map((question) => ({
					...question,
					options: question.options ? JSON.parse(question.options ?? '') : null,
				}));
				state.questions = mappedQuestions;
				state.loaders.getOne = false;
			})
			.addCase(getAdmissionForm.rejected, (state) => {
				state.loaders.getOne = undefined;
			});
	},
});

// export const {
//   updateQuestionType,
//   updateQuestionText,
//   updateQuestionOption,
//   appendOption,
// } = admissionFormSlice.actions;
export default admissionFormSlice.reducer;
