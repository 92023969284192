import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useTypedSelector } from '@Features/store';
import { Spacer } from '@Styled/utilities';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Admissions from '../Admissons/Admissions';
import AssignmentsTable from './Components/Assignments/AssignmentsTable';
import TestsTable from './Components/TestsTable';

const Submissions = () => {
	const { hash } = useLocation();
	const { url } = useRouteMatch();
	const history = useHistory();

	const auth = useTypedSelector((state) => state.auth);

	const tabIndex = useMemo<HashString>(() => {
		if (!hash) return 'test';
		return hash as HashString;
	}, [hash]);

	useEffect(() => {
		if (performance.navigation.type == 1) {
			history.push({
				pathname: `${url}/test/table`,
			});
		}
		return () => {
			performance.navigation.type == null;
		};
	}, [performance.navigation.type]);

	return (
		<Container>
			<RoutedTabs baseURL={url} withRouter>
				<RoutedTabs.Tabs
					$hashPrefix="#"
					hashes={['test', 'assignments', 'admissions']}
					onChange={() => {}}
					orientation="vertical"
					value={tabIndex}
				>
					{(!auth.user.parent_id || auth?.grants?.has('test_submission')) && (
						<RoutedTabs.Item
							value="test"
							label={<RoutedTabs.ItemTitle>TESTS</RoutedTabs.ItemTitle>}
							icon={
								<RoutedTabs.ItemIcon mIconType="Outlined">
									<EdIcon mIconType="Outlined">live_help</EdIcon>
								</RoutedTabs.ItemIcon>
							}
						/>
					)}
					{(!auth.user.parent_id ||
						auth?.grants?.has('assignment_submission')) && (
						<RoutedTabs.Item
							value="assignments"
							label={<RoutedTabs.ItemTitle>Assignments</RoutedTabs.ItemTitle>}
							icon={
								<RoutedTabs.ItemIcon mIconType="Outlined">
									ballot
								</RoutedTabs.ItemIcon>
							}
						/>
					)}
					{(!auth.user.parent_id ||
						auth?.grants?.has('admission_submission')) && (
						<RoutedTabs.Item
							value="admissions"
							label={<RoutedTabs.ItemTitle>Admission</RoutedTabs.ItemTitle>}
							icon={
								<RoutedTabs.ItemIcon mIconType="Outlined">
									app_registration
								</RoutedTabs.ItemIcon>
							}
						/>
					)}
				</RoutedTabs.Tabs>
				<Spacer mx={'0.625rem'} />
				<RoutedTabs.Content value={tabIndex}>
					<RoutedTabs.ContentItem hash="test">
						{(!auth.user.parent_id || auth?.grants?.has('test_submission')) && (
							<TestsTable />
						)}
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="assignments">
						{(!auth.user.parent_id ||
							auth?.grants?.has('assignment_submission')) && (
							<AssignmentsTable />
						)}
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="admissions">
						{(!auth.user.parent_id ||
							auth?.grants?.has('admission_submission')) && <Admissions />}
					</RoutedTabs.ContentItem>
				</RoutedTabs.Content>
			</RoutedTabs>
		</Container>
	);
};

const Container = styled.div`
	padding: 1.75rem 1.063rem;
	padding-inline-end: 4rem;
	font-weight: bold;
`;

export default Submissions;
