import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import ExpiredStatus from '@Components/UI/Utilities/ExpiredStatus/ExpiredStatus';
import { TableHeader } from '@Features/generics/generics.type';
import { useTypedSelector } from '@Features/store';
import { TableCell, Tooltip } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
	studentAttendancesExportData,
	studentAttendancesGetAll,
} from '../Slices/StudentTestsSlice';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
	id: number;
};

type Time = {
	[key: string]: number;
};

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'Section', fieldName: 'section_name' },
	{ displayName: 'Course Name', fieldName: 'course_name', width: '18%' },
	{ displayName: 'State', fieldName: 'state' },
	{ displayName: 'Date', fieldName: 'enrolled_at' },
	{ displayName: 'St.Status', fieldName: 'student_status' },
	{ displayName: 'progress', fieldName: 'progress_status' },
	{ displayName: 'Time', fieldName: 'time' },
];

const AttendanceTable: FC<Props> = ({ id }) => {
	const dispatch = useDispatch();
	const auth = useTypedSelector((state) => state.auth);
	const { fetchAll, meta, filters, selectedFilters, dateRange, query, sortBy } =
		useTypedSelector((state) => state.StudentAttendances);
	const { currentEntity: StudentEntity } = useTypedSelector(
		(state) => state.Students
	);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
			export: exportFlag,
		}: onPageChangeRequestMeta) => {
			if (StudentEntity.id)
				dispatch(
					studentAttendancesGetAll({
						page: activePage,
						perPage: perPage ?? 10,
						sortBy,
						filters,
						query,
						from: startDate,
						to: endDate,
						classroom_id: id,
						student_id: StudentEntity?.id,
						export: exportFlag ?? false,
					})
				);
		},
		[dispatch, StudentEntity?.id]
	);

	const ExportTableData = () => {
		if (studentAttendancesExportData)
			dispatch(
				studentAttendancesExportData({
					filters: selectedFilters,
					page: meta?.current_page ?? 1,
					perPage: meta?.per_page ?? 10,
					query: query ?? '',
					sortBy,
					from: dateRange.from,
					to: dateRange.to,
					classroom_id: id,
					student_id: StudentEntity?.id,
				})
			);
	};

	const renderStatus = (status: string) => {
		let color: string;
		switch (status) {
			case 'not started':
				return (color = '#ff3100');
			case 'completed':
				return (color = '#00d66b');
			case 'in progress':
				return (color = '#2196f3');
			default:
				return;
		}
	};

	const findTime = (num: number) => {
		if (num < 1) {
			return '0';
		}
		const qualifier = (num: number) => (num > 1 ? '' : '');
		const numToStr = (num: number, unit: string) =>
			num > 0 ? `${num}${unit} ${qualifier(num)}` : '';
		const oneMinute = 60;
		const oneHour = oneMinute * 60;
		const oneDay = oneHour * 24;
		const oneYear = oneDay * 365;
		const times: Time = {
			year: ~~(num / oneYear),
			day: ~~((num % oneYear) / oneDay),
			hour: ~~((num % oneDay) / oneHour),
			minute: ~~((num % oneHour) / oneMinute),
		};
		let str = '';
		for (let [key, value] of Object.entries(times)) {
			str += numToStr(times[key], key[0]);
		}
		const arr = str.trim().split(' ');
		const res: Array<any> = [];
		arr.forEach((x, i) => {
			res.push(x);
		});
		return res.slice(0, 3).join(' ');
	};

	return (
		<>
			{(auth.user.type === 'super' || auth.grants.has('exp-st')) && (
				<FlexLayout justifyContent="flex-end">
					<ExportButton
						onClick={() => ExportTableData()}
						startIcon={<EdIcon>south</EdIcon>}
						variant="contained"
					>
						Export
					</ExportButton>
				</FlexLayout>
			)}
			<MainTable
				total={meta?.total}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={Number(meta?.last_page)}
				RowsperPage={Number(meta?.per_page)}
				$hasActions={true}
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll?.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll?.map((att) => {
						return (
							<MainTableRow key={att.id} hover>
								<TableCell>
									{att?.section?.name ? att?.section?.name : '-'}
								</TableCell>
								<TableCell>{att?.name ? att?.name : '-'}</TableCell>
								<TableCell>
									{!att?.enrollment
										? '-'
										: att?.enrollment.expired
										? 'expired'
										: 'active'}
								</TableCell>
								{!att?.enrollment || !att?.enrollment?.expire_at ? (
									<TableCell>-</TableCell>
								) : (
									<Tooltip
										placement="top"
										title={
											<DateTimeFormatter date={att?.enrollment?.expire_at} />
										}
									>
										<TableCell>
											<DateTimeFormatter date={att?.enrollment?.expire_at} />
										</TableCell>
									</Tooltip>
								)}
								<TableCell>
									{!att?.enrollment ? (
										'-'
									) : (
										<StatueText
											color={renderStatus(att?.enrollment?.progress_status)}
										>
											{att?.enrollment?.progress_status}
										</StatueText>
									)}
								</TableCell>
								<TableCell>
									{!att?.enrollment ? '-' : `${att?.enrollment?.progress}%`}
								</TableCell>
								<Tooltip
									title={findTime(Number(att?.pivot?.time))}
									placement="top"
								>
									<TableCell>
										{!att?.pivot || !att?.pivot?.time
											? '-'
											: findTime(Number(att?.pivot?.time))}
									</TableCell>
								</Tooltip>
								<TableCell />
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

const ExportButton = styled(RoundButton)`
	margin-right: 1rem;
	box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.38);
`;
const StatueText = styled.div<{ color: string | undefined }>`
	color: ${({ color }) => color};
	font-weight: 500;
	text-transform: capitalize;
`;
export default AttendanceTable;
