import EdCheckbox from '@Components/UI/Inputs/EdCheckbox/EdCheckbox';
import { useTypedSelector } from '@Features/store';
import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TestPayload } from '../../../../../Types/Test/Test.types';
import { StyledInfoLabel } from '../TestOptions.styled';

const TestOptionsQuestionBehaviorSection = () => {
	const { control } = useFormContext<TestPayload>();
	const {
		currentEntity: { active },
	} = useTypedSelector((state) => state.Tests);
	return (
		<>
			<FlexLayout px={'1rem'}>
				<EdCheckbox
					disabled={active}
					control={control}
					name="test.allow_movement"
					label={'Allow answering questions in any order.'}
				/>
			</FlexLayout>
			<StyledInfoLabel m={0} mt="2rem" mb={'1.375rem'}>
				View Questions
			</StyledInfoLabel>
			<FlexLayout px={'1rem'} flexDirection="column">
				<Controller
					control={control}
					name="test.view_mode"
					render={({ field: { onChange, ...others } }) => {
						return (
							<FormControl>
								<RadioGroup
									onChange={(e, v) => onChange(v)}
									aria-labelledby="demo-radio-buttons-group-label"
									{...others}
								>
									<FormControlLabel
										disabled={active}
										value="single"
										control={<Radio />}
										label="Per question"
									/>
									<FormControlLabel
										disabled={active}
										value="multiple"
										control={<Radio />}
										label="Per Page"
									/>
								</RadioGroup>
							</FormControl>
						);
					}}
				/>
			</FlexLayout>
			<StyledInfoLabel m={0} mt={'2rem'} mb={'1.375rem'}>
				Randomization
			</StyledInfoLabel>
			<FlexLayout px={'1rem'} flexDirection="column">
				<EdCheckbox
					disabled={active}
					control={control}
					name="test.shuffle_questions"
					label={'Shuffle questions'}
				/>

				<EdCheckbox
					disabled
					control={control}
					name="test.shuffle_answers"
					label={'Shuffle possible answers'}
				/>
			</FlexLayout>
		</>
	);
};

export default TestOptionsQuestionBehaviorSection;
