import React from 'react';
import Question from './Question';
import { useQuestionsAndAnswersContext } from './QuestionsAndAnswers';
import { useGetDiscussionQuestionsListQuery } from '@Features/classrooms/instructorQuestionsAndAnswersApi';
import { useTypedSelector } from '@Features/store';
import { QuestionTypes } from './QuestionsAndAnswersConstants';
import PageLoader from '@Components/PageLoader';
import { Alert, AlertTitle } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';

type Props = {};

const Questions: React.FC<Props> = () => {
	const location = useLocation();
	const dateRangeUrlSearch = new URLSearchParams(location.search);
	const currentClassroom = useTypedSelector(
		(state) => state.classroom.currentClassroom
	);
	const { queryParams } = useQuestionsAndAnswersContext();
	const requestPayload: ListDiscussionQuestionRequestPayload = {
		classroom_id: currentClassroom?.id!,
		filters: [
			{ type: 'Q&A' },
			{ solved: queryParams?.type === QuestionTypes.answered },
		],
	};
	if (queryParams.query) requestPayload.query = queryParams.query;
	if (queryParams.unit_id)
		requestPayload.filters.push({ unit_id: queryParams.unit_id });
	if (queryParams['courses.code'])
		requestPayload.filters.push({
			'courses.code': queryParams['courses.code'],
		});
	if (dateRangeUrlSearch.get('from'))
		requestPayload.from = dateRangeUrlSearch.get('from') as string;
	if (dateRangeUrlSearch.get('to'))
		requestPayload.to = dateRangeUrlSearch.get('to') as string;

	const questionsListQuery = useGetDiscussionQuestionsListQuery(
		requestPayload,
		{
			skip: !requestPayload.classroom_id,
		}
	);

	if (questionsListQuery.isLoading) return <PageLoader />;

	if (
		(questionsListQuery.isError && questionsListQuery.error) ||
		!questionsListQuery.data
	)
		return (
			<Alert severity="error">
				<AlertTitle>Error</AlertTitle>
				{(questionsListQuery?.error as AxiosResponse<ApiResponse<undefined>>)
					?.data?.message ?? 'can not load admissions list for this classroom'}
			</Alert>
		);

	if (questionsListQuery.data.data.length === 0)
		return (
			<Alert severity="info">
				<AlertTitle>Info</AlertTitle>
				no questions found
			</Alert>
		);

	return (
		<>
			{questionsListQuery.data.data.map((question) => (
				<Question key={question.id} question={question} />
			))}
		</>
	);
};

export default Questions;
