import { useTypedSelector } from '@Features/store';
import { assistantsGetSingle } from '@Pages/Assistants/Slices/Assistants.Slices';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditAssistantInfo from './EditNoteInfo';
import { notesGetSingle } from '@Pages/Assistants copy/Slices/Notes.Slices';
import EditNoteInfo from './EditNoteInfo';

const EditNote = () => {
	const { id } = useParams<{ id: string }>();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Notes);

	const dispatch = useDispatch();
	useEffect(() => {
		if (id) {
			dispatch(notesGetSingle({ id }));
		}
	}, []);
	return (
		<>{currentEntity && getSingle === 'fulfilled' && <EditNoteInfo />}</>
	);
};

export default EditNote;
