import React from 'react';
import { DisclosureReturn } from '@Hooks/useDisclosure';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	Button,
} from '@mui/material';

type Props = {
	title?: string;
	body?: string;
	confirmButtonText?: string;
	cancelButtonText?: string;
	onConfirm?: () => void;
	onCancel?: () => void;
} & Pick<DisclosureReturn, 'isOpen' | 'onClose'>;

const ConfirmationPopup: React.FC<Props> = ({
	isOpen,
	onClose,
	title,
	body,
	confirmButtonText = 'Confirm',
	cancelButtonText = 'Cancel',
	onConfirm,
	onCancel,
}) => {
	const confirmationHandler = () => {
		if (onConfirm) onConfirm();
		onClose();
	};

	const cancellationHandler = () => {
		if (onCancel) onCancel();
		onClose();
	};

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{body}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={cancellationHandler} color="error" variant="text">
					{cancelButtonText}
				</Button>
				<Button onClick={confirmationHandler} variant="contained" autoFocus>
					{confirmButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationPopup;
