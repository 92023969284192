import React, { Suspense, useEffect } from 'react';
import styled from 'styled-components';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
	RouteProvider,
	useRouteProvider,
} from '@Providers/Routes/RoutesProvider';
import EditTestModel from './Pages/EditTestModel/EditTestModel';
import TestModelsTable from './TestModelsTable';
import AddTestModel from './Pages/AddTestModel/AddTestModel';

const TestModels = () => {
	const { path } = useRouteMatch();

	return (
		<PageContainer>
			<RouteProvider path={path}>
				<React.Suspense fallback={<div>loading...</div>}>
					<Switch>
						<Route path={path} exact>
							<Suspense fallback={<div>loading...</div>}>
								<TestModelsTable />
							</Suspense>
						</Route>
						<Route path={`${path}/add`}>
							<AddTestModel />
						</Route>
						<Route path={`${path}/:id`}>
							<EditTestModel />
						</Route>
					</Switch>
				</React.Suspense>
			</RouteProvider>
		</PageContainer>
	);
};

const PageContainer = styled.div`
	padding-left: 2.625rem;
`;

export default TestModels;
