import PrivateRoute from '@Components/ConditionalRoutes/PrivateRoute';
import { useTypedSelector } from '@Features/store';
import Login from '@Pages/Login';
import React from 'react';
import {
	BrowserRouter,
	Redirect,
	Route,
	Switch,
	useLocation,
} from 'react-router-dom';
import DashboardLayout from './DashboardLayout';

const DebugRouter = ({ children }: React.PropsWithChildren<any>) => {
	return children;
};
const RoutesLayout = () => {
	const { isAuth } = useTypedSelector((state) => state.auth);
	const { pathname } = useLocation();
	return (
		<BrowserRouter>
			<DebugRouter>
				<Switch>
					{/* <RouteProvider> */}
					{/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}
					<Route exact path={'/'}>
						{isAuth && <Redirect to={pathname} />}
						{!isAuth && <Redirect to="/login" />}
					</Route>
					<Route path={'/login'} render={() => <Login />} />
					<PrivateRoute path={'/dashboard'}>
						<DashboardLayout />
					</PrivateRoute>
					{/* </RouteProvider> */}
				</Switch>
			</DebugRouter>
		</BrowserRouter>
	);
};

export default RoutesLayout;
