import EdFab from '@Components/UI/Utilities/EdFab/EdFab';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import { Fab } from '@mui/material';
import {
	CURRICULUM_SCHEMA,
	CURRICULUM_FORM,
} from '@Pages/ClassRooms/Schema/ClassroomCurriculum.schema';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { useEffect, createContext } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useTypedSelector } from '@Features/store';
import { ClassroomTabsRequester } from '@Pages/ClassRooms/Services/ClassroomTabs/ClassroomTabs.req';
import TabAccordion from './Components/TabAccordion/TabAccordion';
import { Tab, TabPayload } from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import { useDispatch } from 'react-redux';
import { getAllClassroomTabs } from '@Pages/ClassRooms/Slices/ClassroomTabs/ClassroomTabs.slice';
import CurriculumForm from './Components/CurriculumForm/CurriculumForm';

const Curriculum = () => {
	const dispatch = useDispatch();
	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const {
		loaders: { fetchAll },
	} = useTypedSelector((state) => state.ClassroomTabs);
	useEffect(() => {
		if (currentClassroom) {
			dispatch(getAllClassroomTabs(currentClassroom.id));
		}
	}, [currentClassroom]);

	return (
		<>{currentClassroom && fetchAll === 'fulfilled' && <CurriculumForm />}</>
	);
};

export default Curriculum;
