import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import { CourseSingleTest } from '../Types/TestReports.types';

interface SingleTestReportCRUD
	extends CRUDBase<CourseSingleTest, CourseSingleTest> {
	notification: (payload: {
		course_id: number;
		attempt_id: number;
	}) => Promise<AxiosResponse<ApiResponse<{ message: string }>>>;
}
export class SingleTestReportRequester implements SingleTestReportCRUD {
	get(id: string): Promise<AxiosResponse<ApiResponse<CourseSingleTest>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<CourseSingleTest>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<CourseSingleTest>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<CourseSingleTest>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<CourseSingleTest, 'id'>
	): Promise<AxiosResponse<ApiResponse<CourseSingleTest>>> {
		throw new Error('Method not implemented.');
	}
	update(
		payload: CourseSingleTest
	): Promise<AxiosResponse<ApiResponse<CourseSingleTest>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: SingleTestReportRequester;
	static getInstance() {
		if (!SingleTestReportRequester.instance) {
			SingleTestReportRequester.instance = new SingleTestReportRequester();
		}
		return SingleTestReportRequester.instance;
	}
	baseURL = 'reports/course';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<CourseSingleTest[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<CourseSingleTest[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<CourseSingleTest[]>>> {
		return await api.post(`${this.baseURL}/tests/single`, payload);
	}

	async notification(payload: { course_id: number; attempt_id: number }) {
		return await api.post<ApiResponse<{ message: string }>>(
			`${this.baseURL}/tests/notification`,
			payload
		);
	}
}
