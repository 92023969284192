import {
	TabPayload,
	TabResponse,
} from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import { ClassroomTabsRequester } from './../../Services/ClassroomTabs/ClassroomTabs.req';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Tab } from '@Pages/ClassRooms/Types/ClassroomTabs.types';

const SLICE_NAME = 'CLASSROOM_TABS';

const initialState: BaseSliceState<Tab> = {
	fetchAll: [],
	filters: [],
	meta: null,
	loaders: {
		fetchAll: 'idle',
	},
	requestStatus: {},
	errors: {
		fetchAll: null,
	},
};

const ClassroomTabSliceActions = {
	getAllClassroomTabs: createAsyncThunk<ApiResponse<TabResponse[]>, number>(
		`${SLICE_NAME}/getAll`,
		async (classroom_id: number) => {
			const { data } = await ClassroomTabsRequester.getInstance().getAll(
				classroom_id
			);
			return data;
		}
	),
};
const ClassroomTabSlice = createSlice<BaseSliceState<Tab>, {}, string>({
	name: SLICE_NAME,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(
				ClassroomTabSliceActions.getAllClassroomTabs.pending,
				(state) => {
					state.loaders.fetchAll = 'pending';
				}
			)
			.addCase(
				ClassroomTabSliceActions.getAllClassroomTabs.fulfilled,
				(state, { payload }) => {
					state.fetchAll = payload.data as Tab[];
					state.loaders.fetchAll = 'fulfilled';
					state.errors.fetchAll = 'DONE';
				}
			)
			.addCase(
				ClassroomTabSliceActions.getAllClassroomTabs.rejected,
				(state) => {
					state.fetchAll = [];
					state.loaders.fetchAll = 'idle';
					state.errors.fetchAll = 'FAILED';
				}
			);
	},
});

export const { getAllClassroomTabs } = ClassroomTabSliceActions;
export default ClassroomTabSlice.reducer;
