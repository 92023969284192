import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { RouteSegment } from '@Constants/routes';
import { SideMenuContext, SideMenuProps } from '../Menu';
import RouteGroupItem from './RouteGroupItem';
import { useTypedSelector } from '@Features/store';

type SegmentProps = {
	item: RouteSegment;
};
const RouteSegmentList: React.FC<SegmentProps> = ({ item }) => {
	const { opened } = useContext(SideMenuContext);
	const [segmentWidth, setSegmentWidth] = useState(0);
	const auth = useTypedSelector((state) => state.auth);
	const SegmentRef = useCallback(
		(e: HTMLDivElement) => {
			if (e && opened) {
				setSegmentWidth(e.getClientRects()[0].width);
			}
		},
		[opened]
	);
	return (
		<MenuSegment ref={SegmentRef} width={segmentWidth} $sidemenuopened={opened}>
			{item.routeGroups.map((_, index) => {
				if (!auth.user.parent_id || auth.grants.has(_.code))
					return <RouteGroupItem key={`${index}-${_.route}-sl`} item={_} />;
			})}
		</MenuSegment>
	);
};
export default RouteSegmentList;

type MenuSegmentProps = {
	width: number;
} & SideMenuProps;
const SegmentTitle = styled.label<SideMenuProps>`
	padding: 0 2.625rem;
	font-size: 0.75rem;
	color: ${(props) => props.theme.palette.grey['600']};
	text-transform: uppercase !important;
	transition-delay: 0.5s;
	transition: all cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s;
	opacity: ${(props) => (props.$sidemenuopened ? '1' : '0')};
`;
const MenuSegment = styled.div<MenuSegmentProps>`
	//margin-top: 0.75rem;
	//margin-bottom: 3.125rem;
	transition: all cubic-bezier(0.68, -0.55, 0.27, 1.55) 1s;
	white-space: nowrap;
	/* width: ${(props) => (props.$sidemenuopened ? `` : '100%')}; */
	max-width: ${(props) => (props.$sidemenuopened ? '40vw' : '15vw')};
	${SegmentTitle} {
	}
`;
