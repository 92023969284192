import { useTypedSelector } from '@Features/store';
import { groupsGetSingle } from '@Pages/ClassRooms/Slices/Groups/Groups.slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EditGroup } from './EditGroup';

export const EditGroupFetch = () => {
	const { groupId } = useParams<{ groupId: string }>();

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(groupsGetSingle({ id: groupId }));
	}, []);

	const {
		loaders: { getSingle },
		currentEntity,
	} = useTypedSelector((state) => state.Groups);

	return <>{getSingle === 'fulfilled' && currentEntity && <EditGroup />}</>;
};
