import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	TestModelPayload,
	AddTestModelSchema,
} from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Schema/TestModel.schema';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { DevTool } from '@hookform/devtools';
import EdTransferList from '@Components/UI/Inputs/EdTransferList/EdTransferList';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { testQuestionsGetAll } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Slice/TestQuestions/TestQuestionsSlice';
import { TestQuestion } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Types/TestQuestion';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import styled from 'styled-components';
import { Button, Checkbox, IconButton, MenuItem } from '@mui/material';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { sum } from 'lodash';
import { rgba } from 'polished';
import {
	QuestionTypeLabel,
	QuestionContent,
	TransferListItemHeader,
	TransferListItem,
	OverallScore,
	QuestionsToTransfer,
	ChosenQuestions,
	TransferListHeader,
} from '../../Components/FormUtils/FormUtils.styled';
import EdCheckbox from '@Components/UI/Inputs/EdCheckbox/EdCheckbox';
import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import { TestModelRequester } from '../../../../Services/TestModel/TestModelRequester';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';
import { useSnackbar } from '@Providers/useSnackbar';
const AddTestModel = () => {
	const dispatch = useAppThunkDispatch();
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const { fetchAll } = useTypedSelector((state) => state.TestQuestions);
	const {
		loaders: { create },
	} = useTypedSelector((state) => state.TestModels);
	const methods = useRouteProvider();
	const { displaySnackbar } = useSnackbar();
	useEffect(() => {
		if (currentEntity && currentEntity.id)
			dispatch(
				testQuestionsGetAll({
					page: 1,
					perPage: 1000,
					filters: [{ test_id: currentEntity.id }],
					export: false,
					query: '',
					sortBy: null,
				})
			);
	}, [currentEntity, dispatch]);

	const {
		control,
		formState: { isValid, isDirty, isSubmitting },
		handleSubmit,
	} = useForm<TestModelPayload>({
		resolver: yupResolver(AddTestModelSchema),
		mode: 'all',
		defaultValues: { test_id: currentEntity?.id },
	});

	const onSubmit = async (form: TestModelPayload) => {
		try {
			await TestModelRequester.instance.create(form);
			displaySnackbar('success', 'Created Test model successfully');
			methods?.goToParent();
		} catch (error) {
			displaySnackbar('error', "Couldn't create test model");
		}
	};
	return (
		<div>
			<DevTool control={control} />
			<EdFormControl
				required
				label={'Test Model Name'}
				control={control}
				name={'name'}
			/>
			<Spacer mb={'1.625rem'} />
			<EdTransferList
				control={control}
				name={'questions'}
				list={fetchAll}
				listKey={'id'}
				listsNames={['Available test questions', 'Model questions']}
			>
				{{
					listHeader: TransferListHeader,
					chosenHeader: TransferListHeader,
					chosenHeaderExtra: (list) => {
						const totalScore = sum(list.map((_) => _.weight));
						return <OverallScore value={totalScore} />;
					},
					list: (item: TestQuestion, index: number) => {
						return <QuestionsToTransfer item={item} index={index} />;
					},
					chosen: (item: TestQuestion, index: number) => {
						return <ChosenQuestions item={item} index={index} />;
					},
				}}
			</EdTransferList>
			<GridLayout gridGap={'4rem'}>
				<div></div>
				<div>
					<FlexLayout flexDirection={'column'}>
						<EdCheckbox
							control={control}
							name="shuffle_questions"
							label="Shuffle questions"
						/>
						<EdCheckbox
							disabled
							control={control}
							name="shuffle_answers"
							label="Shuffle answers"
						/>
					</FlexLayout>
				</div>
			</GridLayout>
			{!currentEntity.active && (
				<FlexLayout gridGap={'1rem'} justifyContent="flex-end">
					<Button disabled={!isDirty} variant={'contained'} color={'warning'}>
						Cancel
					</Button>
					<EdButton
						onClick={handleSubmit(onSubmit, (e) => console.log(e))}
						type="submit"
						disabled={!isValid || isSubmitting}
						variant={'contained'}
						edcolor="dodgerBlue"
					>
						Save
					</EdButton>
				</FlexLayout>
			)}
		</div>
	);
};

export default AddTestModel;
