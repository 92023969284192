import React from 'react';
import QuestionAnswerText from './QuestionAnswerText';
import {
	AdmissionQuestionContainer,
	AdmissionQuestion,
	AdmissionQuestionType,
	AdmissionQuestionAnswer,
} from './AdmissionQuestions.styled';

type Props = {
	question: string;
	answer: string;
	type: string;
};

export const AdmissionTextOrMcqView: React.FC<Props> = ({
	question,
	answer,
	type,
}) => {
	return (
		<AdmissionQuestionContainer>
			<AdmissionQuestion>Q1. {question}</AdmissionQuestion>
			<AdmissionQuestionType>{type}</AdmissionQuestionType>
			{QuestionAnswerText}
			<AdmissionQuestionAnswer>{answer}</AdmissionQuestionAnswer>
		</AdmissionQuestionContainer>
	);
};

export default AdmissionTextOrMcqView;
