import { yupResolver } from '@hookform/resolvers/yup';
import {
	Accordion,
	TextField,
	Icon,
	AccordionSummary,
	AccordionDetails,
	RadioGroup,
	Radio,
	FormControlLabel,
	AccordionProps,
	IconButton,
} from '@mui/material';

import { FlexLayout, Spacer } from '@Styled/utilities';
import React, {
	Profiler,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Control,
	Controller,
	ControllerFieldState,
	useController,
	useForm,
	useWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import { AdmissionQuestionContext } from '../ClassroomAdmission/ClassroomAdmission';
import {
	AdmissionQuestionBase,
	ClassroomAdmissionPayload,
	ClassroomAdmissionForm,
} from '@Pages/ClassRooms/Types/ClassroomAdmission.types';
import { QuestionTypesMETA } from '@Pages/ClassRooms/Constants/ClassroomAdmission.constants';
import { QUESTION_SCHEMA } from '@Pages/ClassRooms/Schema/ClassroomAdmission.schema';
import { DevTool } from '@hookform/devtools';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import DragButton from '@Components/UI/Buttons/EdDragButton/EdDragButton';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { memoize, values } from 'lodash';
type QuestionProps = {
	index: number;
	question: AdmissionQuestionBase;
	onSwitchDelete?: boolean;
	control: Control<ClassroomAdmissionForm>;
	dragHandlers?: DraggableProvidedDragHandleProps | null;
	onDeleteQuestion: (index: number) => void;
};

const AdmissionQuestion: React.FC<QuestionProps> = ({
	question,
	index,
	onSwitchDelete,
	control,
	dragHandlers,
	onDeleteQuestion,
}) => {
	const { update } = useContext(AdmissionQuestionContext);

	const [optionValue, setOptionValue] = useState('');

	const [expanded, setExpanded] = useState(false);

	const {
		fieldState,
		field: { onChange, ...optionsProps },
	} = useController({
		control,
		name: `questions.${index}.options`,
	});
	const updateQuestion = (question: AdmissionQuestionBase) => {
		update(index, question);
	};

	const onAddOption = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			const options = Array.from(question.options ?? []);
			options.push(optionValue);
			setOptionValue('');
			onChange(options);
			updateQuestion({ ...question, options });
		}
	};

	const onDeleteOption = (index: number) => {
		const options = Array.from(question.options ?? []);
		options.splice(index, 1);
		onChange(options);
		updateQuestion({ ...question, options });
	};

	const hasOption = useMemo(() => {
		return QuestionTypesMETA[question.type]?.hasOptions;
	}, [question.type]);

	const isExpandable = useMemo(() => {
		return QuestionTypesMETA[question.type]?.expandable;
	}, [question.type]);

	const onExpand = () => {
		if (QuestionTypesMETA[question.type]?.expandable) {
			setExpanded(!expanded);
		}
	};

	return (
		<QuestionWrapper>
			<QuestionNumber>Q {index + 1}.</QuestionNumber>
			<QuestionAccordion expanded={expanded}>
				<QuestionSummary
					expandIcon={
						<Icon
							onClick={onExpand}
							style={{
								opacity: isExpandable ? '1' : '0',
							}}
						>
							expand_more
						</Icon>
					}
				>
					<QuestionContainer>
						{dragHandlers && <DragButton dragHandlers={dragHandlers} />}
						<Controller
							control={control}
							name={`questions.${index}.question`}
							render={({ field: { onChange, ...fieldProps }, fieldState }) => {
								return (
									<QuestionTextField
										onChange={(e) => {
											onChange(e);
											// updateQuestion({ ...question, question: e.target.value });
										}}
										{...fieldProps}
										error={fieldState.invalid}
										size="small"
									/>
								);
							}}
						/>
						<Controller
							control={control}
							name={`questions.${index}.type`}
							render={({ field: { onChange, ...fieldProps } }) => {
								return (
									<RadioGroup
										{...fieldProps}
										onChange={(e, v) => {
											onChange(v);
											const type = v as QuestionType;
											updateQuestion({
												...question,
												type: v as QuestionType,
												options: type === 'mcq' ? [] : null,
											});
										}}
										row
									>
										<FormControlLabel
											value={'mcq'}
											label="MCQ"
											control={<Radio />}
										/>
										<FormControlLabel
											value={'essay'}
											label="Text"
											control={<Radio />}
										/>
										<FormControlLabel
											value={'upload'}
											label="Upload"
											control={<Radio />}
										/>
									</RadioGroup>
								);
							}}
						/>
					</QuestionContainer>
				</QuestionSummary>
				{hasOption && (
					<OptionsContainer {...optionsProps}>
						<TextField
							value={optionValue}
							onChange={(e) => setOptionValue(e.target.value)}
							placeholder={`Option`}
							onKeyDown={onAddOption}
							variant="standard"
							error={fieldState.invalid}
							helperText={
								fieldState.invalid ? 'Options must have at least 1 item' : ''
							}
						/>
						{question.options &&
							question.options.map((_: any, oIndex: number) => {
								return (
									<Controller
										key={`${oIndex}-${_}`}
										control={control}
										name={`questions.${index}.options.${oIndex}`}
										render={({ field }) => {
											return (
												<OptionInput>
													<TextField
														{...field}
														placeholder={`Option ${oIndex + 1}`}
														variant="standard"
													/>
													<Spacer mx={'.75rem'} />
													<Icon onClick={() => onDeleteOption(oIndex)}>
														cancel
													</Icon>
												</OptionInput>
											);
										}}
									/>
								);
							})}
					</OptionsContainer>
				)}
			</QuestionAccordion>
			<IconButton
				onClick={() => {
					onDeleteQuestion(index);
				}}
			>
				<EdIcon>delete</EdIcon>
			</IconButton>
		</QuestionWrapper>
	);
};

export default AdmissionQuestion;

const QuestionWrapper = styled.div`
	margin-bottom: 1.875rem;
	display: flex;
	align-items: center;
	.Mui-focused,
	.Mui-focusVisible {
		background-color: transparent;
	}
`;
const QuestionSummary = styled(AccordionSummary)``;
const QuestionAccordion = styled(Accordion)`
	flex: 1;
	margin: 0 1.5rem;
	&.Mui-expanded {
		margin: 0 1.5rem;
	}
`;
const QuestionContainer = styled(FlexLayout)`
	width: 100%;
	align-items: center;
	.MuiRadio-root.Mui-checked {
		color: #6c63ff;
	}
`;

const QuestionTextField = styled(TextField)`
	flex: 1;
	margin: 0 1rem;
`;

const OptionsContainer = styled(AccordionDetails)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 3.313rem;
`;
const OptionInput = styled.div`
	display: flex;
	align-items: center;
	.MuiIcon-root {
		font-size: 14px;
		cursor: pointer;
	}
`;

const QuestionNumber = styled.span`
	font-size: 1rem;
	font-weight: bold;
`;
