import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSettingsRequest } from '@Services/settings/settings.req';
import createThunkBody from '@Utils/createThunkBody';
import { SettingsState } from './settingsSlice.types';

/* side effects */
export const fetchSettings = createAsyncThunk<
	SettingsState,
	undefined,
	{ rejectValue: string }
>(
	'settings/fetch',
	async (_, thunkApi) =>
		await createThunkBody<SettingsState>(
			fetchSettingsRequest(),
			thunkApi,
			'Error fetching settings'
		)
);
const initialState: SettingsState = {
	fawry: true,
	aman: true,
	masary: true,
	credit_card: true,
	vf: true,
	online_cl: true,
	parent_portal: true,
	revision_section: true,
	exams_section: true,
	progress: true,
	drawerOpened: false,
};

export const toggleAppDrawer = createAction<boolean, 'toggleDrawer'>(
	'toggleDrawer'
);

const settingsSlice = createSlice({
	name: 'settings',
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchSettings.fulfilled, (state, { payload }) => {
			state = { ...state, ...payload };
		});
		builder.addCase(toggleAppDrawer, (state, payload) => {
			state.drawerOpened = payload.payload;
		});
	},
});

export default settingsSlice.reducer;
