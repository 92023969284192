import React from 'react';
import styled from 'styled-components';

type Props = {
	label: string;
	value: string;
};

const AdmissionFormHeaderRecord: React.FC<Props> = ({ label, value }) => {
	return (
		<StyledRecord>
			<StyledRecordLabel>{label}</StyledRecordLabel>
			<StyledRecordValue>{value}</StyledRecordValue>
		</StyledRecord>
	);
};

const StyledRecord = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: 1fr;
	gap: 32px;
	align-items: center;
	line-height: 22px;
`;

const StyledRecordLabel = styled.p``;

const StyledRecordValue = styled.p`
	font-weight: 600;
	font-style: italic;
`;

export default AdmissionFormHeaderRecord;
