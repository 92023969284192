import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import EdBox from '@Components/UI/Utilities/EdBox/EdBox';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton, InputAdornment } from '@mui/material';
import { Divider, FlexLayout, Spacer } from '@Styled/utilities';
import React, { useEffect, useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { object, string } from 'yup';
import AddQuestionList from '../Components/AddQuestionList/AddQuestionList';
import TestQuestions from './TestQuestions';
import { cloneDeep } from 'lodash';
import { TestPayload } from '../../../Types/Test/Test.types';
import TestModels from './TestModels/TestModels';
import { testsUpdate } from '../Slice/TestSlice';
import { useSnackbar } from '@Providers/useSnackbar';
import TestOptions from './TestOptions/TestOptions';
import TestOverviewHeader from '../Components/TestOverviewHeader/TestOverviewHeader';
import ImportsTab from './TestImports/ImportsTab';
const SCHEMA = object({
	name: string(),
});
export const TestOverview: React.FC = () => {
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const { url } = useRouteMatch();

	const defaultValues = useMemo(() => {
		return {
			id: currentEntity?.id,
			test: currentEntity,
			unit: currentEntity?.unit,
		} as TestPayload;
	}, []);
	const { control, handleSubmit, resetField } = useForm<TestPayload>({
		resolver: yupResolver(SCHEMA),
		defaultValues: cloneDeep(defaultValues),
	});

	const { fieldState } = useController({
		control,
		name: 'unit.name',
	});

	const { displaySnackbar } = useSnackbar();

	const dispatch = useAppThunkDispatch();
	const onSave = async (form: TestPayload) => {
		try {
			await dispatch(testsUpdate(form)).unwrap();
			resetField('unit.name', { defaultValue: form.unit.name });
			displaySnackbar('success', 'Updated Unit name successfully');
		} catch (error) {
			displaySnackbar('error', "Couldn't update unit name");
		}
	};

	const handleReset = () => {
		resetField('unit.name', { defaultValue: defaultValues.unit.name });
	};
	return (
		<FlexLayout px="4rem">
			<EdBox paddingLeft="0" display="flex" flexDirection="column">
				<RoutedTabs baseURL={url} width="100%" withRouter>
					<RoutedTabs.Tabs
						value={'questions'}
						hashes={['questions', 'options']}
						$hashPrefix="#"
						orientation="vertical"
					>
						<RoutedTabs.Item
							value="questions"
							icon={
								<RoutedTabs.ItemIcon className="material-icons-outlined">
									quiz
								</RoutedTabs.ItemIcon>
							}
							label={
								<RoutedTabs.ItemTitle>Test Questions</RoutedTabs.ItemTitle>
							}
						/>
						<RoutedTabs.Item
							value="options"
							icon={
								<RoutedTabs.ItemIcon className="material-icons-outlined">
									settings_suggest
								</RoutedTabs.ItemIcon>
							}
							label={<RoutedTabs.ItemTitle>Test Options</RoutedTabs.ItemTitle>}
						/>
						<RoutedTabs.Item
							value="models"
							icon={
								<RoutedTabs.ItemIcon className="material-icons-outlined">
									style
								</RoutedTabs.ItemIcon>
							}
							label={<RoutedTabs.ItemTitle>Test Models</RoutedTabs.ItemTitle>}
						/>

						<RoutedTabs.Item
							value="imports"
							icon={
								<RoutedTabs.ItemIcon className="material-icons-outlined">
									style
								</RoutedTabs.ItemIcon>
							}
							label={<RoutedTabs.ItemTitle>Test Imports</RoutedTabs.ItemTitle>}
						/>

						{!currentEntity.active && (
							<>
								<Spacer my="0.625rem" />
								<Divider />
								<AddQuestionList />
							</>
						)}
					</RoutedTabs.Tabs>
					<Spacer mx="1rem" />
					<FlexLayout flexDirection="column" flex="1">
						<RoutedTabs.Content value="questions">
							<RoutedTabs.ContentItem hash="" alwaysVisible>
								<TestOverviewHeader />
							</RoutedTabs.ContentItem>

							<RoutedTabs.ContentItem hash="questions">
								<div>{currentEntity && <TestQuestions />}</div>
							</RoutedTabs.ContentItem>
							<RoutedTabs.ContentItem hash="models">
								<div>{currentEntity && <TestModels />}</div>
							</RoutedTabs.ContentItem>
							<RoutedTabs.ContentItem hash="options">
								<div>{currentEntity && <TestOptions />}</div>
							</RoutedTabs.ContentItem>
							<RoutedTabs.ContentItem hash="imports">
								<div>{currentEntity && <ImportsTab />}</div>
							</RoutedTabs.ContentItem>
						</RoutedTabs.Content>
					</FlexLayout>
				</RoutedTabs>
			</EdBox>
		</FlexLayout>
	);
};

export default TestOverview;
