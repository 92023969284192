import MainTable from '@Components/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import { banStudentFromClassroom } from '@Features/classrooms/classroomSlice';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { studentsGetSingle } from '@Pages/Students/Slice/Students.Slice';
import { ClassRoom } from '@Services/students/students.res.types';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { classRoomTabGetAll } from '../Slices/ClassRoomsTab.Slices';
import BanModel from './BanModel';
import { useSnackbar } from '@Providers/useSnackbar';
import styled from 'styled-components';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { Button, TableCell, Tooltip } from '@mui/material';
import NoResults from '@Components/NoResults/NoResults';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import axios from 'axios';

const TABLE_HEADS = [
	{ displayName: 'title', fieldName: 'title' },
	{ displayName: 'label', fieldName: 'label' },
	{ displayName: 'instructor', fieldName: 'instructor' },
	{ displayName: 'type', fieldName: 'type' },
	{ displayName: 'status', fieldName: 'status' },
	{
		displayName: 'enrolled status',
		fieldName: 'enrolled_status',
		width: '15%',
	},
	{ displayName: 'enrolled at', fieldName: 'enrolled_at', width: '15%' },
	{ displayName: 'banned on', fieldName: 'banned_on', width: '10%' },
	{ displayName: '', fieldName: '' },
];

const ClassroomTable = () => {
	const dispatch = useAppThunkDispatch();
	const { fetchAll, meta, dateRange, query, filters, sortBy } =
		useTypedSelector((state) => state.ClassRoomTab);
	const { currentEntity } = useTypedSelector((state) => state.Students);

	const history = useHistory();
	const [openModel, setOpenModel] = useState(false);
	const [active, setActive] = useState<boolean>(false);
	const [classroom_id, setClassId] = useState<number>(0);
	const [user_id, setUserId] = useState<number>(0);
	const { displaySnackbar } = useSnackbar();

	const auth = useTypedSelector((state) => state.auth);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				classRoomTabGetAll({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
					id: currentEntity?.id as any,
				})
			);
		},
		[dispatch, currentEntity?.id]
	);
	const renderStatus = (classroom: ClassRoom) => {
		switch (classroom.pivot.pivot_active) {
			case true:
				return <StatueText color="#5AC0FC">Active</StatueText>;
			default:
				return <StatueText color="#ff3100">Banned</StatueText>;
		}
	};

	const handleModelOpen = (
		active: boolean,
		classId: number,
		userId: number
	) => {
		setOpenModel(true);
		setActive(active);
		setClassId(classId);
		setUserId(userId);
	};
	const handleModelClose = () => {
		setClassId(0);
		setUserId(0);
		setOpenModel(false);
	};

	const onBanStudent = async (message?: string) => {
		const student = {
			classroom_id,
			user_id,
			active: !active,
			note: message,
		};

		try {
			await dispatch(banStudentFromClassroom(student)).unwrap();
			if (active) {
				displaySnackbar('success', 'Banned Student successfully');
				dispatch(studentsGetSingle({ id: currentEntity.uuid }));
				dispatch(
					classRoomTabGetAll({
						page: 1,
						perPage: 10,
						sortBy: '',
						filters,
						query: query as string,
						from: dateRange.from,
						to: dateRange.to,
						id: currentEntity?.id as any,
					})
				);
			} else {
				displaySnackbar('success', 'Activated Student successfully');
				dispatch(studentsGetSingle({ id: currentEntity.uuid }));
				dispatch(
					classRoomTabGetAll({
						page: 1,
						perPage: 10,
						sortBy,
						filters,
						query: query as string,
						from: dateRange.from,
						to: dateRange.to,
						id: currentEntity?.id as any,
					})
				);
			}
			setOpenModel(false);
		} catch (error) {
			displaySnackbar('error', error);
		}
	};

	return (
		<>
			<BanModel
				openModel={openModel}
				handleModelClose={handleModelClose}
				ban={active}
				onConfirmation={onBanStudent}
			/>
			<MainTable
				total={meta?.total ?? 0}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				RowsperPage={Number(meta?.per_page)}
				$hasActions
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll?.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll?.map((classroom) => {
						return (
							<MainTableRow
								key={classroom.id}
								hover
								onClick={() => {
									history.push({
										pathname: `classroom/${classroom.id}/attendance`,
									});
								}}
							>
								<Tooltip title={classroom.title} placement="top">
									<TableCell>{classroom.title}</TableCell>
								</Tooltip>
								<TableCell>{classroom.label}</TableCell>
								<TableCell>{classroom.instructor?.user?.first_name}</TableCell>
								<TableCell>{classroom.type}</TableCell>
								<TableCell>{classroom.status}</TableCell>
								<TableCell>{renderStatus(classroom)}</TableCell>
								<Tooltip
									title={
										<DateTimeFormatter
											date={classroom.pivot.pivot_created_at}
										/>
									}
									placement="top"
								>
									<TableCell>
										<DateTimeFormatter
											date={classroom.pivot.pivot_created_at}
										/>
									</TableCell>
								</Tooltip>
								<Tooltip
									title={
										classroom.pivot.pivot_active === false && (
											<DateTimeFormatter
												date={classroom.pivot.pivot_updated_at}
											/>
										)
									}
									placement="top"
								>
									<TableCell>
										{classroom.pivot.pivot_active === false ? (
											<DateTimeFormatter
												date={classroom.pivot.pivot_updated_at}
											/>
										) : (
											'-'
										)}
									</TableCell>
								</Tooltip>
								<TableCell>
									<Tooltip
										placement="left"
										title={classroom.active ? 'Click to ban' : 'Click to Unban'}
									>
										<Button
											id="banUnBanUSer"
											onClick={(e) => {
												e.stopPropagation();
												handleModelOpen(
													classroom?.pivot.pivot_active,
													classroom?.pivot.pivot_classroom_id,
													classroom?.pivot.pivot_user_id
												);
											}}
										>
											{!classroom.pivot.pivot_active ? (
												<RedAccountBoxIcon />
											) : (
												<BlueAccountBoxIcon />
											)}
										</Button>
									</Tooltip>
								</TableCell>
								<TableCell />
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

const StatueText = styled.div<{ color: string }>`
	color: ${({ color }) => color};
	font-weight: 500;
	text-transform: capitalize;
`;

const RedAccountBoxIcon = styled(AccountBoxIcon)`
	color: #f74343;
	height: 26px;
	width: 26px;
`;
const BlueAccountBoxIcon = styled(AccountBoxIcon)`
	color: #5ac0fc;
	height: 26px;
	width: 26px;
`;

export default ClassroomTable;
