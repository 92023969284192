import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import { Box, FlexLayout } from '@eduact/ed-system';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, TextField } from '@mui/material';
import { Typography } from '@mui/material';
import { Paper } from '@mui/material';
import { TinyMCEStyles } from '@Styled/tinymce';
import { Spacer } from '@Styled/utilities';
import React from 'react';
import {
	Controller,
	UseFieldArrayUpdate,
	useForm,
	useFormContext,
} from 'react-hook-form';
import styled from 'styled-components';
import { boolean, number, object, ref } from 'yup';
import { SubmissionsRequester } from '../Services/Submissions.requester';
import {
	Answers,
	SubmitAttemptQuestionPayload,
} from '../Types/Submissions.types';
import AnswerAttachments from './AnswerAttachments';

type Props = {
	answer: Answers;
	index: number;
	update: UseFieldArrayUpdate<
		{
			answers: Array<SubmitAttemptQuestionPayload>;
		},
		'answers'
	>;
};

export const AnswerSchema = object({
	question_id: number().required(),
	attempt_id: number().required(),
	score: number()
		.required()
		.max(ref('weight')),
		// .transform((v, o) => {
		// 	return v === 0 ? null : v;
		// }),
	correct: boolean(),
	weight: number().required(),
	submitted: boolean().nullable(true).required(),
});
const AttemptQuestion: React.VoidFunctionComponent<Props> = ({
	answer,
	index,
	update,
}) => {
	const {
		control,
		setValue,
		formState: { isValid, isDirty },
		handleSubmit,
		getValues,
		reset,
	} = useForm<SubmitAttemptQuestionPayload>({
		resolver: yupResolver(AnswerSchema),
		defaultValues: {
			attempt_id: answer.test_attempt_id,
			question_id: answer.question.id,
			score: answer.score,
			weight: answer.question.weight,
			submitted: !!answer.score,
		},
	});

	const handleSubmitQuestion = async (data: SubmitAttemptQuestionPayload) => {
		try {
			const {} = await SubmissionsRequester.getInstance().submitQuestion(data);
			update(index, { ...data, submitted: true });
			reset({ ...data, submitted: true });
		} catch (error) {}
	};
	return (
		<AnswerPaper>
			<FlexLayout justifyContent={'space-between'}>
				<Typography fontWeight={'bold'}>Q{answer.question.order}.</Typography>
				<FlexLayout alignItems={'flex-end'}>
					<Typography fontWeight={'500'} color={'GrayText'}>
						Points
					</Typography>
					<Spacer mx={'0.5rem'} />
					<Controller
						control={control}
						name={`score`}
						render={({
							field: { onChange, ...other },
							fieldState: { error },
						}) => {
							return (
								<ResultInput
									onChange={(e) => {
										onChange(e);
										update(index, {
											score: Number(e.target.value),
											attempt_id: answer.test_attempt_id,
											question_id: answer.question.id,
											weight: answer.question.weight,
											submitted: null,
										});
									}}
									helperText={error?.message}
									error={!!error}
									{...other}
								/>
							);
						}}
					/>

					<Typography fontWeight={700}>/{answer.question.weight}</Typography>
				</FlexLayout>
			</FlexLayout>
			<QuestionContent>
				<Divider />
				<FlexLayout flexDirection={'column'}>
					<span>
						<TinyMCEStyles />
						<QuestionHTML
							dangerouslySetInnerHTML={{ __html: answer.question.content }}
						></QuestionHTML>
					</span>
					<Divider textAlign="left">
						<AnswerLabel>Answer</AnswerLabel>
					</Divider>
					{answer.content.options.type === 'text' && (
						<TextAnswer>{answer.content.options.answer}</TextAnswer>
					)}
					{answer.content.options.type === 'attachment' && (
						<FlexLayout>
							<AnswerAttachments answer={answer.content.options.answer} />
						</FlexLayout>
					)}
				</FlexLayout>
				<FlexLayout justifyContent={'flex-end'}>
					<Button
						onClick={handleSubmit(handleSubmitQuestion)}
						disabled={!isValid || !isDirty}
						variant="outlined"
					>
						SUBMIT
					</Button>
				</FlexLayout>
			</QuestionContent>
		</AnswerPaper>
	);
};

export default AttemptQuestion;

const AnswerPaper = styled(Paper)`
	padding: 2rem;
`;

const ResultInput = styled(EdNumericInput)`
	.MuiOutlinedInput-input {
		width: 27px;
		height: 27px;
		padding: 0 0.5rem;
		/* box-sizing: border-box; */
	}
`;

const QuestionContent = styled.div`
	padding: 0 3.125rem;
	margin-top: 1.25rem;
`;

const QuestionHTML = styled.div`
	margin: 2rem 0;
`;

const AnswerLabel = styled(Typography)`
	font-size: 0.875rem;
	color: #707070;
	/* margin: 0 1.25rem; */
`;

const TextAnswer = styled.div`
	font-size: 1.125rem;
`;
