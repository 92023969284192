import React from 'react';
import AdmissionFormHeader from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/AdmissionFormHeader';
import AdmissionsFooter from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/AdmissonsFooter';
import {
	AdmissionTextOrMcqView,
	AdmissionUploadView,
} from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/AdmissionQuestions';
import styled from 'styled-components';
import { useAdmissionsContext } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/Admissions';
import { useSingleClassroomResponseQuery } from '@Features/classrooms/InstructorAdmissionsApi';
import { Alert, AlertTitle } from '@mui/material';
import PageLoader from '@Components/PageLoader';
import { AxiosResponse } from 'axios';

const AdmissionForm: React.FC = () => {
	const { selectedAdmissionIdQueryParam: admissionId } = useAdmissionsContext();

	const admissionQuery = useSingleClassroomResponseQuery(
		{ id: admissionId! },
		{ skip: !admissionId }
	);

	const renderAnswer = (answer: AdmissionAnswer) => {
		if (answer.question.type === 'upload')
			return (
				<AdmissionUploadView
					question={answer.question.question}
					answer={answer.answer}
					key={answer.id}
				/>
			);
		else
			return (
				<AdmissionTextOrMcqView
					key={answer.id}
					question={answer.question.question}
					answer={answer.answer}
					type={answer.question.type}
				/>
			);
	};

	if (admissionQuery.isUninitialized)
		return (
			<StyledAdmissionForm>
				<StyledFormContent>
					<Alert severity="info">
						<AlertTitle>Info</AlertTitle>
						Select an admission request
					</Alert>
				</StyledFormContent>
			</StyledAdmissionForm>
		);

	if (admissionQuery.isLoading) return <PageLoader />;

	if ((admissionQuery.isError && admissionQuery.error) || !admissionQuery.data)
		return (
			<StyledAdmissionForm>
				<StyledFormContent>
					<Alert severity="error">
						<AlertTitle>Error</AlertTitle>
						{(admissionQuery?.error as AxiosResponse<ApiResponse<undefined>>)
							?.data?.message ?? 'could not loan admission request'}
					</Alert>
				</StyledFormContent>
			</StyledAdmissionForm>
		);

	const answers = admissionQuery.data.data.responses;

	return (
		<StyledAdmissionForm>
			<StyledFormContent>
				<AdmissionFormHeader />
				{answers.map((answer) => renderAnswer(answer))}
			</StyledFormContent>
			{admissionQuery.data.data.status === 'pending' && (
				<AdmissionsFooter admissionRequestId={admissionId!} />
			)}
		</StyledAdmissionForm>
	);
};

const StyledAdmissionForm = styled.div`
	position: relative;
	height: 735px;
	border-radius: 10px;
	box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
	overflow: auto;
`;

const StyledFormContent = styled.div`
	height: 80%;
	padding: 40px;
`;

export default AdmissionForm;
