import { getCourseById } from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
	Redirect,
	Route,
	Switch,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import { SingleCourseRoute } from './Constants/routes';
import { Test } from './Pages/CourseForm/Pages/CourseContent/Pages/Test/Test';
import { CourseContext } from './Context/CourseContext';
import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import { useClassroomProvider } from '@Providers/ClassroomProvider/ClassroomProvider';

const SingleCourse: React.FC = () => {
	const { id, label } = useParams<{ id: string; label: string }>();
	const dispatch = useDispatch();
	const { currentCourse } = useTypedSelector((state) => state.classroom);
	const { url, path } = useRouteMatch();
	const { loaded } = useClassroomProvider();

	const { addLink, removeLink } = useContext(BreadcrumbContext);
	const { setURL, setCurrentRouteGroup } = useInvalidateRouteGroup({
		routeGroup: SingleCourseRoute,
		baseURL: `${url}/info`,
		routePlacement: 2,
		mode: 'end',
		extraProps: {
			id,
			label,
		},
	});
	useEffect(() => {
		dispatch(
			getCourseById({
				id,
			})
		);
	}, [id]);

	useEffect(() => {
		if (!url || !currentCourse || !loaded) return;
		addLink &&
			addLink({
				route: url + '/edit/content',
				title: currentCourse.name,
				routeHash: 'course',
			});
		setURL(url);
		setCurrentRouteGroup && setCurrentRouteGroup(SingleCourseRoute);
		return () => {
			removeLink?.('course');
		};
	}, [loaded, currentCourse, url]);

	const triggerUpdate = () => {
		dispatch(
			getCourseById({
				id,
			})
		);
	};

	return (
		<CourseContext.Provider
			value={{
				triggerUpdate,
				id: Number(id),
			}}
		>
			<Switch>
				<Route path={path} exact>
					<Redirect to={`${url}/edit/info`} />
				</Route>
				<Route path={`${url}/test/:id`}>
					<Test />
				</Route>

				{SingleCourseRoute.routes.map((route) => {
					return (
						<Route key={route.route} path={`${path}/${route.route}`}>
							{route.component}
						</Route>
					);
				})}
			</Switch>
		</CourseContext.Provider>
	);
};

export default SingleCourse;
