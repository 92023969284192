import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from '@Base/services/services.base';
import api from '@Services/api';
import { AxiosResponse } from 'axios';
import {
	AssignmentSubmission,
	AssignmentSubmissionPayload,
} from '../Types/Submissions.types';

interface SubmissionsCRUD extends CRUDBase<AssignmentSubmission> {
	// submitQuestion(
	// 	payload: SubmitAttemptQuestionPayload
	// ): Promise<AxiosResponse<any>>;
	// submitAttempt(payload: SubmitAttemptPayload): Promise<AxiosResponse<any>>;
	reviewAssignment(
		payload: AssignmentSubmissionPayload
	): Promise<AxiosResponse<any>>;
}
export class AssignmentSubmissionRequester implements SubmissionsCRUD {
	async reviewAssignment(
		payload: AssignmentSubmissionPayload
	): Promise<AxiosResponse<any>> {
		return await api.post('assignment/submission/score', payload);
	}
	baseURL?: string | undefined;
	public static instance: AssignmentSubmissionRequester =
		new AssignmentSubmissionRequester();
	export?(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	getAll(
		payload?: GetAllMeta | undefined
	): Promise<AxiosResponse<ApiResponse<AssignmentSubmission[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<AssignmentSubmission[]>>>;
	async getAll(
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<AssignmentSubmission[]>>> {
		return await api.post('assignment/fetch/submissions', payload);
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<AssignmentSubmission>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<AssignmentSubmission>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<AssignmentSubmission>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<AssignmentSubmission>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<AssignmentSubmission, 'id'>
	): Promise<AxiosResponse<ApiResponse<AssignmentSubmission>>> {
		throw new Error('Method not implemented.');
	}
	update(
		payload: AssignmentSubmission
	): Promise<AxiosResponse<ApiResponse<AssignmentSubmission>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(
		payload: AssignmentSubmission
	): Promise<AxiosResponse<ApiResponse<AssignmentSubmission>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
}
