import { object, number, string, array } from 'yup';

export const TestQuestionBaseSchema = object({
	// id: number(),
	test_id: number().required(),
	weight: number().positive().required(),
	content: string().required(),
	feedback: string().nullable(true),
	order: number().nullable(true),
	tags: array(number().required()),
});
