import { IAllProps } from '@tinymce/tinymce-react';
import { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { useQuillEditor } from './useQuillEditor';
type QuillEditorProps = {
	min_height?: number;
	max_height?: number;
} & IAllProps;

export const QuillEditor = ({
	value,
	onEditorChange,
	min_height,
	disabled,
	max_height,
}: QuillEditorProps) => {
	const { editor, id } = useQuillEditor({
		value,
		onChange(value) {
			onEditorChange?.(value, editor.current?.editor as any);
		},
		disabled,
	});

	return (
		<StyledEditor min_height={min_height} max_height={max_height}>
			{/* <ReactQuill
				ref={editor as any}
				theme={'snow'}
				value={innerValue}
				modules={modules}
				formats={formats}
				onChange={(value, delta, source, _editor) => {
					if (_editor.getContents().length() === 1) {
						onEditorChange?.('', editor.current?.editor as any);
						setInnerValue('');
						return;
					}
					setInnerValue(value);
					onEditorChange?.(value, editor.current?.editor as any);
				}}
			/> */}
			<div id={id} className={id}></div>
			{/* {counterElement} */}
		</StyledEditor>
	);
};

const StyledEditor = styled.div<{ min_height?: number; max_height?: number }>`
	border: 2px solid #eee;
	border-radius: 10px;
	box-shadow: none;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow: auto;
	min-height: ${({ min_height }) => `${min_height || 400}px`};
	max-height: ${({ max_height }) => max_height && `${max_height}px`};

	.quill {
		display: flex;
		flex-direction: column;
	}
	.ql-toolbar {
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
		position: sticky;
		top: 0;
	}
	.ql-container {
		flex: 1;
		border-bottom-left-radius: 0.25rem;
		border-bottom-right-radius: 0.25rem;
	}
	.ql-toolbar.ql-snow {
		border: unset;
		background-color: #fff;
		border-bottom: none;
		box-shadow: 0 2px 2px -2px rgba(34, 47, 62, 0.1),
			0 8px 8px -4px rgba(34, 47, 62, 0.07);
		padding: 4px 0;
		/* margin-top: -6px; */
	}
	.ql-container.ql-snow {
		border: unset;
	}
	.ql-snow .ql-picker.ql-header {
		&:hover {
			background: #f0f0f0;
			border: 0;
			box-shadow: none;
			color: #222f3e;
		}
		background: #f7f7f7;
		padding: 0 4px;
		user-select: none;
		width: 130px;
		align-items: center;
		background: #fff;
		border: 0;
		border-radius: 3px;
		box-shadow: none;
		color: #222f3e;
		display: flex;
		flex: 0 0 auto;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		height: 28px;
		justify-content: center;
		margin: 6px 1px 5px 0;
		outline: 0;
		padding: 0;
		text-transform: none;
	}
	.ql-snow .ql-picker.ql-header.ql-expanded .ql-picker-label {
		background: #f0f0f0;
		border: 0;
		box-shadow: none;
		color: #222f3e;
	}
	.ql-toolbar.ql-snow .ql-picker-label {
		display: flex;
		align-items: center;
	}

	.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
		background-color: #fff;
		border: 1px solid transparent;
		border-radius: 6px;
		box-shadow: 0 0 2px 0 rgba(34, 47, 62, 0.2),
			0 4px 8px 0 rgba(34, 47, 62, 0.15);
		display: inline-block;
		overflow: hidden;
		vertical-align: top;
		z-index: 1150;
	}
	.ql-snow .ql-picker-options .ql-picker-item {
		background-color: #fff;
		color: #222f3e;
		padding: 4px 8px;
		align-items: center;
		border-radius: 3px;
		color: #222f3e;
		display: flex;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		user-select: none;
		&:hover {
			background-color: #006ce7;
			color: #fff;
		}
	}
	.ql-snow .ql-picker-options .ql-picker-item.ql-selected {
		background-color: #006ce7;
		color: #fff;
	}
	.ql-counter {
		/* border: 1px solid #ccc; */
		border-top: 2px solid #eee;
		/* border-width: 0px 1px 1px 1px; */
		color: #222f3e;
		padding: 5px 15px;
		text-align: right;
	}
`;
