import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import useRouteGroup from '@Hooks/Routes/useRouteGroup';
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ClassRoomsTable from './ClassRoomsTable';

const ClassRooms: React.FC = () => {
	const routeGroup = useRouteGroup('classrooms');

	const { path, url } = useRouteMatch();
	const { isCurrent } = useInvalidateRouteGroup({
		mode: 'end',
		baseURL: '/dashboard/classrooms',
		routeGroup: routeGroup,
		routePlacement: 2,
	});
	const { removeLink } = useContext(BreadcrumbContext);
	useEffect(() => {
		if (isCurrent) {
			removeLink && removeLink('classroom');
		}
	}, [isCurrent]);

	return (
		<Switch>
			{routeGroup && !Array.isArray(routeGroup) && (
				<>
					<Route exact path={url}>
						<ClassRoomsTable />
					</Route>

					{routeGroup.routes.map((_) => {
						return (
							<Route key={_.route} strict path={`${path}/${_.route}`}>
								{_.component}
							</Route>
						);
					})}
				</>
			)}
		</Switch>
	);
};

export default ClassRooms;
