import { uploadPicture } from '@Services/uploads/uploads.req';
import { useEffect, useMemo, useRef } from 'react';
import { Counter, toolbarOptions } from './editor.config';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { v4 as uuid } from 'uuid';
import MagicUrl from 'quill-magic-url';
import ResizeModule from '@ssumo/quill-resize-module';
import 'quill-paste-smart';

type QuillEditorProps = {
	value?: string;
	onChange?: (value: string) => void;
	container?: string;
	disabled?: boolean;
};

export const useQuillEditor = ({
	value,
	onChange,
	container,
	disabled,
}: QuillEditorProps) => {
	const editor = useRef<Quill>();
	const elementRef = useRef<HTMLDivElement>();
	const counterRef =
		useRef<
			React.DetailedReactHTMLElement<
				React.HTMLAttributes<HTMLElement>,
				HTMLElement
			>
		>();
	const isMounted = useRef(false);
	const id = useRef(uuid());

	const modules = useMemo(() => {
		return {
			toolbar: toolbarOptions,
			magicUrl: true,
			resize: {
				locale: {
					altTip: 'Resize Image',
					inputTip: 'input',
					floatLeft: 'Left',
					floatRight: 'Right',
					center: 'Center',
					restore: 'Restore',
				},
			},
			clipboard: {
				handleImagePaste(image: File) {
					saveToServer(image);
				},
			},
		};
	}, []);
	const formats = useMemo(() => {
		return [
			'header',
			'font',
			'size',
			'bold',
			'italic',
			'underline',
			'align',
			'strike',
			'script',
			'blockquote',
			'background',
			'list',
			'bullet',
			'indent',
			'link',
			'image',
			'color',
			'code-block',
		];
	}, []);

	useEffect(() => {
		if (isMounted.current) return;

		const initiate = () => {
			const element = document.getElementById(
				container || `${id.current}-editor`
			) as HTMLDivElement;

			if (!element) return;

			elementRef.current = element;
			Quill.register('modules/magicUrl', MagicUrl, true);
			Quill.register('modules/counter', Counter, true);
			Quill.register('modules/resize', ResizeModule);

			editor.current = new Quill(element, {
				modules,
				formats,
				theme: 'snow',
				readOnly: disabled,
			});

			let _editor = editor.current;

			_editor.on('text-change', () => {
				const count = _editor.getContents().length() - 1;
				if (count === 0) {
					onChange?.('');
					return;
				}
				const html = _editor.root.innerHTML;
				onChange?.(html);
			});

			if (!_editor) return;
			(_editor?.getModule('toolbar') as any).addHandler('image', () => {
				console.log('HANDLER');
				selectLocalImage();
			});

			_editor.root.innerHTML = value || '';
			isMounted.current = true;
		};
		initiate();
	}, []);

	useEffect(() => {
		if (!isMounted.current) return;
		if (disabled) editor.current?.disable();
		else editor.current?.enable();
	}, [disabled]);

	function imageMatcher(_: any) {
		console.log(_);
	}
	function selectLocalImage() {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.click();

		// Listen upload local image and save to server
		input.onchange = () => {
			const file = input.files?.[0];
			if (!file) return;
			// file type is only image.
			if (/^image\//.test(file.type)) {
				saveToServer(file);
			} else {
				console.warn('You could only upload images.');
			}
		};
	}
	async function saveToServer(file: File) {
		const blob = new Blob([file], { type: file.type });

		const {
			data: {
				data: { uri },
			},
		} = await uploadPicture({
			folder: 'tinymc',
			photo: blob,
		});
		insertToEditor(uri);
	}
	function insertToEditor(url: string) {
		// push image url to rich editor.
		const range = editor.current?.getSelection();
		if (!range) return;
		editor.current?.editor?.insertEmbed(range.index, 'image', url);
		if (!editor.current) return;
		onChange?.(editor.current?.root.innerHTML);
	}

	return {
		editor: editor,
		id: container || `${id.current}-editor`,
		counterElement: counterRef.current,
	};
};
