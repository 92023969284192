import api from '@Services/api';
import { AxiosResponse } from 'axios';

import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GenerateCardMeta,
	GenerateCodesMeta,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';
import {
	Submissions,
	SubmitAttemptPayload,
	SubmitAttemptQuestionPayload,
} from '../Types/Submissions.types';

interface SubmissionsCRUD extends CRUDBase<Submissions, Submissions> {
	submitQuestion(
		payload: SubmitAttemptQuestionPayload
	): Promise<AxiosResponse<any>>;
	submitAttempt(payload: SubmitAttemptPayload): Promise<AxiosResponse<any>>;
}
export class SubmissionsRequester implements SubmissionsCRUD {
	export?(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<Submissions>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<Submissions>>>;
	get(
		id: string | number,
		tId?: string | number | undefined,
		payload?: GetSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<Submissions>>>;
	get(
		id: unknown,
		tId?: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<Submissions>>> {
		throw new Error('Method not implemented.');
	}
	create(
		payload: Omit<Submissions, 'id'>
	): Promise<AxiosResponse<ApiResponse<Submissions>>> {
		throw new Error('Method not implemented.');
	}
	update(
		payload: Submissions
	): Promise<AxiosResponse<ApiResponse<Submissions>>> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload?: DeleteSingleEntityPayload | undefined
	): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: unknown,
		payload?: unknown
	): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	generate?(
		payload: GenerateCodesMeta | GenerateCardMeta
	): Promise<AxiosResponse<string>> {
		throw new Error('Method not implemented.');
	}
	toggleActive?(
		payload: Submissions
	): Promise<AxiosResponse<ApiResponse<Submissions>>> {
		throw new Error('Method not implemented.');
	}
	process?(id: number): Promise<AxiosResponse<ApiResponse<any>>> {
		throw new Error('Method not implemented.');
	}
	private static instance: SubmissionsRequester;
	static getInstance() {
		if (!SubmissionsRequester.instance) {
			SubmissionsRequester.instance = new SubmissionsRequester();
		}
		return SubmissionsRequester.instance;
	}
	baseURL = '/test/fetch/attempts';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<Submissions[]>>>;
	getAll(payload: number): Promise<AxiosResponse<ApiResponse<Submissions[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<Submissions[]>>> {
		return await api.post(`${this.baseURL}`, payload);
	}

	async submitQuestion(
		payload: SubmitAttemptQuestionPayload
	): Promise<AxiosResponse<any>> {
		return await api.post('/test/questions/grade', payload);
	}

	async submitAttempt(
		payload: SubmitAttemptPayload
	): Promise<AxiosResponse<any>> {
		return await api.post('/test/submit', payload);
	}
}
