import React from 'react';
import styled from 'styled-components';
import AdmissionFormHeaderRecord from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/AdmissionFormHeader/AdmissionFormHeaderRecord';
import { useSingleClassroomResponseQuery } from '@Features/classrooms/InstructorAdmissionsApi';
import { useAdmissionsContext } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons/Admissions';
import { Alert, AlertTitle } from '@mui/material';
import { AxiosResponse } from 'axios';
import moment from 'moment';

type Props = {};

const AdmissionFormHeader: React.FC<Props> = () => {
	const { selectedAdmissionIdQueryParam: admissionId } = useAdmissionsContext();
	const admissionQuery = useSingleClassroomResponseQuery(
		{ id: admissionId! },
		{ skip: !admissionId }
	);

	if ((admissionQuery.isError && admissionQuery.error) || !admissionQuery.data)
		return (
			<Alert severity="error">
				<AlertTitle>Error</AlertTitle>
				{(admissionQuery?.error as AxiosResponse<ApiResponse<undefined>>)?.data
					?.message ?? 'could not loan admission request'}
			</Alert>
		);

	const user = admissionQuery.data.data.student.user;
	const requestDate = admissionQuery.data.data.created_at;
	const rejectionCount = admissionQuery.data.data.student.rejection_count;

	return (
		<StyledRecords>
			<AdmissionFormHeaderRecord label="Username" value={user.username} />
			<div />
			<div />
			<AdmissionFormHeaderRecord label="First Name" value={user.first_name} />
			<AdmissionFormHeaderRecord label="Phone" value={user.phone_number} />
			<AdmissionFormHeaderRecord
				label="Date of Request"
				value={moment(requestDate).format('D/M/YYYY')}
			/>
			<AdmissionFormHeaderRecord label="Last Name" value={user.last_name} />
			<AdmissionFormHeaderRecord label="Email" value={user.email} />
			<AdmissionFormHeaderRecord
				label="Rejections Count"
				value={rejectionCount?.toString() ?? 0}
			/>
		</StyledRecords>
	);
};

const StyledRecords = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	gap: 14px 100px;
	font-size: 14px;
	margin-bottom: 45px;

	padding-bottom: 20px;
	border-bottom: 1px solid;
`;

export default AdmissionFormHeader;
