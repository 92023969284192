import api from '@Services/api';
import {
	SingleStudentData,
	SingleStudentResponse,
} from '@Services/students/students.res.types';
import { AxiosResponse } from 'axios';
import {
	CRUDBase,
	DeleteSingleEntityPayload,
	GetAllMeta,
	GetSingleEntityPayload,
} from 'src/base/services/services.base';

interface StudentsCRUD
	extends CRUDBase<SingleStudentResponse, SingleStudentData> {}
export class StudentsRequester implements StudentsCRUD {
	private static instance: StudentsRequester;
	static getInstance() {
		if (!StudentsRequester.instance) {
			StudentsRequester.instance = new StudentsRequester();
		}
		return StudentsRequester.instance;
	}
	baseURL = 'user';
	getAll(
		payload?: GetAllMeta
	): Promise<AxiosResponse<ApiResponse<SingleStudentData[]>>>;
	getAll(
		payload: number
	): Promise<AxiosResponse<ApiResponse<SingleStudentData[]>>>;
	async getAll(
		payload?: any
	): Promise<import('axios').AxiosResponse<ApiResponse<SingleStudentData[]>>> {
		return await api.post(`${this.baseURL}/all`, payload);
	}

	async export(payload: GetAllMeta): Promise<AxiosResponse<string>> {
		return await api.post(`${this.baseURL}/all`, { ...payload, export: true });
	}
	get(id: string): Promise<AxiosResponse<ApiResponse<SingleStudentData>>>;
	get(id: number): Promise<AxiosResponse<ApiResponse<SingleStudentData>>>;
	get(
		id: string | number,
		payload: GetSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<SingleStudentData>>>;
	async get(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<SingleStudentData>>> {
		return await api.post(`${this.baseURL}`, { uuid: id });
	}

	async create(
		payload: SingleStudentResponse
	): Promise<AxiosResponse<ApiResponse<SingleStudentData>>> {
		return await api.post(`${this.baseURL}/create`, payload);
	}
	async update(
		payload: any
	): Promise<AxiosResponse<ApiResponse<SingleStudentData>>> {
		return await api.put(`${this.baseURL}/update`, payload);
	}
	delete(id: number): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(id: string): Promise<AxiosResponse<ApiResponse<any>>>;
	delete(
		id: string | number,
		payload: DeleteSingleEntityPayload
	): Promise<AxiosResponse<ApiResponse<SingleStudentData>>>;
	async delete(
		id: any,
		payload?: any
	): Promise<AxiosResponse<ApiResponse<any>>> {
		return await api.delete(`${this.baseURL}/delete`, {
			data: {
				uuid: id,
			},
		});
	}
}
