import React from 'react';
import { RouteGroup } from '@Constants/routes';
import ImportsTab from '@Pages/Imports';

export const ImportsRoute: RouteGroup = {
	isRouted: true,
	isParent: true,
	title: 'Imports',
	icon: 'redo',
	route: 'imports',
	code: 'imports',
	component: <ImportsTab />,
	routes: [],
};
