import React from 'react';
import { object, string } from 'yup';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import TestQuestionsTable from '../Components/TestQuestionsTable';
import MCQ from '../Components/Questions/MCQ/MCQ';
import EditMCQ from '../Components/Questions/MCQ/EditMCQ/EditMCQ';
import Ordering from '../Components/Questions/Ordering/Ordering';
import EditOrdering from '../Components/Questions/Ordering/EditOrdering';
import TagsModal from '../Components/TagsModal/TagsModal';
import {
	RouteProvider
} from '@Providers/Routes/RoutesProvider';
import EssayQuestion from './EssayQuestion';
import GapQuestion from './GapQuestion/GapQuestion';
const SCHEMA = object({
	name: string(),
});
// const GapQuestion = React.lazy(() => import('./GapQuestion/GapQuestion'));

const TestQuestions = () => {
	const { path } = useRouteMatch();
	// const { setParent } = useRouteProvider();
	// useEffect(() => {
	//   setParent(path);
	// }, [path]);
	return (
		<>
			<RouteProvider path={path}>
				<React.Suspense fallback={<div>loading</div>}>
					<Switch>
						<Route path={`${path}`} exact>
							<TestQuestionsTable />
						</Route>
						<Route path={`${path}/tags`}>
							<TagsModal />
						</Route>
						<Route path={`${path}/mcq`} exact>
							<MCQ />
						</Route>
						<Route path={`${path}/mcq/:id`} exact>
							<EditMCQ />
						</Route>
						<Route path={`${path}/gap`}>
							<GapQuestion />
						</Route>
						<Route path={`${path}/essay`}>
							<EssayQuestion />
						</Route>
						<Route path={`${path}/ordering`} exact>
							<Ordering />
						</Route>
						<Route path={`${path}/ordering/:id`} exact>
							<EditOrdering />
						</Route>
					</Switch>
				</React.Suspense>
			</RouteProvider>
		</>
	);
};

export default TestQuestions;
