import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { DevTool } from '@hookform/devtools';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { Button, Icon, InputAdornment } from '@mui/material';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { FlexLayout, Spacer } from '@Styled/utilities';
import { useTypedSelector } from '@Features/store';
import { useForm } from 'react-hook-form';
import { Edit_Document } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Schema/DocumentInfo.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from '@Providers/useSnackbar';
import { useHistory } from 'react-router-dom';
import { DocumentsRequester } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Services/Documents.req';
import { DocPayload } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Documents/Types/Documents.types';
import { uploadDocument } from '@Services/uploads/uploads.req';
import { cloneDeep } from 'lodash';
import { LinearProgress } from '@mui/material';

type Props = {};

const SUPPORTED_FORMATS = [
	'video/x-matroska',
	'video/flv',
	'video/3gpp',
	'video/mp4',
	'video/quicktime',
	'video/avi',
	'video/webm',
	'',
];

const EditDocumentFormPage: React.FC<Props> = () => {
	const { currentEntity } = useTypedSelector((state) => state.Documents);
	const defaultValues = useMemo<DocPayload | undefined>(() => {
		if (currentEntity) {
			const dv: DocPayload = {
				document_id: currentEntity.document.id,
				unit: currentEntity.document.unit,
				document: {
					uri: currentEntity.document.uri,
					size: currentEntity.document.size,
					file_name: currentEntity.document.file_name,
					extension: currentEntity.document.extension,
				},
			};
			return dv;
		}
	}, []);
	const { handleSubmit, control, setValue, getValues } = useForm<DocPayload>({
		resolver: yupResolver(Edit_Document),
		mode: 'all',
		defaultValues: cloneDeep(defaultValues),
	});
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();
	const onSubmit = handleSubmit(async (form: DocPayload) => {
		try {
			const {
				data: { data },
			} = await DocumentsRequester.getInstance().update(form);
			displaySnackbar('success', 'Document Is Edited Successfully');
			history.goBack();
		} catch (e) {
			const msg = e?.data?.message || 'Unable To Edit Document';
			displaySnackbar('error', msg);
		}
	});

	const [uploadPercentage, setUploadPercentage] = useState<
		number | undefined
	>();
	const UploadDocument = async (blob: File | Blob | null) => {
		try {
			const {
				data: {
					data: { uri, extension, size, file_name },
				},
				status,
			} = await uploadDocument(
				{
					document: blob,
					folder: 'documents',
				},
				{
					onUploadProgress(progressEvent) {
						const { loaded, total } = progressEvent;
						let percent = Math.floor((loaded * 100) / total);
						if (percent === 100) {
							setUploadPercentage(undefined);
						} else if (percent < 100) {
							setUploadPercentage(percent);
						}
					},
				}
			);
			setValue('document.uri', uri);
			setValue('document.extension', extension);
			setValue('document.size', size);
			setValue('document.file_name', file_name);
			return status === 200;
		} catch (e) {
			const msg = e?.data?.message || 'Unable to Upload Documet ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<DevTool control={control} />
			<EdFormControl
				control={control}
				name="unit.name"
				label="Unit Name"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<Icon className="material-icons-outlined">description</Icon>
						</InputAdornment>
					),
				}}
			/>
			<FlexLayout mt="32px" alignItems={'center'} gridGap="1rem">
				<StyledDiv>
					<EdUploadFile
						aspectRation={1}
						folder="documents"
						uploadLabel="UPLOAD FILE"
						uploadIcon={<Icon>upload_file</Icon>}
						control={control}
						name="document.uri"
						label=" "
						type="document"
						onFileUpload={UploadDocument as any}
					/>
				</StyledDiv>
				{uploadPercentage !== undefined && (
					<LabelWrapper>
						<Label>
							<span>{uploadPercentage}%</span>
						</Label>
						<LinearProgressStyle
							variant="determinate"
							value={uploadPercentage}
						/>
					</LabelWrapper>
				)}
			</FlexLayout>
			<FlexLayout justifyContent="flex-end">
				<Button
					variant="contained"
					color="warning"
					onClick={() => history.goBack()}
				>
					Cancel
				</Button>
				<Spacer mx="0.5rem" />
				<Button variant="contained" type="submit">
					Save
				</Button>
			</FlexLayout>
		</form>
	);
};

const StyledHeader = styled.p`
	color: ${(props) => props.theme.palette?.purple?.main};
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 35px;
`;
const StyledDiv = styled.div`
	width: 28.4rem;
	margin-top: 32px;
`;


const LinearProgressStyle = styled(LinearProgress)`
	width: 8.25rem;
	height: 1.063rem;
	border-radius: 10px;
	background: #afafaf;
`;

const LabelWrapper = styled.div`
	position: relative;
`;

const Label = styled.div`
	position: absolute;
	top: 1px;
	left: 6px;
	z-index: 1;
	text-align: left;
	display: flex;
	align-items: center;
	margin-top: 0.05rem;
	span {
		width: 100%;
		font-size: 0.75rem;
		color: #fff;
		font-weight: 500;
	}
`;
export default EditDocumentFormPage;
