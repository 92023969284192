import useBoolean from './useBoolean';

type Options = {
	defaultIsOpen?: boolean;
};

const defaultOptions: Options = {
	defaultIsOpen: false,
};

export const useDisclosure = (options: Options = defaultOptions) => {
	const {
		value: isOpen,
		setTrue: onOpen,
		setFalse: onClose,
		setValue: setIsOpen,
		toggle,
	} = useBoolean(options.defaultIsOpen);

	return { isOpen, onOpen, onClose, setIsOpen, toggle };
};

export type DisclosureReturn = ReturnType<typeof useDisclosure>;
