import {
	Autocomplete,
	Button,
	Checkbox,
	Chip,
	Popper,
	TextField,
} from '@mui/material';
import React, { FC, SyntheticEvent, useCallback, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CourseWithPrerequisites } from '@Services/classrooms/classrooms.res.types';
import { useDataProvider } from './DataProvider';
import { FlexLayout } from '@Styled/utilities';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { Box } from '@eduact/ed-system';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
};

const PopperMy = function (props: any) {
	return <PopperStyle {...props} />;
};

const AddSequenceAutoComplete: FC<Props> = ({ handleModelClose }) => {
	const { inSequence, outSequence, setOutSequence } =
		useDataProvider<CourseWithPrerequisites>();

	const [defaultValue, setDefaultValue] = useState<CourseWithPrerequisites[]>(
		[]
	);

	const [selected, setSelected] = useState<string | null>();
	const [array, setArray] = useImmer<Array<CourseWithPrerequisites>>([]);

	const selectedSequence = useCallback(() => {
		const max =
			inSequence &&
			inSequence
				?.map((_) => _.sequence)
				.reduce((a: any, b: any) => {
					return Math.max(a, b);
				}, 0);
		const nArray = array?.map((_, index) => {
			return Object.assign({}, _, { sequence: (max as number) + index + 1 });
		});
		inSequence?.push(...nArray);

		const sortedArr = outSequence?.filter(function (val) {
			return array.indexOf(val) === -1;
		});

		setOutSequence(sortedArr);
		handleModelClose(false);
	}, [selected]);


	return (
		<FlexLayout
			flex={1}
			flexDirection="column"
			justifyContent={'space-between'}
		>
			<FlexLayout flexDirection="column" gridGap="1rem">
				<AutocompleteStyle
					multiple
					id="checkboxes-tags-demo"
					readOnly={false}
					filterSelectedOptions
					disableCloseOnSelect
					clearOnBlur={true}
					PopperComponent={PopperMy}
					onChange={(e: SyntheticEvent<Element, Event>, v: any) => {
						setSelected(e.currentTarget.textContent);
					
						setDefaultValue(v);
						setArray(v);
					}}
					sx={{
						width: '100%',
					}}
					value={defaultValue}
					options={outSequence as readonly CourseWithPrerequisites[]}
					groupBy={(option) =>
						(option as CourseWithPrerequisites)?.section?.name
					}
					getOptionLabel={(option: unknown) =>
						(option as CourseWithPrerequisites).name
					}
					renderOption={(props, option, { selected }) => (
						<>
							<li {...props}>
								<CheckboxStyle
									checkedIcon={<CheckBoxIcon fontSize="small" />}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								<Label> {(option as CourseWithPrerequisites).name} </Label>
							</li>
						</>
					)}
					renderTags={(options: unknown) => {
						return (options as unknown as CourseWithPrerequisites[])?.map(
							(_: CourseWithPrerequisites) => (
								<Text>{(_ as CourseWithPrerequisites).name}, &nbsp;</Text>
							)
						);
					}}
					renderInput={(params) => {
						return <TextField {...params} placeholder="Search" />;
					}}
				/>
				<FlexLayout
					gridGap={'1rem'}
					flexWrap={'wrap'}
					maxHeight={'40vh'}
					overflow={'scroll'}
				>
					{defaultValue?.map((x, i) => {
						return (
							<Box height="fit-content">
								<Chip
									key={x.id}
									title={x.name}
									label={x.name}
									onDelete={() => {
										const _copy = [...defaultValue];
										const index = _copy.findIndex((_) => _.id === x.id);
										_copy.splice(index, 1);
										// setArray(_copy);
										setDefaultValue((prev) => _copy);
									}}
								/>
							</Box>
						);
					})}
				</FlexLayout>
			</FlexLayout>
			<FlexLayout justifyContent="flex-end" alignItems="flex-end">
				<Button
					variant="text"
					color="warning"
					onClick={() => {
						handleModelClose(false);
					}}
				>
					Cancel
				</Button>
				<Button
					variant="text"
					color="secondary"
					onClick={() => {
						selectedSequence();
					}}
				>
					Confirm
				</Button>
			</FlexLayout>
		</FlexLayout>
	);
};

const AutocompleteStyle = styled(Autocomplete)`
	.MuiOutlinedInput-root {
		/* width: 20rem; */
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap: nowrap;
		overflow: hidden;
		justify-content: flex-end;
	}
`;

const Header = styled.div`
	font-size: 1rem;
	font-weight: 500;
	margin-left: 15px;
	color: #251b33;
`;

const Text = styled.div`
	font-size: 1rem;
	font-weight: 500;
	color: #251b33;
	white-space: nowrap;
`;

const PopperStyle = styled(Popper)`
	height: 12.6rem;
	width: 20rem;
	overflow-y: scroll;
	overflow-x: hidden;
	border-radius: 4px;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);

	&::-webkit-scrollbar {
		display: none;
	}
`;

const Label = styled.p`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 70px;
`;

const CheckboxStyle = styled(Checkbox)`
	padding: 0;
`;

export default AddSequenceAutoComplete;
