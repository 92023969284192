import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import SelectController from '@Components/UI/Inputs/SelectController/SelectController';
import { getEducationalInfo } from '@Features/classrooms/educationalSlice';
import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Icon, MenuItem } from '@mui/material';
import { Divider, GridLayout, Spacer } from '@Styled/utilities';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
	ClassroomForm,
	FULL_SCHEMA,
} from '@Pages/ClassRooms/Schema/ClassroomForm.schema';
import { DevTool } from '@hookform/devtools';
import { Flatten } from '@Utils/types/Flatten';
import EdUploadFile from '@Components/UI/Inputs/EdUploadFile/EdUploadFile';
import { createClassroom } from '@Services/classrooms/classrooms.req';
import { useHistory } from 'react-router-dom';
import { DASHBOARD_BASE_ROUTE } from '@Constants/routes';
import {
	PaymentMethods,
	ClassroomLanguages,
	ClassroomStatuses,
	ClassroomSubTypes,
	ClassroomTypes,
} from '@Pages/ClassRooms/Constants/Classroom.constants';
import {
	ClassroomPayload,
	EducationalInfoControlType,
} from '@Pages/ClassRooms/Types/Classroom.types';
import SelectChip from '@Components/UI/Inputs/SelectChip/SelectChip';
import { ChipFlexLayout } from '@Pages/ClassRooms/Components/AddEditClassroom.styled';
import { useSnackbar } from '@Providers/useSnackbar';
type onNextCallback = (formData: ClassroomPayload) => void;
type Props = {
	onHasAdmissionChange: React.Dispatch<boolean>;
	onNext: onNextCallback;
	onIsValidChange?: React.Dispatch<boolean>;
};
const AddClassroomBasicInfo: React.FC<Props> = ({
	onHasAdmissionChange,
	onIsValidChange,
	onNext,
}) => {
	const { dropdownInstructors, dropdownCategories } = useTypedSelector(
		(state) => state.dropdowns
	);
	const { educational_info, educational_type } = useTypedSelector(
		(state) => state.educational
	);
	const { displaySnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		formState: { isValid, isDirty },
		getValues,
		watch,
		control,
		setValue,
	} = useForm<ClassroomForm>({
		resolver: yupResolver(FULL_SCHEMA),
		mode: 'all',
		defaultValues: {
			educationLanguages: [],
			educationSections: [],
			educationTypes: [],
			educationYears: [],
		},
	});
	const hasAdmissionWatch = watch('has_admission');
	//
	const watchTypes = watch('educationTypes', []);
	const watchYears = watch('educationYears', []);
	const watchLanguages = watch('educationLanguages', []);
	const watchSections = watch('educationSections', []);

	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);
	useEffect(() => {
		if (hasAdmissionWatch !== undefined) {
			onHasAdmissionChange(hasAdmissionWatch);
		}
	}, [hasAdmissionWatch, onHasAdmissionChange]);

	useEffect(() => {
		onIsValidChange && onIsValidChange(isValid);
	}, [isValid]);

	const years = React.useMemo(() => {
		if (!watchTypes || watchTypes.length === 0) {
			setValue('educationYears', []);
			return [];
		}
		let flatYears = educational_info
			.filter((_) => _.id && watchTypes.includes(_.id))
			.map(({ educationYears }) => educationYears)
			.flat();
		flatYears = flatYears.filterDups('id');

		return flatYears;
	}, [watchTypes?.length, educational_info]);

	const languages = React.useMemo(() => {
		if (!watchTypes || watchTypes.length === 0) {
			setValue('educationLanguages', []);
			return [];
		}
		let flatLanguages = educational_info
			.filter((_) => _.id && watchTypes.includes(_.id))
			.map(({ educationLanguages }) => educationLanguages)
			.flat();
		flatLanguages = flatLanguages.filterDups('id');

		return flatLanguages;
	}, [watchTypes?.length, educational_info]);

	const sections = React.useMemo(() => {
		if (!watchYears) return;
		if (watchYears.length === 0) {
			setValue('educationSections', []);
			return [];
		}
		let flatYears = educational_info
			.map(({ educationYears }) => educationYears)
			.flat()
			.filterDups('id')
			.filter((_) => watchYears.includes(_.id ?? -1));

		let flatSections = flatYears
			.map(({ educationSections }) => educationSections)
			.flat()
			.filterDups('id');

		return flatSections;
	}, [watchYears?.length, educational_info]);

	const isChildrenDisabled = React.useMemo(() => {
		return !watchTypes || (watchTypes && watchTypes.length === 0);
	}, [watchTypes]);

	const onDeleteValue = (
		control: EducationalInfoControlType,
		value: number
	) => {
		const values = getValues()[control];
		if (!values) return;
		values.splice(
			values.findIndex((_) => _ === value),
			1
		);
		setValue(control, values, { shouldValidate: true });
	};

	const onSave = async (form: Flatten<ClassroomForm>) => {
		try {
			const {
				data: { data },
			} = await createClassroom(form);
			displaySnackbar('success', `${data.title} Created Successfully`);
			history.push({
				pathname: `${DASHBOARD_BASE_ROUTE}/classrooms/classroom/${data.label}/edit`,
				hash: '#info',
			});
		} catch (error) {}
	};

	return (
		<>
			<SectionLayout
				gridGap={'2rem'}
				gridTemplateColumns={{ sm: 'repeat(2,1fr)' }}
			>
				<EdFormControl
					control={control}
					required
					name="title"
					label={'Title'}
				/>

				<EdFormControl
					control={control}
					required
					select
					name="instructor_id"
					label={'Instructor'}
				>
					{dropdownInstructors.map((_, index) => {
						return (
							<MenuItem
								value={_.id}
							>{`${_.first_name} ${_.last_name}`}</MenuItem>
						);
					})}
				</EdFormControl>
				<EdFormControl
					control={control}
					required
					select
					name="category_id"
					label={'Category'}
				>
					{dropdownCategories.map((_, index) => {
						return <MenuItem value={_.id}>{`${_.name}`}</MenuItem>;
					})}
				</EdFormControl>

				<EdFormControl
					control={control}
					required
					multiline
					name="description"
					label={'Description'}
				/>
				<EdFormControl
					control={control}
					multiline
					name="prerequisites"
					label={'Prerequisites'}
				/>
			</SectionLayout>
			<Divider />
			<SectionLayout>
				<EdFormControl
					control={control}
					required
					name="label"
					label={'Label'}
				/>

				<EdFormControl
					control={control}
					required
					select
					name="type"
					label={'Type'}
				>
					{ClassroomTypes.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_}`} value={_}>
								{_}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<EdFormControl
					control={control}
					required
					select
					name="sub_type"
					label={'Sub Type'}
				>
					{ClassroomSubTypes.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_}`} value={_}>
								{_}
							</MenuItem>
						);
					})}
				</EdFormControl>

				<EdFormControl
					control={control}
					required
					select
					name="language"
					label={'Language'}
				>
					{ClassroomLanguages.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_}`} value={_}>
								{_}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<EdFormControl control={control} required name="code" label={'Code'} />
			</SectionLayout>
			<Divider />
			<SectionLayout>
				<EdFormControl
					control={control}
					required
					select
					name="payment_methods_allowed"
					label={'Payment Methods'}
				>
					{PaymentMethods.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_.value}`} value={_.value}>
								{_.title}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<EdFormControl
					control={control}
					required
					select
					name="status"
					label={'Status'}
				>
					{ClassroomStatuses.map((_, index) => {
						return (
							<MenuItem key={`${index}-${_}`} value={_}>
								{_}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<SectionLayout gridTemplateColumns={'repeat(1,1fr)'}>
					<EdFormControl
						control={control}
						switch
						name="active"
						label={'Active'}
					/>
					<EdFormControl
						control={control}
						switch
						name="accessible"
						label={'Accessible'}
					/>
					<EdFormControl
						control={control}
						switch
						name="has_admission"
						label={'Has Admission'}
					/>
					<EdFormControl
						control={control}
						switch
						name="auto_accept_admission"
						label="Auto approve requests"
					/>
					<EdFormControl
						control={control}
						switch
						name="admission_status"
						label={'Admission Status'}
					/>
				</SectionLayout>
			</SectionLayout>
			<Divider />
			<SectionLayout>
				<EdUploadFile
					required
					folder="classrooms"
					uploadLabel="UPLOAD"
					uploadIcon={<Icon>add</Icon>}
					control={control}
					name="thumbnail"
					label="Cover"
					optimize={false}
				/>
				<EdFormControl
					control={control}
					width="4.75rem"
					type="number"
					inputMode="numeric"
					name="weight"
					label={'Weight'}
				/>
			</SectionLayout>
			<Divider label="Educational Info." />
			<SectionLayout>
				<EdFormControl
					control={control}
					required
					select
					name="educationTypes"
					multiple
					width="17rem"
					renderValues={(value) => {
						return (
							<div>
								{watchTypes &&
									watchTypes.map((id) => {
										const name = educational_type.find(
											(_) => _.value === id
										)?.name;
										return (
											<SelectChip
												onDelete={() => onDeleteValue('educationTypes', id)}
												label={name}
											/>
										);
									})}
							</div>
						);
					}}
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<ChipFlexLayout>
									{watchTypes &&
										watchTypes.map((id) => {
											const name = educational_type.find(
												(_) => _.value === id
											)?.name;
											return <>{name},</>;
										})}
								</ChipFlexLayout>
							);
						},
					}}
					label={'Type'}
				>
					{educational_type.map((_, index) => {
						return (
							<MenuItem value={_.value}>
								<Checkbox checked={watchTypes?.includes(_.value)} />
								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>

				<EdFormControl
					control={control}
					required
					disabled={isChildrenDisabled}
					select
					multiple
					width="17rem"
					renderValues={(value) => {
						return (
							<div>
								{watchYears &&
									watchYears.map((id) => {
										const name = years.find((_) => _.id === id)?.name;
										return (
											<SelectChip
												onDelete={() => onDeleteValue('educationYears', id)}
												label={name}
											/>
										);
									})}
							</div>
						);
					}}
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<ChipFlexLayout>
									{watchYears &&
										watchYears.map((i, index) => {
											const name = years.find((_) => _.id === i)?.name;
											return <>{name},</>;
										})}
								</ChipFlexLayout>
							);
						},
					}}
					name="educationYears"
					label={'Year'}
				>
					{years.map((_, index) => {
						return (
							<MenuItem key={`ed-year-${_.id}`} value={_.id}>
								<Checkbox
									checked={
										_.id !== undefined &&
										watchYears &&
										watchYears.includes(_.id)
									}
								/>

								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>

				<EdFormControl
					control={control}
					required
					disabled={isChildrenDisabled}
					select
					multiple
					width="17rem"
					renderValues={(selected) => {
						return (
							<div>
								{watchLanguages &&
									watchLanguages.map((i, index) => {
										const name = languages.find((_) => _.id === i)?.name;
										return (
											<>
												<SelectChip
													onDelete={() =>
														onDeleteValue('educationLanguages', i)
													}
													label={name}
												/>
											</>
										);
									})}
							</div>
						);
					}}
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<ChipFlexLayout>
									{watchLanguages &&
										watchLanguages.map((i, index) => {
											const name = languages.find((_) => _.id === i)?.name;
											return <>{name},</>;
										})}
								</ChipFlexLayout>
							);
						},
					}}
					name="educationLanguages"
					label={'Language'}
				>
					{languages.map((_, index) => {
						return (
							<MenuItem key={`ed-lng-${_.id}`} value={_.id}>
								<Checkbox
									checked={
										_.id !== undefined &&
										watchLanguages &&
										watchLanguages.includes(_.id)
									}
								/>
								{_.name}
							</MenuItem>
						);
					})}
				</EdFormControl>

				{sections && sections.length > 0 && (
					<EdFormControl
						width="17rem"
						control={control}
						required
						disabled={isChildrenDisabled}
						select
						multiple
						renderValues={(selected) => {
							return (
								<div>
									{watchSections &&
										watchSections.map((i, index) => {
											const name = sections.find((_) => _.id === i)?.name;
											return (
												<>
													<SelectChip
														onDelete={() =>
															onDeleteValue('educationSections', i as number)
														}
														label={name}
													/>
												</>
											);
										})}
								</div>
							);
						}}
						SelectProps={{
							multiple: true,
							renderValue: (selected) => {
								return (
									<ChipFlexLayout>
										{watchSections &&
											watchSections.map((i, index) => {
												const name = sections.find((_) => _.id === i)?.name;
												return <>{name},</>;
											})}
									</ChipFlexLayout>
								);
							},
						}}
						name="educationSections"
						label={'Section'}
					>
						{sections.map((_, index) => {
							return (
								<MenuItem key={`ed-lng-${_.id}`} value={_.id}>
									<Checkbox
										checked={
											_.id !== undefined &&
											watchSections &&
											watchSections.includes(_.id)
										}
									/>
									{_.name}
								</MenuItem>
							);
						})}
					</EdFormControl>
				)}
			</SectionLayout>
			<ActiosnContainer>
				<Button
					disabled={!isDirty}
					variant="contained"
					color="warning"
					onClick={() => history.push('/dashboard/classrooms')}
				>
					Cancel
				</Button>
				<Spacer mx={'.5rem'} />
				<Button
					onClick={() => {
						if (hasAdmissionWatch) {
							onNext(getValues() as ClassroomPayload);
						} else {
							onSave(getValues());
						}
					}}
					disabled={!isDirty || !isValid}
					variant="contained"
				>
					{hasAdmissionWatch ? 'Next' : 'Save'}
				</Button>
			</ActiosnContainer>
			<DevTool control={control} />
		</>
	);
};
const UploadWrapper = styled.div`
	display: grid;
	grid-template-columns: 70% 30%;
	grid-gap: 1rem;
`;
export const SectionLayout = styled(GridLayout)`
	/* margin-right: 5.5rem; */
	/* margin-right: 2.5rem; */
	grid-gap: 2rem;
`;
export const ActiosnContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 8.313rem;
`;

export default AddClassroomBasicInfo;
