import { useTypedSelector } from '@Features/store';
import { assistantsGetSingle } from '@Pages/Assistants/Slices/Assistants.Slices';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditAssistantInfo from './EditAssistantInfo';

const EditAssistant = () => {
	const { id: uuid } = useParams<{ id: string }>();
	const {
		currentEntity,
		loaders: { getSingle },
	} = useTypedSelector((state) => state.Assistants);

	const dispatch = useDispatch();
	useEffect(() => {
		if (uuid) {
			dispatch(assistantsGetSingle({ id: uuid }));
		}
	}, []);
	return (
		<>{currentEntity && getSingle === 'fulfilled' && <EditAssistantInfo />}</>
	);
};

export default EditAssistant;
