import { Box, FlexLayout } from '@eduact/ed-system';
import { DateFormatter } from '@eduact/utils';
import {
	Button,
	Dialog,
	DialogProps,
	IconButton,
	Modal,
	ModalProps,
	RadioGroup,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import moment from 'moment';
import { Spacer } from '@Styled/utilities';
import { Divider } from '@mui/material';
import {
	Controller,
	FormProvider,
	useFieldArray,
	useForm,
} from 'react-hook-form';
import { array, number, object, ref, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DevTool } from '@hookform/devtools';
import {
	AssignmentSubmission,
	AssignmentSubmissionPayload,
	SubmitAttemptQuestionPayload,
} from '../../Types/Submissions.types';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { FormControlLabel } from '@mui/material';
import { Radio } from '@mui/material';
import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import AttachmentContent from './AttachmentContent';
import { AssignmentSubmissionRequester } from '../../Services/Assignments.requester';
import TinyMceControl from '@Components/UI/TinyMceControl/TinyMceControl';

type Props = { children?: any; submission: AssignmentSubmission } & Omit<
	DialogProps,
	'children'
>;

const schema = object({
	// answers: array(AnswerSchema),
	submission_id: number().required(),
	score: number().required().max(ref('overall')),
	overall: number(),
	status: string().required(),
	feedback: string(),
});
const SubmissionModal: React.VoidFunctionComponent<Props> = ({
	open,
	children,
	submission,
	...props
}) => {
	const { control, formState, handleSubmit } =
		useForm<AssignmentSubmissionPayload>({
			resolver: yupResolver(schema),
			defaultValues: {
				submission_id: submission.id,
				score: submission.score ?? null,
				status: submission.status ?? null,
				overall: submission.assignment.overall_score,
				feedback: submission.feedback,
			},
			// mode: 'all',
		});

	const { isValid , isDirty , isSubmitting } = formState;

	const handleSubmitAttempt = async (data: AssignmentSubmissionPayload) => {
		try {
		
			await AssignmentSubmissionRequester.instance.reviewAssignment(data);
			props.onClose?.({}, 'backdropClick');
		} catch (error) {}
	};
	const [questionExpanded, setQuestionExpanded] = useState(true);
	return (
		<Dialog
			open={open}
			{...props}
			fullWidth
			maxWidth="lg"
			PaperProps={{ sx: { minHeight: '90vh' } }}
		>
			<DevTool control={control} />
			<DialogWrapper>
				<SubmissionContainer>
					<FlexLayout mb={'1.25rem'} justifyContent={'space-between'}>
						<FlexLayout flexDirection={'column'}>
							<Typography fontWeight={600}>
								{submission.user.username}
							</Typography>
							<Spacer mb="1.875rem" />
							<Typography color={'GrayText'}>
								{submission.user.first_name} {submission.user.first_name}
							</Typography>
						</FlexLayout>
						<FlexLayout flexDirection={'column'}>
							<Typography fontWeight={600}>
								{submission.assignment.unit.course.name}
							</Typography>
							<Spacer mb="1.875rem" />
							<Typography color={'GrayText'}>
								{submission.assignment.unit.name}
							</Typography>
						</FlexLayout>
						<FlexLayout flexDirection={'column'}>
							<Typography color={'GrayText'}>
								{moment(submission.updated_at).format('DD/MM/Y, hh:mm A')}
							</Typography>
							<Spacer mb="1.875rem" />
							<Typography color={'GrayText'}>
								{/* {moment(submissionAttempt.created_at).format('DD/MM/Y hh:mm A')} */}
							</Typography>
						</FlexLayout>
					</FlexLayout>
					<Divider />
					<Spacer mb="2.813rem" />
					<FlexLayout flex={1} width="100%">
						<QuestionContainer opened={questionExpanded}>
							<FlexLayout justifyContent={'space-between'}>
								{questionExpanded && (
									<Typography fontWeight={600} fontSize={18}>
										Assignment Questions
									</Typography>
								)}
								<IconButton
									onClick={() => setQuestionExpanded((prev) => !prev)}
								>
									<EdIcon>arrow_back_ios_new</EdIcon>
								</IconButton>
							</FlexLayout>
							<Spacer mb={'2.813rem'} />
							{questionExpanded && (
								<>
									{submission.assignment.question_type === 'attachment' && (
										<iframe
											width={'100%'}
											height="100%"
											src={submission.assignment.question}
										></iframe>
									)}
									{submission.assignment.question_type === 'text' && (
										<div
											dangerouslySetInnerHTML={{
												__html: submission.assignment.question,
											}}
										></div>
									)}
								</>
							)}
						</QuestionContainer>
						<ReviewContainer>
							<FlexLayout justifyContent={'space-between'}>
								<Controller
									control={control}
									name="status"
									render={({ field }) => {
										return (
											<RadioGroup
												row
												aria-labelledby="demo-radio-buttons-group-label"
												{...field}
											>
												<FormControlLabel
													value="passed"
													control={<Radio color="secondary" />}
													label="Passed"
												/>
												<FormControlLabel
													value="failed"
													control={<Radio color="secondary" />}
													label="Failed"
												/>
											</RadioGroup>
										);
									}}
								/>
								<FlexLayout alignItems={'flex-end'}>
									<Typography fontWeight={'500'} color={'GrayText'}>
										Points
									</Typography>
									<Spacer mx={'0.5rem'} />
									<Controller
										control={control}
										name={`score`}
										render={({
											field: { ref, ...others },
											fieldState: { error },
										}) => {
											return (
												<ResultInput
													helperText={error?.message}
													error={!!error}
													{...others}
												/>
											);
										}}
									/>

									<Typography fontWeight={700}>
										/{submission.assignment.overall_score}
									</Typography>
								</FlexLayout>
							</FlexLayout>
							<Spacer mb="3.125rem" />
							<Typography>Submitted answer</Typography>
							{submission.content_type === 'text' && (
								<div>{submission.content}</div>
							)}
							{submission.content_type === 'attachment' && (
								<AttachmentContent answer={submission.content} />
							)}
							<Spacer mb={'1rem'} />
							<Controller
								control={control}
								name="feedback"
								render={({ field: { value, onChange, onBlur } }) => (
									<TinyMceControl
										min_height={0}
										max_height={200}
										value={value}
										onEditorChange={onChange}
										// onChange={onChange}
										onBlur={onBlur}
									/>
								)}
							/>
						</ReviewContainer>
					</FlexLayout>
				</SubmissionContainer>

				<Footer>
					<Button
						onClick={handleSubmit(handleSubmitAttempt)}
						disabled={!isValid || !isDirty || isSubmitting}
						variant="contained"
					>
						SUBMIT
					</Button>
				</Footer>
			</DialogWrapper>
		</Dialog>
	);
};

export default SubmissionModal;

const DialogWrapper = styled.div`
	min-height: 58.75rem;
	/* min-width: 60vw; */
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	height: 100%;
	/* background: yellow; */
	width: 100%;
	min-height: inherit;
`;
const SubmissionContainer = styled.div`
	padding: 0 2.5rem;
	flex: 1;
	display: flex;
	flex-direction: column;
	padding-top: 3.75rem;
`;

const Footer = styled.div`
	background: #f5f5f5;
	position: sticky;
	bottom: 0;
	box-shadow: 0px -3px 6px #00000029;
	display: grid;
	place-content: center;
	padding: 1.625rem;
`;
const QuestionContainer = styled.div<{ opened: boolean }>`
	width: ${(props) => (props.opened ? '50%' : '12px')};
	/* background: red; */
	transition: all ease-in-out 200ms;
	span {
		transform: ${(props) => (!props.opened ? 'rotate(180deg)' : '')};
		transform-origin: center;
		transition: all ease-in-out 300ms;
	}
`;
const ReviewContainer = styled(Box)`
	flex: 1;
	padding: 2rem;
	/* background: blue; */
`;

const ResultInput = styled(EdNumericInput)`
	.MuiOutlinedInput-input {
		width: 27px;
		height: 27px;
		padding: 0 0.5rem;
		/* box-sizing: border-box; */
	}
`;
