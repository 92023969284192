import React, { useState, useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from '@Providers/useSnackbar';
import { Button } from '@mui/material';
import { GridLayout, FlexLayout } from '@Styled/utilities';
import {
	ClassroomCourse,
	CourseWithPrerequisites,
} from '@Services/classrooms/classrooms.res.types';
import { useTypedSelector } from '@Features/store';
import { ROLES_PATHS_SCHEMA } from './Schema/RolesPaths.schema';
import { useImmer } from 'use-immer';
import { getSingleCourseRequest } from '@Services/classrooms/classrooms.req';
import { useHistory } from 'react-router-dom';
import { getAllCourses } from '@Features/classrooms/classroomSlice';
import useQueryURL from '@Hooks/useQueryUrl';
import { useDispatch } from 'react-redux';
import { unitsGetAll } from '../CourseContent/Pages/Contents/Slices/Units.slice';
import { RolesPathsRequester } from './Services/RolesPaths.requester';
import PrerequisitesCourse from './Components/PrerequisitesCourse';
import CourseCompletionSelect from './Components/CourseCompletionSelect';
import UnitOrderSelect from './Components/UnitOrderSelect';
import UnitSelect from './Components/UnitSelect';
import ProgressPercentage from './Components/ProgressPercentage';
import { useFormUpdater } from '@Hooks/Forms/useFormUpdater';
import PrerequisiteCriteria from './Components/PrerequisiteCriteria';
import { MultiSelectOptions, RolesPathsTypes } from './types/RolesPaths.types';

const RolesPaths = () => {
	const { displaySnackbar } = useSnackbar();
	const history = useHistory();
	const { selectedQuery } = useQueryURL();
	const dispatch = useDispatch();
	const { currentCourse, currentClassroom } = useTypedSelector(
		(state) => state.classroom
	);
	const [prerequisitesPaths, setPrerequisitesPaths] = useImmer<
		Array<MultiSelectOptions>
	>([]);
	const [selectedCourse, setSelectedCourse] = useState<
		ClassroomCourse & CourseWithPrerequisites
	>();

	const { perPage } = useTypedSelector((state) => state.classroom);

	const defaultValues: RolesPathsTypes = React.useMemo(
		() => ({
			units:
				selectedCourse?.units.length === 0
					? []
					: (selectedCourse?.units
							?.filter((_) => _.id !== undefined)
							.filter((_) => _.progress_included === true)
							.map((_) => _.id) as number[]),
			progress_criteria: selectedCourse?.progress_criteria,
			progress_percentage: selectedCourse?.progress_percentage,
			sequential_units: selectedCourse?.sequential_units,
			course_id: selectedCourse?.id,
			prerequisite_criteria: selectedCourse?.prerequisite_criteria,
			prerequisites: selectedCourse?.prerequisites_paths?.map(
				(_) => _
			) as any[],
		}),
		[selectedCourse]
	);
	const {
		formState,
		getValues,
		reset,
		register,
		resetField,
		control,
		watch,
		setValue,
		...formMethods
	} = useForm<RolesPathsTypes>({
		resolver: yupResolver(ROLES_PATHS_SCHEMA),
		mode: 'all',
		defaultValues,
	});

	const watchProgress = watch('progress_criteria');
	const watchUnits = watch('units', []);
	const progressPerc = watch('progress_percentage');

	const [updater] = useFormUpdater({
		data: defaultValues,
		setValue,
		keys: [
			'progress_criteria',
			'progress_percentage',
			'sequential_units',
			'units',
			'prerequisite_criteria',
			'prerequisites',
		],
	});

	useEffect(() => {
		updater();
	}, [defaultValues]);

	const onPageRequestUpdate = useCallback(() => {
		dispatch(
			getAllCourses({
				page: 1,
				perPage: perPage,
				filters: [],
				query: selectedQuery,
				sortBy: null,
				classroom: currentClassroom?.label as string,
			})
		);
	}, [currentClassroom?.label]);

	useEffect(() => {
		onPageRequestUpdate();
	}, [currentClassroom?.label]);

	useEffect(() => {
		if (currentCourse) {
			dispatch(unitsGetAll(currentCourse.id));
		}
	}, [currentCourse]);

	const course_id = currentCourse?.id as number | null;

	const onSave = async (form: RolesPathsTypes) => {
		if (watchProgress === 'subset' && watchUnits?.length === 0) {
			displaySnackbar('error', 'you must have atleast one unit');
		} else if (watchProgress === 'percentage' && progressPerc === 0) {
			displaySnackbar('error', 'you must put percentage value');
		} else {
			try {
				const prerequisitesArray: any = [];
				for (let [index, path] of Object.entries(prerequisitesPaths)) {
					for (const value of Object.values(
						Object.values(path).filter((value) => value.checked)
					)) {
						prerequisitesArray.push({
							path: Number(index) + 1,
							prerequisite: value.value,
						});
					}
				}
				const { data } = await RolesPathsRequester.getInstance().update({
					...form,
					prerequisites: prerequisitesArray,
					course_id: currentCourse?.id,
				});
				displaySnackbar('success', data?.message);
			} catch (e) {
				const msg = e?.data.message;
				displaySnackbar('error', msg);
			}
		}
	};

	const fetchCourse = async () => {
		try {
			if (currentCourse?.id) {
				const {
					data: { data },
				} = await getSingleCourseRequest(currentCourse?.id.toString());
				setSelectedCourse(data);
			}
		} catch (e) {
			displaySnackbar(
				'error',
				e.response.data.message || 'error in fetching single course'
			);
		}
	};

	useEffect(() => {
		fetchCourse();
	}, [currentCourse?.id]);

	return (
		<>
			{selectedCourse && (
				<FormProvider
					control={control}
					setValue={setValue}
					getValues={getValues}
					watch={watch}
					formState={formState}
					reset={reset}
					register={register}
					resetField={resetField}
					{...formMethods}
				>
					<form onSubmit={formMethods.handleSubmit(onSave)}>
						<GridLayout gridGap="2.813rem" gridTemplateColumns="repeat(1,1fr)">
							<FlexLayout
								justifyContent="space-between"
								alignItems="center"
								width="60%"
							>
								<label>Units order</label> <UnitOrderSelect />
							</FlexLayout>
							<FlexLayout
								justifyContent="start"
								alignItems="start"
								gridGap="2rem"
							>
								<FlexLayout
									justifyContent="space-between"
									alignItems="center"
									width="60%"
								>
									<label>Course completion</label> <CourseCompletionSelect />
								</FlexLayout>
								<div>
									{watchProgress === 'subset' ? (
										<UnitSelect selectedCourse={selectedCourse} />
									) : null}
									{watchProgress === 'percentage' ? (
										<ProgressPercentage />
									) : null}
								</div>
							</FlexLayout>
							<FlexLayout
								justifyContent="space-between"
								alignItems="center"
								width="60%"
							>
								<label>Prerequisite Criteria</label> <PrerequisiteCriteria />
							</FlexLayout>
							<PrerequisitesCourse
								name="prerequisites"
								control={control}
								classroom_id={currentClassroom?.id}
								course_id={course_id}
								prerequisitesPaths={prerequisitesPaths}
								setPrerequisitesPaths={setPrerequisitesPaths}
								selectedCourse={selectedCourse}
								setSelectedCourse={setSelectedCourse}
							/>
							<FlexLayout justifyContent="end" gridGap="1rem" marginTop="4rem">
								<Button
									variant="contained"
									color="warning"
									disabled={!formState.isDirty}
									onClick={() => {
										history.goBack();
									}}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									disabled={!formState.isDirty}
									onClick={() => {
										const data = getValues();
										onSave(data);
									}}
								>
									save
								</Button>
							</FlexLayout>
						</GridLayout>
					</form>
				</FormProvider>
			)}
		</>
	);
};
export default RolesPaths;
