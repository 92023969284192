import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import { useTypedSelector } from '@Features/store';
import { RouteProvider } from '@Providers/Routes/RoutesProvider';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import { submissionsGetAll } from '../Slices/Submissions.slice';
import { Submissions } from '../Types/Submissions.types';
import AttemptModal from './AttemptModal';
import TableDropdowns from './TableDropdowns/TableDropdowns';
import TestCard from './TestCard';

const TestsTable = () => {
	const dispatch = useDispatch();
	const { fetchAll, filters, meta } = useTypedSelector(
		(state) => state.Submissions
	);
	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const [reload, setReload] = useState<boolean | undefined>();
	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (currentClassroom?.id) {
				dispatch(
					submissionsGetAll({
						page: activePage,
						filters: filters,
						perPage: perPage ?? 10,
						query: query,
						sortBy: sortBy,
						from: startDate,
						to: endDate,
						classroom_id: currentClassroom?.id,
					})
				);
				// setReload(false);
			}
		},
		[currentClassroom?.id, reload]
	);
	const { path, url } = useRouteMatch();
	const [isOpen, setIsOpen] = useState(false);
	const [currentAttempt, setCurrentAttempt] = useState<
		Submissions | undefined
	>();

	const history = useHistory();

	const handleModalClose = () => {
		setIsOpen(false);
		setCurrentAttempt(undefined);
		history.replace(`${path}`);
	};

	return (
		<RouteProvider path={path}>
			<Container>
				<Header>Tests</Header>
				<Switch>
					<Route path={`${path}`} exact>
						<Redirect to={`${path}/table`} />
					</Route>
					<Route
						path={`${path}/table`}
						exact
						render={() => {
							return (
								<>
									<TableDropdowns />
									<MainTable
										total={meta?.total ?? 0}
										dateFilter={false}
										pageNum={meta?.current_page}
										tableFilters={filters}
										tableHeads={[]}
										totalPages={meta?.last_page ?? 0}
										RowsperPage={Number(meta?.per_page)}
										$hasActions
										onPageChangeRequest={onPageChangeRequest}
										renderItems={() => {
											return fetchAll?.map((test, index) => {
												return (
													<MainTableRow key={index}>
														<TestCard
															test={test}
															onClick={(test) => {
																setIsOpen(true);
																setCurrentAttempt(test);
																history.replace(`${path}/attempt`);
															}}
														/>
													</MainTableRow>
												);
											});
										}}
									/>
								</>
							);
						}}
					/>
					<Route
						path={`${path}/attempt`}
						exact
						render={() => {
							return (
								<>
									{currentAttempt && (
										<AttemptModal
											submissionAttempt={currentAttempt}
											open={isOpen}
											onClose={handleModalClose}
										/>
									)}
								</>
							);
						}}
					></Route>
				</Switch>
			</Container>
		</RouteProvider>
	);
};

const Header = styled.p`
	font-size: 1.125rem;
	color: #6c63ff;
	margin-bottom: 1.125rem;
	padding: 0rem 1rem;
`;
const Container = styled.div`
	thead {
		display: none;
	}
	tr {
		border: none;
		box-shadow: unset;
	}
	div:has(table) {
		box-shadow: none !important;
		position: relative;
	}
	table {
		margin-top: 2rem;
	}
`;

export default TestsTable;
