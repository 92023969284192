import ClassRooms from '@Pages/ClassRooms';
import Students from '@Pages/Students';
import SingleClassroom from '@Pages/ClassRooms/Pages/SingleClassroom/SingleClassroom';
import ClassRoomSubscriptions from '@Pages/ClassRooms/Pages/SingleClassroom/Components/ClassRoomSubscriptions';
import AddClassroom from '@Pages/ClassRooms/Pages/AddClassroom/AddClassroom';
import EditClassroom from '@Pages/ClassRooms/Pages/EditClassroom/EditClassroom';
import CourseForm from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/CourseForm';
import Curriculum from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Curriculum/Curriculum';
import { SingleCourseRoute } from '@Pages/ClassRooms/Pages/SingleCourse/Constants/routes';
import SequenceManagement from '@Pages/ClassRooms/Pages/SingleClassroom/Components/SequenceManagement/SequenceManagement';
import Info from '@Pages/Students/StudentTabs/Info/Info';
import ClassRoomTab from '@Pages/Students/StudentTabs/ClassRooms/ClassRoomTab';
import CoursesTab from '@Pages/Students/StudentTabs/Courses/Courses';
import Invoices from '@Pages/Students/StudentTabs/Invoices';
import SingleStudentInvoice from '@Pages/Students/StudentTabs/Invoices/Components/SingleStudentInvoice';
import ScratchCodesTable from '@Pages/Students/StudentTabs/ScratchCards/ScratchCodesTable';
import { ImportsRoute } from '@Pages/Imports/Constants/routes';
import React from 'react';
import Admissions from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Admissons';
import QuestionsAndAnswers from '@Pages/ClassRooms/Pages/SingleClassroom/Components/QuestionsAndAnswers';
import { ScratchCardsRoute } from '@Pages/ScratchCards/Constants/routes';
import AddAssistant from '@Pages/Assistants/Pages/AddAssistant/AddAssistant';
import Assistants from '@Pages/Assistants/Assistants';
import EditAssistant from '@Pages/Assistants/Pages/EditAssistant/EditAssistant';
import SingleTestTable from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseReports/Components/Tests/SingleTestTable';
import Tests from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseReports/Components/Tests/Tests';
import ClassroomAnalytics from '@Pages/ClassRooms/Pages/SingleClassroom/Components/ClassroomAnalytics';
import Submissions from '@Pages/ClassRooms/Pages/SingleClassroom/Components/Submissions';
import StudentClassroomReport from '@Pages/Students/StudentTabs/ClassRooms/Pages/StudentClassroomReport';
import Notes from '@Pages/Assistants copy/Notes';
import AddNote from '@Pages/Assistants copy/Pages/AddNote/AddNote';
import EditNote from '@Pages/Assistants copy/Pages/EditNote/EditNote';
import { GroupRouteGroup } from '@Pages/ClassRooms/Pages/Groups/constants/groups.routes';

export type ExtraPropsIdentifier = 'id' | 'label';
export type BreadcrumbRouteHash =
	| 'classroom'
	| 'course'
	| 'role'
	| 'category'
	| 'newsfeed'
	| 'admins'
	| 'instructor'
	| 'students'
	| 'assistants'
	| 'info';
export type ExtraProps = {
	[key in ExtraPropsIdentifier]?: string;
};
export type SingleRouteDisplay = {
	title: string;
	route: string;
	routePrefix?: ExtraPropsIdentifier;
};
export type SingleRoute = SingleRouteDisplay & {
	code: string;
	forceNavigate?: boolean;
	component: JSX.Element | React.ReactNode;
	offMenu?: boolean;
	isExact?: boolean;
	icon?: string;
};
export type HashedSingleRoute = SingleRoute & { hash: string };
export type RouteGroup = SingleRoute & {
	routes: Array<SingleRoute | RouteGroup | HashedSingleRoute>;
	isRouted?: boolean;
	isParent?: boolean;
	customRouting?: boolean;
	overviewRoute?: string;
};
export type RouteSegment = {
	title: string;
	routeGroups: RouteGroup[];
};

export const DASHBOARD_BASE_ROUTE = '/dashboard';

export const AddClassroomRouteGroup: SingleRoute = {
	code: '',
	route: 'create/classroom',
	title: 'Create',
	component: <AddClassroom />,
	offMenu: true,
	isExact: true,
	icon: '',
};

export const EditClassroomRouteGroup: SingleRoute = {
	code: '',
	component: <EditClassroom />,
	route: 'edit',
	offMenu: true,
	title: '',
};
export const AddCourseRouteGroup: SingleRoute = {
	code: '',
	component: <CourseForm />,
	route: 'create/course',
	offMenu: true,
	title: '',
};

export const SingleClassroomRoute: RouteGroup = {
	code: '',
	icon: '',
	route: 'classroom/:label',
	isRouted: true,
	offMenu: true,
	isParent: true,
	component: <SingleClassroom />,
	title: 'Single Classroom',
	customRouting: true,
	routePrefix: 'label',
	routes: [
		AddCourseRouteGroup,
		SingleCourseRoute,
		EditClassroomRouteGroup,
		{
			title: 'Curriculum',
			code: 'course_content',
			component: <Curriculum />,
			route: 'curriculum',
		},
		{
			code: 'course_content',
			component: <SequenceManagement />,
			route: 'sequence',
			title: 'sequence',
		} as SingleRoute,
		{
			code: 'classroom_tab',
			component: <ClassRoomSubscriptions />,
			route: 'users',
			title: 'Users',
		} as SingleRoute,
		// {
		// 	code: 'admission',
		// 	component: <Admissions />,
		// 	route: 'admissions',
		// 	title: 'Admissions',
		// } as SingleRoute,
		{
			code: 'discussions',
			component: <QuestionsAndAnswers />,
			route: 'questions-and-answers',
			title: 'Q & A',
		} as SingleRoute,
		{
			code: 'v-anlt-cl',
			component: <ClassroomAnalytics />,
			route: 'analytics',
			title: 'Analytics',
		} as SingleRoute,
		{
			code: 'submissions',
			component: <Submissions />,
			route: 'submissions',
			title: 'Submissions',
		} as SingleRoute,
		GroupRouteGroup,
	],
};
const StudentReport: RouteGroup = {
	title: 'report',
	route: ':id',
	component: <StudentClassroomReport />,
	icon: '',
	code: 'exp-st',
	isRouted: true,
	isParent: true,
	routes: [],
};
export const StudentClassroom: RouteGroup = {
	title: 'classrooms',
	route: 'classroom',
	icon: '',
	code: 'students',
	component: <ClassRoomTab />,
	routes: [StudentReport],
	isRouted: true,
	isParent: true,
};

const StudentCourse: SingleRoute = {
	title: 'courses',
	route: 'courses',
	component: <CoursesTab />,
	icon: '',
	code: 'students',
};
const StudentScratchCode: SingleRoute = {
	title: 'Scratch Codes',
	route: 'scratchcard',
	component: <ScratchCodesTable />,
	icon: '',
	code: 'students',
};
export const SingleInvoiceRoute: SingleRoute = {
	code: '',
	component: <SingleStudentInvoice />,
	route: ':id',
	offMenu: true,
	title: '',
};
export const StudentInvoice: RouteGroup = {
	title: 'invoices',
	route: 'invoice',
	component: <Invoices />,
	icon: '',
	code: '',
	isRouted: true,
	isParent: true,
	routes: [SingleInvoiceRoute],
};
export const StudentInfo: RouteGroup = {
	code: '',
	route: ':id',
	title: 'Info',
	overviewRoute: 'info',
	icon: '',
	offMenu: true,
	isParent: true,
	isRouted: true,
	component: <Info />,
	routes: [StudentClassroom, StudentCourse, StudentScratchCode],
};

export const StudentTabs: RouteGroup = {
	title: 'Students',
	icon: 'groups',
	route: 'students',
	code: 'students',
	isRouted: true,
	offMenu: true,
	isParent: true,
	component: <Students />,
	routes: [StudentInfo],
};

export const AddAssistantRoute: SingleRoute = {
	code: '',
	route: 'create/assistant',
	component: <AddAssistant />,
	offMenu: true,
	title: '',
};
export const EditAssistantRoute: SingleRoute = {
	code: '',
	component: <EditAssistant />,
	route: 'edit/assistant/:id',
	offMenu: true,
	isExact: true,
	title: '',
};

export const AssistantsRouteGroup: RouteGroup = {
	code: 'assistants',
	component: <Assistants />,
	route: 'assistants',
	title: 'Assistants',
	icon: 'supervisor_account',
	routes: [AddAssistantRoute, EditAssistantRoute],
	isRouted: true,
	isParent: true,
};

export const AddNoteRoute: SingleRoute = {
	code: '',
	route: 'create/note',
	component: <AddNote />,
	offMenu: true,
	title: '',
};
export const EditNoteRoute: SingleRoute = {
	code: '',
	component: <EditNote />,
	route: 'edit/note/:id',
	offMenu: true,
	isExact: true,
	title: '',
};
export const NotesRouteGroup: RouteGroup = {
	code: 'notes',
	component: <Notes />,
	route: 'notes',
	title: 'Notes',
	icon: 'auto_stories',
	routes: [AddNoteRoute, EditNoteRoute],
	isRouted: true,
	isParent: true,
};

const SingleTestReport: SingleRoute = {
	icon: '',
	code: '',
	route: '/:id',
	title: 'Single Test Report',
	component: <SingleTestTable />,
	offMenu: true,
};
export const TestsReport: RouteGroup = {
	code: '',
	route: '/reports/test',
	title: 'Test',
	icon: '',
	isRouted: true,
	isParent: true,
	component: <Tests />,
	routes: [SingleTestReport],
};

export const routes: Array<RouteSegment> = [
	{
		title: 'Student Management',
		routeGroups: [
			StudentTabs,
			{
				title: 'Classrooms',
				route: 'classrooms',
				code: 'classroom_tab',
				icon: 'auto_stories',
				component: <ClassRooms />,
				isRouted: true,
				isParent: true,
				routes: [SingleClassroomRoute, AddClassroomRouteGroup],
			},
			AssistantsRouteGroup,
			ImportsRoute,
			ScratchCardsRoute,
			NotesRouteGroup,
		],
	},
];
