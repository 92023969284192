import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { Button, IconButton, InputAdornment } from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { TestPayload } from '../../../../Types/Test/Test.types';
import { cloneDeep } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from '@Providers/useSnackbar';
import EdAnimatedIcon from '@Components/UI/Utilities/EdAnimatedIcon/EdAnimatedIcon';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { testsGetSingle, testsUpdate } from '../../Slice/TestSlice';
import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import { TestRequester } from '../../Services/TestRequester/TestRequester';
import styled from 'styled-components';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
const SCHEMA = object({
	name: string(),
});
const TestOverviewHeader = () => {
	const { currentEntity } = useTypedSelector((state) => state.Tests);

	const defaultValues = useMemo(() => {
		return {
			id: currentEntity?.id,
			test: currentEntity,
			unit: currentEntity?.unit,
		} as TestPayload;
	}, []);

	const dispatch = useAppThunkDispatch();
	const { control, handleSubmit, resetField } = useForm<TestPayload>({
		resolver: yupResolver(SCHEMA),
		defaultValues: cloneDeep(defaultValues),
	});

	const { displaySnackbar } = useSnackbar();

	const { fieldState } = useController({
		control,
		name: 'unit.name',
	});

	const onSave = async (form: TestPayload) => {
		try {
			await dispatch(
				testsUpdate({
					id: form.id,
					unit: form.unit,
					test: {
						active: form.test?.active,
						active_end_date: form.test?.active_end_date,
						active_start_date: form.test?.active_start_date,
						allow_movement: form.test?.allow_movement,
						allow_repetition_when: form.test?.allow_repetition_when,
						allowed_trials: form.test?.allowed_trials,
						created_at: form.test?.created_at,
						duration: form.test?.duration,
						end_text: form.test?.end_text,
						gradeSchema: form.test?.gradeSchema,
						gradeScheme: form.test?.gradeScheme,
						id: form.test?.id,
						locked: form.test?.locked,
						message_if_failed: form.test?.message_if_failed,
						message_if_passed: form.test?.message_if_passed,
						model_mode: form.test?.model_mode,
						overall_score: form.test?.overall_score,
						passing_unit: form.test?.passing_unit,
						passing_value: form.test?.passing_value,
						questions: form.test?.questions,
						show_correct_if_failed: form.test?.show_correct_if_failed,
						show_correct_if_passed: form.test?.show_correct_if_passed,
						show_grade: form.test?.show_grade,
						show_score_percentage: form.test?.show_score_percentage,
						show_score_value: form.test?.show_score_value,
						shuffle_answers: form.test?.shuffle_answers,
						shuffle_questions: form.test?.shuffle_questions,
						start_text: form.test?.start_text,
						title: form.test?.title,
						unit_id: form.test?.unit_id,
						updated_at: form.test?.updated_at,
						uuid: form.test?.uuid,
						view_mode: form.test?.view_mode,
					},
				})
			).unwrap();
			resetField('unit.name', { defaultValue: form.unit.name });
			displaySnackbar('success', 'Updated Unit name successfully');
		} catch (error) {
			displaySnackbar('error', error.data.message);
		}
	};

	const handleReset = () => {
		resetField('unit.name', { defaultValue: defaultValues.unit.name });
	};

	const handleActivateTest = async () => {
		try {
			if (!currentEntity?.id) return;
			await TestRequester.instance.activateTest(currentEntity?.id);
			await dispatch(testsGetSingle({ id: currentEntity?.id })).unwrap();
			displaySnackbar('success', 'Test activated successfully');
		} catch (e) {
			const msg = e?.data.message || 'Unable to Active Test ';
			displaySnackbar('error', msg);
		}
	};

	const handleDeactivateTest = async () => {
		try {
			if (!currentEntity?.id) return;
			await TestRequester.instance.deActivateTest(currentEntity?.id);
			await dispatch(testsGetSingle({ id: currentEntity?.id })).unwrap();
			displaySnackbar('success', 'Test Deactivated successfully');
		} catch (e) {
			const msg = e?.response?.data.message || 'Unable to Deactive Test ';
			displaySnackbar('error', msg);
		}
	};

	return (
		<FlexLayout
			mb="2.5rem"
			justifyContent={'space-between'}
			alignItems="center"
		>
			<FlexLayout paddingLeft="2.625rem" alignItems="center">
				<EdFormControl
					disabled={currentEntity.active}
					size="small"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<EdIcon mIconType="Outlined">live_help</EdIcon>
							</InputAdornment>
						),
					}}
					control={control}
					label="Unit Name"
					name="unit.name"
				/>
				{fieldState.isDirty && (
					<>
						<Spacer mx="0.5rem" />
						<IconButton onClick={handleSubmit(onSave)} type="submit">
							<EdAnimatedIcon animation="shake">
								<EdIcon>check_circle_outline</EdIcon>
							</EdAnimatedIcon>
						</IconButton>
						<IconButton color="warning" onClick={handleReset} type="submit">
							<EdAnimatedIcon animation="shake">
								<EdIcon mIconType="Outlined">cancel</EdIcon>
							</EdAnimatedIcon>
						</IconButton>
					</>
				)}
			</FlexLayout>
			<FlexLayout gridGap={'1rem'} alignItems="center">
				{currentEntity.active && (
					<TestActivationStatus background="#e5fbf0">
						<EdIcon mIconType="Outlined">info</EdIcon>
						<span>This test is active now</span>
					</TestActivationStatus>
				)}
				{!currentEntity.active &&
					currentEntity.locked &&
					currentEntity.active_start_date && (
						<TestActivationStatus background="rgb(255, 133, 50)">
							<EdIcon mIconType="Outlined">info</EdIcon>
							<span>
								This test is scheduled on
								<DateTimeFormatter date={currentEntity.active_start_date} />
							</span>
						</TestActivationStatus>
					)}
				{currentEntity.locked && (
					<EdButton
						onClick={handleDeactivateTest}
						variant="contained"
						edcolor="cadet"
					>
						Deactivate
					</EdButton>
				)}
				{!currentEntity.locked && (
					<EdButton
						onClick={handleActivateTest}
						variant="contained"
						color="primary"
					>
						Activate Test
					</EdButton>
				)}
			</FlexLayout>
		</FlexLayout>
	);
};

export default TestOverviewHeader;

const TestActivationStatus = styled(FlexLayout)<{ background: string }>`
	background: ${({ background }) => background};
	color: #0d3c61;
	padding: 0.375rem 1rem;
	grid-gap: 1rem;
	font-weight: 600;
	align-items: center;
	border-radius: 4px;
	font-size: 0.875rem;
`;
