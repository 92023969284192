import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import MainTable from '@Components/MainTable';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import NoResults from '@Components/NoResults/NoResults';
import { useTypedSelector } from '@Features/store';
import { TableCell } from '@mui/material';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { studentsExportData, studentsGetAll } from '../Slice/Students.Slice';

const TABLE_HEADS = [
	{ displayName: 'username', fieldName: 'username' },
	{ displayName: 'firstname', fieldName: 'first_name' },
	{ displayName: 'lastname', fieldName: 'last_name' },
	{ displayName: 'email', fieldName: 'email' },
	{ displayName: 'registered on', fieldName: 'created_at' },
];

type Props = {
	setBar: any;
};
const StudentsTable: React.FC<Props> = ({ setBar }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { setIsLoading } = usePageLoader();
	const [doExport, setDoExport] = useState(false);

	const { fetchAll, loaders, meta, requestStatus, filters, errors } =
		useTypedSelector((state) => state.Students);
	const { displaySnackbar } = useSnackbar();
	const [, setFlag] = useState(false);

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (doExport) {
				dispatch(
					studentsExportData({
						page: activePage,
						perPage: perPage ?? 10,
						sortBy,
						filters,
						query,
						from: startDate,
						to: endDate,
					})
				);
				setFlag(false);
			} else {
				dispatch(
					studentsGetAll({
						page: activePage,
						perPage: perPage ?? 10,
						sortBy,
						filters,
						query,
						from: startDate,
						to: endDate,
					})
				);
			}
		},
		[dispatch, doExport]
	);

	const handleTableItemClick = useCallback(
		(id: string) => () => {
			history.push(`/dashboard/students/${id}`);
		},
		[history]
	);

	useEffect(() => {
		if (loaders.fetchAll === 'fulfilled') setIsLoading(true);
		setBar(true);
		if (loaders.fetchAll === 'rejected') setIsLoading(false);
	}, [loaders.fetchAll, setIsLoading]);

	useEffect(() => {
		if (loaders.export) {
			setIsLoading(true);
		}
		if (loaders.export === undefined) setIsLoading(false);
	}, [loaders.export, errors.export, setIsLoading]);
	useEffect(() => {
		if (requestStatus.export === 'DONE') {
			displaySnackbar('success', 'Exported Successfully');
		}
		if (requestStatus.export === 'FAILED') {
			displaySnackbar('error', 'Can Not Export!');
		}
	}, [displaySnackbar, requestStatus.export]);

	useEffect(() => {
		if (doExport) setDoExport(false);
	}, [doExport]);

	return (
		<>
			<MainTable
				total={meta?.total ?? 0}
				dateFilter
				pageNum={meta?.current_page}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				RowsperPage={Number(meta?.per_page)}
				$hasActions
				onPageChangeRequest={onPageChangeRequest}
				renderItems={() => {
					if (fetchAll.length === 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return fetchAll.map((student) => {
						return (
							<MainTableRow
								key={student.uuid}
								hover
								onClick={handleTableItemClick(student.uuid)}
							>
								<TableCell>{student.username}</TableCell>
								<TableCell>{student.first_name}</TableCell>
								<TableCell>{student.last_name}</TableCell>
								<TableCellStyle>{student.email}</TableCellStyle>
								<TableCell>
									<DateTimeFormatter date={student.created_at} />
								</TableCell>
								<TableCell />
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

const TableCellStyle = styled(TableCell)`
	font-family: Roboto;
	font-size: 1rem;
	padding: 0 1.063rem 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

export default StudentsTable;
