import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
	AddTestModelSchema,
	EditTestModelSchema,
	TestModelPayload,
} from '../../../../Schema/TestModel.schema';
import {
	testModelsGetAll,
	testModelsGetSingle,
} from '../../../../Slice/TestModel/TestModel.slice';
import { testQuestionsGetAll } from '../../../../Slice/TestQuestions/TestQuestionsSlice';
import EditTestModelInfo from './EditTestModelInfo';

const EditTestModel = () => {
	const dispatch = useAppThunkDispatch();
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		if (id) dispatch(testModelsGetSingle({ id: Number(id) }));
	}, [id]);
	const { currentEntity, loaders } = useTypedSelector(
		(state) => state.TestModels
	);

	return (
		<>
			{currentEntity && loaders.getSingle === 'fulfilled' && (
				<EditTestModelInfo />
			)}
		</>
	);
};

export default EditTestModel;
