import React from 'react';
import { SvgIcon } from '@mui/material';
export const AnalyticsFree: React.FC = ({ ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="49"
			height="49"
			viewBox="0 0 49 49"
		>
			<g data-name="Group 1775">
				<g data-name="Group 1722">
					<path
						data-name="Path 6853"
						d="M28.583 18.359a2.045 2.045 0 1 1 2.045 2.041 2.051 2.051 0 0 1-2.045-2.041zm-14.314-2.045h10.224v-4.09H14.269zM42.9 13.247V27.5l-5.77 1.921-3.435 11.431H22.448v-4.09h-4.09v4.09H7.112S2 23.552 2 17.336A11.24 11.24 0 0 1 13.247 6.09h10.224A10.214 10.214 0 0 1 31.65 2a3.063 3.063 0 0 1 3.067 3.067 2.864 2.864 0 0 1-.245 1.186 9.884 9.884 0 0 0-.654 2.347l4.642 4.642zm-4.09 4.09h-2.048l-7.157-7.157a14.749 14.749 0 0 1 .532-3.906 6.167 6.167 0 0 0-4.274 3.906H13.247a7.165 7.165 0 0 0-7.157 7.156c0 3.844 2.495 13.6 4.11 19.426h4.069v-4.09h12.269v4.09h4.11l3.169-10.531 4.989-1.677z"
						transform="translate(-2 6.148)"
						fill="#fff1c4"
					/>
				</g>
				<path data-name="Rectangle 716" fill="none" d="M0 0h49v49H0z" />
				<path
					data-name="Path 6852"
					d="M34.672 15.153 27.516 8a14.749 14.749 0 0 1 .532-3.906A6.167 6.167 0 0 0 23.773 8H11.157A7.165 7.165 0 0 0 4 15.153C4 19 6.495 28.751 8.11 34.578h4.069v-4.09h12.269v4.09h4.11l3.169-10.531 4.989-1.677v-7.217zM22.4 14.13H12.179v-4.09H22.4zm6.134 4.09a2.045 2.045 0 1 1 2.045-2.045 2.051 2.051 0 0 1-2.041 2.045z"
					transform="translate(3.179 4.273)"
					fill="#ffc300"
				/>
				<g data-name="Group 1721">
					<path
						data-name="Path 6853"
						d="M28.583 18.359a2.045 2.045 0 1 1 2.045 2.041 2.051 2.051 0 0 1-2.045-2.041zm-14.314-2.045h10.224v-4.09H14.269zM42.9 13.247V27.5l-5.77 1.921-3.435 11.431H22.448v-4.09h-4.09v4.09H7.112S2 23.552 2 17.336A11.24 11.24 0 0 1 13.247 6.09h10.224A10.214 10.214 0 0 1 31.65 2a3.063 3.063 0 0 1 3.067 3.067 2.864 2.864 0 0 1-.245 1.186 9.884 9.884 0 0 0-.654 2.347l4.642 4.642zm-4.09 4.09h-2.048l-7.157-7.157a14.749 14.749 0 0 1 .532-3.906 6.167 6.167 0 0 0-4.274 3.906H13.247a7.165 7.165 0 0 0-7.157 7.156c0 3.844 2.495 13.6 4.11 19.426h4.069v-4.09h12.269v4.09h4.11l3.169-10.531 4.989-1.677z"
						transform="translate(1.09 2.09)"
						fill="#fad661"
					/>
				</g>
			</g>
		</svg>
	);
};

export const AnalyticsWallet: React.FC = ({ ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="59.024"
			height="49.658"
			viewBox="0 0 59.024 49.658"
		>
			<g data-name="Group 1776">
				<g data-name="Group 1714" transform="translate(-602.434 -184.481)">
					<rect
						data-name="Rectangle 713"
						width="44"
						height="35"
						rx="5"
						transform="translate(604 197)"
						fill="#c8c5ff"
					/>
					<path
						data-name="Path 6848"
						d="M0 0h59.024v49.658H0z"
						transform="translate(602.434 184.481)"
						fill="none"
					/>
					<path
						data-name="Path 6849"
						d="M27.16 30.213c-3.047 0-5.54-1.891-5.54-4.2V13.4c0-2.311 2.493-4.2 5.54-4.2h16.62V4.246H5v30.169h38.781v-4.2z"
						transform="translate(607.556 189.603)"
						fill="#5c52ff"
					/>
					<path
						data-name="Path 6850"
						d="M49.329 11.124V7.168C49.329 4.876 47.012 3 44.181 3H8.148C5.291 3 3 4.876 3 7.168v29.176c0 2.292 2.291 4.168 5.148 4.168h36.033c2.831 0 5.148-1.876 5.148-4.168v-3.876a5.456 5.456 0 0 0 2.574-4.46V15.5a5.293 5.293 0 0 0-2.574-4.38zM46.755 15.5V28H28.738V15.5zM8.148 36.344V7.168h36.033v3.956H28.738c-2.831 0-5.148 2.088-5.148 4.38v12.5c0 2.292 2.316 4.46 5.148 4.46h15.443v3.876z"
						transform="translate(605.507 187.554)"
						fill="#5c52ff"
					/>
					<rect
						data-name="Rectangle 712"
						width="26"
						height="18"
						rx="5"
						transform="translate(628 200)"
						fill="#b6b1ff"
					/>
					<path
						data-name="Path 6851"
						d="M3.036 0A3.036 3.036 0 1 1 0 3.036 3.036 3.036 0 0 1 3.036 0z"
						transform="translate(638.996 206.274)"
						fill="#8780fe"
					/>
				</g>
			</g>
		</svg>
	);
};
export const AnalyticsTotal: React.FC = ({ ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="103.25"
			height="88.25"
			viewBox="0 0 103.25 88.25"
		>
			<g data-name="Group 1777">
				<g data-name="Group 1713">
					<g data-name="Group 1717">
						<g data-name="groups_black_24dp (2)">
							<path
								data-name="Rectangle 2"
								transform="translate(0 3)"
								fill="none"
								d="M0 0h70v67H0z"
							/>
							<path
								data-name="Path 15"
								d="M44.487 31.024a39.337 39.337 0 0 1 15.719 3.336 11.064 11.064 0 0 1 6.524 10.121v6.006H22.243v-5.969A10.986 10.986 0 0 1 28.768 34.4a38.705 38.705 0 0 1 15.719-3.376zm-29.658.927a7.414 7.414 0 1 0-7.414-7.414 7.436 7.436 0 0 0 7.414 7.414zm4.189 4.078a25.885 25.885 0 0 0-4.189-.371 25.767 25.767 0 0 0-10.306 2.15A7.455 7.455 0 0 0 0 44.666v5.82h16.683v-5.968a16.678 16.678 0 0 1 2.335-8.489zM44.487 6a11.122 11.122 0 1 1-11.122 11.122A11.107 11.107 0 0 1 44.487 6z"
								transform="translate(.74 9.34)"
								fill="#9ddafe"
							/>
						</g>
					</g>
					<g data-name="Group 1711">
						<g data-name="groups_black_24dp (2)">
							<path
								data-name="Rectangle 2"
								transform="translate(5)"
								fill="none"
								d="M0 0h70v67H0z"
							/>
							<path
								data-name="Path 15"
								d="M44.487 31.024a39.337 39.337 0 0 1 15.719 3.336 11.064 11.064 0 0 1 6.524 10.121v6.006H22.243v-5.969A10.986 10.986 0 0 1 28.768 34.4a38.705 38.705 0 0 1 15.719-3.376zm-29.658.927a7.414 7.414 0 1 0-7.414-7.414 7.436 7.436 0 0 0 7.414 7.414zm4.189 4.078a25.885 25.885 0 0 0-4.189-.371 25.767 25.767 0 0 0-10.306 2.15A7.455 7.455 0 0 0 0 44.666v5.82h16.683v-5.968a16.678 16.678 0 0 1 2.335-8.489zM44.487 6a11.122 11.122 0 1 1-11.122 11.122A11.107 11.107 0 0 1 44.487 6z"
								transform="translate(5.658 6.061)"
								fill="#009ffe"
							/>
						</g>
					</g>
					<g data-name="Group 1712">
						<path
							data-name="Line 171"
							transform="translate(84 52)"
							stroke="#9ddafe"
							strokeLinecap="round"
							strokeWidth="6.5px"
							fill="none"
							d="M0 0v33"
						/>
						<path
							data-name="Line 172"
							transform="rotate(90 15.574 84.426)"
							stroke="#9ddafe"
							strokeLinecap="round"
							strokeWidth="6.5px"
							fill="none"
							d="M0 0v32.787"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export const AnalyticsInstructor: React.FC = ({ ...props }) => {
	return (
		<svg
			data-name="assignment_ind_black_24dp (1)"
			xmlns="http://www.w3.org/2000/svg"
			width="47.031"
			height="47.03"
			viewBox="0 0 47.031 47.03"
		>
			<path data-name="Path 6854" d="M0 0h47.03v47.03H0z" fill="none" />
			<path
				data-name="Path 6855"
				d="M32.434 5H5v27.434h27.434zM18.717 6.96a5.879 5.879 0 1 1-5.879 5.879 5.9 5.9 0 0 1 5.879-5.879zm11.758 23.515H6.96v-3c0-4.9 7.78-7.015 11.758-7.015s11.758 2.116 11.758 7.015z"
				transform="translate(4.798 4.798)"
				fill="#ff7858"
			/>
			<path
				data-name="Path 6856"
				d="M37.607 6.644a3.885 3.885 0 0 0-1.058-1.058 4.333 4.333 0 0 0-.666-.353 3.945 3.945 0 0 0-1.528-.314h-8.193a5.855 5.855 0 0 0-11.052 0H6.919A3.931 3.931 0 0 0 3 8.838v27.435a3.931 3.931 0 0 0 3.919 3.919h27.435a3.945 3.945 0 0 0 1.528-.314 4.333 4.333 0 0 0 .666-.353 3.885 3.885 0 0 0 1.058-1.058 4.023 4.023 0 0 0 .666-2.195V8.838a4.023 4.023 0 0 0-.666-2.195zM20.636 4.429a1.5 1.5 0 1 1-1.078.49 1.467 1.467 0 0 1 1.078-.49zm13.718 31.844H6.919V8.838h27.435zM20.636 22.556a5.879 5.879 0 1 0-5.879-5.879 5.9 5.9 0 0 0 5.879 5.879zm0 1.724c-3.978 0-11.757 2.12-11.757 7.02v3.018h23.515v-3c0-4.919-7.78-7.035-11.758-7.035z"
				transform="translate(2.879 .96)"
				fill="#ff3100"
			/>
		</svg>
	);
};

export const AnalyticsScratchCards: React.FC = ({ ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48.001"
			height="44"
			viewBox="0 0 48.001 44"
		>
			<g data-name="Group 1719">
				<path
					data-name="Rectangle 714"
					transform="translate(13 5)"
					fill="#fed3b6"
					d="M0 0h32v24H0z"
				/>
				<g data-name="Group 1716">
					<path transform="rotate(-90 22 22)" fill="none" d="M0 0h44v44H0z" />
					<path
						d="M30.211 0H4.316A4.307 4.307 0 0 0 0 4.273v29.91h4.316V4.273h25.895z"
						transform="rotate(-90 21.5 20.5)"
						fill="#ffd4b8"
					/>
					<path
						data-name="Path"
						d="M30.211 0H4.316A4.307 4.307 0 0 0 0 4.273v29.91h4.316V4.273h25.895z"
						transform="rotate(-90 21.5 17.5)"
						fill="#ff6700"
					/>
					<path
						data-name="Path"
						d="M28.053 0H4.316A4.307 4.307 0 0 0 0 4.273v29.91a4.307 4.307 0 0 0 4.316 4.273h23.737a4.307 4.307 0 0 0 4.316-4.273V4.273A4.307 4.307 0 0 0 28.053 0z"
						transform="rotate(-90 21.457 11.911)"
						fill="#ff8532"
					/>
					<path
						data-name="Path"
						d="M23.737 29.91H0V0h23.737z"
						transform="rotate(-90 21.436 7.617)"
						fill="#ff8532"
					/>
					<path
						data-name="Line 165"
						transform="translate(16.5 20.5)"
						stroke="#fdbc91"
						strokeWidth={'3.75px'}
						fill="none"
						d="M0 0h25"
					/>
				</g>
			</g>
		</svg>
	);
};
