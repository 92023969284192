import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginFormData } from '@Pages/Login/login.types';
import { useDispatch } from 'react-redux';
import { login } from '@Features/auth/authSlice';
import { useTypedSelector } from '@Features/store';
import { Label, Input, Alert } from 'reactstrap';
import { CenteredLayout, StyledLoginForm } from './login.styled';
import LogoImg from './Assets/login.svg';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FlexLayout } from '@Styled/utilities';
import { Button } from '@mui/material';
/* schema validation */
const LoginFormSchema = yup.object().shape({
	identifier: yup.string().required(),
	password: yup.string().required(),
});

const Login = () => {
	/* hooks */
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		handleSubmit,
		control,
		register,
		formState: { errors },
	} = useForm<LoginFormData>({
		mode: 'onSubmit',
		resolver: yupResolver(LoginFormSchema),
		defaultValues: {
			identifier: '',
			password: '',
		},
	});



	/* selectors */
	const auth = useTypedSelector((state) => state.auth);
	/* state */
	const [loginError, setLoginError] = useState<string | undefined>('');

	/* handlers */
	const onSubmit = (values: LoginFormData) => {
		dispatch(login(values));
	};

	/* Effects */

	/* handle login request ui */
	useEffect(() => {
		if (auth.loaders.login) {
			setLoginError('');
		}
		if (auth.isAuth) {
			history.push('/dashboard');
		}
		if (auth.loaders.login === undefined) {
			setLoginError(auth.errors.login);
		}
	}, [auth.loaders.login, auth.errors.login]);

	return (
		<CenteredLayout>
			{loginError && <AlertStyle color="danger">{loginError}</AlertStyle>}
			<StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
				<FlexLayout
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					gridGap="1.2rem"
					marginBottom="3.8rem"
					marginTop="1rem"
				>
					<img src={LogoImg} className="mb-2" width={200} alt="Eduact" />
					<Text>Instructor Portal</Text>
				</FlexLayout>
				<FlexLayout
					justifyContent="space-between"
					marginBottom="2rem"
					alignItems="center"
					pl="2rem"
					pr="2rem"
				>
					<LabelStyle for="adminEmail">Email address</LabelStyle>

					{/*<LogInInput*/}
					{/*  placeholder='Enter your email or phone number'*/}
					{/*  name='identifier'*/}
					{/*  innerRef={register('identifier').ref}*/}
					{/*/>*/}

					<Controller
						control={control}
						name="identifier"
						render={({ field }) => (
							<LogInInput
								{...field}
								placeholder="Enter your email or phone number"
							/>
						)}
					/>
				</FlexLayout>
				<FlexLayout justifyContent="space-between" pl="2rem" pr="2rem">
					<LabelStyle for="adminPassword">password</LabelStyle>
					<Controller
						control={control}
						name="password"
						render={({ field }) => (
							<LogInInput
								type="password"
								{...field}
								id="adminPassword"
								// placeholder="Enter password"
							/>
						)}
					/>
				</FlexLayout>
				<FlexLayout justifyContent="flex-end" pr="2.4rem">
					<LogInBtn variant="contained" color="primary" type="submit">
						{auth.loaders.login ? 'Loading....' : 'Login'}
					</LogInBtn>
				</FlexLayout>
			</StyledLoginForm>
		</CenteredLayout>
	);
};

const Text = styled.p`
	font-size: 1.125rem;
	font-weight: normal;
	color: #979797;
`;
const LogInInput = styled(Input)`
	width: 16.25rem;
	height: 2.125rem;
	border: solid 1px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
`;
const LabelStyle = styled(Label)`
	font-size: 1rem;
	font-weight: 500;
	color: #251b33;
`;

const LogInBtn = styled(Button)`
	height: 2.75rem;
	width: 7.375rem;
	margin-top: 3.063rem;
	margin-bottom: 2.188rem;
	border-radius: 24px;
	box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.38);
	&:hover {
		background: #5ac0fc;
	}
`;
const AlertStyle = styled(Alert)`
	color: red;
	font-size: 2rem;
	font-weight: 500;
	margin-bottom: 0.4rem;
`;

export default Login;
