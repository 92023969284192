import { object, number, string, array, boolean, ref, date } from 'yup';

export const GradeSchema = object({
	grade: string().required(),
	from: number().required().max(99),
	to: number().required().moreThan(ref('from')).max(100),
});
export const TestOptionsSchema = object({
	id: number().required(),

	unit: object({
		name: string().required(),
		active: boolean().required(),
		order: number().required(),
	}),
	gradeSchema: array(GradeSchema).optional(),
	test: object({
		duration: number(),
		passing_value: number()
			.nullable(true)
			.transform((o, v) => (v === '' ? null : o)),
		allowed_trials: number().optional().nullable(true),
		start_text: string().optional().nullable(true),
		message_if_passed: string().optional().nullable(true),
		message_if_failed: string().optional().nullable(true),
		passing_unit: string().nullable(true),
		allow_repetition_when: string().nullable(true),
		shuffle_questions: boolean().required().default(false),
		shuffle_answers: boolean().required().default(false),
		show_correct_if_passed: boolean().required().default(false),
		show_correct_if_failed: boolean().required().default(false),
		show_score_percentage: boolean().required().default(false),
		show_score_value: boolean().required().default(false),
		show_grade: boolean().required().default(false),
		allow_movement: boolean().required().default(false),
		active_start_date: date().nullable(true),
		active_end_date: date().nullable(true),
		test_summary: boolean().optional().default(false),
	}),
	allowRepetition: boolean(),
	defineGradeSchema: boolean(),
	showCorrectAnswer: boolean(),
	passOrFailMessage: boolean(),
	showTestPeriod: boolean(),
});
