import { BreadcrumbContext } from '@Components/UI/Layout/Layout';
import { NotesRouteGroup } from '@Constants/routes';
import useInvalidateRouteGroup from '@Hooks/Routes/useInvalidateRouteGroup';
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotesTable from './NotesTable';
import { RouteProvider } from '@Providers/Routes/RoutesProvider';

const Notes = () => {
	const { url, path } = useRouteMatch();
	const { isCurrent } = useInvalidateRouteGroup({
		baseURL: '/dashboard/notes',
		mode: 'include',
		routeGroup: NotesRouteGroup,
	});

	const { removeLink } = useContext(BreadcrumbContext);
	useEffect(() => {
		if (isCurrent) {
			// removeLink?.('notes');
		}
	}, [isCurrent]);

	return (
		<Switch>
			<RouteProvider path={path} >
				{NotesRouteGroup && !Array.isArray(NotesRouteGroup) && (
					<>
						<Route exact path={url}>
							<NotesTable />
						</Route>

						{NotesRouteGroup.routes.map((_) => {
							return (
								<Route strict path={`${path}/${_.route}`}>
									{_.component}
								</Route>
							);
						})}
					</>
				)}
			</RouteProvider>
		</Switch>
	);
};

export default Notes;
