import React, { useState } from 'react';
import SortingIcons from '@Components/MainTable/SortingIcons';
import Pagination from '@Components/Pagination';
import addQueryString from '@Utils/addQueryString';
import debounce from 'debounce';
import qs from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFilterBar, { StyledChip } from './TableFilterBar';
import smallLoader from '@Assets/img/smallLoader.svg';
import DatePicker from './DatePicker';
import { TableHeader } from '@Features/generics/generics.type';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TableFiltersButton from './TableFiltersButton';
import Typography from '@mui/material/Typography';
import useSelectedFilters from '@Hooks/useSelectedFiltersUrl';
import styled from 'styled-components';
import { chipFilterName } from '@Utils/chipFilterName';
import {
	Search,
	SearchIconWrapper,
	StyledInputBase,
	StyledMainTableRow,
	StyledTableBody,
} from './MainTable.styled';
import { TableRowProps } from '@mui/material';
import { TextAlignProps } from 'styled-system';
import { MainTableProps } from './MainTable.types';
interface Props {
	tableHeads: Array<string> | Array<TableHeader>;
	tableFilters: Array<any>;
	totalPages: number;
	total?: number;
	RowsperPage?: number;
	pageNum?: number;
	renderCustomHeads?: boolean;
	customHeads?: Array<string>;
	dateFilter?: boolean;
	$hasActions?: boolean;
	renderItems(): any;

	onPageChangeRequest?(
		activePage: number,
		sortBy: any,
		filters: Array<any>,
		query: string,
		startDate: string,
		endDate: string,
		perPage?: number
	): void;
}

export const MainTableRow: React.FC<TableRowProps & TextAlignProps> = ({
	children,
	textAlign = 'center',
	...props
}) => {
	return (
		<StyledMainTableRow textAlign={textAlign} {...props}>
			{children}
		</StyledMainTableRow>
	);
};

const StyledHead = styled(TableHead)`
	position: relative;
	svg {
		position: absolute;
		left: 100%;
	}
	th {
		text-transform: uppercase;
	}
`;

const MainTable: React.FC<MainTableProps> = ({
	tableHeads,
	$hasActions = false,
	tableFilters,
	totalPages,
	total,
	pageNum = 0,
	RowsperPage = 0,
	renderCustomHeads,
	customHeads,
	dateFilter = true,
	renderItems = () => {},
	onPageChangeRequest,
	refetch = false,
	renderExtraFilters,
	renderSubHeader
}) => {
	const history = useHistory();
	const location = useLocation();
	const [sortBy, setSortBy] = useState(null);
	const [perPage, setPerPage] = useState(10);
	const [debounceLoading, setDebounceLoading] = useState(false);
	const queries = qs.parse(location.search.replace('?', ''));
	const queryValue = queries['query'] || '';
	const [input, setInput] = useState<any>(queryValue);

	const renderTableHeads = () => {
		const getHeader = (head: string | TableHeader) => {
			if (typeof head === 'object') {
				return head;
			}
			return head;
		};
		return (
			<TableRow sx={{ height: '3.5rem' }}>
				{tableHeads.map((head: any, index: number) => (
					<TableCell
						align="center"
						sx={{
							fontSize: '1rem',
							fontWeight: 500,

							whiteSpace: 'nowrap',
						}}
						key={
							typeof head === 'object'
								? `${head.fieldName}-${index}`
								: `${head}-${index}`
						}
					>
						<SortingIcons
							renderCustomHeads={renderCustomHeads}
							customHead={customHeads?.[index]}
							head={getHeader(head)}
							setSortBy={setSortBy}
							sortBy={sortBy}
							canSort={typeof head === 'object' ? head.canSort : true}
						/>
					</TableCell>
				))}
				{$hasActions && <TableCell></TableCell>}
			</TableRow>
		);
	};

	const handleChange = debounce((value: string) => {
		setInput(value);
		const querySearch = addQueryString(location.search, 'query', value);
		const search = addQueryString(querySearch, 'page', 1);
		history.push({ search });
		setDebounceLoading(false);
	}, 1000);

	const handlePerPageChange = debounce((value: any) => {
		setPerPage(value);
		const querySearch = addQueryString(
			location.search,
			'query',
			queries['query']?.toString() ?? ''
		);
		const search = addQueryString(querySearch, 'page', 1);
		const perPage = addQueryString(search, 'perPage', value);
		history.push({ search: perPage });
		setDebounceLoading(false);
	}, 1000);

	let Rows = 0;
	let totalRows = 0;
	if (total && RowsperPage && pageNum) {
		Rows = RowsperPage * pageNum + 1 - RowsperPage;
		totalRows = RowsperPage * pageNum;
		if (totalRows > total) {
			totalRows = total;
		}
	}
	const [_selectedFilters, setSelectedFilters] = useState([]);
	const { selectedFilters } = useSelectedFilters();
	const [show, setShow] = useState(false);
	const [functionToCall, createFunctionToCall] = useState(
		() => (name: any, value: any) => {}
	);
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginBottom: '1.375rem',
					marginTop: '0.813rem',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					{dateFilter && <DatePicker />}
					{tableFilters && tableFilters.length > 0 && (
						<TableFiltersButton
							handleClick={() => setShow(!show)}
							show={show}
						/>
					)}
					{renderExtraFilters && renderExtraFilters()}
				</Box>
				<Search>
					<SearchIconWrapper>
						{debounceLoading ? (
							<img
								className="pl-1"
								src={smallLoader}
								alt="Loading.."
								width={15}
							/>
						) : (
							<SearchIcon
								sx={{ color: '#6c63ff', width: '18px', height: '18px' }}
							/>
						)}
					</SearchIconWrapper>
					<StyledInputBase
						inputProps={{ 'aria-label': 'search' }}
						onChange={(e) => {
							handleChange(e.target.value);
						}}
						placeholder="Search"
						defaultValue={input}
					/>
				</Search>
			</Box>
			{tableFilters?.length > 0 && (
				<TableFilterBar
					show={show}
					setShow={setShow}
					tableFilters={tableFilters}
					setSelectedFilters={setSelectedFilters}
					createFunctionToCall={createFunctionToCall}
				/>
			)}

			{!show && (
				<Box
					sx={{
						m: 3,
					}}
				>
					{selectedFilters.length > 0 && (
						<>
							{selectedFilters &&
								selectedFilters.map((filter: any, index) => (
									<StyledChip
										key={`${filter?.name}-${index}`}
										sx={{
											m: 1,
											color: '#2196f3',
											border: 'solid 1px #2196f3',
											fontSize: '12px',
											fontWeight: 600,
											fontFamily: 'Montserrat',
											fontStyle: 'normal',
										}}
										color="primary"
										variant="outlined"
										label={chipFilterName(tableFilters, filter)}
										onDelete={() => functionToCall(filter?.name, filter?.value)}
									/>
								))}
						</>
					)}
				</Box>
			)}
			{renderSubHeader && renderSubHeader()}
			<TableContainer
				component={Paper}
				sx={{
					'&::-webkit-scrollbar': {
						height: '6px',
					},
					'&::-webkit-scrollbar-track': {
						backgroundColor: '#979797',
					},
				}}
			>
				<Table
					sx={{ minWidth: 650, tableLayout: 'fixed', whiteSpace: 'normal' }}
					aria-label="content table"
				>
					<colgroup>
						{tableHeads.map((theader: TableHeader | string, index: number) => {
							return typeof theader === 'object' ? (
								<col
									width={theader.width ?? ''}
									key={`${theader.fieldName}-${index}`}
								></col>
							) : (
								<col key={`${theader}-${index}`}></col>
							);
						})}
					</colgroup>
					{/* <TableHead>{renderTableHeads()}</TableHead> */}
					<StyledHead>{renderTableHeads()}</StyledHead>
					<StyledTableBody $hasActions={$hasActions}>
						{renderItems()}
					</StyledTableBody>
				</Table>
			</TableContainer>

			<Box
				sx={{
					marginBottom: '2rem',
					marginTop: '1rem',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '55%' }}>
					<Pagination
						totalPages={totalPages}
						onPageChangeRequest={onPageChangeRequest}
						sortBy={sortBy}
						refetch={refetch}
					/>
				</Box>

				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}
				>
					<Typography
						sx={{
							fontFamily: 'Roboto',
							fontSize: '14px',
							textAlign: 'left',
							color: 'rgba(0, 0, 0, 0.6)',
							marginRight: '10px',
						}}
					>
						Rows per page :
					</Typography>
					<FormControl>
						<Select
							variant="standard"
							sx={{ fontSize: '14px', fontFamily: 'Roboto' }}
							value={queries['perPage'] ?? perPage}
							onChange={(e) => handlePerPageChange(e.target.value)}
						>
							<MenuItemStyle value={5}>5</MenuItemStyle>
							<MenuItemStyle value={10}>10</MenuItemStyle>
							<MenuItemStyle value={25}>25</MenuItemStyle>
							<MenuItemStyle value={50}>50</MenuItemStyle>
						</Select>
					</FormControl>
					<Box component="div">
						<Typography
							sx={{
								fontFamily: 'Roboto',
								fontSize: '14px',
								textAlign: 'left',
								color: 'rgba(0, 0, 0, 0.6)',
								marginLeft: '10px',
							}}
						>
							{Rows} - {totalRows} of {total}
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default React.memo<MainTableProps>(MainTable);

const MenuItemStyle = styled(MenuItem)`
	font-size: 14px;
	font-family: Roboto;
`;
