import React, { useCallback, useState } from 'react';
import MainTable from '@Components/MainTable';
import EdEmptyValue from '@Components/UI/Utilities/EdEmptyValue/EdEmptyValue';
import DateTimeFormatter from '@Components/DateTimeFormatter/DateTimeFormatter';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import { useDispatch } from 'react-redux';
import { TableCell, Tooltip } from '@mui/material';
import { useTypedSelector } from '@Features/store';
import { MainTableRow } from '@Components/MainTable/MainTable';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import { scratchCardsGetAll } from '@Pages/ScratchCards/Slices/ScratchCards.slice';
import NoResults from '@Components/NoResults/NoResults';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'SERIAL', fieldName: 'serial', canSort: true },
	{ displayName: 'CODE', fieldName: 'code', canSort: true },
	{ displayName: 'BATCH', fieldName: 'batch' },
	{ displayName: 'CLASSROOM ', fieldName: 'title' },
	{ displayName: 'COURSE ', fieldName: 'name' },
	{ displayName: 'USED', fieldName: 'user_id', canSort: false },
	{ displayName: 'USED AT', fieldName: 'updated_at', width: '15%' },
	{ displayName: 'EMAIL', fieldName: 'email', width: '15%' },
	{ displayName: 'CREATED AT', fieldName: 'created_at', width: '15%' },
];
const ScratchCardsTable = () => {
	const {
		fetchAll,
		filters,
		meta,
		// query,
	} = useTypedSelector((state) => state.ScratchCards);
	const [searchValue, setSearchValue] = useState<string>();
	const dispatch = useDispatch();
	const onPageRequestChange = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
			export: exportData,
		}: onPageChangeRequestMeta) => {
			setSearchValue(query);
			dispatch(
				scratchCardsGetAll({
					page: activePage,
					filters: filters,
					perPage: perPage ?? 10,
					query: query ?? '',
					export: exportData ?? false,
					sortBy: sortBy,
					from: startDate,
					to: endDate,
				})
			);
		},
		[dispatch]
	);

	return (
		<div>
			<MainTable
				dateFilter={false}
				tableFilters={filters}
				tableHeads={TABLE_HEADS}
				totalPages={meta?.last_page ?? 0}
				onPageChangeRequest={onPageRequestChange}
				RowsperPage={meta?.per_page}
				pageNum={meta?.current_page}
				total={meta?.total}
				renderItems={() => {
					if (!searchValue)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					if (fetchAll.length === 0) {
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					} else {
						return fetchAll.map((code) => {
							return (
								<MainTableRow>
									<TableCell>{code.serial}</TableCell>
									<TableCell>{code.code}</TableCell>
									<TableCell>{code.batch}</TableCell>
									<Tooltip title={code.classroom?.title} placement="top">
										<TableCell>{code.classroom?.title}</TableCell>
									</Tooltip>
									<Tooltip title={code.course?.name} placement="top">
										<TableCell>
											{code.course === null
												? '-'
												: code.course?.name === null
												? '-'
												: code.course?.name}
										</TableCell>
									</Tooltip>
									<TableCell>
										<EdActiveStatus active={code.user_id !== null} />
									</TableCell>
									<Tooltip
										title={<DateTimeFormatter date={code.updated_at} />}
										placement="top"
									>
										<TableCell>
											{code.user_id !== null ? (
												<DateTimeFormatter date={code.updated_at} />
											) : (
												'-'
											)}
										</TableCell>
									</Tooltip>
									<Tooltip title={code.email} placement="top">
										<TableCell>
											<EdEmptyValue value={code.email} />
										</TableCell>
									</Tooltip>
									<Tooltip
										title={<DateTimeFormatter date={code.created_at} />}
										placement="top"
									>
										<TableCell>
											<DateTimeFormatter date={code.created_at} />
										</TableCell>
									</Tooltip>
								</MainTableRow>
							);
						});
					}
				}}
			/>
		</div>
	);
};

export default ScratchCardsTable;
