import React, {
	createContext,
	FC,
	PropsWithChildren,
	useContext,
	useMemo,
} from 'react';
import {
	Controller,
	useController,
	UseControllerProps,
	UseControllerReturn,
} from 'react-hook-form';
import { Checkbox, IconButton, MenuItem, Paper } from '@mui/material';
import { FlexItem, FlexLayout } from '@Styled/utilities';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import styled from 'styled-components';
import { rgba } from 'polished';
import { intersection, union, difference, sortBy } from 'lodash';
import { isIntersected } from './utils/utils';

type TransferListContextType = {
	onSelect: (
		value: boolean,
		index: number,
		id: any,
		offset: TransferListOffset
	) => void;
	checked: Array<number>;
	lhsItems: Array<number>;
	rhsItems: Array<number>;
	lhsSelectAll: boolean;
	rhsSelectAll: boolean;
	onReorder: (dir: ReorderDirection, index: number, id: any) => void;
};

type TransferListOffset = 'from' | 'to';
type ReorderDirection = 'up' | 'down';

const TransferListContext = createContext<TransferListContextType>({
	onSelect: () => {},
	lhsItems: [],
	rhsItems: [],
	lhsSelectAll: false,
	rhsSelectAll: false,
	checked: [],
	onReorder: () => {},
});

export declare type FieldValues = Record<string, any>;
type ChildrenObject<T extends unknown> = {
	list: (i: T, index: number) => React.ReactElement<typeof TransferListItem>;
	chosen: (i: T, index: number) => React.ReactElement<typeof TransferListItem>;
	listHeader?: () => React.ReactNode;
	chosenHeader?: () => React.ReactNode;
	chosenHeaderExtra?: (list: T[]) => React.ReactNode;
};
type BaseTransferListProps<T> = {
	list: Array<T>;
	listKey: string;
	listsNames: [string, string];
	showCounter?: boolean;
	children: ChildrenObject<T>;
};
type Props<T extends unknown> =
	| (BaseTransferListProps<T> & { control?: null; name?: string })
	| (BaseTransferListProps<T> & { name: string; control: any });

function EdTransferList<T extends unknown>(props: PropsWithChildren<Props<T>>) {
	const { listsNames, children, list, control, name, listKey } = props;
	const controller = useController({
		control: control,
		name: name ?? '',
		shouldUnregister: control === undefined,
	});

	const {
		field: { value, onChange, onBlur, ref },
	} = controller;
	const [checked, setChecked] = React.useState<number[]>([]);

	const getKey = () => listKey as keyof T;
	const currentValue = useMemo<Array<number>>(() => {
		return value ?? [];
	}, [value]);

	const left = useMemo<Array<number>>(() => {
		if (listKey) {
			const listMapped = list.map((it) => it[getKey()] as unknown as number);
			return difference(listMapped, currentValue) as Array<number>;
		}
		return difference(list as unknown as number[], currentValue);
	}, [listKey, list, currentValue]);

	const right = useMemo<Array<number>>(() => {
		return currentValue;
	}, [currentValue]);

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const numberOfChecked = (items: readonly number[]) => {
		return isIntersected(checked, items).length;
	};

	const handleToggleAll = (list: number[]) => {
		if (numberOfChecked(list) === list.length) {
			setChecked(difference(checked, list));
		} else {
			setChecked(union(checked, list));
		}
	};

	const getTitle = (offset: TransferListOffset) =>
		offset === 'from' ? listsNames[0] : listsNames[1];

	const renderChildrenList = (
		offset: TransferListOffset,
		panelList: number[]
	) => {
		let callbackList: any[] = [];

		if (!listKey) {
			callbackList = list.filter((it) =>
				panelList.includes(it as unknown as number)
			);
		} else {
			callbackList = list.filter((it) =>
				panelList.includes(it[getKey()] as unknown as number)
			);
		}
		if (offset === 'to') {
			callbackList = sortBy(callbackList, function (item) {
				if (listKey) {
					return panelList.indexOf(item[getKey()]);
				} else {
					return panelList.indexOf(item);
				}
			});
		}
		return callbackList.map((it, i) => {
			return (
				<div key={`chosen-${i}-${listKey ? it[listKey] : it}`}>
					{offset === 'from' ? children.list(it, i) : children.chosen(it, i)}
				</div>
			);
		});
	};
	const generateTransferListPanel = (
		offset: TransferListOffset,
		panelList: number[]
	) => {
		const {
			children: { listHeader, chosenHeader, chosenHeaderExtra },
			list,
			listKey,
		} = props;
		return (
			<FlexItem flex={'1'}>
				<TransferListPaper>
					<TransferListHeader>
						<FlexLayout alignItems={'center'}>
							<Checkbox
								checked={
									numberOfChecked(panelList) === panelList.length &&
									panelList.length !== 0
								}
								onClick={() => handleToggleAll(panelList)}
								indeterminate={
									numberOfChecked(panelList) !== panelList.length &&
									numberOfChecked(panelList) !== 0
								}
							/>
							<FlexLayout flexDirection={'column'}>
								<TransferListHeaderTitle>
									{getTitle(offset)}
								</TransferListHeaderTitle>
								<TransferListHeaderSubTitle>
									{numberOfChecked(panelList)} / {panelList.length} Selected
								</TransferListHeaderSubTitle>
							</FlexLayout>
						</FlexLayout>
						{offset === 'to' &&
							chosenHeaderExtra &&
							chosenHeaderExtra(
								listKey
									? list.filter((it) =>
											panelList.includes(it[getKey()] as unknown as number)
									  )
									: list.filter((it) =>
											panelList.includes(it as unknown as number)
									  )
							)}
					</TransferListHeader>
					{offset === 'from' && listHeader && listHeader()}
					{offset === 'to' && chosenHeader && chosenHeader()}
					{renderChildrenList(offset, panelList)}
				</TransferListPaper>
			</FlexItem>
		);
	};

	const onItemListClick = (
		value: boolean,
		index: number,
		id: any,
		offset: TransferListOffset
	) => {
		const newChecked = [...checked];
		const currentIndex = newChecked.findIndex((_) => _ === id);
		if (!value && currentIndex !== -1) {
			newChecked.splice(currentIndex, 1);
		} else {
			newChecked.push(id);
		}
		setChecked(newChecked);
	};

	const onTransfer = () => {
		const valueArr = Array.from(currentValue);
		if (control) {
			onChange(valueArr.concat(leftChecked));
		}
		setChecked(difference(checked, leftChecked));
	};
	const onDeTransfer = () => {
		const valueArr = Array.from(currentValue);
		if (control) {
			onChange(difference(valueArr, rightChecked));
		}
		setChecked(difference(checked, rightChecked));
	};

	const onReorder = (dir: ReorderDirection, index: number, id: number) => {
		const swapArrayLoc = (_arr: any[], from: number, to: number) => {
			const arr = _arr;
			[arr[from], arr[to]] = [arr[to], arr[from]];
			return arr;
		};
		let newArr = [];
		if (dir === 'down') {
			newArr = swapArrayLoc(right, index + 1, index);
		} else {
			newArr = swapArrayLoc(right, index - 1, index);
		}
		onBlur();
		onChange([...newArr]);
	};
	return (
		<div key={`${name}-ed-transfer-list`} onBlur={onBlur} ref={ref}>
			<TransferListContext.Provider
				value={{
					checked,
					onSelect: onItemListClick,
					lhsItems: left,
					lhsSelectAll: left.length === numberOfChecked(left),
					rhsSelectAll: right.length === numberOfChecked(right),
					rhsItems: right,
					onReorder,
				}}
			>
				<FlexLayout alignItems={'center'}>
					{generateTransferListPanel('from', left)}

					<TransferListAction>
						<IconButton
							onClick={onTransfer}
							disabled={leftChecked.length === 0}
						>
							<EdIcon mIconType={'Round'}>chevron_right</EdIcon>
						</IconButton>
						<IconButton
							onClick={onDeTransfer}
							disabled={rightChecked.length === 0}
						>
							<EdIcon mIconType={'Round'}>chevron_left</EdIcon>
						</IconButton>
					</TransferListAction>
					{generateTransferListPanel('to', right)}
				</FlexLayout>
			</TransferListContext.Provider>
		</div>
	);
}

export default EdTransferList;

const TransferListPaper = styled(Paper)`
	min-height: 25rem;
	max-height: 25rem;
	overflow: auto;
	::-webkit-scrollbar {
		display: none;
	}
`;
const TransferListAction = styled(FlexLayout)`
	flex-direction: column;
	margin: 0 1rem;
`;
const TransferListHeader = styled(FlexLayout)`
	padding: 1.75rem;
	position: sticky;
	top: 0;
	z-index: 4;
	background: inherit;
	font-family: 'Roboto', cursive;
	font-size: 0.875rem;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${rgba('#000', 0.12)};
`;
const TransferListHeaderTitle = styled.div`
	margin-bottom: 3px;
	font-weight: 500;
`;
const TransferListHeaderSubTitle = styled.div`
	color: ${rgba('#000', 0.38)};
`;

type ListItemProps = {
	selected?: boolean;
	index: number;
	item: any;
	id: any;
	offset: TransferListOffset;
};
const TransferListItem: FC<ListItemProps> = ({
	offset,
	children,
	index,
	id,
}) => {
	const { onSelect, checked, lhsSelectAll, rhsSelectAll, onReorder, rhsItems } =
		useContext(TransferListContext);
	const selected = useMemo(() => {
		if (offset === 'from') {
			return !!checked.find((_) => _ === id || lhsSelectAll);
		}
		if (offset === 'to') {
			return !!(checked.find((_) => _ === id) || rhsSelectAll);
		}
	}, [checked, lhsSelectAll, rhsSelectAll]);

	return (
		<StyledTransferListItem
			offset={offset}
			onClick={() => {
				if (id) {
					onSelect(!selected, index, id, offset);
				}
			}}
		>
			<ListItemActions>
				<IconButton
					onClick={(e) => {
						e.stopPropagation();
						onReorder('up', index, id);
					}}
					disabled={index === 0}
				>
					<EdIcon>arrow_upward</EdIcon>
				</IconButton>
				<IconButton
					onClick={(e) => {
						e.stopPropagation();
						onReorder('down', index, id);
					}}
					disabled={index === rhsItems.length - 1}
				>
					<EdIcon>arrow_downward</EdIcon>
				</IconButton>
			</ListItemActions>
			<ListItemCheckbox checked={selected} />
			<FlexLayout width={'100%'}>{children}</FlexLayout>
		</StyledTransferListItem>
	);
};

const ListItemCheckbox = styled(Checkbox)`
	position: absolute;
	left: 0;
`;
const ListItemActions = styled.div`
	position: absolute;
	right: 0;
	display: none;
	align-items: center;
	gap: 1rem;
	z-index: 4;
`;
const StyledTransferListItem = styled(MenuItem)<{ offset: TransferListOffset }>`
	display: flex;
	width: 100%;
	align-items: center;
	padding: 0 1rem;
	min-height: 3rem;
	&:hover {
		${ListItemActions} {
			display: ${(props) => (props.offset === 'to' ? 'flex' : '')};
		}
	}
`;

EdTransferList.ListItem = TransferListItem;
