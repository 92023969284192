import {
	getAllClassroomEnrollments,
	getAllClassroomsGraph,
	getClassroomAnalyticsDatatable,
	getClassroomEnrollments,
	getClassroomGraph,
} from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import {
	Icon,
	Paper,
	PaperProps,
	Button,
	ButtonGroup,
	IconButton,
	TextField,
	Box,
	TableCell,
	TableRow,
	MenuItem,
	Checkbox,
	TextFieldProps,
} from '@mui/material';
import {
	deepPurple,
	green,
	grey,
	orange,
	red,
	yellow,
} from '@mui/material/colors';
import {
	ClassroomGraphPayload,
	EnrollmentType,
	GraphPayload,
	GraphSlice,
	GraphSliceType,
} from '@Services/classrooms/classrooms.res.types';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import MultipleLinesChart from '@Components/UI/Charts/MultipleLinesChart/MultipleLinesChart';
import { object, mixed, array, date, number } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MUISelectController, {
	OptionKeyPair,
} from '@Components/UI/Inputs/MUISelectController/MUISelectController';
import { FlexItem, FlexLayout, Spacer } from '@Styled/utilities';
import MonthRangePicker from '@Components/UI/Pickers/MonthRangePicker/MonthRangePicker';
import YearRangePicker from '@Components/UI/Pickers/YearRangePicker/YearRangePicker';
// import { StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import '@mui/lab';
import DateRangePickerPopup, {
	DateRangeOption,
} from '@Components/UI/Pickers/DateRangePickerPopup/DateRangePickerPopup';

import { useParams } from 'react-router-dom';
import { fetchCoursesDropdown } from '@Features/dropdowns/dropdownsSlice';
import {
	AnalyticsCardsContainer,
	EnrollmentsCard,
	TypeInfo,
	EnrollmentTypeCard,
	TypeValue,
	TypeName,
	enrollmentIcons,
	FormControlsWrapper,
	FormControlLabel,
	StyledIconButton,
	FormActionsWrapper,
} from '@Components/UI/Analytics/Analytics.styled';
import { TableHeader } from '@Features/generics/generics.type';
import { exportClassroomAnalytics } from '@Features/classrooms/exportSlice';
import MainTable from '@Components/MainTable';
import NoResults from '@Components/NoResults/NoResults';
import RoundButton from '@Components/UI/Buttons/RoundButton';
import { WrappedTableCell } from '@Components/UI/Tables/Tables.styled';
import { AnalyticsTotal } from '@Components/UI/Analytics/Assets/Icons/Icons';
import AddClassroomButton from '@Pages/ClassRooms/Components/AddClassroomButton';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import SelectChip from '@Components/UI/Inputs/SelectChip/SelectChip';
import { AnalyticsSlices } from '@Pages/ClassRooms/Constants/Classroom.constants';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import { mergeWith, unionWith, merge } from 'lodash';
import ClassroomUnitsAnalytics from './ClassroomUnitsAnalytics/ClassroomUnitsAnalytics';
const SCHEMA = object().shape({
	slice: mixed<GraphSliceType>().required(),
	classroom_ids: array().of(number()),
	dates: array(),
});

type FormType = {
	slice: GraphSliceType;
	course_ids: number[];
	dates: any;
};
const TABLE_HEADS: TableHeader[] = [
	{
		displayName: 'Course',
		fieldName: 'course_name',
		canSort: false,
		width: '20%',
	},
	{ displayName: 'Wallet', fieldName: 'wallet', canSort: false },
	{ displayName: 'ScratchCards', fieldName: 'scratchcards', canSort: false },
	{ displayName: 'Free', fieldName: 'free', canSort: false },
	{ displayName: 'Instructor', fieldName: 'instructor', canSort: false },
	{ displayName: 'Total', fieldName: 'total', canSort: false },
];

const ClassroomAnalytics: React.FC = () => {
	const dispatch = useDispatch();
	const {
		analytics: {
			currentClassroom: {
				enrollments,
				graph,
				datatable: { data, filters, meta },
			},
		},
		currentClassroom,
	} = useTypedSelector((state) => state.classroom);


	const mergedEnrollments = useMemo(() => {
		const mappedEnrollments = enrollments?.purchase_methods.map((_) => {
			if (_.purchase_method === 'instructor') {
				const admin = enrollments.purchase_methods.find(
					(_) => _.purchase_method === 'admin'
				);
				return merge(_, admin);
			} else {
				return _;
			}
		});
		const adminIndex = mappedEnrollments?.findIndex(
			(_) => _.purchase_method === 'admin'
		);
		if (adminIndex) {
			mappedEnrollments?.splice(adminIndex, 1);
		}
		return mappedEnrollments;
	}, [enrollments]);

	const { dropdownCourses } = useTypedSelector((state) => state.dropdowns);
	const { label } = useParams<{ label: string }>();
	const [doExport, setDoExport] = useState(false);

	const defaultValues = useMemo<FormType>(() => {
		const startDate = new Date();
		startDate.setDate(startDate.getDate() - 14);
		return {
			course_ids: [],
			slice: 'day',
			dates: [startDate, new Date()],
		};
	}, []);
	const {
		control,
		watch,
		register,
		setValue,
		reset,
		handleSubmit,
		getValues,
		formState: { isValid, isDirty },
	} = useForm<FormType>({
		resolver: yupResolver(SCHEMA),
		defaultValues: defaultValues,
		mode: 'all',
	});

	useEffect(() => {
		register('dates');
		if (!currentClassroom) return;
		dispatch(fetchCoursesDropdown(currentClassroom.id));
		dispatch(getClassroomEnrollments(currentClassroom.id));
		dispatch(
			getClassroomGraph({
				from: defaultValues.dates[0],
				to: defaultValues.dates[1],
				slice: 'day',
				course_ids: [],
				classroom_id: currentClassroom.id,
			})
		);
	}, [currentClassroom?.id]);

	const pickTypeColor = useCallback((type: EnrollmentType) => {
		if (type === 'instructor') {
			return red[500];
		}
		if (type === 'wallet') {
			return deepPurple[500];
		}
		if (type === 'scratchcard') {
			return orange[500];
		}
		if (type === 'free') {
			return yellow[600];
		}
		if (type === 'admin') {
			return green['A700'];
		}
	}, []);

	const getGraph = useCallback((args: ClassroomGraphPayload) => {
		dispatch(getClassroomGraph(args));
	}, []);

	const watchSliceType = watch('slice');
	const watchCourses = watch('course_ids');

	const handleFilterForm = (data: FormType) => {
		if (currentClassroom) {
			getGraph({
				course_ids: data.course_ids,
				slice: data.slice,
				from: data.dates[0],
				to: data.dates[1],
				classroom_id: currentClassroom?.id,
			});
		}
	};

	const [dateRange, setDateRangeOption] =
		useState<DateRangeOption>('last 2 weeks');

	const handleDateRangeChange = (type: DateRangeOption) => {
		setDateRangeOption(type);

		if (type === 'custom') return;
		const startDate = new Date();
		const endDate = new Date();
		if (type === 'last 2 weeks') {
			startDate.setDate(startDate.getDate() - 14);
		}
		if (type === 'last month') {
			startDate.setMonth(startDate.getMonth() - 1);
		}
		if (type === 'last 3 months') {
			startDate.setMonth(startDate.getMonth() - 2);
		}
		setValue('dates', [startDate, endDate], {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement>();

	const handleDatatableRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			if (!currentClassroom) return;
			if (doExport) {
				dispatch(
					exportClassroomAnalytics({
						page: activePage,
						filters: filters,
						perPage: perPage ?? 10,
						classroom_id: currentClassroom.id,
						from: startDate,
						to: endDate,
						query: query,
						sortBy: sortBy,
					})
				);
				setDoExport(false);
			} else {
				//
				dispatch(
					getClassroomAnalyticsDatatable({
						page: activePage,
						perPage: perPage,
						filters: filters,
						from: startDate,
						to: endDate,
						sortBy: sortBy,
						classroom_id: currentClassroom.id,
						query: query,
					})
				);
			}
		},
		[currentClassroom, doExport]
	);

	const onDeleteValue = (control: 'course_ids', value: number) => {
		const values = getValues()[control];
		if (!values) return;
		values.splice(
			values.findIndex((_) => _ === value),
			1
		);
		setValue(control, values);
	};

	const resetForm = () => {
		if (!currentClassroom) return;
		reset({
			course_ids: [],
			slice: 'day',
			dates: defaultValues.dates,
		});
		setDateRangeOption('last 2 weeks');
		register('dates');
		getGraph({
			classroom_id: currentClassroom.id,
			course_ids: [],
			slice: 'day',
			from: defaultValues.dates[0],
			to: defaultValues.dates[1],
		});
	};
	return (
		<>
			{enrollments && (
				<AnalyticsCardsContainer>
					<EnrollmentsCard elevation={10}>
						<AnalyticsTotal />

						<TypeInfo>
							<span>{enrollments.purchase_methods_total}</span>
							<label>Enrollments</label>
						</TypeInfo>
					</EnrollmentsCard>
					{enrollments.purchase_methods
						.map((en) => {
							if (en.purchase_method === 'scratchcard') {
								return {
									...en,
									purchase_method_count:
										Number(en.purchase_method_count) +
										Number(
											enrollments.purchase_methods.find(
												(_) => _.purchase_method === 'admin'
											)?.purchase_method_count ?? 0
										),
								};
							}
							return en;
						})
						.filter((_) => _.purchase_method !== 'admin')
						.map((en) => {
							return (
								<EnrollmentTypeCard color={pickTypeColor(en.purchase_method)}>
									<TypeInfo>
										{enrollmentIcons[en.purchase_method]}
										<TypeValue>{en.purchase_method_count}</TypeValue>
									</TypeInfo>
									<TypeName>{en.purchase_method}</TypeName>
								</EnrollmentTypeCard>
							);
						})}
				</AnalyticsCardsContainer>
			)}

			<Box width="100%" display="flex" justifyContent="flex-end">
				<RoundButton
					onClick={() => setDoExport(true)}
					startIcon={<Icon>south</Icon>}
					variant="contained"
				>
					Export
				</RoundButton>
				<Spacer mx={'0.625rem'} />
				<AddClassroomButton />
			</Box>

			<MainTable
				tableFilters={filters as Array<any>}
				RowsperPage={meta?.per_page}
				onPageChangeRequest={handleDatatableRequest}
				totalPages={meta?.last_page ?? 0}
				total={meta?.total}
				pageNum={meta?.current_page}
				tableHeads={TABLE_HEADS}
				renderItems={() => {
					if (data.length <= 0)
						return (
							<TableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</TableRow>
						);
					return data.map((en) => {
						return (
							<TableRow>
								<WrappedTableCell align="center">
									{en.course_name}
								</WrappedTableCell>
								<TableCell align="center">
									{en.purchase_methods[0].purchase_method_count}
								</TableCell>
								<TableCell align="center">
									{en.purchase_methods[1].purchase_method_count}
								</TableCell>
								<TableCell align="center">
									{en.purchase_methods[2].purchase_method_count}
								</TableCell>
								<TableCell align="center">
									{en.purchase_methods[3].purchase_method_count}
								</TableCell>
								<TableCell align="center">
									{en.purchase_methods_total}
								</TableCell>
							</TableRow>
						);
					});
				}}
			/>

			<FormControlsWrapper>
				<FormControlLabel>Select Course(s)</FormControlLabel>

				<EdFormControl
					width="12rem"
					select
					multiple
					SelectProps={{
						multiple: true,
						renderValue: (selected) => {
							return (
								<>
									{watchCourses.map((_) => {
										const cl = dropdownCourses.find((cl) => _ === cl.id);
										return <>{cl?.name},</>;
									})}
								</>
							);
						},
					}}
					label={''}
					name="course_ids"
					control={control}
				>
					{dropdownCourses &&
						dropdownCourses.map((_, index) => {
							return (
								<MenuItem value={_.id} key={`${index}-${_.id}`}>
									<Checkbox checked={watchCourses?.includes(_.id)} />
									<div>{_.name}</div>
								</MenuItem>
							);
						})}
				</EdFormControl>
				<Spacer mx={14} />

				<EdFormControl select name="slice" control={control}>
					{AnalyticsSlices.map((_) => {
						return (
							<MenuItem key={_.value} value={_.value}>
								{_.label}
							</MenuItem>
						);
					})}
				</EdFormControl>
				<Spacer mx={14} />

				<StyledIconButton
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						setOpen(!open);
						setAnchorEl(e.currentTarget);
					}}
				>
					<Icon>event</Icon>
				</StyledIconButton>
				<DateRangePickerPopup
					open={open}
					anchorEl={anchorEl}
					onClose={() => {
						setOpen(false);
					}}
					onChange={handleDateRangeChange}
					rangeOptionValue={dateRange}
				>
					<>
						<DateRangePickerPopup.Body>
							{(watchSliceType === 'day' || watchSliceType === 'week') && (
								<Controller
									control={control}
									name="dates"
									render={({ field: { value, onChange, ref, onBlur } }) => {
										return (
											<div></div>
											// <StaticDateRangePicker
											//   calendars={2}
											//   showToolbar={false}
											//   onChange={onChange}
											//   value={value}
											//   ref={ref}
											//   renderInput={(
											//     startProps: TextFieldProps,
											//     endProps: TextFieldProps
											//   ) => (
											//     <>
											//       <TextField {...startProps} />
											//       <TextField {...endProps} />
											//     </>
											//   )}
											// />
										);
									}}
								/>
							)}

							{watchSliceType === 'month' && (
								<MonthRangePicker control={control as any} name="dates" />
							)}
							{watchSliceType === 'year' && (
								<YearRangePicker control={control as any} name="dates" />
							)}
						</DateRangePickerPopup.Body>
					</>
				</DateRangePickerPopup>
			</FormControlsWrapper>
			<FlexLayout alignItems="flex-end">
				<FlexItem flex="1">
					<>
						{watchCourses.map((_) => {
							const cl = dropdownCourses.find((cl) => cl.id === _);
							return (
								<SelectChip
									onDelete={() => onDeleteValue('course_ids', _)}
									label={cl?.name}
								/>
							);
						})}
					</>
				</FlexItem>
				<FlexLayout>
					<Button onClick={() => resetForm()} variant="outlined">
						Reset
					</Button>
					<Spacer mx={'1rem'} />
					<Button
						disabled={!isValid}
						onClick={handleSubmit(handleFilterForm)}
						variant="contained"
					>
						Apply
					</Button>
				</FlexLayout>
			</FlexLayout>

			<MultipleLinesChart
				sliceType={watchSliceType}
				colors={(slice: GraphSlice) => {
					return pickTypeColor(slice.id as EnrollmentType);
				}}
				data={graph}
			/>
			<ClassroomUnitsAnalytics />
		</>
	);
};

export default ClassroomAnalytics;
