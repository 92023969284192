import { useTypedSelector } from '@Features/store';
import PermissionContainer from '@Pages/Assistants/Components/AssistantPermissions/PermissionContainer';
import { grantsGetAll } from '@Pages/Assistants/Slices/Grants/Grants.slices';
import { Grants } from '@Pages/Assistants/Types/Assistants.types';
import React, { FC, useEffect, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

type Props = {
	name: string;
};

const Permissions: FC<Props> = ({ name }) => {
	const Grants: Grants[] = [
		{
			id: 1,
			code: '637ed00fa101450488c8d78d250c3ed2',
			name: 'grant 1',
		},
		{
			id: 3,
			code: '5515fb80f12547ff8a301308f00a725d',
			name: 'grant 3',
		},
	];
	const { control } = useFormContext();
	const {
		field: { onChange, value, onBlur, ref },
	} = useController({
		control,
		name,
	});

	const dispatch = useDispatch();
	const { fetchAll, filters, query } = useTypedSelector(
		(state) => state.Grants
	);
	useEffect(() => {
		dispatch(
			grantsGetAll({
				page: 1,
				perPage: 10,
				filters,
				query: query as string,
				sortBy: {},
			})
		);
	}, []);

	const selectedValue: Array<any> = useMemo(() => {
		return [...new Set(value as any)];
	}, [value]);

	return (
		<Wrapper onBlur={onBlur} ref={ref}>
			{fetchAll?.map((grant) => {
				const { id } = grant;
				const group = fetchAll?.find((y) => y.id === grant.id);
				return (
					<div key={`grant-${id}`}>
						{group && (
							<PermissionContainer
								permission={group}
								onGrantSelect={(selected: boolean, perValue: number) => {
									const values: Array<number> = selectedValue;
									if (!values && selected) {
										onChange([perValue]);
										return;
									}
									if (selected) {
										if (!values?.find((_) => _ === perValue)) {
											onChange([...values, perValue]);
										}
										return;
									}
									const index = values?.findIndex((_) => _ === perValue);
									values.splice(index, 1);
									onChange(values);
								}}
								currentGrant={selectedValue ?? []}
							/>
						)}
					</div>
				);
			})}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding-left: 4.5rem;
`;

export default Permissions;
