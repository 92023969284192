import EdCheckbox from '@Components/UI/Inputs/EdCheckbox/EdCheckbox';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import { EdFormControlLabel } from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import EdNumericInput from '@Components/UI/Inputs/EdNumericInput/EdNumericInput';
import { useTypedSelector } from '@Features/store';
import { MenuItem } from '@mui/material';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TestPayload } from '../../../../../Types/Test/Test.types';

const TestOptionsRepetitionSection = () => {
	const { control, watch, resetField, setValue } =
		useFormContext<TestPayload>();
	// const [showAllowRepetition, setShowAllowRepetition] =
	//   useState<boolean>(false);
	const {
		currentEntity: { active },
	} = useTypedSelector((state) => state.Tests);
	const showAllowRepetition = watch('allowRepetition');

	useEffect(() => {
		if (!showAllowRepetition) {
			setValue('test.allowed_trials', 1);
			resetField('test.allow_repetition_when');
		}
	}, [resetField, showAllowRepetition]);
	return (
		<>
			<FlexLayout mb="2rem">
				<EdCheckbox
					disabled={active}
					control={control}
					name="allowRepetition"
					label="Allow repetition"
				/>
				<Spacer mr={'1.625rem'} />
				{showAllowRepetition && (
					<EdFormControl
						disabled={active}
						showLabel={false}
						select
						control={control}
						name="test.allow_repetition_when"
					>
						<MenuItem value={'failed'}>If failed</MenuItem>
						<MenuItem value={'always'}>Always</MenuItem>
					</EdFormControl>
				)}
			</FlexLayout>
			{showAllowRepetition && (
				<FlexLayout>
					<EdFormControlLabel>Maximum number of attempts</EdFormControlLabel>
					<Controller
						control={control}
						name="test.allowed_trials"
						render={({ field, fieldState }) => {
							return (
								<EdNumericInput
									disabled={active}
									{...field}
									size="small"
									name="test.allowed_trials"
									placeholder="Times"
									helperText={fieldState.error?.message}
									style={{ marginLeft: 50 }}
								/>
							);
						}}
					/>
				</FlexLayout>
			)}
		</>
	);
};

export default TestOptionsRepetitionSection;
