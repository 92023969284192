import { HashString } from '@Components/UI/Tabs/RoutedTabs/RoutedTabs.types';
import RoutedTabs from '@Components/UI/Tabs/Tabs';
import { getClassroomByLabel } from '@Features/classrooms/classroomSlice';
import { Spacer } from '@Styled/utilities';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	Redirect,
	Route,
	useHistory,
	useLocation,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import EditClassroomBasicInfo from './Components/EditClassroomBasicInfo';
import { useTypedSelector } from '@Features/store';
import styled from 'styled-components';
import { border, BorderProps, space, SpaceProps } from 'styled-system';
import { getAdmissionForm } from '@Features/classrooms/admissionFormSlice';
import ClassroomAdmission from '@Pages/ClassRooms/Components/ClassroomAdmission/ClassroomAdmission';
import { fetchCoursesDropdown } from '@Features/dropdowns/dropdownsSlice';
import { getEducationalInfo } from '@Features/classrooms/educationalSlice';
import { usePageLoader } from '@Providers/LoadingProvider';
const EditClassroom: React.FC = () => {
	const { path, url } = useRouteMatch();
	const { hash } = useLocation();
	const [tabIndex, setTabIndex] = useState<HashString>(hash as HashString);
	const [hasAdmission, setHasAdmission] = useState(false);
	const dispatch = useDispatch();
	const { setIsLoading } = usePageLoader();
	const {
		currentClassroom,
		loaders: { getOne },
	} = useTypedSelector((state) => state.classroom);
	const { id: admission_id, loaders } = useTypedSelector(
		(state) => state.classroomAdmissionForm
	);

	useEffect(() => {
		dispatch(getEducationalInfo());
	}, []);

	useEffect(() => {
		if (currentClassroom) dispatch(fetchCoursesDropdown(currentClassroom.id));
	}, [currentClassroom]);

	useEffect(() => {
		if (currentClassroom?.admission_form_id) {
			dispatch(getAdmissionForm(currentClassroom.admission_form_id));
		}
	}, [currentClassroom?.admission_form_id]);

	useEffect(() => {
		if (getOne === undefined || loaders.getOne === undefined) return;
		const isLoading = getOne || loaders.getOne;
		setIsLoading(Boolean(isLoading));
	}, [getOne, loaders.getOne]);

	return (
		<CardContainer>
			<RoutedTabs>
				<RoutedTabs.Tabs
					$hashPrefix="#"
					hashes={['#info', '#admission']}
					onChange={(e, value) => setTabIndex(value)}
					orientation="vertical"
					value={tabIndex}
				>
					<RoutedTabs.Item
						value="#info"
						label={<RoutedTabs.ItemTitle>Basic Info</RoutedTabs.ItemTitle>}
						icon={<RoutedTabs.ItemIcon>menu_book</RoutedTabs.ItemIcon>}
					/>
					<RoutedTabs.Item
						value="#admission"
						label={<RoutedTabs.ItemTitle>Admission</RoutedTabs.ItemTitle>}
						disabled={!hasAdmission}
						icon={<RoutedTabs.ItemIcon>post_add</RoutedTabs.ItemIcon>}
					/>
				</RoutedTabs.Tabs>
				<Spacer mx={'1rem'} />
				<RoutedTabs.Content value={tabIndex}>
					<RoutedTabs.ContentItem hash="#info">
						{currentClassroom && getOne === 'fulfilled' && (
							<EditClassroomBasicInfo
								onHasAdmissionChange={(val) => {
									setHasAdmission(val);
								}}
							/>
						)}
					</RoutedTabs.ContentItem>
					<RoutedTabs.ContentItem hash="#admission">
						{currentClassroom && admission_id && loaders.getOne === false && (
							<ClassroomAdmission mode="edit" />
						)}
						{currentClassroom && !admission_id && (
							<ClassroomAdmission mode="create" />
						)}
					</RoutedTabs.ContentItem>
				</RoutedTabs.Content>
			</RoutedTabs>
		</CardContainer>
	);
};

export default EditClassroom;

const CardContainer = styled.div<SpaceProps & BorderProps>`
	padding: 1.375rem;
	border-radius: 10px;
	border: 1px solid ${(props) => props.theme.palette.primary.main};
	${space};
	${border}
`;
