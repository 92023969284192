import EdFab from '@Components/UI/Utilities/EdFab/EdFab';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import {
	CURRICULUM_FORM,
	CURRICULUM_SCHEMA,
} from '@Pages/ClassRooms/Schema/ClassroomCurriculum.schema';
import { ClassroomTabsRequester } from '@Pages/ClassRooms/Services/ClassroomTabs/ClassroomTabs.req';
import { Tab, TabPayload } from '@Pages/ClassRooms/Types/ClassroomTabs.types';
import { FlexLayout, Spacer } from '@Styled/utilities';
import React, { useEffect, useState } from 'react';
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from 'react-beautiful-dnd';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
	FabButtonLabel,
	PageWrapper,
	TabDraggable,
	TabDroppable,
} from '../../Curriculum.styled';
import TabAccordion from '../TabAccordion/TabAccordion';
import { cloneDeep } from 'lodash';
import { CurriculumContext } from '../../Context/Curriculum.context';
import { useSnackbar } from '@Providers/useSnackbar';
import { DevTool } from '@hookform/devtools';
import { getAllClassroomTabs } from '@Pages/ClassRooms/Slices/ClassroomTabs/ClassroomTabs.slice';

const CurriculumForm = () => {
	const { currentClassroom } = useTypedSelector((state) => state.classroom);
	const { displaySnackbar } = useSnackbar();
	const dispatch = useAppThunkDispatch();
	const { fetchAll, loaders } = useTypedSelector(
		(state) => state.ClassroomTabs
	);

	const [needReOrder, setNeedReOrder] = useState(false);
	const { control, setValue, getValues, ...formMethods } =
		useForm<CURRICULUM_FORM>({
			resolver: yupResolver(CURRICULUM_SCHEMA),
			mode: 'all',
			reValidateMode: 'onChange',
			criteriaMode: 'all',
			defaultValues: {
				tabs: cloneDeep(fetchAll),
			},
		});

	const { fields, append, update, remove } = useFieldArray({
		control,
		name: 'tabs',
		keyName: 'tabId',
	});

	const watchFields = formMethods.watch('tabs');
	const controlledFields = fields.map((field, index) => {
		return { ...field, ...watchFields[index] };
	});

	const onAddTab = async () => {
		if (!currentClassroom) return;
		const newTab: TabPayload = {
			classroom_id: currentClassroom.id,
			name: `Tab ${controlledFields.length + 1} name`,
			order: controlledFields.length + 1,
			sections: [],
		};

		const {
			status,
			data: { data },
		} = await ClassroomTabsRequester.getInstance().create(newTab);
		if (status === 200) {
			append({
				...newTab,
				id: data.id,
			});
		}
	};

	const onUpdateForm = (index: number, tab: Tab) => {
		update(index, tab);
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) return;
		if (result.type === 'TABS') {
			reorderTabs(result.destination.index, result.source.index);
		} else if (result.type === 'SECTIONS') {
			if (result.destination.droppableId === result.source.droppableId) {
				const tabIndex: number = Number(
					result.destination.droppableId.split('-')[1]
				);
				reorderTabSections(
					tabIndex,
					result.destination.index,
					result.source.index
				);
			} else {
				const tabAIndex = Number(result.source.droppableId.split('-')[1]);
				const tabBIndex = Number(result.destination.droppableId.split('-')[1]);
				changeSectionTab(
					tabAIndex,
					tabBIndex,
					result.source.index,
					result.destination.index
				);
			}
		} else if (result.type === 'COURSES') {
			if (result.destination.droppableId === result.source.droppableId) {
				const sectionIndex = Number(
					result.destination.droppableId.split('-')[3]
				);
				const tabIndex = Number(result.destination.droppableId.split('-')[1]);
				const tab = controlledFields[tabIndex];
				reorderSectionCourses(
					tab,
					tabIndex,
					sectionIndex,
					result.destination.index,
					result.source.index
				);
			} else {
				const sourceSectionIndex = Number(
					result.source.droppableId.split('-')[3]
				);
				const sourceTabIndex = Number(result.source.droppableId.split('-')[1]);
				const desSectionIndex = Number(
					result.destination.droppableId.split('-')[3]
				);
				const desTabIndex = Number(
					result.destination.droppableId.split('-')[1]
				);
				const sourceTab = controlledFields[sourceTabIndex];
				const desTab = controlledFields[desTabIndex];
				changeCourseSection(
					sourceTab,
					desTab,
					sourceTabIndex,
					desTabIndex,
					sourceSectionIndex,
					desSectionIndex,
					result.source.index,
					result.destination.index
				);
			}
		}
		setNeedReOrder(true);
	};

	const changeCourseSection = (
		sourceTab: Tab,
		desTab: Tab,
		sourceTabIndex: number,
		desTabIndex: number,
		sourceSectionIndex: number,
		desSectionIndex: number,
		indexA: number,
		indexB: number
	) => {
		const sectionACourses = Array.from(
			controlledFields[sourceTabIndex].sections[sourceSectionIndex].courses
		);
		const [deleted] = sectionACourses.splice(indexA, 1);
		let sectionBCourses = Array.from(
			controlledFields[desTabIndex].sections[desSectionIndex]?.courses
		);
		if (sectionBCourses) {
			sectionBCourses.splice(indexB, 0, deleted);
		} else {
			sectionBCourses = [deleted];
		}

		setValue(
			`tabs.${sourceTabIndex}.sections.${sourceSectionIndex}.courses`,
			sectionACourses
		);
		setValue(
			`tabs.${desTabIndex}.sections.${desSectionIndex}.courses`,
			sectionBCourses
		);
	};

	const reorderSectionCourses = (
		tab: Tab,
		tabIndex: number,
		sectionIndex: number,
		indexA: number,
		indexB: number
	) => {
		const courses = Array.from(tab.sections[sectionIndex].courses);
		if (courses.length < 2) return;
		courses.splice(indexA, 0, courses.splice(indexB, 1)[0]);
		tab.sections[sectionIndex].courses = courses;
		setValue(`tabs.${tabIndex}.sections.${sectionIndex}.courses`, courses);
	};

	const changeSectionTab = (
		tabAIndex: number,
		tabBIndex: number,
		indexA: number,
		indexB: number
	) => {
		const tabASections = Array.from(controlledFields[tabAIndex].sections);
		const [deleted] = tabASections.splice(indexA, 1);
		const tabBSections = Array.from(controlledFields[tabBIndex].sections);
		tabBSections.splice(indexB, 0, deleted);
	
		setValue(`tabs.${tabAIndex}.sections`, tabASections);
		setValue(`tabs.${tabBIndex}.sections`, tabBSections);
	};
	const reorderTabSections = (
		tabIndex: number,
		indexA: number,
		indexB: number
	) => {
		// const tab = fields[tabIndex];
		const tab = getValues().tabs[tabIndex];
		const sections = Array.from(tab.sections);
		if (sections.length < 2) return;
		sections.splice(indexA, 0, sections.splice(indexB, 1)[0]);
	
		update(tabIndex, { ...controlledFields[tabIndex], sections: sections });
		setValue(`tabs.${tabIndex}.sections`, sections);
	};
	const reorderTabs = (indexA: number, indexB: number) => {
		const list = Array.from(controlledFields);
		list.splice(indexA, 0, list.splice(indexB, 1)[0]);
		update(indexA, {
			...controlledFields[indexA],
			order: indexB + 1,
		});
		update(indexB, {
			...controlledFields[indexB],
			order: indexA + 1,
		});
		setValue('tabs', list);
	};

	const onSubmitReOrder = async () => {
		try {
			displaySnackbar('success', 'Curriculum re-ordered successfully');
			setNeedReOrder(false);
		} catch (error) {
			displaySnackbar('error', "Couldn't re-order curriculum");
		}
	};

	const cancelOrder = async () => {
		if (!currentClassroom) return;
		await dispatch(getAllClassroomTabs(currentClassroom.id)).unwrap();
		setValue('tabs', fetchAll);
		setNeedReOrder(false);
	};
	return (
		<div>
			<DevTool control={control} placement="top-left" />
			<PageWrapper>
				<FlexLayout alignItems="center">
					<EdFab onClick={onAddTab} size="small" edbackgroundColor="purple">
						<EdIcon fontSize="medium">add</EdIcon>
					</EdFab>
					<Spacer mx={'0.5rem'} />
					<FabButtonLabel>Add Tab</FabButtonLabel>
				</FlexLayout>
				<Spacer mb="2.25rem" />
				<FormProvider
					getValues={getValues}
					control={control}
					setValue={setValue}
					{...formMethods}
				>
					<CurriculumContext.Provider
						value={{
							updateTab: onUpdateForm,
							deleteTab: remove,
						}}
					>
						<DragDropContext onDragEnd={onDragEnd}>
							<div>
								<Droppable droppableId="TABS" type="TABS">
									{(
										{ droppableProps, innerRef, placeholder },
										{ isDraggingOver }
									) => {
										return (
											<TabDroppable
												isDraggingOver={isDraggingOver}
												{...droppableProps}
												ref={innerRef}
											>
												{placeholder}
												{loaders.fetchAll === 'fulfilled' &&
													controlledFields.map((tab, index) => {
														return (
															<Draggable
																draggableId={`tab-${tab.id ?? tab.fdId}`}
																key={`tab-${tab.id ?? tab.fdId}`}
																index={index}
															>
																{(
																	{ draggableProps, innerRef, dragHandleProps },
																	{ isDragging }
																) => {
																	const _tab: Tab = tab;
																	return (
																		<TabDraggable
																			{...draggableProps}
																			ref={innerRef}
																		>
																			<TabAccordion
																				index={index}
																				dragHandlers={dragHandleProps}
																				tab={_tab}
																				isDragging={isDragging}
																			/>
																		</TabDraggable>
																	);
																}}
															</Draggable>
														);
													})}
											</TabDroppable>
										);
									}}
								</Droppable>
							</div>
						</DragDropContext>
					</CurriculumContext.Provider>
				</FormProvider>
				<FlexLayout justifyContent="flex-end" width="100%">
					{needReOrder && (
						<>
							<Button onClick={cancelOrder} variant="contained" color="warning">
								Cancel
							</Button>
							<Spacer mx="1rem" />
							<Button onClick={onSubmitReOrder} variant="contained">
								Save Order
							</Button>
						</>
					)}
				</FlexLayout>
			</PageWrapper>
		</div>
	);
};

export default CurriculumForm;
