import EdSpeedDial, {
	EdSpeedDialAction,
} from '@Components/UI/Utilities/EdSpeedDial/EdSpeedDial';
import { TableCell } from '@mui/material';
import { Course } from '@Services/students/students.res.types';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import LoopIcon from '@mui/icons-material/Loop';
import ReplayIcon from '@mui/icons-material/Replay';
import { rgba } from 'polished';
import ExpiredStatus from '@Components/UI/Utilities/ExpiredStatus/ExpiredStatus';
import UserProgress from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/UserProgress';
import { useTypedSelector } from '@Features/store';
import UserProgressModel from './Models/UserProgressModel';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import { UsersRequester } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Services/Userprogress/UserProgressRequester';
import { coursesTabGetAll } from '../../Slices/CourseTab.Slices';
import { useDispatch } from 'react-redux';
import useFilters from '@Hooks/useFilters';
import useQueryURL from '@Hooks/useQueryUrl';
import { useSnackbar } from '@Providers/useSnackbar';
import { enrolledUsersUnitsGetSingle } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Users/Slice/users.Slice';
import TodayIcon from '@mui/icons-material/Today';
import ExpiredModel from './Models/ExpiredModel';
import { ActionsTableRow } from '@Components/MainTable/MainTable.styled';

type Props = {
	course: Course;
};
type Time = {
	[key: string]: number;
};

const ProgressTable: FC<Props> = ({ course }) => {
	const { currentEntity } = useTypedSelector((state) => state.Students);
	const dispatch = useDispatch();
	const { selectedFilters } = useFilters();
	const { selectedQuery } = useQueryURL();
	const { displaySnackbar } = useSnackbar();
	const [openResetModel, setOpenResetModel] = useState<boolean>(false);
	const [currentCourse, setCurrentCourse] = useState<number>();
	const [openExpireModel, setOpenExpireModel] = useState<boolean>(false);
	const [openCompleteModel, setOpenCompleteModel] = useState<boolean>(false);
	const [openSyncronizeModel, setOpenSyncronizeModel] =
		useState<boolean>(false);
	const [openUserProgressModel, setOpenUserProgressModel] =
		useState<boolean>(false);
	const UserProgressModalClose = async (close: boolean, confirm?: boolean) => {
		setOpenUserProgressModel(false);
	};
	const handleModelResetClose = async (close: boolean, confirm?: boolean) => {
		if (confirm) {
			try {
				await UsersRequester.getInstance().resetStudent(
					currentCourse as number,
					[currentEntity?.id]
				);
				displaySnackbar('success', `Course Reseted successfully`);
				dispatch(
					coursesTabGetAll({
						page: 1,
						perPage: 10,
						sortBy: '',
						filters: selectedFilters,
						query: selectedQuery,
						id: currentEntity?.id as any,
					})
				);
			} catch (e) {
				const msg = e.response?.data.message || 'Unable to Reset Course ';
				displaySnackbar('error', msg);
			}
		}
		setOpenResetModel(false);
	};

	const handleModelSyncronizeClose = async (
		close: boolean,
		confirm?: boolean
	) => {
		if (confirm) {
			try {
				await UsersRequester.getInstance().syncronizeStudent(
					currentCourse as number,
					[currentEntity?.id]
				);
				displaySnackbar('success', `Course syncronized successfully`);
				dispatch(
					coursesTabGetAll({
						page: 1,
						perPage: 10,
						sortBy: '',
						filters: selectedFilters,
						query: selectedQuery,
						id: currentEntity?.id as any,
					})
				);
			} catch (e) {
				const msg = e.response?.data.message || 'Unable to syncronize Course ';
				displaySnackbar('error', msg);
			}
		}
		setOpenSyncronizeModel(false);
	};

	const handleModelCompleteClose = async (
		close: boolean,
		confirm?: boolean
	) => {
		if (confirm) {
			try {
				await UsersRequester.getInstance().completeStudent(
					currentCourse as number,
					[currentEntity?.id]
				);
				displaySnackbar('success', `User Progress completed successfully`);
				dispatch(
					coursesTabGetAll({
						page: 1,
						perPage: 10,
						sortBy: '',
						filters: selectedFilters,
						query: selectedQuery,
						id: currentEntity?.id as any,
					})
				);
			} catch (e) {
				const msg =
					e.response?.data.message || 'Unable to complete User Progress ';
				displaySnackbar('error', msg);
			}
		}
		setOpenCompleteModel(false);
	};

	const renderStatus = (course: Course) => {
		switch (course?.progress?.progress_status) {
			case 'completed':
				return (
					<StatueText color="#00d66b">
						{course?.progress?.progress_status}
					</StatueText>
				);
			case 'in progress':
				return (
					<StatueText color="#2196f3">
						{course?.progress?.progress_status}
					</StatueText>
				);
			default:
				return (
					<StatueText color="#ff3100">
						{course?.progress?.progress_status}
					</StatueText>
				);
		}
	};

	const UserProgressExpireModalClose = async (
		close: boolean,
		confirm?: boolean
	) => {
		setOpenExpireModel(false);
	};

	const getSingleUser = (courseId: number) => {
		try {
			if (courseId) {
				dispatch(
					enrolledUsersUnitsGetSingle({
						id: courseId,
						tId: currentEntity?.id,
					})
				);
			}
		} catch (error) {}
	};

	const learningTime = useMemo(() => {
		return Number(course?.pivot.time);
	}, [course]);

	const findTime = (num: number) => {
		if (num < 1) {
			return '0';
		}
		const qualifier = (num: number) => (num > 1 ? '' : '');
		const numToStr = (num: number, unit: string) =>
			num > 0 ? `${num}${unit} ${qualifier(num)}` : '';
		const oneMinute = 60;
		const oneHour = oneMinute * 60;
		const oneDay = oneHour * 24;
		const oneYear = oneDay * 365;
		const times: Time = {
			year: ~~(num / oneYear),
			day: ~~((num % oneYear) / oneDay),
			hour: ~~((num % oneDay) / oneHour),
			minute: ~~((num % oneHour) / oneMinute),
		};
		let str = '';
		for (let [key, value] of Object.entries(times)) {
			str += numToStr(times[key], key[0]);
		}
		const arr = str.trim().split(' ');
		const res: Array<any> = [];
		arr.forEach((x, i) => {
			res.push(x);
		});
		return res.slice(0, 2).join(' ');
	};

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to reset this course?"
				open={openResetModel}
				handleClose={handleModelResetClose}
			/>
			<EdConfirmModal
				text="Are you sure you want to syncronize this course?"
				open={openSyncronizeModel}
				handleClose={handleModelSyncronizeClose}
			/>
			<EdConfirmModal
				text="Are you sure you want to complete this course?"
				open={openCompleteModel}
				handleClose={handleModelCompleteClose}
			/>
			<UserProgressModel
				handleModelClose={UserProgressModalClose}
				openModel={openUserProgressModel}
				setOpenModel={setOpenUserProgressModel}
				currentCourse={currentCourse as number}
			/>

			<ExpiredModel
				handleModelClose={UserProgressExpireModalClose}
				openModel={openExpireModel}
				setOpenModel={setOpenExpireModel}
				currentCourse={currentCourse as number}
			/>

			<TableCellStyled>
				{course?.pivot.pivot_expire_at &&
					Intl.DateTimeFormat('en-US', {
						dateStyle: 'short',
						timeStyle: 'short',
					}).format(new Date(course?.pivot?.pivot_expire_at))}
			</TableCellStyled>
			<TableCellStyled>
				{course?.progress.expired !== undefined && (
					<ExpiredStatus expired={!course?.progress?.expired} />
				)}
			</TableCellStyled>
			<TableCell>{renderStatus(course)}</TableCell>
			<TableCellStyled>
				<div
					onClick={(e) => {
						e.stopPropagation();
						setOpenUserProgressModel(true);
						setCurrentCourse(course?.id);
						getSingleUser(course?.id);
					}}
				>
					<UserProgress progress={course?.progress?.progress} />
				</div>
			</TableCellStyled>
			<TableCell>
				{course.pivot.time === null || course.pivot.time === '0' ? (
					'-'
				) : (
					<p>{findTime(learningTime)}</p>
				)}
			</TableCell>
			{course?.progress?.expired === false ? (
				<ActionsTableRow>
					<EdSpeedDialStyle ariaLabel="SpeedDial basic example">
						<EdSpeedDialActionStyle
							key="Reset"
							icon={<ReplayIcon />}
							tooltipTitle="Reset"
							onClick={(e) => {
								e.stopPropagation();
								setOpenResetModel(true);
								setCurrentCourse(course?.id);
							}}
						/>
						<EdSpeedDialActionStyle
							key="Syncronize"
							icon={<LoopIcon />}
							tooltipTitle="Syncronize"
							onClick={(e) => {
								e.stopPropagation();
								setOpenSyncronizeModel(true);
								setCurrentCourse(course?.id);
							}}
						/>
						<EdSpeedDialActionStyle
							key="Complete"
							icon={<CheckIcon />}
							tooltipTitle="Complete"
							onClick={(e) => {
								e.stopPropagation();
								setOpenCompleteModel(true);
								setCurrentCourse(course?.id);
							}}
						/>
					</EdSpeedDialStyle>
				</ActionsTableRow>
			) : (
				<ActionsTableRow>
					<EdSpeedDialStyle ariaLabel="SpeedDial basic example">
						<EdSpeedDialActionStyle
							key="Extend Course"
							icon={<TodayIcon />}
							tooltipTitle="Extend Course"
							onClick={(e) => {
								e.stopPropagation();
								setOpenExpireModel(true);
								setCurrentCourse(course?.id);
							}}
						/>
						<EdSpeedDialActionStyle
							key="Reset"
							icon={<ReplayIcon />}
							tooltipTitle="Reset"
							onClick={(e) => {
								e.stopPropagation();
								setOpenResetModel(true);
								setCurrentCourse(course?.id);
							}}
						/>
						<EdSpeedDialActionStyle
							key="Syncronize"
							icon={<LoopIcon />}
							tooltipTitle="Syncronize"
							onClick={(e) => {
								e.stopPropagation();
								setOpenSyncronizeModel(true);
								setCurrentCourse(course?.id);
							}}
						/>
						<EdSpeedDialActionStyle
							key="Complete"
							icon={<CheckIcon />}
							tooltipTitle="Complete"
							onClick={(e) => {
								e.stopPropagation();
								setOpenCompleteModel(true);
								setCurrentCourse(course?.id);
							}}
						/>
					</EdSpeedDialStyle>
				</ActionsTableRow>
			)}
		</>
	);
};

export const TableCellStyled = styled(TableCell)`
	font-family: Roboto;
	font-size: 1rem;
	padding: 0 1.063rem 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;
const EdSpeedDialActionStyle = styled(EdSpeedDialAction)`
	z-index: 999;
	&:hover {
		background-color: ${(props) =>
			rgba(props.theme.palette.primary.main, 0.95)};
	}
`;
const StatueText = styled.div<{ color: string }>`
	color: ${({ color }) => color};
	font-weight: 500;
	text-transform: capitalize;
`;
const EdSpeedDialStyle = styled(EdSpeedDial)`
	z-index: 999;
`;
export default ProgressTable;
