import {
	object,
	number,
	array,
	string,
	StringSchema,
	boolean,
	date,
} from 'yup';

class QuestionTypeSchema extends StringSchema<QuestionType> {
	static create() {
		return new QuestionTypeSchema();
	}
}
export const QUESTION_SCHEMA = object({
	id: number(),
	type: QuestionTypeSchema.create().required(),
	question: string().required(),
	order: number().required(),
	options: array(string().required()).when('type', {
		is: 'mcq',
		then: array(string().required()).min(1).required(),
		otherwise: array(string().required()).nullable(true),
	}),
	created_at: date().nullable(),
	updated_at: date().nullable(),
	questionId: string(),
});

export const ADMISSION_SCHEMA = object({
	id: number(),
	admissionForm: object({
		name: string().min(1).required(),
		description: string().min(1).required(),
		active: boolean().default(false),
		created_at: date().nullable(),
		updated_at: date().nullable(),
	}).required(),
	questions: array(QUESTION_SCHEMA.required()),
});
