import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import EdCheckbox from '@Components/UI/Inputs/EdCheckbox/EdCheckbox';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import EdTransferList from '@Components/UI/Inputs/EdTransferList/EdTransferList';
import { useAppThunkDispatch, useTypedSelector } from '@Features/store';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { useRouteProvider } from '@Providers/Routes/RoutesProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
	EditTestModelSchema,
	TestModelPayload,
} from '../../../../Schema/TestModel.schema';

import { testQuestionsGetAll } from '../../../../Slice/TestQuestions/TestQuestionsSlice';
import { TestQuestion } from '../../../../Types/TestQuestion';
import {
	ChosenQuestions,
	OverallScore,
	QuestionsToTransfer,
	TransferListHeader,
} from '../../Components/FormUtils/FormUtils.styled';
import { sum } from 'lodash';
import {
	testModelsToggleActive,
	testModelsUpdate,
} from '../../../../Slice/TestModel/TestModel.slice';
import { TestModel } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/CourseForm/Pages/CourseContent/Pages/Test/Types/TestModel.types';
const EditTestModelInfo = () => {
	const dispatch = useAppThunkDispatch();
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const {
		currentEntity: TestModel,
		loaders: { update },
	} = useTypedSelector((state) => state.TestModels);
	const { fetchAll } = useTypedSelector((state) => state.TestQuestions);
	const methods = useRouteProvider();
	const { displaySnackbar } = useSnackbar();

	useEffect(() => {
		if (currentEntity && currentEntity?.id)
			dispatch(
				testQuestionsGetAll({
					page: 1,
					perPage: 1000,
					filters: [{ test_id: currentEntity?.id }],
					export: false,
					query: '',
					sortBy: null,
				})
			);
	}, [currentEntity, dispatch]);

	const {
		control,
		formState: { isValid, isDirty, isSubmitting },
		handleSubmit,
	} = useForm<TestModelPayload>({
		resolver: yupResolver(EditTestModelSchema),
		mode: 'all',
		defaultValues: {
			...TestModel,
			test_id: currentEntity?.id,
			questions: TestModel?.questions?.map((_) => _?.id),
		},
	});

	const onSubmit = async (form: TestModelPayload) => {
		try {
			await dispatch(testModelsUpdate(form));
			displaySnackbar('success', 'Updated Test model successfully');
			methods?.goToParent();
		} catch (error) {
			displaySnackbar('error', "Couldn't update test model");
		}
	};

	return (
		<div>
			<DevTool control={control} />
			<EdFormControl
				required
				label={'Test Model Name'}
				control={control}
				name={'name'}
			/>
			<Spacer mb={'1.625rem'} />
			<EdTransferList
				control={control}
				name={'questions'}
				list={fetchAll}
				listKey={'id'}
				listsNames={['Available test questions', 'Model questions']}
			>
				{{
					listHeader: TransferListHeader,
					chosenHeader: TransferListHeader,
					chosenHeaderExtra: (list) => {
						const totalScore = sum(list.map((_) => _.weight));
						return <OverallScore value={totalScore} />;
					},
					list: (item: TestQuestion, index: number) => {
						return (
							<QuestionsToTransfer
								key={`${item.id}-${index}`}
								item={item}
								index={index}
							/>
						);
					},
					chosen: (item: TestQuestion, index: number) => {
						return (
							<ChosenQuestions
								key={`${item.id}-${index}`}
								item={item}
								index={index}
							/>
						);
					},
				}}
			</EdTransferList>

			<GridLayout gridGap={'4rem'}>
				<div></div>
				<div>
					<FlexLayout flexDirection={'column'}>
						<EdCheckbox
							control={control}
							name="shuffle_questions"
							label="Shuffle questions"
						/>
						<EdCheckbox
							disabled
							control={control}
							name="shuffle_answers"
							label="Shuffle answers"
						/>
					</FlexLayout>
				</div>
			</GridLayout>
			{!currentEntity.active && (
				<FlexLayout gridGap={'1rem'} justifyContent="flex-end">
					<Button
						onClick={() => methods?.goToParent()}
						disabled={!isDirty}
						variant={'contained'}
						color={'warning'}
					>
						Cancel
					</Button>
					<EdButton
						onClick={handleSubmit(onSubmit)}
						type="submit"
						disabled={!isValid || !isDirty || isSubmitting}
						variant={'contained'}
						edcolor="dodgerBlue"
					>
						Save
					</EdButton>
				</FlexLayout>
			)}
		</div>
	);
};

export default EditTestModelInfo;
