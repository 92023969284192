import { TableBody, TableCell, TableRow } from '@mui/material';
import { InputBase } from '@mui/material';
import { LongTextWrapper } from '@Styled/utilities';
import styled from 'styled-components';
import { TextAlignProps, textAlign } from 'styled-system';
export const HandlersContainer = styled.div`
	display: flex;
	/* max-width: 45%; */
	width: 100%;
	align-items: center;
`;

export const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: '2.188rem',
	border: 'solid 0.063rem ',
	borderColor: '#6c63ff',
	backgroundColor: '#ffffff',
	marginRight: '10px',
	// marginLeft: '89px',
	maxWidth : '32.4rem',
	width: '31.438rem',
	height: '2.563rem',
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	right: '0',
	pointerEvents: 'none',
	display: 'flex',
	borderColor: '#6c63ff',
	alignItems: 'center',
	justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
	},
}));

export const StyledMainTableRow = styled(TableRow)<TextAlignProps>`
	td,
	th {
		height: 4rem;
		${textAlign}
		cursor: pointer;
	}
	.MuiTableCell-body {
		font-family: Roboto;
		font-size: 1rem;
		padding: 0 1.063rem 0;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

export const StyledTableBody = styled(TableBody)<{ $hasActions?: boolean }>`
	/* .MuiTableCell-body:last-of-type {
    position: sticky;
    right: 0;
  } */
`;
export const ActionsTableRow = styled(TableCell)`
&.MuiTableCell-root{
	overflow: unset;
}
`