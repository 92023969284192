import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import {
	QueryStringBase,
	useQueryString,
} from '@Hooks/useQueryString/useQueryString';
import {
	ClickAwayListener,
	Divider,
	IconButton,
	MenuItem,
	TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { ExtraPropsBase, getVariant } from '@Styled/theme';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'querystring';

import { FlexLayout, Spacer, VerticalDivider } from '@Styled/utilities';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import EdButton from '@Components/UI/Buttons/EdButton/EdButton';
import { debounce } from 'lodash';
import { LayoutProps, position, PositionProps } from 'styled-system';
export type QueryStringAppBar = {
	[`FILTER_tags.name`]: string;
	query?: string;
	expand?: string;
} & QueryStringBase;

type Props = {
	title?: string;
	filters: Array<FetchFilter>;
	onPageRequest: (args: onPageChangeRequestMeta) => void;
	renderExtraActions?: () => React.ReactNode;
} & ExtraPropsBase &
	PositionProps;
const EdTableAppBar: FC<Props> = ({
	title,
	filters,
	onPageRequest,
	renderExtraActions,
	...props
}) => {
	const { params, edit, remove } = useQueryString<QueryStringAppBar>();
	const { pathname } = useLocation();
	const { search } = useLocation();
	const [queryOpened, setQueryOpened] = useState(false);
	const [initialFilters, setInitialFilters] = useState(
		qs.parse(search.replace('?', ''))
	);
	const [query, setQuery] = useState<string>(params.query || '');

	useEffect(() => {
		const getFilers = () => {
			const _filters = Object.keys(params).map((_) => {
				const filterName = `${_.replace('FILTER_', '')}`;
				if (_.includes('FILTER')) {
					return {
						[`${filterName}`]: params[_ as keyof QueryStringAppBar],
					};
				}
			});
			return _filters.filter((_) => _);
		};
		onPageRequest({
			activePage: params.page ?? 1,
			perPage: params.perPage ?? 10,
			filters: getFilers(),
			sortBy: null,
			query: query,
			export: false,
		});
	}, [params, onPageRequest, query]);

	const handleFilterChange = async (filter: FetchFilter, value: any) => {
		if (value === '') {
			const key = `FILTER_${filter.value}` as keyof QueryStringAppBar;
			remove(key);
		} else {
			edit({
				[`FILTER_${filter.value}` as keyof QueryStringAppBar]: value,
			});
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (query === '') {
				remove('query');
			} else {
				edit({ query });
			}
		}, 500);

		return () => clearTimeout(timer);
	}, [query]);

	const handleQueryChange = (value?: string) => {
		setQuery(value ? value : '');
	};

	return (
		<TestHeader {...props}>
			<FlexLayout alignItems="center" justifyContent="space-between">
				{title && <AppBarTitle>{title}</AppBarTitle>}
				<FlexLayout>
					{filters && filters.length > 0 && (
						<FlexLayout>
							{filters.map((filter, index) => {
								const value =
									initialFilters[`FILTER_${filter.value}`] ||
									params[`FILTER_${filter.value}` as keyof QueryStringAppBar] ||
									'';
								return (
									<FlexLayout
										key={`${filter.name}-${index}`}
										alignItems="center"
									>
										<FilterLabel>{filter.name}</FilterLabel>
										<Spacer mx="0.75rem" />
										<CustomSelect
											onChange={(e) =>
												handleFilterChange(filter, e.target.value)
											}
											value={value}
											SelectProps={{
												IconComponent: (props) => (
													<EdIcon {...props}>keyboard_arrow_down</EdIcon>
												),
											}}
											hiddenLabel={!!value}
											size="small"
											variant="filled"
											label={value ? '' : 'All Tags'}
											InputLabelProps={{
												disableAnimation: true,
												focused: false,
												sx: {
													fontWeight: '600',
												},
											}}
											select
										>
											<MenuItem value={''}>None</MenuItem>
											{filter.options.map((opt, index) => (
												<MenuItem
													key={`${opt.name}-${index}`}
													value={opt.value}
												>
													{opt.name}
												</MenuItem>
											))}
										</CustomSelect>
									</FlexLayout>
								);
							})}
							<StyledDivider orientation="vertical" variant="middle" />
						</FlexLayout>
					)}

					{renderExtraActions && (
						<>
							<FlexLayout alignItems="center">
								{renderExtraActions()}
							</FlexLayout>
						</>
					)}
					<FlexLayout alignItems="center">
						<ClickAwayListener onClickAway={() => setQueryOpened(false)}>
							<SearchWrapper>
								<IconButton onClick={() => setQueryOpened(!queryOpened)}>
									<EdIcon>search</EdIcon>
								</IconButton>
								<SearchInput opened={queryOpened}>
									<input
										title="search"
										value={query}
										onChange={(e) => handleQueryChange(e.target.value)}
									/>
								</SearchInput>
							</SearchWrapper>
						</ClickAwayListener>
					</FlexLayout>
				</FlexLayout>
			</FlexLayout>
		</TestHeader>
	);
};

export default EdTableAppBar;
const TestHeader = styled.div<ExtraPropsBase>`
	padding: 0.8rem 1.5rem;
	background: ${grey['100']};
	${(props) => getVariant({ prop: 'edcolor', variantPath: 'appbars' })}
	${position}
  box-shadow: 0 5px 3px 0px #0000000d;
`;

const AppBarTitle = styled.div`
	font-weight: bold;
`;
const CustomSelect = styled(TextField)`
	max-width: 8rem;
	overflow: hidden;
	.MuiFilledInput-root {
		background-color: none;
		:hover {
			background-color: transparent;
			::before {
				border-bottom: transparent;
			}
		}
		::before {
			border-bottom: none;
		}
	}
	.Mui-focused {
		::after {
			border-bottom: none;
			transform: none;
		}
	}
	.MuiSelect-select {
		min-width: 8rem;
		max-width: 8rem;
	}
`;

const FilterLabel = styled.label`
	color: ${grey['400']};
`;

const StyledDivider = styled(Divider)`
	height: unset;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
`;

const SearchWrapper = styled.div`
	position: relative;
	z-index: 10;
	padding: 0.5rem;
`;

const SearchInput = styled.div<{ opened: boolean }>`
	position: absolute;
	border-radius: 30px;

	background: ${(props) => (props.opened ? '#FFF' : 'transparent')};
	min-width: ${(props) => (props.opened ? '35rem' : '0px')};
	/* max-width: 40vw; */
	height: 100%;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: -1;
	transition: all ease-in-out 200ms;
	overflow: hidden;
	padding: 0 1rem;
	input {
		display: ${(props) => (props.opened ? 'block' : 'none')};
		border: none;
		height: 100%;
		width: 100%;
		outline: none;
	}
`;
