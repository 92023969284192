import {
	Checkbox,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Grants } from '../../Types/Assistants.types';

type Props = {
	permission: Grants;
	onGrantSelect: (selected: boolean, value: number) => void;
	currentGrant: Array<number>;
};

const PermissionContainer: FC<Props> = ({
	permission,
	onGrantSelect,
	currentGrant,
}) => {
	const select = useMemo(() => {
		return currentGrant.find((_) => _ === permission.id) ? true : false;
	}, [currentGrant, permission]);

	return (
		<ListItemButton>
			<NameWrapper>
				<ListItemIcon>
					<Checkbox
						edge="start"
						checked={select ? true : false}
						onClick={() => {
							onGrantSelect(!select, permission?.id);
						}}
						inputProps={{
							'aria-labelledby': `checkbox-list-label-${permission?.id}`,
						}}
					/>
				</ListItemIcon>

				<ListItemText primary={permission?.name} />
			</NameWrapper>
		</ListItemButton>
	);
};

const NameWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.25rem;
	width: 100%;
	font-size: 0.875rem;
	.MuiTypography-root {
		font-family: Montserrat;
		font-size: 0.875rem;
		font-weight: 600;
		color: #251b33;
	}
	.MuiListItemIcon-root {
		min-width: 0rem;
		min-height: 0rem;
	}
	.MuiButtonBase-root {
		padding: 0;
		margin-right: 0.5rem;
	}
	svg {
		width: 1.125rem;
		height: 1.125rem;
	}
`;

export default PermissionContainer;
