import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import {
	GraphSlice,
	GraphSliceType,
} from '@Services/classrooms/classrooms.res.types';
import styled from 'styled-components';

type Props = {
	data: GraphSlice[];
	sliceType?: GraphSliceType;
	colors?: any;
};
const MultipleLinesChart: React.FC<Props> = ({
	data,
	sliceType = 'day',
	colors,
}) => {
	return (
		<div>
			<ChartStyle>
				<ResponsiveLine
					xScale={{ type: 'point' }}
					yScale={{ type: 'linear' }}
					lineWidth={2}
					areaOpacity={0.1}
					isInteractive
					useMesh
					enableGridX
					enableGridY
					enableCrosshair
					enableSlices="x"
					animate
					axisLeft={{
						format: (e) => Math.floor(e) === e && e,
					}}
					axisBottom={{
						legendPosition: 'middle',
						legend: sliceType,
						legendOffset: 75,
						ticksPosition: 'after',
						tickSize: 20,
					}}
					colors={colors}
					crosshairType="cross"
					enablePoints
					legends={[
						{
							anchor: 'top-right',
							direction: 'column',
							itemWidth: 30,
							itemHeight: 20,
						},
					]}
					margin={{ left: 30, bottom: 150, top: 30, right: 30 }}
					data={data}
				/>
			</ChartStyle>
		</div>
	);
};

const ChartStyle = styled.div`
	min-width: 0;
	min-height: 50vh;
	height: 70vh;
	padding: 1rem;
`;

export default MultipleLinesChart;
