import { BaseSliceController } from '@Base/slices/slices.base';
import { AssignmentSubmissionRequester } from '../Services/Assignments.requester';
import { SubmissionsRequester } from '../Services/Submissions.requester';
import { AssignmentSubmission, Submissions } from '../Types/Submissions.types';

export class AssignmentsSubmissionsSlice extends BaseSliceController<
	AssignmentSubmission,
	'AssignmentsSubmissions',
	AssignmentSubmission
> {
	static instance = new AssignmentsSubmissionsSlice();

	constructor() {
		super('AssignmentsSubmissions', AssignmentSubmissionRequester.instance);
	}
}

export const { assignmentsSubmissionsGetAll } = AssignmentsSubmissionsSlice.instance.getActions();
