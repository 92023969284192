import { MenuItem, Tooltip } from '@mui/material';
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SideMenuMobileGroupList, StyledMenuItem } from '../Menu.styled';
import { RouteGroup } from '../../../../constants/routes';
import { SideMenuContext, SideMenuProps } from '../Menu';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';

type RouteGroupProps = {
	item: RouteGroup;
};
const RouteGroupItem: React.FC<RouteGroupProps> = ({ item }) => {
	const history = useHistory();
	const { pathname } = useLocation();

	const [menuOpened, setMenuOpened] = useState(false);
	const { opened } = useContext(SideMenuContext);
	const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>();
	const [menuHeight, setMenuHeight] = useState(0);
	const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

	const MenuItemRef = useCallback(
		(e: HTMLLIElement) => {
			if (e) {
				if (mobileMenuOpened) {
					setAnchorEl(e);
				} else {
					setAnchorEl(null);
				}
			}
		},
		[mobileMenuOpened]
	);

	const handleMobileMenuClose = () => setMobileMenuOpened(false);

	const handleNavigation = (route: string) => {
		if (opened) {
			history.push(`/dashboard/${route}`);
		} else {
			setMobileMenuOpened(true);
		}
	};
	const handleChildNavigation = (route: string) => {
		history.push(`/dashboard/${route}`);
		setMobileMenuOpened(false);
	};

	const isSelected = useMemo(() => {
		return pathname.includes(item.route);
	}, [item.route, pathname]);

	useEffect(() => {
		setMenuOpened(isSelected);
	}, [isSelected]);

	const groupListRef = useCallback(
		(e: HTMLDivElement) => {
			if (e && isSelected) {
				setMenuHeight(e.scrollHeight);
			}
			return e as Element;
		},
		[isSelected]
	);

	return (
		<div>
			<StyledMenuItem
				$sidemenuopened={opened}
				selected={isSelected}
				onClick={() => handleNavigation(item.route)}
				ref={MenuItemRef}
			>
				<Tooltip placement="top" title={item.title}>
					<div>
						<EdIcon style={{ opacity: isSelected ? 1 : 0.5, color: '#6C63FF' }}>
							{item.icon}
						</EdIcon>
					</div>
				</Tooltip>
				<label>{item.title}</label>
			</StyledMenuItem>
			{(item.routes.length > 0 || item.isRouted) && (
				<SideMenuMobileGroupList
					onClose={handleMobileMenuClose}
					anchorEl={anchorEl}
					anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
					open={mobileMenuOpened}
				>
					{item.isRouted && (
						<MenuItem onClick={() => handleChildNavigation(item.route)}>
							{item.title}
						</MenuItem>
					)}
					{item.routes.map((route, index) => {
						if (!route.offMenu)
							return (
								<MenuItem
									key={`${index}-${route.route}`}
									onClick={() =>
										handleChildNavigation(`${item.route}/${route.route}`)
									}
								>
									{route.title}
								</MenuItem>
							);
					})}
				</SideMenuMobileGroupList>
			)}
			<GroupListWrapper
				height={menuHeight}
				ref={groupListRef}
				opened={menuOpened}
				$sidemenuopened={opened}
			>
				{item.routes.length > 0 && (
					<GroupList>
						{item.routes.map((route, index) => {
							if (!route.offMenu) {
								const isSelected = pathname.endsWith(
									`${item.route}/${route.route}`
								);
								return (
									<SingleRouteItem
										isselected={isSelected}
										key={`${index}-${route.route}-rgi`}
										onClick={() =>
											handleChildNavigation(`${item.route}/${route.route}`)
										}
										$sidemenuopened={opened}
									>
										<SelectedDot />
										{route.title}
									</SingleRouteItem>
								);
							}
						})}
					</GroupList>
				)}
			</GroupListWrapper>
		</div>
	);
};

export default RouteGroupItem;

const SelectedDot = styled.div`
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: #fff;
	margin-right: 1rem;
`;
const GroupList = styled.div`
	color: ${(props) => props.theme.palette.grey['600']};
	grid-column: 2/2;
	margin: 0 2rem;
	font-size: 0.75rem;
`;
const GroupListWrapper = styled.div<{
	opened: boolean;
	height: number;
	$sidemenuopened?: boolean;
}>`
	display: grid;
	grid-template-columns: 0.5fr 4fr;
	max-height: ${(props) => (props.opened ? `${props.height}px` : '0px')};
	overflow: hidden;
	transition: all ease-in-out 450ms;
	${GroupList} {
		display: ${(props) => (!props.$sidemenuopened ? 'none' : '')};
	}
`;
const SingleRouteItem = styled.div<SideMenuProps & { isselected?: boolean }>`
	margin: 12px 2rem;
	opacity: ${(props) => (props.$sidemenuopened ? '1' : '0')};
	transition: inherit;
	color: ${(props) => (props.isselected ? '#e2e1e4' : '')};
	cursor: pointer;
	display: flex;
	align-items: center;
	${SelectedDot} {
		opacity: ${(props) => (props.isselected ? '1' : '0')};
	}
`;
