import { useTypedSelector } from '@Features/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { FlexLayout, GridLayout, Spacer } from '@Styled/utilities';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GAP_SCHEMA } from '../../../Schema/Gap.schema';
import { TypedTestQuestionPayload } from '../../../Types/TestQuestion';
import { Editor as tinymceEditor } from 'tinymce';
import EdFormControl from '@Components/UI/Inputs/EdFormControl/EdFormControl';
import {
	Button,
	Checkbox,
	InputAdornment,
	ListItemText,
	MenuItem,
	Typography,
} from '@mui/material';
import TagChip from '@Components/UI/Chips/TagChip/TagChip';
import EdIcon from '@Components/UI/Utilities/EdIcon/EdIcon';
import {
	EdFormControlLabel,
	EdFormControlError,
} from '@Components/UI/Inputs/EdFormControlLabel/EdFormControlLabel';
import { DevTool } from '@hookform/devtools';
import { useHistory } from 'react-router-dom';
import { TestQuestionsRequester } from '../../../Services/TestQuestions/TestQuestionsRequester';
import { useSnackbar } from '@Providers/useSnackbar';
import { useGotoParentRoute } from '@Providers/Routes/RoutesProvider';
import styled from 'styled-components';
import TinyMceControl from '@Components/UI/TinyMceControl/TinyMceControl';
import AddTag from '../../../Components/AddTag';
const EditGapQuestionForm = () => {
	const [addedId, setAddedId] = useState<number | undefined>();
	const { currentEntity } = useTypedSelector((state) => state.Tests);
	const editorRef = useRef<tinymceEditor | null>(null);
	const feedbackEditorRef = useRef<tinymceEditor | null>(null);
	const { dropdownTags } = useTypedSelector((state) => state.dropdowns);
	const { currentEntity: currentQuestion } = useTypedSelector(
		(state) => state.TestQuestions
	);
	const { control, watch, formState, handleSubmit, setValue } = useForm<
		TypedTestQuestionPayload<'gap'>
	>({
		mode: 'all',
		resolver: yupResolver(GAP_SCHEMA),
		defaultValues: {
			...currentQuestion,
			test_id: currentEntity?.id,
			tags: currentQuestion?.tags.map((q) => q.id),
			type: 'gap',
		},
	});
	const watchTags = watch('tags');

	useEffect(() => {
		if (addedId !== undefined) {
			setValue('tags', [...watchTags, addedId]);
		}
	}, [addedId]);

	const { displaySnackbar } = useSnackbar();
	const history = useHistory();
	const onSave = async (form: TypedTestQuestionPayload<'gap'>) => {
		try {
			await TestQuestionsRequester.instance.update(form);
			displaySnackbar('success', 'Updated question successfully');
			history.goBack();
		} catch (error) {
			displaySnackbar('error', "Couldn't update question");
		}
	};
	const goToParent = useGotoParentRoute();
	const { isDirty } = formState;

	return (
		<FlexLayout flexDirection="column" px="2.625rem" flex="1">
			<FlexLayout flex="1">
				<Controller
					control={control}
					name="content"
					render={({
						field: { value, onChange, ...props },
						fieldState: { error },
					}) => {
						return (
							<FlexLayout flexDirection={'column'} width="100%">
								<TinyMceControl
									disabled={currentEntity.active}
									{...props}
									value={value}
									onEditorChange={(e) => onChange(e)}
									onInit={(evt, editor) => (editorRef.current = editor)}
								/>
								<EdFormControlError error={error}>
									{error?.message}
								</EdFormControlError>
							</FlexLayout>
						);
					}}
				/>
			</FlexLayout>
			<Note>
				Note: Compose the question and use [ ] for the possible answers. For
				example, The [(biggest),bigger] planet of our solar system is
				[jupiter,(neptune),earth]. When you use , to offer multiple answers the
				one with ( ) should be the correct one.
			</Note>
			<Spacer my="1.5rem" />
			<GridLayout gridTemplateColumns={'repeat(1,1fr)'} gridGap="1.875rem">
				<FlexLayout alignItems="center">
					<EdFormControl
						width="5rem"
						type="number"
						control={control}
						disabled={currentEntity.active}
						name="weight"
						label="Weight"
					/>
					<Spacer mx="0.5rem" />
					<Typography fontWeight="600">Points</Typography>
				</FlexLayout>
				<FlexLayout gridGap="1rem">
					<EdFormControl
						disabled={currentEntity.active}
						label="Tags"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<EdIcon mIconType="Regular">local_offer</EdIcon>
								</InputAdornment>
							),
						}}
						SelectProps={{
							multiple: true,
							renderValue: (value) => {
								const values = Array.from(value as number[]);
								return (
									<>
										{values.map((v) => {
											const tag = dropdownTags.find((_) => _.id === v);
											return <>{tag?.name},</>;
										})}
									</>
								);
							},
						}}
						control={control}
						name="tags"
						select
						multiple
						renderValues={(value) => {
							return (
								<>
									<Spacer mb="1rem" />
									<FlexLayout
										// maxWidth={"17rem"}
										overflow="auto"
										py={'0.4rem'}
										flexWrap="wrap"
										gridGap="0.5rem"
									>
										{dropdownTags
											.filter((_) => watchTags.includes(_.id))
											.map((tag) => {
												return (
													<TagChip
														bgColor={tag.theme}
														icon={<EdIcon>local_offer</EdIcon>}
														label={tag.name}
													/>
												);
											})}
									</FlexLayout>
								</>
							);
						}}
					>
						{dropdownTags.map((tag, index) => {
							return (
								<MenuItem value={tag.id} key={`tag-${tag.id}`}>
									<Checkbox
										sx={{ color: tag.theme }}
										checked={watchTags.includes(tag.id)}
									/>
									<ListItemText primary={tag.name} />
								</MenuItem>
							);
						})}
					</EdFormControl>
					{!currentEntity.active && <AddTag setAddedId={setAddedId} />}
				</FlexLayout>
				<FlexLayout>
					<EdFormControlLabel>Feedback</EdFormControlLabel>
					<Controller
						control={control}
						name="feedback"
						render={({ field: { onChange, value, ...props }, fieldState }) => {
							return (
								<TinyMceControl
									disabled={currentEntity.active}
									{...props}
									min_height={200}
									value={value}
									onEditorChange={(e) => onChange(e)}
									onInit={(evt, editor) => (feedbackEditorRef.current = editor)}
								/>
							);
						}}
					/>
				</FlexLayout>
			</GridLayout>
			{!currentEntity.active && (
				<FlexLayout mt="1.688rem" justifyContent="flex-end">
					<Button
						variant="contained"
						color="warning"
						onClick={() => goToParent()}
					>
						Cancel
					</Button>
					<Spacer mx="0.5rem" />
					<Button
						onClick={handleSubmit(onSave, (e) => console.log(e))}
						disabled={!isDirty}
						variant="contained"
					>
						Save
					</Button>
				</FlexLayout>
			)}
			<DevTool control={control} />
		</FlexLayout>
	);
};

export const Note = styled.div`
	font-size: 1rem;
	font-weight: normal;
	color: ${(props) => props.theme.palette.platinum?.[600]};
	margin-bottom: 1rem;
	line-height: 1.5rem;
`;
export default EditGapQuestionForm;
