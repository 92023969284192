import { createApi } from '@reduxjs/toolkit/dist/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import api from '@Services/api';

type AxiosBaseQueryArgs = {
	url: string;
	method: AxiosRequestConfig['method'];
	data?: AxiosRequestConfig['data'];
	params?: AxiosRequestConfig['params'];
};

type AxiosBaseQuery = BaseQueryFn<AxiosBaseQueryArgs, unknown, unknown>;

const axiosBaseQuery =
	({ baseUrl }: { baseUrl: string } = { baseUrl: '' }): AxiosBaseQuery =>
	async ({ url, method, data, params }) => {
		try {
			const result = await api({ url: baseUrl + url, method, data, params });
			return { data: result.data };
		} catch (axiosError) {
			let err = axiosError as AxiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data || err.message,
				},
			};
		}
	};

export const baseApiSlice = createApi({
	reducerPath: 'base-api',
	baseQuery: axiosBaseQuery(),
	endpoints: () => ({}),
	tagTypes: ['Admission', 'DiscussionQuestion'],
});
