import { array, boolean, number, object, string } from 'yup';

const emailValidation =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^ <>()[\]\\., ;: \s @"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneValidation = /^01[0-2,5]{1}[0-9]{8}$/;

export const NOTE_SCHEMA = object({
	name: string().required(),
	price: number().positive().required(),
	old_price: number().optional(),
	description: string(),
	purchase_link: string().matches(
		/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/g,
		{ message: 'Invalid url' }
	),
	photo: string().required(),
	education_year_id: number().required(),
});

export const EDIT_NOTE = object({
	id: number().required(),
}).concat(NOTE_SCHEMA);
