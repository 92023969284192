import { ClassroomUnitsAnalytics } from '@Pages/ClassRooms/Pages/SingleClassroom/Components/ClassroomUnitsAnalytics/Types/ClassroomUnitsAnalytics.types';
import { CourseUnitsAnalytics } from '@Pages/ClassRooms/Pages/SingleCourse/Pages/Analytics/CourseUnitsAnalytics/Types/CourseUnitsAnalytics.types';
import api from '@Services/api';
import EdResponse from '@Services/generics/response';
import {
	AllClassroomsGraphPayload,
	ClassroomEnrollment,
	ClassroomGraphPayload,
	ClassroomSection,
	CourseEnrollment,
	CourseGraphPayload,
	FetchClassroomsResponse,
	GetAllCoursesResponse,
	GetAllEnrollmentResponse,
	GetEnrolledResponse,
	GetSingleClassroomResponse,
	GetSingleCourseResponse,
	GetUnEnrolledResponse,
	GraphSlice,
	SingleClassroomData,
	SingleCourseResponse,
	UpdatePrerequisitesResponse,
} from './classrooms.res.types';

/* classrooms */
export const getAllClassRoomsRequest = async (
	page: number,
	perPage: number,
	filters: Array<any>,
	sortBy: any,
	query: string,
	from?: Date,
	to?: Date
) =>
	await api.post<FetchClassroomsResponse>('classroom/all', {
		page,
		perPage,
		filters,
		// sortBy: Object.keys(sortBy).length > 0 ? sortBy : undefined,
		sortBy,
		query,
		from,
		to,
	});

export const getSingleClassroomRequest = async (label: string) =>
	await api.post<GetSingleClassroomResponse>('classroom', { label });

interface getReqType {
	page: number;
	perPage: number;
	classroom_id: number;
	sortBy?: any;
	filters: Array<any>;
	query: string;
	from?: Date;
	to?: Date;
}

export const getAllEnrollments = async (
	page: number,
	perPage: number,
	classroom_id: number,
	filters: any,
	query: string,
	sortBy: any,
	from?: Date,
	to?: Date
) => {
	const data: getReqType = {
		page,
		perPage,
		classroom_id,
		filters,
		query,
		from,
		to,
		sortBy,
	};
	return await api.post<GetAllEnrollmentResponse>(
		'classroom/enrollments',
		data
	);
};

export const deleteClassroomRequest = async (label: string) =>
	await api.delete('classroom/delete', {
		data: {
			label,
		},
	});

export const classroomsExportAll = async (data: { from?: Date; to?: Date }) =>
	await api.post('enrollment/classrooms/analytic/export', data);

export const createClassroom = async (data: any) =>
	await api.post<ApiResponse<SingleClassroomData>>('classroom/create', data);

type UploadPhotoArgs = {
	blob: Blob;
	label: string;
};
export const uploadClassroomPhoto = async ({
	blob,
	label,
}: UploadPhotoArgs) => {
	const formData = new FormData();
	formData.append('photo', blob);
	formData.append('label', label);
	return await api.post('classroom/photo/upload', formData);
};
export const toggleBanStudentClassroom = async (data: {
	classroom_id: number;
	user_id: number;
	active: boolean;
	note ? : string
}) => await api.post('user/ban/toggle', data);

export const updateClassroom = async (data: any) =>
	await api.put<ApiResponse<SingleClassroomData>>('classroom', data);

/* courses */

export const getAllCoursesRequest = async (
	page: number,
	perPage: number,
	filters: Array<any>,
	sortBy: any,
	query: string,
	classroom: string,
	from?: Date,
	to?: Date
) =>
	await api.post<GetAllCoursesResponse>('course/all', {
		page,
		perPage,
		filters,
		sortBy,
		query,
		classroom,
		from,
		to,
	});

export const getSingleCourseRequest = async (id: string) =>
	await api.post<GetSingleCourseResponse>('course', { id });

export const getClassroomSectionsRequest = async (id: string) =>
	await api.get<EdResponse<ClassroomSection[]>>(`dropdown/sections/${id}`);

export const deleteCourseRequest = async (id: number) =>
	await api.delete('course/delete', {
		data: {
			id,
		},
	});

export const updateCourse = async (data: any, id?: number) =>
	await api.put('course/update', { ...data, id });

//create course
export const createCourse = async (data: any, classroom_id?: number) => {
	return await api.post<SingleCourseResponse>('course/create', {
		...data,
		classroom_id,
	});
};

/* enrollment */
export const getUnenrolledUsersRequest = async (
	page: number,
	perPage: number,
	filters: Array<any>,
	sortBy: any,
	query: string,
	courseId: number,
	from?: Date,
	to?: Date
) =>
	await api.post<GetUnEnrolledResponse>('course/unenrolled', {
		page,
		perPage,
		filters,
		sortBy: Object.keys(sortBy).length > 0 ? sortBy : undefined,
		query,
		id: courseId,
		from,
		to,
	});

export const getEnrolledUsersRequest = async (
	page: number,
	perPage: number,
	filters: Array<any>,
	sortBy: any,
	query: string,
	courseId: number,
	from?: Date,
	to?: Date
) =>
	await api.post<GetEnrolledResponse>('course/enrolled', {
		page,
		perPage,
		filters,
		sortBy: Object.keys(sortBy).length > 0 ? sortBy : undefined,
		query,
		courseId: courseId,
		from,
		to,
	});

export const getCourseUsersRequest = async (
	page: number,
	perPage: number,
	filters: Array<any>,
	sortBy: any,
	query: string,
	courseId: number,
	from?: Date,
	to?: Date
) => {
	return await api.post<GetEnrolledResponse>(`course/students`, {
		page,
		perPage,
		filters,
		sortBy,
		query,
		courseId: courseId,
		from,
		to,
	});
};
export const getCourseProgressRequest = async (
	page: number,
	perPage: number,
	filters: Array<any>,
	sortBy: any,
	query: string,
	courseId: number,
	from?: Date,
	to?: Date
) => {
	return await api.post<GetEnrolledResponse>(`course/students?enrolled=true`, {
		page,
		perPage,
		filters,
		sortBy,
		query,
		courseId: courseId,
		from,
		to,
	});
};

export const getEnrolledCountRequest = async (
	classroomId: number,
	courseId?: number
) =>
	await api.post('enrollment/count', {
		classroom_id: classroomId,
		course_id: courseId,
	});

export const getEnrolledAnalysisRequest = async (
	classroomId: number,
	flag: boolean,
	from?: Date,
	to?: Date
) =>
	await api.post('enrollment/classroom/analytic', {
		classroom_id: classroomId,
		export: flag,
		from,
		to,
	});

export const enrollUserRequest = async (
	email: string,
	id: number,
	deduct: boolean | undefined
) =>
	await api.post('course/enroll', {
		email,
		id,
		deduct,
	});

export const UnEnrollUserRequest = async (
	email: string,
	id: number,
	refund: boolean | undefined
) =>
	await api.post('course/unenroll', {
		email,
		id,
		refund,
	});

export const getEducationalInfoRequest = async () =>
	await api.get('education/info');

type UpdatePrerequisitesRequestObjectType = {
	course_id: number | null;
	prerequisites: Array<{ path: number; prerequisite: number }>;
};
export const updatePrerequisitesRequest = async (
	reqObject: UpdatePrerequisitesRequestObjectType
) => {
	return api.post<UpdatePrerequisitesResponse>(
		'course/prerequisite/update',
		reqObject
	);
};

export const GetAllClassroomsEnrollmentsRequest = async () => {
	return api.get<ApiResponse<ClassroomEnrollment>>(
		'enrollment/classrooms/count'
	);
};

export const GetClassroomUnitsAnalyticsRequest = async (id: number) => {
	return api.post<ApiResponse<ClassroomUnitsAnalytics>>(
		'classroom/analytics/time',
		{
			classroom_id: id,
		}
	);
};

export const GetCourseUnitsAnalyticsRequest = async (id: number) => {
	return api.post<ApiResponse<CourseUnitsAnalytics>>('enrollment/course/time', {
		course_id: id,
	});
};

export const GetAllClassroomsAnalyticsDatatableRequest = async (
	payload: DatatablePayload
) => {
	return api.post<ApiResponse<ClassroomEnrollment[]>>(
		'enrollment/classrooms/analytic',
		payload
	);
};

export const GetClassroomAnalyticsDatatableRequest = async (
	payload: ClassroomDatatablePayload
) => {
	return api.post<ApiResponse<CourseEnrollment[]>>(
		'enrollment/classroom/analytic',
		payload
	);
};
export const ExportAllClassroomsAnalyticsRequest = async (
	payload: DatatablePayload
) => {
	return api.post<string>('enrollment/classrooms/analytic', {
		...payload,
		export: true,
	});
};

export const ExportClassroomAnalyticsRequest = async (
	payload: ClassroomDatatablePayload
) => {
	return api.post<string>('enrollment/classroom/analytic', {
		...payload,
		export: true,
	});
};

export const GetAllClassroomsGraphRequest = async (
	payload: AllClassroomsGraphPayload
) => {
	return api.post<ApiResponse<GraphSlice[]>>(
		'enrollment/classrooms/analytic/graph',
		payload
	);
};

export const GetClassroomEnrollmentsRequest = async (id: number) => {
	return api.post<ApiResponse<ClassroomEnrollment>>(
		'enrollment/classroom/count',
		{
			classroom_id: id,
		}
	);
};

export const GetClassroomGraphRequest = async (
	payload: ClassroomGraphPayload
) => {
	return api.post<ApiResponse<GraphSlice[]>>(
		'enrollment/classroom/analytic/graph',
		payload
	);
};

export const GetCourseEnrollmentsRequest = async (id: number) => {
	return api.post<ApiResponse<CourseEnrollment>>('enrollment/course/count', {
		course_id: id,
	});
};

export const GetCourseGraphRequest = async (payload: CourseGraphPayload) => {
	return api.post<ApiResponse<GraphSlice[]>>(
		'enrollment/course/analytic/graph',
		payload
	);
};
