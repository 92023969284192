import MainTable from '@Components/MainTable';
import NoResults from '@Components/NoResults/NoResults';
import { deleteClassroomRequest } from '@Services/classrooms/classrooms.req';
import { getAllClassrooms } from '@Features/classrooms/classroomSlice';
import { useTypedSelector } from '@Features/store';
import useConfirmModal from '@Hooks/useConfirmModal';
import { usePageLoader } from '@Providers/LoadingProvider';
import { useSnackbar } from '@Providers/useSnackbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { exportClassrooms } from '@Features/classrooms/exportSlice';
import TableCell from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { SpeedDial, SpeedDialAction, Tooltip } from '@mui/material';
import styled from 'styled-components';
import { TableHeader } from '@Features/generics/generics.type';
import { MainTableRow } from '@Components/MainTable/MainTable';
import AddClassroomButton from './Components/AddClassroomButton';
import { TooltipTitle } from '@Utils/TooltipTitle';
import { onPageChangeRequestMeta } from '@Components/MainTable/MainTable.types';
import EdConfirmModal from '@Components/UI/Modals/EdConfirmModal/EdConfirmModal';
import EdActiveStatus from '@Components/UI/Utilities/EdActiveStatus/EdActiveStatus';
import { MoreVert } from '@mui/icons-material';

const TABLE_HEADS: Array<TableHeader> = [
	{ displayName: 'TITLE', fieldName: 'title' },
	{ displayName: 'TYPE', fieldName: 'type' },
	{ displayName: 'YEAR', fieldName: 'education_years', canSort: false },
	{ displayName: 'STATUS', fieldName: 'status' },
	{
		displayName: 'ADMISSION',
		fieldName: 'has_admission',
		canSort: false,
	},
];

export interface match<P> {
	params: P;
	isExact: boolean;
	path: string;
	url: string;
}

const ClassRoomsTable: React.FC = () => {
	/* hooks */
	const dispatch = useDispatch();
	const history = useHistory();
	const { pathname } = useLocation();
	const { displaySnackbar } = useSnackbar();
	const [currentClassroomLabel, setCurrentClassroomLabel] = useState('');
	const { setIsLoading, isLoading } = usePageLoader();
	const [openModel, setOpenModel] = useState(false);
	const [flag, setFlag] = useState(false);
	/* selectors */
	const {
		errors,
		loaders,
		classrooms = [],
		lastPage,
		totalResults,
		perPage,
		page,
		classroomFilters,
	} = useTypedSelector((state) => state.classroom);

	const eduYears = [
		{ name: 'First_Secondary', color: '#C7C5F4', breif: ' 1st' },
		{ name: 'Second_Secondary', color: '#A7A2F1', breif: '2nd' },
		{ name: 'Third_Secondary', color: '#5AC0FC', breif: '3rd' },
		{ name: 'Grade_10', color: '#6C63FF', breif: 'G10' },
		{ name: 'Grade_11', color: '#FF8532', breif: 'G11' },
		{ name: 'Grade_12', color: '#FFD037', breif: 'G12' },
		{ name: 'Third_Preparatory', color: '#5AC0FC', breif: '3rd' },
	];

	const handleModelOpen = (label: string) => {
		setOpenModel(true);
		setCurrentClassroomLabel(label);
	};

	const handleModelClose = (close: boolean, confirm?: boolean) => {
		if (confirm) {
			deleteClassroomRequest(currentClassroomLabel)
				.then(({ data }) => {
					setShow(false);
					displaySnackbar('success', data?.message);
					dispatch(
						getAllClassrooms({
							page: 1,
							perPage: 10,
							filters: [],
							sortBy: '',
							query: '',
						})
					);
				})
				.catch((e) => {
					const msg = e.response?.data.message || 'unable to delete';
					setShow(false);
					displaySnackbar('error', msg);
				});
		}
		setOpenModel(false);
		// setDeletedLabel(null);
	};

	const { setShow } = useConfirmModal({
		withLoader: true,
		onConfirm: () => {
			deleteClassroomRequest(currentClassroomLabel)
				.then(({ data }) => {
					setShow(false);
					displaySnackbar('success', data?.message);
					dispatch(
						getAllClassrooms({
							page: 1,
							perPage: 10,
							filters: [],
							sortBy: {},
							query: '',
						})
					);
				})
				.catch((e) => {
					const msg = e.response?.data.message || 'unable to delete';
					setShow(false);
					displaySnackbar('error', msg);
				});
		},
		message: 'Are you sure you want to delete this item',
	});

	const onPageChangeRequest = useCallback(
		({
			activePage,
			endDate,
			filters,
			query,
			sortBy,
			startDate,
			perPage,
		}: onPageChangeRequestMeta) => {
			dispatch(
				getAllClassrooms({
					page: activePage,
					perPage: perPage ?? 10,
					sortBy,
					filters,
					query,
					from: startDate,
					to: endDate,
				})
			);
			if (flag) {
				dispatch(exportClassrooms({ from: startDate, to: endDate }));
				setFlag(false);
			}
		},
		[dispatch, flag]
	);

	const handleTableItemClick = useCallback(
		(id: string) => () => {
			history.push({
				pathname: `/dashboard/classrooms/classroom/${id}/edit`,
				hash: '#info',
			});
		},
		[history]
	);

	const _handlePreviewCoursesClick = useCallback(
		(id: string, name: string, crid: number) => {
			history.push(`${pathname}/classroom/${id}`);
		},
		[history]
	);

	useEffect(() => {
		// if (!isLoading && loaders.getAll === true) setIsLoading(true);
		// if (isLoading && loaders.getAll === false) setIsLoading(false);
		if (isLoading && loaders.getAll === undefined) {
			// setIsLoading(false);
			displaySnackbar('error', errors.getAll);
		}
	}, [loaders.getAll, setIsLoading, isLoading, displaySnackbar, errors.getAll]);

	return (
		<>
			<EdConfirmModal
				text="Are you sure you want to delete this item"
				open={openModel}
				handleClose={handleModelClose}
			/>
			<MainTable
				onPageChangeRequest={onPageChangeRequest}
				totalPages={lastPage}
				tableFilters={classroomFilters}
				total={Number(totalResults)}
				RowsperPage={Number(perPage)}
				pageNum={Number(page)}
				tableHeads={TABLE_HEADS}
				renderItems={() => {
					if (classrooms.length <= 0)
						return (
							<MainTableRow>
								<TableCell colSpan={TABLE_HEADS.length}>
									<NoResults />
								</TableCell>
							</MainTableRow>
						);
					return classrooms.map((cr) => {
						return (
							<MainTableRow
								key={cr.label}
								hover
								sx={{
									'&:active': { backgroundColor: '#6c63ff' },
								}}
							>
								<TableCell
									sx={{
										fontFamily: 'Roboto',
										fontSize: '1rem',
										padding: '0 1.063rem 0',
										whiteSpace: 'nowrap',
										textOverflow: 'ellipsis',
										overflow: 'hidden',
									}}
									onClick={() =>
										_handlePreviewCoursesClick(cr.label, cr.title, cr.id)
									}
								>
									<a title={cr.title}> {cr.title} </a>
								</TableCell>

								<TableCell
									sx={{
										fontFamily: 'Roboto',
										fontSize: '1rem',
										padding: '0 1.063rem 0',
										whiteSpace: 'nowrap',
									}}
									onClick={() =>
										_handlePreviewCoursesClick(cr.label, cr.title, cr.id)
									}
								>
									{cr.type}
								</TableCell>

								<TableCell
									sx={{
										padding: '0 1.063rem 0',
										whiteSpace: 'nowrap',
									}}
									onClick={() =>
										_handlePreviewCoursesClick(cr.label, cr.title, cr.id)
									}
								>
									<Tooltip
										title={TooltipTitle(cr?.educationYears)}
										placement="top"
										sx={{ marginBottom: '-10px' }}
									>
										<StyledAvatarGrouped max={2}>
											{cr?.educationYears.map((edu: any) =>
												eduYears.map(
													(i: any) =>
														i.name === edu.name && (
															<Avatar
																alt="education year"
																key={edu.id}
																sx={{
																	backgroundColor: `${i.color}`,
																	fontSize: '10px',
																	fontFamily: 'Roboto',
																}}
															>
																{i.breif}
															</Avatar>
														)
												)
											)}
										</StyledAvatarGrouped>
									</Tooltip>
								</TableCell>

								<TableCell
									sx={{
										fontFamily: 'Roboto',
										fontSize: '1rem',
										padding: '0 1.063rem 0',
										whiteSpace: 'nowrap',
									}}
									onClick={() =>
										_handlePreviewCoursesClick(cr.label, cr.title, cr.id)
									}
								>
									{cr.status}
								</TableCell>
								<TableCell
									sx={{
										padding: '0 1.063rem 0',
										whiteSpace: 'nowrap',
									}}
									onClick={() =>
										_handlePreviewCoursesClick(cr.label, cr.title, cr.id)
									}
								>
									<EdActiveStatus active={cr.has_admission} />
								</TableCell>
							
							</MainTableRow>
						);
					});
				}}
			/>
		</>
	);
};

export default React.memo(ClassRoomsTable);

export const StyledAvatarGrouped = styled(AvatarGroup)`
	.MuiAvatar-root {
		width: 30px;
		height: 30px;
		font-size: 10px;
	}
`;

export const StyleAddBtn = styled(Button)`
	background: ${(props) => props.theme.palette.slateBlue.main};
	&:hover {
		background: ${(props) => props.theme.palette.slateBlue[500]};
	}
`;

export const StyleEditeIcon = styled(EditIcon)`
	color: ${(props) => props.theme.palette.darkPurple.main};
	&:hover {
		color: ${(props) => props.theme.palette.mayaBlue.main};
	}
`;

export const StyleDeleteIcon = styled(DeleteIcon)`
	color: ${(props) => props.theme.palette.darkPurple.main};
	&:hover {
		color: ${(props) => props.theme.palette.mayaBlue.main};
	}
`;
